import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/modules/faq/faq.vue"),
    meta: { noHeader: false },
    children: [
      {
        path: "",
        name: "overview",
        component: () => import("@/modules/faq/faq-overview.vue"),
        meta: { title: "Delivery Drivers Inc | FAQ" },
      },
      {
        path: ":subdir",
        name: "subdir",
        component: () => import("@/modules/faq/faq-subdir.vue"),
        meta: { title: "Delivery Drivers Inc | FAQ" },
      },
    ],
  },
  {
    path: "/faq/directDeposit",
    name: "DirectDepositFaq",
    // beforeEnter: locationValidationGuard,
    component: () => import("@/modules/faq/DirectDepositFaq.vue"),
    meta: {
      title: "FAQS | Direct Deposit",
      layout: "one",
      step: 'DD',
    },
  },
  
  {
    path: "/faq/branch",
    name: "BranchFaq",
    // beforeEnter: locationValidationGuard,
    component: () => import("@/modules/faq/BranchFaq.vue"),
    meta: {
      title: "FAQS | Branch",
      layout: "one",
      step: 'branch',
    },
  },

  {
    path: "/faq/OnHoldFaq",
    name: "OnHoldFaq",
    component: () => import("@/modules/faq/OnHoldFaq.vue"),
    meta: {
      title: "FAQS | Enrollment OnHold",
      layout: "one",
      step: 'HoldFaq',
    },
  },
] as Array<RouteRecordRaw>;
