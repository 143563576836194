
import { defineComponent, ref, onMounted } from "vue";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import { IconArrowLeft,IconArrowRight } from "@/components";
import { DateTime } from "luxon";
import Loader from "@/components/modals/Loader.vue";
import { useRoute,useRouter } from "vue-router";


export default defineComponent({
  name: "OneSummary",
  components: { IconArrowLeft,IconArrowRight, Loader },
  setup() {
    const startDate = ref();
    const endDate = ref();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const depositSummaries: any = ref([]);
    const depositRemarks=ref("");
    const showRemarks=ref(false);
    onMounted(() => {
        startDate.value = formatDate(route.query.startDate);
        endDate.value = formatDate(route.query.endDate);

        proxyApi.post("/getDepositSummariesRemarks", encodeWithParam({ startDate: route.query.startDate, endDate: route.query.endDate }))
        .then(({ data }) => {
          depositRemarks.value = data.result[0]?.memo;
          if(depositRemarks?.value?.length>0)
          {
            showRemarks.value=true;
          }
        })
        getEarningsBreakdown({startDate: route.query.startDate,endDate:route.query.endDate})
    });

    const dayBreakup = (sDate)=>{
      router.push({name: "OneDaySummary",query:{startDate: route.query.startDate,endDate: route.query.endDate,selectedDate:sDate, type: "ONE"}})
    }

    const getEarningsBreakdown = (dates) => {
      isLoading.value = true;
      proxyApi
        .post("/getOneEarningForPayPeriod", encodeWithParam(dates))
        .then(({ data }) => {
          depositSummaries.value = data.result.map((summary)=>{
            return {
                postedDate: formatDate(summary.postedDate.split("T")[0]),
                totalAmount: summary.totalAmount,
                unformattedDate: summary.postedDate.split("T")[0]
            }
          });
          isLoading.value = false;
        })
        .catch((e: any) => {
          isLoading.value = false;
        });
    };


    
    const formatDate = (date) => {
      return DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("dd MMM yyyy");
    }
    
    const formatDatePacific = (date) => {
      return DateTime.fromFormat(date, "yyyy-MM-dd").setZone("America/Los_Angeles").toFormat("dd MMM yyyy");
    }

    return {
      formatDate,
      formatDatePacific,
      startDate,
      endDate,
      isLoading,
      getEarningsBreakdown,
      depositSummaries,
      dayBreakup,
      depositRemarks,
      showRemarks
    };
  },
  methods: {

    formatCurrency(amount, space) {
      amount == undefined || isNaN(amount) ? (amount = 0) : amount;
      if (!space) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
      } else {
        const formattedMoney = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
        return `${formattedMoney.substring(0, 1)} ${formattedMoney.substring(
          1,
          formattedMoney.length
        )}`;
      }
    },
    isMobile() {
      return checklistMethods.isMobile();
    },
  },
});
