
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import router from "@/router";
import { setStatus } from "@/services/stage.service";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { defineComponent, ref, onMounted } from "vue";
import checklistMethods from "./components/Checklist/Helpers/Index";
export default defineComponent({
  name: "LocationUpdate",
  setup() {
    const domainURL = ref("");
    onMounted(async () => {
      proxyApi.post("/validateLocationStatus", encodeWithParam({ clientLocationID: state.user.locationId})).then(async(data: any) => {
        const isRecruiting = data?.data?.result[0]?.isRecruiting
        let thresholdCheck:any= await proxyApi.post("/thresholdCheck", encodeWithParam({ clientLocationID: state.user.locationId }))
        if (isRecruiting && thresholdCheck.data.allowSignup) {
          proxyApi.post('/addApplicationStatus', encodeWithParam({ stepName: 'ELIGIBILITY', stepStatus: 'INCOMPLETE' }));
          setStatus("ELIGIBILITY", "INCOMPLETE")
          proxyApi.post('/addApplicationStatus', encodeWithParam({ stepName: 'ELIGIBILITY', stepStatus: 'COMPLETE' }));
          setStatus("ELIGIBILITY", "COMPLETE")

          if (checklistMethods.isMobile()) {
            router.push({
              name: 'checklist',
              params: { step: "personalDetails" },
              query: {
                locationId: state.user.locationId,
                name: state.user.locationName
              },
            })
          } else {
            router.push({
              name: 'personalDetails',
              query: {
                locationId: state.user.locationId,
                name: state.user.locationName
              }
            })
          }
        } else {
          domainURL.value = `${location.origin}/login`
        }
      })

    })
    return { domainURL }
  }
})

