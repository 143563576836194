
import { defineComponent, ref, onMounted } from "vue";
import PendingApplication from "@/modules/signup/components/PendingApplication.vue";
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";
export default defineComponent({
  name: "PendingApplications",
  components: { PendingApplication },
  setup() {
    const pendingApplications={step:"COMPLETION",status:"COMPLETE"}

    onMounted(() => {
      gTagEvent("View Pending Application Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
    })
    
    return { 
      state,
      pendingApplications,
    };
  },
});
