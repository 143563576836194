
import { defineComponent, ref, Ref, onMounted, watch } from 'vue';
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import { proxyApi } from "@/http/http.config";
import { state } from '@/harlem/stores';
import { gTagEvent } from '@/util/GoogleAnalytics';

export default defineComponent({
  name: "GettingStartedResources",
  setup() {
    const videos = ref<{
      title: string;
      overlay: string;
      src: string;
    }[]>([]);

    const activeVideo: Ref<null | number> = ref(null);
    const activeItem = ref(0);

    let container: HTMLElement | null;

    function showVideoHandler(item, index) {
      gTagEvent(`Clicked Embedded Video: ${item}`, "Dashboard", `partner_id: ${state.user.partnerId}`)
      activeVideo.value = index;
    }

    function isMobile() {
      return checklistMethods.isMobile();
    }

    function scrollTo (index: number) {
      if(activeItem.value !== index) {
        activeVideo.value = null;
        activeItem.value = index;

        let selectedItem = document.getElementById(`carousel-item${index}`)
        if (container && selectedItem) {
          container.scroll({
            left: selectedItem.offsetLeft - container.offsetLeft,
            behavior: "smooth",
          });
        }
      }   
    }

    function debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func(...args); }, timeout);
      };
    }

    function handleScroll() {
      let containerLeft = (Math.round(container?.getBoundingClientRect().left ?? 0));
      let firstCarousalItem = document.getElementById('carousel-item0');

      let currentItem = Math.round(Math.abs((Math.round(firstCarousalItem?.getBoundingClientRect().left ?? 0) - containerLeft) / 240));
      if (activeItem.value != currentItem) {
        activeVideo.value = null;
        activeItem.value = currentItem
      }
    }

    const debouncedScrollHandle = debounce(handleScroll, 100);

    onMounted(() => {
      proxyApi.get('/getGettingStartedResources').then(({ data }) => {
        videos.value = data.result?.length > 0 ? JSON.parse(data.result[0].value)?.gettingStartedResources : [];
      })
    })

    watch(videos, () => {
      setTimeout(() => {
        container = document.getElementById('carousel-container');
        let firstCarousalItem = document.getElementById('carousel-item0');
        if (firstCarousalItem && container) {
          container.addEventListener('scroll', debouncedScrollHandle);
        }
      }, 100)
    })

    return {
      videos,
      activeVideo,
      showVideoHandler,
      isMobile,
      activeItem,
      scrollTo,
    }
  }
})

