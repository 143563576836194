
import { defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { decodeJwt } from "@/composables/user.composable";
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";
export default defineComponent({
  name: "DocumentVersions",
  components: { },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const originalId = route.params.id;
    const tokenData = decodeJwt();
    onMounted(() => {
      gTagEvent("`View Document Versions: ${originalId}`", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
    })

    return { state, router };
  },
});
