<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 27 27" fill="none">
      <path d="M14.0002 25.6666C20.4435 25.6666 25.6668 20.4433 25.6668 14C25.6668 7.55666 20.4435 2.33331 14.0002 2.33331C7.55684 2.33331 2.3335 7.55666 2.3335 14C2.3335 20.4433 7.55684 25.6666 14.0002 25.6666Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14 19.8333H14.0117" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.605 10.5C10.8793 9.72027 11.4207 9.06278 12.1333 8.64398C12.8459 8.22518 13.6837 8.07209 14.4984 8.21182C15.313 8.35156 16.0519 8.7751 16.5842 9.40744C17.1165 10.0398 17.4079 10.8401 17.4066 11.6667C17.4066 14 13.9066 15.1667 13.9066 15.1667" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  
  export default defineComponent({
    name: "IconHistory",
  });
  </script>