
import { IconArrowLeft, IconArrowRight, Snackbar, WalmartTaxConsentPolicy, TaxConsentFaq } from "@/components";
import Loader from "@/components/modals/Loader.vue";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { gTagEvent } from "@/util/GoogleAnalytics";
import MicroModal from "micromodal";
import { defineComponent, onMounted, reactive, ref } from "vue";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";

export default defineComponent({
    name: "Settings",
    components: { IconArrowLeft, IconArrowRight, Snackbar, WalmartTaxConsentPolicy, Loader, TaxConsentFaq },
    methods: {
    isMobile() {
        return checklistMethods.isMobile();
        },
    },
    setup() {
        const consent = ref(false)
        const loading = reactive({
            pastTaxConsentRes: true,
        })
        const eTaxErr = ref(false)

        onMounted(() => {
            getTaxConsent();
            gTagEvent("View Settings Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
        })

        const snackbarSettings = reactive({
            showSnackbar: false,
            position: 'bottom-center',
        })

        const updateTaxConsent = () => {
            proxyApi.post('/taxConsent', encodeWithParam({
                taxConsent: consent.value,
                signedOn: new Date(),
            })).then((res) => {
                const isSuccess = res.data.success;
                if (isSuccess) {
                    gTagEvent("Update Paperless Tax Consent", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
                }
            })
        }

        const getTaxConsent = () => {
            proxyApi.get('/taxConsent').then(({ data }) => {
                const hasTaxConsentRes = data.result[0]
                if (hasTaxConsentRes) {
                    consent.value = data.result[0].tax_consent
                }
                
                loading.pastTaxConsentRes = false
            }).catch((err) => {
                console.log(err)
            })
        }

        const toggleConsent = () => {
            consent.value = !consent.value
            if (!snackbarSettings.showSnackbar) {
                snackbarSettings.showSnackbar = true
                setTimeout(() => snackbarSettings.showSnackbar = false, 1500);
            }
            updateTaxConsent();
        }

        const openModal = (modal: string) => {
            MicroModal.show(modal);
        };

        const closeModal = () => {
            MicroModal.close();
        };
        const walmartTaxConfirmation = (val) => {
      proxyApi.post('/taxConsent', encodeWithParam({
        taxConsent: val,
        signedOn: new Date(),
      })).then((res) => {
        const isSuccess = res.data.success;
        if (isSuccess) {
          eTaxErr.value = false
          gTagEvent("Update Paperless Tax Consent", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
        }
        MicroModal.close("walmart-tax-consent-policy")

      })
    }

        return {
            consent,
            toggleConsent,
            snackbarSettings,
            openModal,
            closeModal,
            loading,
            walmartTaxConfirmation,
            eTaxErr
        }
    }
});
