
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
} from "vue";
import { Form, Field, configure } from "vee-validate";
import {
  FieldError,
  StateSelectorValidated,
  TextFieldValidated,
} from "@/components";
import * as yup from "yup";
import { state } from "@/harlem/stores";
import Autocomplete from "vue3-autocomplete";
import { isCountyRequired } from "@/util/AddressUtilities";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { gTagEvent } from "@/util/GoogleAnalytics";
export default defineComponent({
  name: "AddressFieldsOne",
  components: {
    FieldError,
    StateSelectorValidated,
    TextFieldValidated,
    Field,
    Form,
    Autocomplete,
  },
  emits: ["save-details", "cancel-edit"],
  props: {
    addressData: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: "",
          address1: "",
          address2: "",
          state: "",
          city: "",
          county: "",
          zipcode: ""
        }
      }
    },
  },
  setup(props, { emit }) {
    configure({
      validateOnModelUpdate: true,
    });

    const selectedState = ref('')
    const addressDisable = ref(true);
    const autoComplete = ref<InstanceType<typeof Autocomplete>>();
    const stateSelected = ref<InstanceType<typeof StateSelectorValidated>>();
    const suggestionText = ref(true);
    const warningText = ref(false);
    const restrictedAddress = ref(["PO","BOX","PO B","PO BO","P.O. BOX", "P.O BOX","PO. BOX", "POBOX" ,"PO BOX","PO    BOX","P.O BO","PO. B","P O Box"," PO  BOX","P.O. BOX","PO BOX","PO  B","PO  BO","P O BOX","P O B","P OB","P O B","P.O. B","PO B","P O","P O"])
    let addressSuggestions: any = ref([]);
    const partnerData = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined;
    };
    partnerData.address1 = "";
    partnerData.address2 = "";
    partnerData.city = "";
    partnerData.state = "";
    partnerData.county = "";
    partnerData.zip = "";
    partnerData.addressID = "";
    const initialTouched = reactive({}) as { [key: string]: boolean };

    onMounted(async () => {
      partnerData.address1 = props.addressData.address1 || '';
      partnerData.address2 = props.addressData.address2 || '';
      partnerData.city = props.addressData.city || '';
      partnerData.state = props.addressData.state || '';
      partnerData.county = props.addressData?.county || '';
      partnerData.zip = props.addressData.zipcode || '';
      partnerData.addressID = props.addressData.id || '';
      selectedState.value = props.addressData?.state || '';

    });
    const schema = yup.object().shape({

      address1: yup.string().trim().required("Street address is required").label("Street Address"),
      address2: yup.string().trim().label("Address"),
      city: yup.string().trim("City can't contain white space at the start or end").strict(true).matches(/^[aA-zZ' ]+$/g, "Enter a valid city").required("City is required").label("City"),
      state: yup.string().trim().required("State is required").label("State"),
      county: yup
        .string()
        .trim("County can't contain white space at the start or end")
        .strict(true)
        .matches(/^[aA-zZ ]+$/g,"Invalid character in the county")
        .when("state", {
          is: (val: string) => isCountyRequired(val),
          then: yup.string().min(4,"Enter a valid county ").required().label("County"),
          otherwise: yup.string().notRequired().label("County"),
        }),
      zip: yup
        .string()
        .trim()
        .required("ZIP code is required")
        .min(5, "Zip Code must be 5 digits")
        .matches(/^(?!00000)\d{5}(?:[-\s]\d{4})?$/g, "Zipcode is required") //Zipcode is required
        .label("Zip Code"),
    });

    let addressSelectedFromSmarty = ref(false);
    const getAddressSuggestion = (searchText: string) => {
      if (searchText.length === 0) {
        suggestionText.value = true;
        warningText.value = false;
      } else {
        suggestionText.value = false;
        warningText.value = true;
      }
      const regex = new RegExp(/^(PO.*BOX*)/);
      if (!restrictedAddress.value.includes(searchText.trim().toUpperCase()) && searchText.trim().toUpperCase().search(regex) === -1) {
        let searchParams = encodeWithParam({ search: searchText })
        proxyApi
          .post("/getAddressSuggestions", searchParams)
          .then(({ data }: any) => {
            if (data.result.length > 0) {
              addressSuggestions.value = data.result.map((item) => {
                return {
                  name: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
                  item,
                };
              });
            } else {
              addressSuggestions.value = [
                {
                  name: "Please enter a valid address",
                  item: {},
                },
              ];
              //addressDisable.value = false;
            }
          }).catch(() => {

            addressSuggestions.value = [
              {
                name: "Please enter a valid address",
                item: {},
              },
            ];
            //addressDisable.value = false;

          });
      } else {
        addressSuggestions.value = [];
        if (searchText.length === 0 && addressSelectedFromSmarty.value) {
          document.getElementById('address1')?.blur();
          partnerData.address1 = "";
          partnerData.address2 = "";
          partnerData.city = "";
          partnerData.state = "";
          partnerData.zip = "";
          partnerData.county = "";
          partnerData.countyRegistered = "";
          selectedState.value = '';
          stateSelected.value?.onChangeState(" ");
        }
      }
    };
    const autoCompleteError = (errors: any) => errors.address1 ? ['has-error'] : []
    const updateStateValue = (e: any, setFieldError: any) => {
      selectedState.value = e.target.value
      if (selectedState.value === 'CA') {
        setFieldError('county', 'County is required')
      } else {
        setFieldError('county', '')
      }
      partnerData.state = selectedState.value;
    }
    const addressSuggestionComputed = computed(() => addressSuggestions.value);
    const selectedAddress = (selectedAddress: any, validate: any, setFieldValue: any) => {
      suggestionText.value = false;
      warningText.value = false;
      setFieldValue('address2', '');
      setFieldValue('city', '');
      setFieldValue('county', '');
      setFieldValue('state', '');
      setFieldValue('zip', '');
      if (Object.keys(selectedAddress.item).length > 0) {
        setFieldValue('address1', selectedAddress.item.streetLine);
        setFieldValue('address2', selectedAddress.item.secondary);
        setFieldValue('city', selectedAddress.item.city);
        setFieldValue('state', selectedAddress.item.state);
        setFieldValue('zip', selectedAddress.item.zipcode);
        partnerData.address1 = selectedAddress.item.streetLine;
        partnerData.address2 = selectedAddress.item.secondary;
        partnerData.city = selectedAddress.item.city;
        partnerData.state = selectedAddress.item.state;
        partnerData.zip = selectedAddress.item.zipcode;
        autoComplete.value?.setText(selectedAddress.item.streetLine);
        // addressDisable.value = false;
        stateSelected.value?.onChangeState(selectedAddress.item.state);
        selectedState.value = selectedAddress.item.state;
        addressDisable.value = true;
        addressSelectedFromSmarty.value = true;
        validate();
      } else {
       // addressDisable.value = false;

        partnerData.address1 = "";
        partnerData.address2 = "";
        partnerData.city = "";
        partnerData.state = "";
        partnerData.zip = "";
        partnerData.county = "";
        partnerData.countyRegistered = "";
      }
    };
    const formData = ref({})
    const saveDetails = (data) => {
      formData.value = data
      console.log("form submited", formData.value)
    }

    const cancel = () => {

      emit("cancel-edit");
    }

    const address2 = (event) => {
      partnerData.address2 = event.target.value;
    }

    const updateCity = (event) => {
      partnerData.city = event.target.value;
    }

    const updateCounty = (event) => {
      partnerData.county = event.target.value;
    }

    const updateZip = (event) => {
      partnerData.zip = event.target.value;
    }

    const saveAddressDetails = () => {
      if (validateAddress(partnerData)) {
        let obj = {
          address1: partnerData.address1,
          address2: partnerData.address2,
          state: partnerData.state,
          city: partnerData.city,
          postalCode: partnerData.zip,
          country: "US",
          addressID: props.addressData.id,
          county: partnerData.state == "CA" ? partnerData.county : null
        }
        proxyApi.post("/updateAddress", encodeWithParam(obj)).then(() => {
          gTagEvent("Update Address", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
          emit("save-details");
        })
      }
    }

    const validateAddress = (data) => {
      if (data?.state == "CA")
        return data.address1 && data.city && data.county && data.zip && data.state
      return data.address1 && data.city && data.zip && data.state
    }

    const letterOnly = (e) => {
      if(e.key === 'Backspace') return true
       if(e.key === ' ') return true
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();      
    }
    
    const inzip = ref(" ")

    proxyApi.get("/getFullProfileInfo").then(({ data }) => {
        if (data.profile.length) {
          partnerData.zip = data.profile[0].postalCode;}})


    return {
      saveDetails,
      address2,
      saveAddressDetails,
      cancel,
      initialTouched,
      partnerData,
      state,
      schema,
      addressSuggestions,
      getAddressSuggestion,
      selectedAddress,
      addressDisable,
      autoComplete,
      stateSelected,
      selectedState,
      autoCompleteError,
      addressSuggestionComputed,
      suggestionText,
      warningText,
      updateStateValue,
      updateZip,
      updateCounty,
      updateCity,
      inzip,
      letterOnly
    };
  },
});
