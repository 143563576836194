
import { defineComponent, onMounted, ref } from 'vue';
import IconWalmartX from "@/components/icons/IconWalmartX.vue";
export default defineComponent({
    name: "FunnelToast",
    components: {
        IconWalmartX
    },
    props: {
        toastBody: {
            required: true,
            type: String
        },
    },
    setup() {
        const isClosed = ref(false);
        const isLoading = ref(true)
        const hasUserClosed = () => {
            isLoading.value = false
        }

        const closeToast = () => {
            console.log("Save close response to DB when user clicks X: PENDING")
        }

        onMounted(() => {
            hasUserClosed()
        })

        return {closeToast, isLoading, isClosed}
    }
})
