
import { defineComponent, onMounted } from "vue";
import { IconAbout } from "@/components"
import { gTagEvent } from "@/util/GoogleAnalytics";
import { state } from "@/harlem/stores";
export default defineComponent({
    name: "PendingBackgroundCheck",
    components: {
        IconAbout
    },
    emits: ['exit'],
    setup(props, {emit}) {
        const closeModal = () => {
            document.body.style.overflow = 'auto'
            emit('exit')
        }
        onMounted(() => {
            gTagEvent("View Pending Background Check Popup", "Earnings", `partner_id: ${state.user.partnerId}`)
        })

        return {closeModal}
    }
});
