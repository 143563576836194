
import { defineComponent,onMounted,ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { proxyApi } from "@/http/http.config";
// import { state } from "@/harlem/stores";
// import { reset } from "@harlem/plugin-reset";
// import { gTagEvent } from "@/util/GoogleAnalytics";
// import CommonService from "@/services/common.service";

export default defineComponent ({
  name: "enroll",
  data(){
    return{
      autoPlay: false,
      loop: false,
      controls: true,
      muted: false,
    };
  },
setup() {
    const route = useRoute();
    const router = useRouter();
    const locationId = route.query.locationId;
    const locationName = route.query.name;
    const campaign = route.query?.campaign || null;
    const medium = route.query?.medium || null;
    const videos=ref<string>('');
    localStorage.clear();
    sessionStorage.removeItem("signupData");
		const enrollNow = () => {          
			router.push({ name: 'signup',
      query: {
            locationId: locationId,
            name: locationName,
            campaign: campaign,
            medium: medium,
          }
        })
		}
    const urlCheck=()=>{
      if(!route.query['locationId'] && !route.query['name']){
       return router.push({ name: "login" });
      }
      if(!route.query['name']){
        return router.push({ name: "login" });
      }
    }

    onMounted(() => {
      urlCheck();
      proxyApi.get('/getEnrollementResources').then(({ data }) => {
        videos.value= data.result[0].value;
      })
      
    });

    return {
      		enrollNow,
          videos
    }
},
});
