<template>
  <div class="container" v-if="paystubObj.netAmount">
    <div class="content">
      <div class="page-actions">
        <div class="action-left">
          <a class="back" href="javascript:history.back()">
            <svg v-svg="'icon-arrow-left'" size="0 0 18 18" />
            {{ payPeriod }}
          </a>
        </div>
      </div>
      <div id="pdfContent">
      <div class="logo-and-line" v-if="showRemarks">
        <div>
          <img class="deposit-remarks-img" src="@/assets/Notes.svg" alt="Deposit Remarks" />
        </div>
        <div class="vertical-dotted-line"></div>
        <div class="deposit-remarks">{{ depositRemarks }}</div>
      </div>
      <div class="earnings-grid deposit-summary-container margin-t-medium" >
        <div>
          <h4>{{ paystubObj.clientLocation }}</h4>
          <div class="flex label-group">
            <p>Check Date</p>
            <p class="margin-l-30pc">Owner</p>
          </div>
          <div class="flex">
            <p>{{ displayDate(paystubObj.checkDate) }}</p>
            <p class="margin-l-30pc">{{ paystubObj.partner.name }}</p>
          </div>
        </div>
        <p class="net-income-heading">Earnings</p>
        <div class="gray-bg module-large total-amount">
          <div class="detail-earning-main">
            <div class="details-header">
              <span>QTY</span>
              <span class="details-type">TYPE</span>
              <span>AMOUNT</span>
            </div>
            <div class="detail-earning" v-if="paystubObj.credits.length > 0">
              <ul v-for="item in paystubObj.credits" :key="item.id">
                <li>
                  <div class="detail-earning-title ">
                    <span>{{item?.count}}</span>  
                    <span style="padding-left:38px">{{ item?.name ? formatLineItems(item.name) : "" }}</span>
                  </div>
                  <div>{{ formatCurrency(item.amount) }}</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="border-line"></div>
          <h5
            class="net-total bold-text"
          >
            Total
            <span> {{ formatCurrency(paystubObj.netAmount) }}</span>
          </h5>
        </div>
      </div>
    </div>
      <div class="flex margin-t-large">
        <a @click="exportToPDF" class="button" style="margin-right:24px">
          Download
        </a>
        <a :href="mailtoLink" class="button">
          Email
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.deposit-summary-container .single-container .detail-prices {
  margin-left: 40px;
  width: 60%;
}
.net-income-heading {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  margin-left: 16px;
}
.deposit-summary-container .single-container .detail-prices .wide {
  width: 20%;
}
.deposit-summary-container .single-container {
  margin-top: 30px;
}
h5 span {
  float: right;
}
.bold-text {
  font-weight: 600;
}
.pay-period {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 32px;
}
.detail-earning-main {
  font-size: 16px;
  color: #3B4459;
}
.detail-earning  li {
  display: flex;
  justify-content: space-between;
    color: #3B4459;
}
.details-header {
  display: flex;
  margin-bottom: 10px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  color: #8D95AA;;
}
.details-type {
  padding-left: 17px;
  width: 100%;
}
.detail-earning .detail-earning-title  {
  width: 100%;
}
.earnings-grid > div {
  padding: 16px 18px !important;
  margin-bottom: 40px;
}
.border-line {
  border: 1px solid #EBEBEb;
  margin-bottom: 24px;
}
ul {
  padding-left: 0 !important;
}

.vertical-dotted-line {
    border-left: 1px dotted #333333; 
    height: 40px;
    margin-left: 10px;
}
.logo-and-line {
    display: flex; 
    align-items: center;
    margin-left: 5px;
    margin-bottom: 20px;
}

.deposit-remarks{
  margin-left: 5px;
  font-family: "Rubik";
  word-spacing: 1px;
  line-height: normal;
}

.deposit-remarks-img{
  height: 58px !important;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .deposit-summary-container .single-container .detail-prices .wide {
    width: 50%;
  }
  .deposit-summary-container .single-container .detail-prices .right-para p {
    font-size: 15px;
  }
  .deposit-summary-container .single-container .detail-prices {
    margin-left: 0px;
    width: 100%;
  }
  .detail-earning .detail-earning-title {
    width: 70%;
  }
}
</style>

<script>
import { computed, defineComponent, inject, ref, onMounted } from "vue";
import { useRoute,useRouter } from "vue-router";
import { DateTime } from "luxon";
import html2pdf from "html2pdf.js";
import { StoreStateSymbol } from "../../store/providers/state.provider";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";

export default defineComponent({
  name: "OneDaySummary",
  setup() {
    const store = inject(StoreStateSymbol);
    const route = useRoute();
    const router = useRouter();
    let paystubObj = ref({});
    const payPeriod = ref("");
    const depositRemarks=ref("");
    const showRemarks=ref(false)
    onMounted(() => {
      singleDepositSummary();
      proxyApi.post("/getDepositSummariesRemarks", encodeWithParam({ startDate: route.query.startDate, endDate: route.query.endDate }))
      .then(({ data }) => {
          depositRemarks.value = data.result[0]?.memo;
          if(depositRemarks?.value?.length>0)
          {
            showRemarks.value=true;
          }
        })
    });

    const singleDepositSummary = () => {
      if (route.query.type === "ONE") {
      proxyApi
        .post(
          "/getOneEarningsDayDetails",
          encodeWithParam({ selectedDate: route.query.selectedDate })
        )
        .then(({ data }) => {
          payPeriod.value = `${formatDate(route.query.startDate)} - ${formatDatePacific(route.query.endDate)}`;
          depositSummaryData(data.result);
        });
      } else if (route.query.type === "ACH") {
        proxyApi
          .post("/getACHEarningsDetails", encodeWithParam({ startDate: route.query.startDate, endDate: route.query.endDate })
        )
        .then(({ data }) => {
          payPeriod.value = `${formatDate(route.query.startDate)} - ${formatDatePacific(route.query.endDate)}`;
          depositSummaryData(data.result);
        })
      }
    };


    const depositSummaryData = async (depositResult) => {

      let totalAmount = depositResult.reduce((accumulator,currentValue) => parseFloat(accumulator)+ parseFloat(currentValue.totalAmount),0);
      let mappingKey = (await proxyApi.get("/mappingKeyDetails"))?.data?.result.reduce((acc, item) =>  {
        const obj = { ...acc }
        obj[item.mappingKey] = item.name
        return obj
      },{})
      let credits = depositResult.map((item,index)=>{
        return {
            id: index,
            name: mappingKey[item.mappingKey],
            amount: item.totalAmount,
            count: item.count
        }

      })
      depositResult.forEach((item) => {
        let customObj = {
          startDate: route.query.startDate,
          endDate: route.query.endDate,
          netAmount: totalAmount,
          //businessIncome: item.check.BUSINESS_INCOME,
          credits: credits,
        //  withholdings: item.check.WITHHOLDING,
          checkDate: route.query.selectedDate || DateTime.fromISO(route.query.endDate).startOf("day").plus({ days: 2 }),
          clientLocation: state.user.locationName,
          partner: {
            name: `${state.user.firstName} ${state.user.lastName}`,
            email: state.user.email,
          },
        };
        paystubObj.value = customObj;
      });
    };
    const exportToPDF = () => {
      html2pdf(document.getElementById("pdfContent"), {
        margin: 1,
        filename: "paystub.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
    };
    const mailtoLink = computed(
      () => `mailto:${state?.user?.email ? state.user.email : ""}?subject=
    Direct Deposit Summary&body=
    Follow this link to view and download your deposit summary.${
      window.location.href
    }\``
    );
    
    const formatDate = (date) => {
      return DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("dd MMM yyyy");
    }

    const formatDatePacific = (date) => {
      return DateTime.fromFormat(date, "yyyy-MM-dd").setZone("America/Los_Angeles").toFormat("dd MMM yyyy");
    }

    return { paystubObj, exportToPDF, store, mailtoLink, payPeriod,depositRemarks,showRemarks };
  },
  methods: {
    displayDate(date) {
      return DateTime.fromISO(date).toFormat("d LLL y");
    },
    formatCurrency(amount, space) {
      amount == undefined || isNaN(amount) ? (amount = 0) : amount;
      if (!space) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
      } else {
        const formattedMoney = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
        return `${formattedMoney.substring(0, 1)} ${formattedMoney.substring(
          1,
          formattedMoney.length
        )}`;
      }
    },
    formatLineItems(item) {
      return item.split(":").length > 1
        ? item.split(":")[item.split(":").length - 1]
        : item;
    },
  },
});
</script>
