
import {  defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { gTagEvent } from "@/util/GoogleAnalytics";

export default defineComponent({
  name: "OneLandingPage",
  components: { },
  setup() {
	const router = useRouter();

	const handleClick = () => {
    gTagEvent("Clicked on 'Get Started' from Spark Value Prop Landing Page", "ONE", true)
		router.push({ name: 'OneLogin', query: { fromSparkLandingPage: 'true' }})
	}

  onMounted(() => {
    gTagEvent("View Spark Value Prop Landing page", "ONE", true)
  })
 
    return {
		handleClick
    };
  },
});
