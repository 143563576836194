
import { useRouter } from "vue-router";
import { disableEarningsTab } from "@/router/Helpers";
import { defineComponent, onBeforeMount, ref } from "vue";
import { IconArrowLeft, IconArrowRight } from "@/components";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import ActiveAccounts from '@/modules/earnings/activeAccounts/ActiveAccounts.vue'

export default defineComponent({
  components: {
    IconArrowLeft,
    IconArrowRight,
    ActiveAccounts,
},
  name: "Earnings",
  setup() {
    const isLoading = ref(true);
    const isMobile = () => {
      return checklistMethods.isMobile();
    }
    const router = useRouter()
    onBeforeMount(async () => {
      await disableEarningsTab().then((data) => {
        const isDisabled = data;
        if (isDisabled) {
          router.push({ name: "dashboard" })
        }
        isLoading.value = false
      })
    })
    return {
      isMobile,
      router,
      isLoading
    };
  },
});
