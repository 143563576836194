import jwtDecode from "jwt-decode";
import { reactive } from "vue";

export type Token = {
  role: string;
  userId: string;
  clientIds: Array<string>;
};

export const decodeJwt = (): Token => {
  const token = localStorage.getItem("access_token") as string;
  const decodedToken: any = jwtDecode(token);
  const claims = "https://hasura.io/jwt/claims";
  return reactive({
    role: decodedToken[claims]["x-hasura-default-role"],
    userId: decodedToken[claims]["x-hasura-user-id"],
    clientIds: decodedToken[claims]["x-hasura-client-ids"],
  });
};
