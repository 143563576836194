<template>
  <div id="walmart-privacy-policy" class="modal" aria-hidden="true" @click="agreeContinue">
     <div
       class="modal-window_overlay policy"
       tabindex="-1"
     >
     
       <div
         class="modal-window_container"
         role="dialog"
         aria-modal="true"
         aria-labelledby="aria-labelledby"
         @click.stop
       >
         <header class="modal-header">
           <div
             class="close-modal close-modal-header"
             style="margin-top: 20px;"
             aria-label="Close modal"
           >
           </div>
         </header>
         <div class="modal-content">
           <div class="closeBut">
             <button @click="agreeContinue" class="xbutton" >
              <b> X </b>
             </button>
           </div>
           <slot>
             <p class="c87"><span class="c43"><b>Spark Driver App Services Privacy Statement </b></span></p>
             <p class="c27">
               <span class="c1 c3">Last updated: March 21, 2024  <br></span>
               <span
                 class="c1">
                 Updates: <br>
               </span>
             </p>
             <p class="c30">
               <span class="headpoint">&nbsp;&nbsp;· Added section for Spark Driver support  <br>
               </span>
               <!-- <span class="c1"></span> -->
               <!-- <span class="headpoint" >&nbsp;&nbsp;· Added a section about how long we keep your information <br></span> -->
               </p>
                 <p><span class="c1 c3">This is the Spark Driver™ App Services Privacy Statement (hereinafter referred to as the “Spark Driver Privacy Statement”) for the Spark Driver Program and use of the Spark Driver App. The Spark Driver Privacy Statement does not apply to information collected from you by a provider of Permissive Service Offers (as defined in the Spark Driver Terms of Use) you elect to engage with through the Spark Driver App.  You are contracting as an independent contractor with Walmart.  As an independent contractor you may choose to perform Services (as defined in the Spark Driver Terms of Use) offered to you on the Spark Driver App.  Please review this Spark Driver Privacy Statement carefully.<br>   Your personal information will be collected as part of the onboarding process with Walmart and the activities you undertake as an independent contractor in the Spark Driver Program. This Spark Driver Privacy Statement explains how your personal information will be used as part of the Spark Driver Program and use of the Spark Driver App. By providing Services as a part of the Spark Driver Program and using the Spark Driver App, you are consenting to this Spark Driver Privacy Statement and all its terms.<br>   This  Spark  Driver  Privacy  Statement  only  applies  to  information  that  is  collected  from  independent contractors and used as part of the Spark Driver Program and use of the Spark Driver App. Customer information, the information of those for whom you perform Services using the Spark Driver App, is subject to the Privacy Notice available at <a href="https://corporate.walmart.com/privacy-security/walmart-privacy-notice" target="_blank"> <u>https://corporate.walmart.com/privacy-security/walmart-privacy-notice </u>. </a></span>
               </p>
             <!-- <p class="c66">
               <span class="c1 c3">Your personal information will be collected as part of the onboarding process with
                 Walmart and the activities you undertake as an independent contractor in the Spark Driver Program. This
                 Spark Driver Privacy Statement explains how your personal information will be used as part of the Spark
                 Driver Program and use of the Spark Driver App. By providing Services as a part of the Spark Driver
                 Program and using the Spark Driver App, you are consenting to this Spark Driver Privacy Statement and
                 all its terms.<br></span>
               <span class="c1"></span><span class="c1 c3">This Spark Driver Privacy
                 Statement only applies to information that is collected from independent contractors and used as part of
                 the Spark Driver Program and use of the Spark Driver App. Customer information, the information of those
                 for whom you perform Services using the Spark Driver App, is subject to the Privacy Statement available at
                 <a
                   href=" https://corporate.walmart.com/privacy-security/walmart-privacy-policy" target="_blank">https://corporate.walmart.com/privacy-security/walmart-privacy-policy</a>.</span><span
                 class="c1"> </span>
             </p> -->
 
             <p class="c10">
               <span class="c1 c3">1. </span><span class="c2"><b><u>Your Information: How We Collect and Use It</u></b></span><span
                 class="c9">&nbsp;</span>
             </p>
             <div class="lDistance">
             <p class="c76">
               <span class="c1">We may collect or receive (and may have collected or received during the 12-month period prior to the Last Updated date of Notice) the categories of personal information listed below. Not all categories will be collected or received for every individual.
 
               </span>
             </p>
             <p class="c19">
               <span class="c1 c3">· <b>Personal identifiers.</b> This includes, but is not limited to, your name, contact
                 information, and physical address,<br>
 
               </span><span class="c1"></span>
               <span class="c1 c3">· <b>Biometric information.</b> Face scans and facial geometry information may be collected and shared with  our  third-party  partner,  Persona,  for  identity  verification  and  fraud  prevention  purposes. Persona  processes  biometric  information  in  accordance  with  its  Privacy  Policy  available  at https://withpersona.com/legal/privacy-policy.<br></span><span class="c1"></span>
               <span class="c1 c3">· <b>Demographic information.</b> This includes, but is
                 not limited to, your age and date of birth. <br></span><span class="c1"></span>
               <span class="c1 c3">·<b> Device and online identifiers and related information.</b> In order to effectively send
                 and receive information through the Spark Driver App, Walmart must receive information about your mobile
                 device. This can include IP addresses, web browser type, mobile operating system version, phone carrier
                 and manufacturer, application installations, device identifiers, mobile advertising identifiers, push
                 notification tokens, and, if you register from a social media account, an identifier related to that
                 account. Walmart may also collect mobile sensor data from your mobile device to improve location
                 accuracy. This information may be shared with third party partners that support the Spark Driver
                 App.<br></span><span class="c1">·<b> Internet, application, and network activity.</b> To help make
                 improvements to the Spark Driver App, information about your interactions with the app, like the pages
                 or other content you view while the app is open, the actions you take within the app itself, and the
                 dates and times you opened the app will be collected. This information may be shared with third party
                 partners that support the Spark Driver App.<br> </span>
             </p>
             <p >
               <span class="c1 c3">· <b>Government identifiers.</b> As  part  of  the  driver  registration  process  and  subsequent  identity verification  checks,  we  may  collect  your  Social  Security  number,  driver’s  license  scans  and information,  driving  record,  vehicle  information,  vehicle  insurance  information,  and  business license or permitting information. Driver’s license scans and driver’s license information may be shared with our third-party partner, Persona, for identity verification and fraud prevention purposes. Persona processes driver’s license information in accordance with its Privacy Policy available at <a href="https://withpersona.com/legal/privacy-policy" target="_blank"> <u>https://withpersona.com/legal/privacy-policy</u>.</a><br>
               </span><span class="c1"></span><span class="c1 c3">· <b>Financial information.</b> Information about your
                 bank routing numbers, tax information, and other payment information provided by you may be collected in
                 order to compensate you for your Services. Financial information may also include claims information.
                 When an accident, injury, or other claim relating to your provision of Services is reported, Walmart may
                 investigate and collect such additional information regarding the claim as determined in Walmart’s
                 discretion.<br> </span><span class="c1"></span>
                 <span class="c1 c3">· <b>Location Information.</b> Your
                   Your precise location will be collected and tracked through the Spark Driver App on your mobile device, which you will use as a participant in the Spark Driver Program. If you label certain locations, such as “home” and “work,” that information may also be collected. Aside from using the location information to track deliveries, this information may also be used to conduct logistical, marketing, and business analytics. If you give us permission through your device settings, or the Spark Driver App, Walmart may collect location data while the app is off in order to communicate available Service Offers (as defined in the Spark Driver Terms of Use) to you and conduct analytics designed to improve the Spark Driver Program.<br></span>
                 <span class="c1">· <b> Background Information on Drivers.</b> Third party partners perform driving record and criminal background
                 checks on independent contractors that provide Services through the Spark Driver Program. Walmart will
                 receive information from them such as publicly available information about an independent contractor
                 driver’s driving record or criminal history.</span>
             </p>
             <p class="c20">
               <span class="c9">·<b> Device Camera and Microphone.</b> Some features of the Spark Driver App may ask you to
                 grant us access to the camera or microphone on your mobile device. We only collect this information when
                 you grant us access on your device.
               </span>
             </p>
             <p class="c7 c67">
               <span class="c1 c3"> <b>Sources of Personal Information.</b> We may collect personal information in the following
                 ways:<br>
                 · Provided directly by you <br>
                 · Collected from a device associated with you or your household<br>
                 · Collected through in-store technology<br>
                 · Collected from an external third-party source<br>
               </span>
             </p>
             <p class="c98">
               <span class="c2"> <b>Additional Uses of Your Personal Information.</b> In addition to the uses outlined above,
                 Walmart may also use your information for activities which include but are not limited to the
                 following:<br>
                 · To create and maintain your supplier account<br>
                 · To fulfill requests and obligations related to our partnership<br>
                 · To conduct auditing and monitoring of transactions and engagement<br>
                 · To protect the security and integrity of our websites, mobile services and our business<br>
                 · To help prevent fraud<br>
                 · To update our operational and technical functionality<br>
                 · To conduct business analysis, such as analytics, projections, identifying areas for operational
                  &nbsp; &nbsp; &nbsp; &nbsp;improvement<br>
                 · To conduct research, development, and training opportunities<br>
                 · To fulfill our legal function or obligations</span>
             </p>
             <p class="c74">
               <span class="c1 c3">Some examples of these activities are in the list below:
                 <br>
                 a. To display available Service Offers to independent contractors<br>
                 b. To display relevant information about you to Receiving Parties (as defined in the Spark Driver Terms
                 of Use) receiving Services from you<br>
 
                 c. To update applicants on the status of an independent contractor registration<br>
 
                 d. To notify independent contractors about Services demand and compensation<br>
 
                 e. To calculate auto insurance policies and analyzing usage patterns for safety and insurance
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;purposes<br>
 
                 f. Provide, improve, expand, and promote the Spark Driver Program<br>
 
                 g. Analyze how the driver community uses the Spark Driver App<br>
 
                 h. Communicate with you, either directly or through one of our third party partners<br>
 
                 i. Send you text messages, calls, and push notifications<br>
 
                 j. Facilitate transactions and payments<br>
 
                 k. Provide you with support<br>
 
                 l. Find and prevent potential fraudulent activities<br>
 
                 m. Respond to trust and safety issues that may arise, including auto incidents, injury claims, disputes
                 regarding deliveries, and requests from government authorities.<br>
               </span>
             </p>
             <p class="c41">
               <span class="c1 c3"><b>Call, Text Information.</b> When you choose to accept a Service Offer, Walmart may
                 interact with you through telephone or SMS text messages, in order to facilitate the accurate and timely
                 delivery of the Service. As part of that process, information will be collected about these
                 communications including the date and time of the call or SMS message, the parties’ phone numbers, and
                 the content of any messages. For security purposes, Walmart may also monitor and/or record the contents
                 of phone calls or SMS messages that are made to, or from, Walmart related to a Service. By registering
                 with the Spark Driver Program, you are expressly consenting to receive SMS text messages and push
                 notifications from Walmart as part of the Program. We may contact you through automated telephone calls
                 or text messages after we obtain your consent. We may contact you in this way for different purposes,
                 including messages related to orders you are delivering or to send you a security verification code. We
                 will provide you more details about the purpose of the automated call or text message at the time you
                 provide consent. </span>
             </p>
            </div>

             <div class="lDistance">
               <!-- <span class="c1 c3"> -->
                <p><b>App Notifications.</b> We may also contact you through an in-app notifications in order to facilitate the accurate and timely delivery of the Service. You can disable in-app notifications through your device settings.  As part of the app notification process, information may be collected about these communications including the date, time, and content of any message. <br></p>
              </div>
                <div><br>
                 <p> <b>2.	Other Limited Sharing Situations: How We Will Share Your Information </b></p>
                    <div class="lDistance">
                      <p> A.	We will not sell or rent your personal information for money. There may be limited situations, where Walmart needs to share information with the Walmart family of companies, or unaffiliated third parties. More information about these third parties is below. Such situations might include, but may not be limited to:</p></div><br> 
                   <p class="subPoint"> 
                    
                  <ol><li class="liClass"> Sharing information with service providers that assist with business activities, such as payment
                 processing, related to the Delivery Program or the Spark Driver App</li><br>
 
                <li class="liClass"> Sharing information with third party companies that contract to use the Spark Driver App as a Service
                 platform to deliver goods to customers (in which case the information shared will be limited to the
                 minimum amount and type of information that is reasonably necessary to fulfill the applicable
                 contract)</li><br>
 
                 <li class="liClass"> If there is a change of corporate control such as a restructuring, merger or sale of our assets</li><br>
 
                <li class="liClass"> If a law enforcement official or government authority requests information and Walmart reasonably
                 believes that disclosure is required or appropriate in order to comply with laws, regulations, or a
                 legal process</li><br>
 
                <li class="liClass"> If the information is necessary to comply with a legal requirement or process, including but not
                 limited to, civil and criminal subpoenas, court orders or other compulsory disclosures</li><br>
 
                <li class="liClass"> If the information is relevant to a claim of personal injury or property damage involving an
                 independent contractor, the Spark Driver Program may share some limited information with an insurance
                 provider. The customer who submitted the insurance claim or their representatives may receive some
                 limited information about the Spark Driver through the insurance claim</li><br>
 
                <li class="liClass"> To provide information about the use of the Delivery Program to potential business partners in
                 aggregated or de-identified form that can’t reasonably be used to identify you</li><br>
 
                <li class="liClass"> At the direction of you or the provider of a Permissive Service Offer, to facilitate the delivery of
                 information to the offeror of a Permissive Service Offer</li><br>
 
                <li class="liClass"> Any other situation where such information is necessary to ensure the effective operation of the
                 Spark Driver Program (in which case such information will be limited to the minimum amount and type of
                 information that is reasonably necessary to accomplish such operations)</li><br>
 
                <li class="liClass"> With your consent. In circumstances other than those described above, we will ask you for your
                 affirmative consent before we share your personal information outside of our corporate family of
                 companies.</li></ol></p>
                </div>
                 <br>
                
             <p class="lDistance"><span class="c9">B. We disclosed your personal information with certain categories of third parties as indicated below, and we may have disclosed the same information during the 12-month period prior to the Last Updated date of this Privacy Notice.
               </span></p>
             <p class="c7">
               <span class="points">1. Service Providers:
                 <p class="subPoint">
                 · Personal identifiers<br>
                 · Demographic information<br>
                 · Device and online identifiers<br>
                 · Location information<br>
                 </p>
                <p class="points"> 2. Third parties we are legally required to share with:</p>
                 <p class="subPoint">
                 · Personal identifiers<br>
                 · Demographic information<br>
                 · Device and online identifiers<br>
                 · Internet, application, and network activity<br>
                 · Government identifiers<br>
                 · Financial information<br>
                 · Location information<br>
                 · Background information<br>
                 </p>
                 <p class="points">3. Third party companies contracting to use the Spark Delivery as a Service Platform<br></p>
                 <p class="subPoint">
                 · Personal identifiers<br>
                 · Vehicle Information<br></p>
               </span>
             </p>
             <p class="c56">
               <span class="c1 c3">3. </span><span class="c2"><b>How We Protect Your Personal Information</b><br></span><span style="margin-left: 7px;">&nbsp;Spark Driver App maintains administrative, technical, and physical controls designed to
                 reasonably <span> &nbsp;&nbsp;&nbsp;safeguard your personal information.</span></span>
             </p>
 
             <!-- <p class="c0">
               <span class="c1 c3">4. </span><span class="c2"><b>California Privacy Rights</b></span><span
                 class="c9">&nbsp;</span>
             </p>
             <p class="c22">
               <span class="c1 c3">If you are a California resident, you have the right to ask that we stop selling your
                 personal information; however, we do not sell your personal information.<br></span><span
                 class="c1">&nbsp;</span><span class="c1 c3">We will not discriminate against you for exercising your
                 rights. This generally means we will not deny you goods or services, charge different prices or rates,
                 provide a different level of service or quality of goods, or suggest that you might receive a different
                 price or level of quality for goods for exercising your rights.</span>
             </p> -->
 
             <p class="c54">
               <span class="c1 c3">4. </span><span class="c2"><b>How Long Do We Retain Your Personal
                 Information?</b></span><span class="c9">&nbsp;</span>
             </p>
             <p class="lDistance" style="margin-top: -10px;">
               <b >We will keep the personal information we collect about you for as long as necessary to carry out the purposes set forth in this Privacy Notice or any other notice provided at the time of data collection, but no longer than as required or permitted under applicable law or internal policy. We dispose of the information we collect in accordance with our retention policies and procedures.</b>
             </p>
             <p> <b>California Privacy Rights </b></p>
             <p style="margin-top: -10px;">If you are a California resident, you can make certain requests regarding your personal information, and we will fulfill each request to the extent required by law. If we are unable to comply with your request in whole or in part, we will notify you with reasons for the denial. We do not offer the option for you to request we stop selling your personal information or sharing it for cross-context behavioral advertising because we do not conduct such selling or sharing of your personal information.</p>
 
             <!-- <p class="c16">
               <span class="c1 c3">5. </span><span class="c2"><b>How to Contact Us</b></span><span class="c9">&nbsp;</span>
             </p> -->
             <p class="c73 c97">
               <span class="c1 c3">As stated in more detail below, you can request that we:</span>
               <br>
               <span>
                <ol><li class="liClass2"> Provide you with access to a copy of and certain details regarding the personal information we have about you.</li>
                <li class="liClass2"> Delete your personal information.</li>
                <li class="liClass2"> Correct your inaccurate personal information.</li>
                <li class="liClass2"> Limit the use or disclosure of your sensitive personal information (SPI).</li></ol></span>
                  
             </p>
             <div>
               <p>To exercise any of these privacy rights, call 1-800-Walmart (1-800-925-6278), press one, and say, “I’d like to exercise my privacy rights.” Or follow the instructions below specific to the type of request.</p> 
             <br><br>
               <p>1. <b>Request to Access My Personal Information:</b> You have the right to request access to the personal information we may have collected about you. To access your personal information, click <a href="https://cpa-ui.walmart.com/affirmation?brandCode=WMT&requestType=ACCESS&persona=SUPPLIER" target="_blank"> “<u>Request My Personal Information</u>.” </a>Requests to access your personal information may be submitted up to two times in  a rolling twelve-month period. In response, we will  return to you the following categories of information, to the extent required by law:</p>
               <p class="otherPoints">
                <ul><li class="liClass2">The categories of personal information we have collected about you.</li> 
                  <li class="liClass2">The categories of sources from which we collect your personal information.</li>
                <li class="liClass2">The business or commercial purpose for collecting, selling, or sharing your personal information.</li>
              <li class="liClass2">The categories of third parties to whom we disclose personal information.</li>
            <li class="liClass2">The specific pieces of personal information we have collected about you.</li>
          <li class="liClass2">A list of categories of personal information that we have sold or shared for cross-context  behavioral advertising, along with the categories of third parties we sold it to or shared it with for cross-context behavioral advertising.</li>
        <li class="liClass2">A list of categories of personal information that we have disclosed for a business purpose, along with the categories of third parties we disclosed it to.</li></ul> </p>
 
               <p>2. <b>Delete My Personal Information:</b> You have the right to ask that we delete your personal information. Once we receive a request, we will delete the personal information (to the extent required by law) we hold about you as of the date of your request from our records and direct our service providers to do the same. Choosing to delete your personal information may impact your ability to use our websites and online features, including closure of your online account, and limit your use of in-store functions that require your personal information. To delete your personal information, click “<a href="https://cpa-ui.walmart.com/affirmation?brandCode=WMT&requestType=ACCESS&persona=SUPPLIER" target="_blank"><u>Request My Personal Information</u></a>.”</p>
               <p>3. <b>Correct My Personal Information:</b> You have the right to ask that we correct the personal information we may have collected about you if that information is inaccurate. We reserve the right to request identifying documentation from you in certain circumstances, as permitted by law. To correct your personal information, click “<a href="https://cpa-ui.walmart.com/affirmation?brandCode=WMT&requestType=ACCESS&persona=SUPPLIER" target="_blank"><u>Request My Personal Information</u></a>.”</p>
               <p>4. <b>Limit the Use and Disclosure of SPI:</b> You have the right to ask that we limit our use and disclosure of your SPI to certain purposes permitted by law and can do so by clicking “<a href="https://cpa-ui.walmart.com/affirmation?brandCode=WMT&requestType=ACCESS&persona=SUPPLIER" target="_blank"><u>Your Privacy Choices</u></a>.”</p>
               <p>We will not discriminate against you for exercising your rights. We will not deny you goods or services, charge different prices or rates, provide a different level of service or quality of goods, or suggest that you might receive a different price or level of quality for goods. Some programs or membership services require usage of personal information to function, so compliance with your request may impact those experiences.</p>
               <p><b>How  Do  We  Verify  Your  Identity  to  Complete  Your  Access,  Correction  and  Deletion  Requests:</b><br>
                 If you request access to, or the correction or deletion of, your personal information we will verify your identity before disclosing the requested information. To do so, we may ask that you log into your account or provide us with your:</p>
                 <p class="lDistance">· First name*, Middle initial, Last name*<br>
                    · Address* <br>
                    · Email address<br>
                    · Phone number</p>
                    <p>*Required field <br>
                     When you make a request, you will be asked to answer a few questions about yourself to help us verify your identity. Third-party identification services may help us with verification to prevent disclosure of your personal information resulting from fraudulent requests. You may be asked to log into your online account and complete a one-time passcode validation. In the event more documentation is necessary to verify your identity, we will notify you directly.</p>
 
                     <p> <b>What If You Can’t Verify My Identity?</b><br> If we can’t verify your identity, we will notify you, and your request(s) to access, request, or delete your personal information may not be processed. Any requests may be denied if we have reason to believe they are fraudulent.</p>
 
                     <p><b>How Do I Submit a Request Using an Authorized Agent?</b><br> If you’d like an authorized agent to exercise your rights for you, they may use the same links described above to submit requests. We require documentation demonstrating your agent’s authority to submit requests on your behalf. For example, a valid power of attorney or other documentation, including a letter, signed by you (the California resident) authorizing the agent to submit your requests. You can download a sample letter from the request form.Documentation may be uploaded at the time you submit your request on our website or provided to us up to 10 business days after. If you submit your request by calling, you can email your forms to <a href="mailto:consumerprivacy@wal-mart.com"><u>consumerprivacy@wal-mart.com</u></a>.</p>
 
                     <p>You can write to the Walmart Privacy Office with any questions or comments about this Notice or about how we handle your personal information. The Privacy Office’s address is:<br> <br>
                      <br><b>Who Can I Contact About the Privacy Statement?</b><br>
                        Walmart Corporate<br>
                         Privacy Office, MS #0160 <br>
                         702 SW 8th Street <br>
                         Bentonville, AR 72716-0160 </p>
                         <p><b>Questions about signing up for the Spark Driver Platform?</b><br>
                          For additional information about the Spark Driver Platform, <a href="https://drive4spark.walmart.com/spark-driver-faq" target="_blank">visit here.</a></p>
                          <p><b>Already started signing up and need to check the status of your application?</b><br>
                          Sign in to your <a href="https://my.ddiwork.com/login" target="_blank">Spark Driver profile </a>created during the enrollment process to check your status.</p>
                          <p><b>Appealing a deactivation?</b><br>
                          If you were deactivated and want to appeal your deactivation, you can log in to your Spark Driver profile and fill out <a href="https://my.ddiwork.com/login?service=appeals" target="_blank">this form.</a>
                          </p>
             </div>
             
           </slot>
         </div>
       </div>
     </div>
   </div>
 </template>
 
 <script>
 import "../../assets/css/components/modals.scss"
 import { DateTime } from "luxon";
 
 export default {
   name: "WalmartPrivacyPolicy",
     emits: ["accept"],
   methods: {
     agreeContinue() {
       this.$emit("accept");
     },
     currentDate() {
       return  DateTime.now().setZone("America/Los_Angeles").toFormat("dd LLL yyyy");;
     },
      mounted() {
     // add a click event listener to the document
     document.addEventListener('click', this.handleOutsideClick);
     },
     beforeUnmount() {
       // remove the click event listener when the component is unmounted
       document.removeEventListener('click', this.handleOutsideClick);
     },
     handleOutsideClick(event) {
       // check if the clicked element is outside the modal content
       const modalContent = document.querySelector('.modal-content');
       if (!modalContent.contains(event.target)) {
         this.agreeContinue() 
       }
     }
   },
 };
 </script>
 
 <style scoped>
 .closeBut{
   float: right;
 }
 .xbutton{
   background-color: white !important;
   cursor: pointer;
   border-radius: 30%;
   border-color: black;
   color: black;
 }

 .lDistance{
  margin-left: 20px;
 }
 .points{
  margin-left: 40px;
 }
 .subPoint{
  margin-left: 55px;
  margin-top: -18px;
 }
 .liClass{
  font-size: 1em;
  margin-left: 30px;
  margin-top: -25px;
 }
 .liClass2{
  font-size: 1em;
  margin-left: 30px;
  margin-top: -20px;
 }
 .otherPoints{
  font-size: 1em;
  margin-left: 30px;
  margin-top: -10px;
 
 }
 </style>
