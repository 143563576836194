
import { computed, defineComponent } from "vue";
import { IconWarning, IconCheck, IconArrowRight } from "@/components";
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";
export default defineComponent({
  name: "DocumentTable",
  components: { IconCheck, IconWarning, IconArrowRight },
  props: {
    title: {
      type: String,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
    noDocMessage: {
      type: String,
      required: false,
      default: "No documents available",
    },
    linkTo: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const fullRouting = (document: any) => {
      const routing = {
        name: props.linkTo,
        params: { id: document.id, title: document.title },
        query: {},
      };
      if (document.clientLocationID) {
        routing.query = { location: document.clientLocationID };
      }
      gTagEvent(`View Document: ${document.title}`, "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
      return routing;
    };

    return { state, fullRouting };
  },
});
