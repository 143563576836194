
import { defineComponent, ref, computed, onMounted, reactive } from "vue";
// import { signup } from "@/http/http.config";
import * as yup from "yup";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { IconWarningOctagon } from "@/components";
// import { Form, Field, ErrorMessage } from "vee-validate";
import router from "@/router";
// import { gTagEvent } from "@/util/GoogleAnalytics";
import { useRoute } from "vue-router";
// import { decode, encodeWithParam } from "@/util/DataEncodeDecode";

export default defineComponent({
  name: "AccountLocked",
  components: {
    
  },
  data() {
    return {
      maxOTPLen: 6,
      usernameRules: yup
        .string()
        .trim("Username can't contain white space at the start or end")
        .strict(true)
        .required("Username is required")
        .min(2, "Minimum length of 2 characters")
        .max(50, "Maximum length of 50 characters"),
      OTPRules:  yup
        .string()
        .trim("OTP can't contain white space at the start or end")
        .strict(true)
        .max(6)
        .required("OTP is required")
    }
  },
  setup() {
    const route = useRoute();
    
    const redirectToForgotPassword = () => {
      return router.push({ name: "forgotPassword" });
    };
 
    // onMounted(() => {
     
    // });

    return {
       redirectToForgotPassword,
    };
    
  },
});
