
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { state } from "@/harlem/stores";
import { getter } from "@/harlem/getter";
import OracleButton from "./OracleButton.vue";
import IconSparkDriverLogo from "./icons/IconSparkDriverLogo.vue";
export default defineComponent({
  name: "PortalHeader",
  emits: ["menuopen"],
  components: {OracleButton,IconSparkDriverLogo},
  setup() {
    const route = useRoute();
    let partnerID = ref("");
    let data:any =sessionStorage.getItem("ddi:mergeTags");
    let fullName = JSON.parse(data);
    let locationData:any =sessionStorage.getItem("ddi:user");
    let locationDetails = JSON.parse(locationData);
    let locationName = locationDetails.locationName;
    const paymentAgreementComplete = ref(false)

    onMounted(() => {
      let element1 = document.getElementById('profileImage');
      element1?.append(fullName?.partner?.firstName.substring(0, 1).toUpperCase() +  fullName?.partner?.lastName.substring(0,1).toUpperCase());
      paymentAgreementComplete.value = state.payment.paymentAgreementComplete
    });

    localStorage.setItem('theme', 'ddi');
    return { state, getter,fullName, locationName, route };
    
  },
  methods: {
    openMenu() {
      this.$emit("menuopen");
    },
  },
});
