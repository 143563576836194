import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "module-large" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}
const _hoisted_3 = { class: "list-unstyled linked-list" }
const _hoisted_4 = { class: "status" }
const _hoisted_5 = { class: "name doc-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_icon_warning = _resolveComponent("icon-warning")!
  const _component_icon_arrow_right = _resolveComponent("icon-arrow-right")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
    (_ctx.documents.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.noDocMessage), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documents, (document) => {
        return (_openBlock(), _createElementBlock("li", {
          key: document.id
        }, [
          _createVNode(_component_router_link, {
            to: _ctx.fullRouting(document)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (document.signature !== '' && document.signature !== null)
                  ? (_openBlock(), _createBlock(_component_icon_check, {
                      key: 0,
                      classes: "success"
                    }))
                  : (_openBlock(), _createBlock(_component_icon_warning, {
                      key: 1,
                      class: "text-alert"
                    }))
              ]),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.type == "Resubmission" ? document.title : document.title), 1),
              _createVNode(_component_icon_arrow_right)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ]))
}