<template>
  <div class="container">
    <div class="content">
      <div class="page-actions">
        <div class="action-left">
          <a class="back" @click="router.go(-1)">
            <svg v-svg="'icon-arrow-left'" size="0 0 18 18" />
            Back
          </a>
        </div>
      </div>
      <div class="inline-links text-right">
        <a @click="exportToPDF" class="action-link">
          <svg v-svg="'icon-download-2'" size="0 0 18 18" />
          Download
        </a>
        <a :href="mailtoLink" class="action-link">
          <svg v-svg="'icon-envelope'" size="0 0 18 18" />
          Email
        </a>
        <router-link
          :to="{
            name: 'documentVersions',
          
          }"
          class="action-link"
        >
          <svg v-svg="'icon-history'" size="0 0 18 18" />
          versions
        </router-link>
      </div>
      <div v-if="htmlDoc">
        <!-- <p class="small">
          <strong>{{ doc.signedOn }} </strong>
        </p> -->
        <div
          class="general-grid"
          ref="pdfContent"
          :style="{
            backgroundColor: 'white',
            padding: '15px',
            marginBottom: '10px',
          }"
          id="signedDocContent"
        >
          <h2>
            <strong>{{title}}</strong>
          </h2>
          <div v-if="htmlDoc">
            <div v-html="htmlDoc"></div>
          </div>
        </div>
        <div class="signature-container">
          <div class="signature">{{ signature }}</div>
        </div>
       
      </div>

     
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, inject, reactive, ref } from "vue";
import { StoreStateSymbol } from "@/store/providers/state.provider";
import { useRoute, useRouter } from "vue-router";
import html2pdf from "html2pdf.js";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from '@/util/DataEncodeDecode';
import handlebars from "handlebars";
import { gTagEvent } from "@/util/GoogleAnalytics";



export default defineComponent({
  name: "Document",
  components: {},
  setup() {
    const store = inject(StoreStateSymbol, {});
    const route = useRoute();
    const router = useRouter();
    const signature = ref(decodeURIComponent(state.user.signature));
    const user = reactive(store.user);
    const htmlDoc = ref("")
    const title = route.params.title

    let docId = {id: route.params.id}
    proxyApi.post("/getDocHtml", encodeWithParam(docId))
    .then(({ data }) => {
      if (data.success) {
        let html = data.result[0].html
        let refinedHtml = html.replace(/(<style[\w\W]+style>)/g, "");
        refinedHtml = refinedHtml.replaceAll('{{partner.signature}}', `<span  style="font-family: La Belle Aurore; font-style: italic; font-weight: 900; font-size: 20px;">${data.result[0]?.signature}</span>`);
        let partner={
        date:data.result[0]?.signedOn,
        partner:{
          signature:data.result[0]?.signature
        }
      }
      // let myHtml = htm.replace(/(<style[\w\W]+style>)/g, "");
      // myHtml = myHtml.replaceAll('{{partner.signature}}', `<span  style="font-family: La Belle Aurore; font-style: italic; font-weight: 900; font-size: 20px;">${partner.partner.signature}</span>`);
        htmlDoc.value = handlebars.compile(refinedHtml)(partner);
      }
    })

   
    const exportToPDF = () => {
      gTagEvent(`Download Document: ${title}`, "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
      html2pdf( htmlDoc.value, {
        margin: [0.3, 0.4],
        filename: title + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
    };
    const mailtoLink = computed(
      () => {
        `mailto:${state.user.email}?subject= ${title}&body= Follow this link to view and download your document.${window.location.href}`
        return gTagEvent(`Email Document: ${title}`, "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
      }
    );
    
    return {
      signature,
      store,
      route,
      router,
      user,
      window,
      exportToPDF,
      mailtoLink,
      htmlDoc,
      title
    };
  },
});
</script>
