
import { defineComponent, ref } from "vue";
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import IconWarning from "@/components/icons/IconWarning.vue";
import { useRoute } from "vue-router";
import { decodeJwt, Token } from "@/composables/user.composable";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";

export default defineComponent({
  name: "Documents",
  components: { IconWarning, IconArrowLeft },
  methods: {
    isMobile() {
      return checklistMethods.isMobile()
    }
  },
  setup() {
    // const tokenData: Token = decodeJwt();
    const route = useRoute();
    const docsToSign = ref(false);
      proxyApi.get("/getOnBoardingDocuments").then(({data})=>{
     if(data.success){
            data.result.map( item => {
              if(item.signature == null && item.title != "Insurance"){
                  docsToSign.value = true
        }
      } 
      )}
    });
    
    return { state, docsToSign };
  },
});
