import DashboardRoutes from "@/modules/dashboard/routes";
import SupportRoutes from "@/modules/support/routes";
import DocumentRoutes from "@/modules/documents/routes/routes";
import FaqRoutes from "@/modules/faq/routes";
import LoginRoutes from "@/modules/login/routes";
import SignupRoutes from "@/modules/signup/routes";
import OneRoutes from "@/modules/one/routes"
import authGuard from "@/router/authGuard";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/error",
    name: "errorPage",
    component: () => import("@/components/ErrorPage.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: true, open: true },
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/modules/login/Login.vue"),
    meta: { title: "Delivery Drivers Inc | Login", noHeader: true, open: true, layout: "account" },
  },
  ...LoginRoutes,
  ...SignupRoutes,
  ...OneRoutes,
  ...DashboardRoutes,
  ...DocumentRoutes,
  ...FaqRoutes,
  ...SupportRoutes
];

const router = createRouter({
  history: createWebHistory(config.BASE_URL || process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes,
});

router.beforeEach(authGuard);
router.beforeEach((to, from, next) => {
  // const excludePages=['/one/signup','/one/login','/one/faqs']

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.title = to.meta.title || "Walmart";

  next();
});

export default router;
