<template>
  <div class="container" v-if="paystubObj.netAmount">
    <div class="content">
      <div class="page-actions">
        <div class="action-left">
          <a class="back" href="javascript:history.back()">
            <svg v-svg="'icon-arrow-left'" size="0 0 18 18" />
            {{ payPeriod }}
          </a>
        </div>
      </div>
      <div id="pdfContent">
      <div class="logo-and-line" v-if="showRemarks">
        <div>
          <img  class="deposit-remarks-img" src="@/assets/Notes.svg" alt="Deposit Remarks" />
        </div>
        <div class="vertical-dotted-line"></div>
        <div class="deposit-remarks">{{ depositRemarks }}</div>
      </div>
      <div class="earnings-grid deposit-summary-container margin-t-medium" >
        <div>
          <h4>{{ paystubObj.clientLocation }}</h4>
          <div class="flex label-group">
            <p>Check Date</p>
            <p class="margin-l-30pc">Owner</p>
          </div>
          <div class="flex">
            <p>{{ displayDate(paystubObj.checkDate) }}</p>
            <p class="margin-l-30pc">{{ paystubObj.partner.name }}</p>
          </div>
        </div>
        <p class="net-income-heading">Earnings</p>
        <div class="gray-bg module-large total-amount">
          <div class="detail-earning-main">
            <div
              class="detail-earning"
              v-if="paystubObj.businessIncome.items.length > 0"
            >
              <ul
                v-for="item in paystubObj.businessIncome.items"
                :key="item.id"
              >
                <li>
                  <div class="detail-earning-title label-color">
                    {{ item?.name ? formatLineItems(item.name) : "" }}
                  </div>
                  <div>{{ formatCurrency(item.amount) }}</div>
                </li>
              </ul>
            </div>
            <div
              class="detail-earning"
              v-if="paystubObj.credits.items.length > 0"
            >
              <ul v-for="item in paystubObj.credits.items" :key="item.id">
                <li>
                  <div class="detail-earning-title label-color">
                    {{ item?.name ? formatLineItems(item.name) : "" }}
                  </div>
                  <div>{{ formatCurrency(item.amount) }}</div>
                </li>
              </ul>
            </div>
            <div
              class="detail-earning"
              v-if="paystubObj.withholdings.items.length > 0"
            >
              <ul v-for="item in paystubObj.withholdings.items" :key="item.id">
                <li>
                  <div class="detail-earning-title label-color">
                    {{ item?.name ? formatLineItems(item.name) : "" }}
                  </div>
                  <div>{{ formatCurrency(item.amount) }}</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="border-line"></div>
          <h5
            class="net-total bold-text"
          >
            Total
            <span> {{ formatCurrency(paystubObj.netAmount.amount) }}</span>
          </h5>
        </div>
      </div>
    </div>
      <div class="flex margin-t-large">
        <a @click="exportToPDF" class="button" style="margin-right:24px">
          Download
        </a>
        <a :href="mailtoLink" class="button">
          Email
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.deposit-summary-container .single-container .detail-prices {
  margin-left: 40px;
  width: 60%;
}
.net-income-heading {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  margin-left: 16px;
}
.deposit-summary-container .single-container .detail-prices .wide {
  width: 20%;
}
.deposit-summary-container .single-container {
  margin-top: 30px;
}
h5 span {
  float: right;
}
.bold-text {
  font-weight: 600;
}
.pay-period {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 32px;
}
.detail-earning-main {
  font-size: 16px;
}
.detail-earning li {
  display: flex;
  justify-content: space-between;
}
.detail-earning .detail-earning-title {
  width: 100%;
}
.earnings-grid > div {
  padding: 16px 18px !important;
  margin-bottom: 40px;
}
.border-line {
  border: 1px solid #EBEBEb;
  margin-bottom: 24px;
}
ul {
  padding-left: 0 !important;
}
.vertical-dotted-line {
    border-left: 1px dotted #333333; 
    height: 40px;
    margin-left: 10px;
}
.logo-and-line {
    display: flex; 
    align-items: center;
    margin-left: 5px;
    margin-bottom: 20px;
}
.deposit-remarks{
  margin-left: 5px;
  font-family: "Rubik";
  word-spacing: 1px;
  line-height: normal;
}

.deposit-remarks-img{
  height: 58px !important;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .deposit-summary-container .single-container .detail-prices .wide {
    width: 50%;
  }
  .deposit-summary-container .single-container .detail-prices .right-para p {
    font-size: 15px;
  }
  .deposit-summary-container .single-container .detail-prices {
    margin-left: 0px;
    width: 100%;
  }
  .detail-earning .detail-earning-title {
    width: 50%;
  }
}
</style>

<script>
import { computed, defineComponent, inject, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { DateTime } from "luxon";
import html2pdf from "html2pdf.js";
import { StoreStateSymbol } from "../../store/providers/state.provider";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";

export default defineComponent({
  name: "DepositSummary",
  setup() {
    const store = inject(StoreStateSymbol);
    const route = useRoute();
    let paystubObj = ref({});
    const payPeriod = ref("");
    const depositRemarks=ref("")
    const showRemarks=ref(false)
    onMounted(() => {
      singleDepositSummary();
    });

    const singleDepositSummary = () => {
      proxyApi
        .post(
          "/singleDepositSummary",
          encodeWithParam({ depositSummaryID: route.params.paystubId })
        )
        .then(({ data }) => {
          const { startDate, endDate } = data.result[0];
          payPeriod.value = `${formatDate(startDate)} - ${formatDatePacific(endDate)}`;
          
          proxyApi.post("/getDepositSummariesRemarks", encodeWithParam({ startDate: startDate, endDate: endDate })).then(({ data }) => {
          depositRemarks.value = data.result[0]?.memo;
          if(depositRemarks?.value?.length>0)
          {
            showRemarks.value=true;
          }
        })
          depositSummaryData(data.result);
        });
    };

    const depositSummaryData = (depositResult) => {
      depositResult.forEach((item) => {
        let customObj = {
          startDate: item.startDate,
          endDate: item.endDate,
          netAmount: item.check.NET,
          businessIncome: item.check.BUSINESS_INCOME,
          credits: item.check.CREDITS,
          withholdings: item.check.WITHHOLDING,
          checkDate: item.generationDate,
          clientLocation: item.locationName,
          partner: {
            name: `${item.firstName} ${item.lastName}`,
            email: item.email,
          },
        };
        paystubObj.value = customObj;
      });
    };
    const exportToPDF = () => {
      html2pdf(document.getElementById("pdfContent"), {
        margin: 1,
        filename: "paystub.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
    };
    const mailtoLink = computed(
      () => `mailto:${state?.user?.email ? state.user.email : ""}?subject=
    Direct Deposit Summary&body=
    Follow this link to view and download your deposit summary.${
      window.location.href
    }\``
    );
    
    const formatDate = (date) => {
      return DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("dd MMM yyyy");
    }

    const formatDatePacific = (date) => {
      return DateTime.fromFormat(date, "yyyy-MM-dd").setZone("America/Los_Angeles").toFormat("dd MMM yyyy");
    }

    return { paystubObj, exportToPDF, store, mailtoLink, payPeriod,depositRemarks,showRemarks };
  },
  methods: {
    displayDate(date) {
      return DateTime.fromISO(date).toFormat("d LLL y");
    },
    formatCurrency(amount, space) {
      amount == undefined || isNaN(amount) ? (amount = 0) : amount;
      if (!space) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
      } else {
        const formattedMoney = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
        return `${formattedMoney.substring(0, 1)} ${formattedMoney.substring(
          1,
          formattedMoney.length
        )}`;
      }
    },
    formatLineItems(item) {
      return item.split(":").length > 1
        ? item.split(":")[item.split(":").length - 1]
        : item;
    },
  },
});
</script>
