
import { defineComponent, ref, onMounted, computed, reactive } from "vue";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import { useRoute, useRouter } from 'vue-router'
import micromodal from "micromodal";
import { DateTime } from "luxon";
import {
  TaxConsent,
  TaxConfirmAddress,
  WalmartTaxConsentPolicy,
  OneNotEligible,
} from "@/components";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { state } from "@/harlem/stores";
import FunnelStatus from "../funnelStatus/FunnelStatusWidget.vue";
import { getHiddenEarningAccounts, getOneStatus } from "../funnelStatus/Helpers";
import { disableEarningsTab } from "@/router/Helpers";
import GettingStartedResources from "./GettingStartedResources.vue";
import { FunnelStatus as driverFunnelStatus } from "@/util/FunnelStatus";
import DriverJourneyMap from "../../components/checklist/DriverJourneyMap/Index.vue"
import Loader from "@/components/modals/Loader.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    OneNotEligible,
    TaxConsent,
    TaxConfirmAddress,
    WalmartTaxConsentPolicy,
    FunnelStatus,
    GettingStartedResources,
    DriverJourneyMap,
    Loader
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const allowTaxesDownload = ref(true);
    const pendingNumber = ref(1);
    const unfinishedNumber = ref(0);
    const docsToSign = ref(false);
    const unfinished = ref(false);
    const progressStage = ref(false);
    const onePaymentDate = DateTime.fromISO(state.user.oneStartDate).toFormat('MM/dd/yyyy')
    const oneStatus:any = ref(null);
    const garnishment = state.user.garnishment
    const oneIntegration = state.user.isOneIntegrated;
    const isOneActive = state.user.isActive;
    const payeeID = sessionStorage.getItem('payeeID')
    const oneSignUp = route.query.oneSignUp
    const branchStatus = ref("")
    const noActiveAccounts = ref(true)
    const isEarningsDisabled = ref(true)
    const attemptedEarningAccount = ref(false)
    const oneEnabled = ref(false)
    const loading = ref(true)
    const onePaymentStarted = computed(() => {
      return DateTime.now().toFormat('MM/dd/yyyy') > onePaymentDate ? true : false;
    });
    const isShowCheckrLink = computed(() => {
      return funnelProgress?.value?.some(data => (data.candidateStatus === driverFunnelStatus.MVR_SUBMITTED  || data.candidateStatus === driverFunnelStatus.CBC_SUBMITTED  || data.candidateStatus === driverFunnelStatus.ENROLLMENT_COMPLETE  || data.candidateStatus === driverFunnelStatus.ENROLLMENT_ON_HOLD ));
    });

    const funnelProgress = ref();

    proxyApi.get("/getOnBoardingDocuments").then(({ data }) => {
      if (data.success) {
        data.result.map(item => {
          if (item.signature === null && item.title !== "Insurance") {
            docsToSign.value = true
          }
        })
      }
    });
    const sessionData: any = sessionStorage.getItem("ddi:user");
    const candidate = JSON.parse(sessionData)

    const reroutePartner = () => {
        const isPaymentAgreementPending = funnelProgress.value.some(obj => obj["candidateStatus"] == "Payment Agreement Pending");
        if (!isPaymentAgreementPending && !attemptedEarningAccount.value) return router.push({ name: "candidateDashboard" })
        else {
          funnelProgress.value.map(item => {
            if (item.name === "Payment Agreement" && item.candidateStatus === "Payment Agreement Pending") progressStage.value = true;
          })
        }
    }

    const handleSubmit = (choice: string) => {
      micromodal.close('modal-tax-consent')
      proxyApi.post('/taxConsent', encodeWithParam({
        taxConsent: choice,
        signedOn: new Date(),
      }))
      if (choice === 'false') micromodal.show('tax-confirm-address')
    }

    const handleConfirm = (choice: string) => {
      micromodal.close('tax-confirm-address')
      if (choice === 'edit') router.push({ name: 'profile' })
    }

    const gTagONE = () => {
      gTagEvent("Dashboard Sent to Create ONE Account", "ONE", true)
    }

    const getBranchStatus = () => {
      proxyApi.get('/getWalletStatus').then(({ data }) => {
        if (data.result.length > 0) {
          const status = data.result[0]?.status
          branchStatus.value = status
        }
      })
    }

    const isAttemptedEarningAccount = async () => {
      attemptedEarningAccount.value = await getHiddenEarningAccounts()
    } 

    const showOneValuePropBanner = () => {
      let validONEStatus, hasPayeeID;
      validONEStatus = (['STARTED', '', undefined].includes(oneStatus.value));
      hasPayeeID = payeeID !== 'null';
      const showBanner = (validONEStatus && !garnishment && hasPayeeID) && oneEnabled.value == false
      return showBanner
    }

    onMounted(async () => {
      if (oneSignUp) {
        micromodal.show('one-not-eligible')
      }
      proxyApi.get('/getTaxConfig').then(({ data }) => {
        allowTaxesDownload.value = JSON.parse(data.result[0].value).driverTaxes
      })
      proxyApi.get('/getIsOneOptionEnabled').then(({ data }) => {
        oneEnabled.value = data.result[0]?.show_one_pymt_provider
      })
      let progress: any = localStorage.getItem("progress")
      let obj = JSON.parse(progress)
      await setFunnelProgressData();
      if (!payeeID || payeeID == 'null') {
        reroutePartner()
      }
      await hasGivenETaxConsent()
      await setOneStatusRef();
      await getBranchStatus();
      await getActiveEarningAccounts();
      await getIsEarningsDisabled();
      await isAttemptedEarningAccount();
      await checkIsShowResourcesSection();

      loading.value = false;
    })

    const getActiveEarningAccounts = () => {
      proxyApi.get('/getActiveEarningAccounts').then(({data}) => {
        const message = data.message
        if (message == "No active accounts"){
          return 
          } else {
            noActiveAccounts.value = false;
          }
      })
    }

    const hasGivenETaxConsent = async () => {
      let flag = false;
      await proxyApi.get('/showTaxConsentPopUp').then(({data}) => {
        flag = (data?.flag === "true")
      })
      if (flag) {
        await proxyApi.get('/taxConsent').then(({data}) => {
          if (data.rowCount !== 1) {
            micromodal.show('modal-tax-consent')
          }
        })
      }
    }
    
    const getIsEarningsDisabled = async () => {
      await disableEarningsTab().then((data) => {
        return isEarningsDisabled.value = data
      });
    }

    const setOneStatusRef = async () => {
      const status = await getOneStatus();
      oneStatus.value = status
    }

    const setFunnelProgressData = async () => {
      let data = await proxyApi.post('/getFunnelProgress', encodeWithParam({
        candidateID: candidate.locationPartnerId
      }))
      console.log("funnel Progress inside Dashboard == ",data.data.result)
      funnelProgress.value = data?.data?.result;
    }

    const checkIsShowResourcesSection = () => {
      const hasNotApprovedOrWithdrawn = funnelProgress?.value?.filter(data =>
        [driverFunnelStatus.APPROVED_MANUAL, driverFunnelStatus.APPROVED_AUTO, driverFunnelStatus.WITHDRAWN].includes(data.candidateStatus)
      ).length == 0;
      return hasNotApprovedOrWithdrawn
    }

    const isApprovedDriver = () => {
      const isApprovedStatus = funnelProgress?.value?.filter(data =>
        [driverFunnelStatus.APPROVED_MANUAL, driverFunnelStatus.APPROVED_AUTO].includes(data.candidateStatus)
      )?.length > 0;
      const hasPayeeID = (payeeID !== 'null');
      const approvedDriver = (isApprovedStatus || hasPayeeID)
      return approvedDriver;
    }

    return {
      unfinishedNumber,
      pendingNumber,
      docsToSign,
      progressStage,
      unfinished,
      allowTaxesDownload,
      handleSubmit,
      handleConfirm,
      micromodal,
      oneStatus,
      garnishment,
      oneEnabled,
      oneIntegration,
      onePaymentStarted,
      isOneActive,
      payeeID,
      gTagONE,
      onePaymentDate,
      oneSignUp,
      router,
      isEarningsDisabled,
      branchStatus,
      showOneValuePropBanner,
      checkIsShowResourcesSection,
      isApprovedDriver,
      isShowCheckrLink,
      loading
    };
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
  }
});
