
import { computed, defineComponent, onMounted, ref } from "vue";
import DocumentTable from "@/modules/documents/DocumentTable.vue";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import Loader from "@/components/modals/Loader.vue";
import { IconCheck, IconArrowRight } from "@/components";
import { gTagEvent } from "@/util/GoogleAnalytics";

// import IconArrowRight from "@/components/icons/IconArrowRight.vue";


export default defineComponent({
  name: "SignedDocuments",
  components: {
     DocumentTable, 
     Loader, 
    //  IconArrowRight 
     IconCheck,
      // IconWarning,
       IconArrowRight
     },
  setup() {
    const locTemplates = ref([]);
    const isLoading = ref(true)
    
    proxyApi.get("/getOnBoardingDocuments").then(({data}) => {
      locTemplates.value = data.result.filter( (item:any) => {
        return !["W9","Insurance"].includes(item.title)
      })
      isLoading.value = false
    });

    const docsToSign = computed(() => {
      return locTemplates.value.filter((item: any) => { 
        return item.optional && (item.signature == "" || item.signature == null)
      }) 
    });
    
    const signedDocs = computed(() => {
      return locTemplates.value.filter((item: any) => { 
        return (item.optional && item.signature != null) || item.signature != null
      })
    });

    onMounted(() => {
      gTagEvent("View Signed Documents", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
    })

    return { signedDocs, docsToSign, isLoading };
  },
});
