
import { defineComponent, onMounted } from "vue";
import { IconAbout } from "@/components"
export default defineComponent({
    name: "PrimaryAccountProcessing",
    components: {
        IconAbout
    },
    emits: ['exit'],
    setup(props, {emit}) {
        const closeModal = () => {
            document.body.style.overflow = 'auto'
            emit('exit')
        }
        return {closeModal}
    }
});
