
import { AxiosResponse } from "axios";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { W9Model } from "@/modules/signup/w9/W9Model";
import { decrypt, encodeWithParam, encodeWithParamutf } from "@/util/DataEncodeDecode";
import {
  TextFieldValidated,
  StateSelectorValidated,
  // Signature,
  IconWarningOctagon,
} from "@/components";
import router from "@/router";
import { proxyApi } from "@/http/http.config";
import { state } from "@/harlem/stores";
import { mutation } from "@/harlem/mutation";
import SelectorValidated from "@/components/forms/SelectorValidated.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useRoute } from 'vue-router';
import { setStatus } from "@/services/stage.service";
import { gTagEvent } from "@/util/GoogleAnalytics";



// /**
//  * Generic W9 Form Component. Component handles save functions automatically,
//  * but exposes a cancel emit that can be used to capture routing, popup,
//  * or other actions as deemed necessary.
//  * props:
//  *    @cancel
//  */
export default defineComponent({
  name: "W9Form",
  components: {
    SelectorValidated,
    // Signature,
    StateSelectorValidated,
    IconWarningOctagon,
    TextFieldValidated,
    Form,
    Field,
    PulseLoader,
  },
  setup() {
    const taxClassifications = [
      {
        value: "single-llc",
        text: "Individual/sole proprietor or single-member LLC",
      },
      { value: "c-corporation", text: "C Corporation" },
      { value: "s-corporation", text: "S Corporation" },
      { value: "partnership", text: "Partnership" },
      { value: "trust", text: "Trust/Estate" },
      { value: "llc", text: "Limited Liability Company" },
    ];
    const route = useRoute();
    const signatureError = ref(false);
    const signature = ref(decodeURIComponent(state.user.signature));
    const initialValues = reactive({}) as { [key: string]: string };
    const initialTouched = reactive({}) as { [key: string]: boolean };
    const pageTimeStart = ref(0)
    const loading = ref(false);
    const einCopy = {
      title: "Employee Identification Number (EIN)",
      body: `<p>EIN is a nine-digit number assigned by the IRS to business entities operating in the United States. If you have a business entity that you would prefer to use instead of your individual tax ID (SSN) please provide that information here.</p><p>Many people will not use this.</p>`,
    };
    onMounted(() => {
      pageTimeStart.value = performance.now()
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          mutation.user.setIP(ip);
        });
      if (state.signup.IRSForm) {
        for (const [key, value] of Object.entries(state.signup.IRSForm)) {
          initialValues[key] = value as any;
          initialTouched[key] = true;
        }
      }
    });
    // const { mutate: signDocument, onDone: onSaved } =
    //   useMutation(SaveSignedDocument);

      proxyApi.get("/getW9Info").then(async ({data})=>{
      if(data.success){
      const decryptedSsn = await decrypt(data?.result[0]?.ssn)
      const fullName = data?.result[0]?.firstName + ' ' + data?.result[0]?.lastName
      initialValues.name = fullName;
      initialValues.businessName = data?.result[0]?.businessName ? data?.result[0]?.businessName: '';
      initialValues.address1 = data?.result[0]?.address1 ? data?.result[0]?.address1 : "";
      initialValues.address2 = data?.result[0]?.address2 ? data?.result[0]?.address2 : "";
      initialValues.city = data?.result[0]?.city;
      initialValues.state = data?.result[0]?.state;
      initialValues.zip = data?.result[0]?.postalCode;
      initialValues.taxClassification = data.result[0].federalTaxClassification ? data.result[0].federalTaxClassification : "single-llc";
      if (!(taxClassifications.some(el => el.value == data.result[0].federalTaxClassification))) {
        taxClassifications.push({"value": data.result[0].federalTaxClassification, "text": data.result[0].federalTaxClassification})
      }
      initialValues.ssn = decryptedSsn.replace(/(\d{3})(\d{2})(\d{4})/,"$1-$2-$3");
      initialValues.ein = data?.result[0].ein ? data?.result[0].ein.replace(/(\d{2})(\d{7})/, "$1-$2"):'';
     }
    });

    const cancel = () => {
         router.push({name: 'documents'})
    }

    const saveForm = (data: W9Model): void => {
      loading.value = true;
      if (signature.value === "") {
        signatureError.value = true;
        loading.value = false;
        return;
      }

      const formData = reactive({}) as any;
      formData["name"] = data.name;
      formData["businessName"] = data.businessName ? data.businessName : null;
      formData["address1"] = data.address1;
      formData["address2"] = `${data.address2 ? data.address2 : null}${
        data.address2 && ","
      } ${data.city ? data.city : null}, ${data.state ? data.state : null} ${
        data.zip ? data.zip : null
      }`;

      formData["taxClassification"] = data.taxClassification
        ? data.taxClassification
        : null;

      formData["taxClassificationOther"] = data.taxClassificationOther
        ? data.taxClassificationOther
        : null;

      formData["llcClassification"] = data.llcClassification
        ? data.llcClassification
        : null;

      formData["exemptions_payeeCode"] = data.exemptions_payeeCode
        ? data.exemptions_payeeCode
        : null;

      formData["exemptions_FATCA"] = data.exemptions_FATCA
        ? data.exemptions_FATCA
        : null;

      formData["accountNumbers"] = data.accountNumbers
        ? data.accountNumbers
        : null;

      formData["taxIDType"] = data.taxIDType ? data.taxIDType : null;
      formData["ssn"] = data.ssn ? data.ssn.split("-").join("") : "";
      formData["ein"] = data.ein ? data.ein.replace('-','') : null;
      formData["signature"] = signature.value;
      formData["userID"] = state.user.userId;
      formData["documentTemplateID"] = route.query.docTempId;
      formData["access_token"] = localStorage.getItem("access_token")
      gTagEvent("Submit Document: W9", "Documents", `partner_id: ${state.user.partnerId}`, true, `${Math.round((performance.now() - pageTimeStart.value)/1000)} seconds`)

      proxyApi.post("/taxForms", encodeWithParamutf(formData)).then(({ data }) => {
    
                router.push({
                name: 'documents',
                query: {locationId: state.user.locationId}})           
      });
    };

    const createSignature = (data: any) => {
      mutation.signup.setIRSForm(data);
       setStatus("SIGNATURE", "INCOMPLETE")
      router.push({name: 'createsignature'})
    };

    const schema = yup.object().shape({
      name: yup
        .string()
        .required()
        .trim()
        .min(2, "Must be longer than 2 characters")
        .label("Name"),
      businessName: yup
        .string()
        .notRequired()
        .trim()
        .label("Business Name"),
      taxClassification: yup
      .string()
      .notRequired()
      .label("Tax Classification"),
      taxClassificationOther: yup
        .string()
        .notRequired()
        .label("Other")
        .when("taxClassification", {
          is: (val: string) => val === "other",
          then: yup
            .string()
            .trim()
            .min(2, "Must be longer than 2 characters")
            .label("Other"),
          otherwise: yup.string().notRequired(),
        }),
      llcClassification: yup
        .string()
        .label("LLC")
        .when("taxClassification", {
          is: (val: string) => val === "llc",
          then: yup.string().required().label("LLC"),
          otherwise: yup.string().notRequired(),
        }),
      ssn: yup
        .string()
        .trim()
        .matches(
          /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/g,
          "SSN format invalid"
        )
        .typeError("SSN must be a number")
        .notOneOf([yup.ref("ein")], "SSN and EIN should not be the same")
        .required()
        .min(9)
        .label("SSN"),
      ein: yup
        .string()
        .notRequired()
        .matches(/^(|.{9,})$/, "Must be at least 9 Characters")
        .notOneOf([yup.ref("ssn")], "SSN and EIN should not be the same")
        .label("EIN (optional)"),
      address1: yup
        .string()
        .trim()
        .min(2, "Must be longer than 2 characters")
        .required()
        .label("Address"),
      address2: yup.string().trim().label("Address"),
      city: yup
        .string()
        .trim()
        .min(2, "Must be longer than 2 characters")
        .required()
        .label("City"),
      state: yup
        .string()
        .trim()
        .min(2, "Must be longer than 2 characters")
        .required()
        .label("State"),
      zip: yup
        .string()
        .trim()
        .min(5, "Zip Code must be 5 digits")
        .required()
        .label("Zip Code"),
    });

    return {
      cancel,
      saveForm,
      createSignature,
      state,
      signatureError,
      initialValues,
      initialTouched,
      schema,
      taxClassifications,
      loading,
      einCopy,
      signature
    };
  },
});
