import { proxyApi } from "../../http/http.config";
import { mutation } from "@/harlem/mutation";

const getWalletStatus = () => {
    return proxyApi.get('/getWalletStatus').then(async (data) => {
        const branchResponse = data.data.result[0]
        mutation.payment.setBranchResponse(branchResponse?.status); 
    })
}

const getActiveEarningAccounts = () => {
    return proxyApi.get('/getActiveEarningAccounts').then(async (data) => {
        const activeAccounts = data.data?.activeAccounts
        if (data.data?.activeAccounts) {
            mutation.payment.setActiveEarningAccounts(activeAccounts)
        }
    })
}

export const getDataForAppState = async () => {
    await getWalletStatus()
    await getActiveEarningAccounts()
}