import gql from "graphql-tag";

export const GetPersonalDocData = gql`
  query GetPersonalDocData {
    partners {
      id
      driversLicenses(where: { status: { _eq: "ACTIVE" } }) {
        id
        expiration
        number
        state
        country
        numberLastFour
      }
      insuranceExpiry
    }
  }
`;

export const GetDashboardSignedDocsByPK = gql`
  query GetDashboardSignedDocsByPK($docId: uuid!) {
    signedDocuments_by_pk(id: $docId) {
      documentContents
      signedOn
      signature
      documentTemplate {
        title
        originalID
      }
    }
  }
`;

export const GetDashboardSignedDocVersionsByOriginalID = gql`
  query GetDashboardSignedDocVersionsByOriginalID(
    $originalID: uuid!
    $userId: uuid!
  ) {
    signedDocuments(
      where: {
        _and: {
          documentTemplate: { originalID: { _eq: $originalID } }
          clientLocations_partner: { partner: { userID: { _eq: $userId } } }
        }
      }
      order_by: { documentTemplate: { version: desc } }
    ) {
      id
      signedOn
      documentTemplate {
        version
        title
        id
      }
    }
  }
`;

export const GetDashboardSignedDocs = gql`
  query GetDashboardSignedDocs($userId: uuid!) {
    documentTemplates(
      where: {
        signedDocuments: {
          clientLocations_partner: { partner: { userID: { _eq: $userId } } }
        }
      }
      order_by: { originalID: desc, version: desc }
      distinct_on: originalID
    ) {
      version
      originalID
      title
      signedDocuments(
        where: {
          clientLocations_partner: { partner: { userID: { _eq: $userId } } }
        }
        order_by: { created_at: desc }
      ) {
        id
        signature
        signedOn
        created_at
        clientLocations_partner {
          location {
            name
            client {
              name
            }
          }
        }
      }
    }
  }
`;

export const GetDashboardDocsForResub = gql`
  query GetDashboardDocsForResub($userId: uuid) {
    documentTemplateUse(where: { partner: { userID: { _eq: $userId } } }) {
      documentTemplate: template {
        title
        id
        version
        status
      }
      id
      clientLocationID
      partner {
        userID
      }
      location {
        name
        client {
          name
        }
      }
    }
  }
`;

export const GetResubDocumentByPk = gql`
query GetResubDocByPk($docId: uuid!) {
  documentTemplates_by_pk(id: $docId) {
    html
    id
    title
    text
    originalID
  }
}
`;

export const GetInsuranceFileID = gql`
  query GetInsuranceFileID {
    partnerFiles(where: { funnelFile: { label: { _eq: "insurance" } } }) {
      fileID
      locationPartnerID
    }
  }
`;
