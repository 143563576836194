import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "checklist-row_title" }
const _hoisted_2 = { class: "checklist-row_arrow" }
const _hoisted_3 = { class: "checklist-button-container" }
const _hoisted_4 = { class: "mobile-checklist_nav-btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCheck = _resolveComponent("IconCheck")!
  const _component_IconArrowRight = _resolveComponent("IconArrowRight")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isMobile() ? 'mobile-module_title check' : 'desktop-module_title')
    }, "Enrollment Forms", 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isMobile() ? '' : 'desktop-checklist_layout')
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardGroupData, (card) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.isMobile() ? 'mobile-checklist_row' : 'desktop-checklist_row'),
          key: card.id
        }, [
          (_ctx.currentStep.label === card.typename)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(card.style + ' currentStep')
              }, null, 2))
            : (card.status === 'COMPLETE' || card.id < _ctx.currentStep.stepIndex)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(card.style + ' finishedStep')
                }, [
                  _createVNode(_component_IconCheck)
                ], 2))
              : (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(card.style)
                }, _toDisplayString(card.id), 3)),
          _createVNode(_component_router_link, {
            to: card.destination,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isMobile())),
            class: _normalizeClass(
        _ctx.isBtnDisabled(card) ?
          // button IS disabled depending if its mobile or desktop
          _ctx.isMobile() ? 'mobile-checklist_row-btn disabled' : 'desktop-checklist_row-btn disabled' :
          // button is NOT disabled depending if its mobile or desktop
          _ctx.isMobile() ? 'mobile-checklist_row-btn' : 'desktop-checklist_row-btn'
      )
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(card.title), 1),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_IconArrowRight)
              ])
            ]),
            _: 2
          }, 1032, ["to", "class"])
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "mobile-checklist_nav-btn-next text",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.nextRouteOnMobile()))
        }, " Next ")
      ], 512), [
        [_vShow, _ctx.isMobile()]
      ])
    ])
  ], 64))
}