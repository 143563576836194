
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import AddressFields from "../../components/forms/AddressFields.vue";
import { DateTime } from "luxon";
// import FileViewer from "@/components/files/FileViewer.vue";
import { state } from "@/harlem/stores";
import { proxyApi, signup } from "@/http/http.config";
import { mutation } from "@/harlem/mutation";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { Field } from "vee-validate";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import { IconArrowLeft } from "@/components";
import { gTagEvent } from "@/util/GoogleAnalytics";
import router from "@/router";
import { SUCCESS_CODES } from "../login/constants";

export default defineComponent({
  name: "Profile",
  components: {
    Field,
    AddressFields,
    IconArrowLeft,
  },
  data() {
    return {
      editEmail: false,
      editIsDisabled: true,
      editAddress: false,
      bussinessShow: false,
      editBusinessAddress: false,
      isBusinessRegistered: false,
      businessAddressData: {
        id: "",
        address1: "",
        address2: "",
        state: "",
        city: "",
        county: "",
        zipcode: ""
      },
      addressData: {
        id: "",
        address1: "",
        address2: "",
        state: "",
        city: "",
        county: "",
        zipcode: ""
      },
      emailValidationMessage: "",
    };
  },
  methods: {
    businessRegister(){   
               localStorage.setItem('NewBusinessRegister', 'true')

      router.push({name: 'NewBusinessRegister'})
    },
    addressSaved() {
      this.editAddress = false;
      this.editBusinessAddress = false;
      this.fetchPartnerData();
    },
    edit(val) {
      gTagEvent("Edit Account Info", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
      switch (val) {
        case "email":
          this.editEmail = true;
          this.emailValidationMessage = "";
          break;
        case "address":
          this.editAddress = true;
          break;
        case "businessAddress":
          this.editBusinessAddress = true;
          break;
      }
    },
    save(val) {
      switch (val) {
        case "email":
          this.saveEmail();
          break;
        case "address":
          this.saveAddress();
          break;
        case "businessAddress":
          this.saveAddress();
          break;
      }
      gTagEvent("Save Account Info", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
    },
    emailValidation(value) {
      this.emailValidationMessage = "";
      this.editIsDisabled = true;
      const regex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      const start = /^([ ]+)/;
      const end = /([ ]+$)/;
      const space = /\s/;
      const ending = /^.*\.com$/;
      const startingchar = /^[^\W_].*/
      if(value.length === 0) {
         return this.emailValidationMessage="Email cannot be blank"
      } 
      if (!regex.test(value)) {
        return this.emailValidationMessage="Not a valid email address"
      }
      if (start.test(value) || end.test(value) || space.test(value)) {
        return this.emailValidationMessage = "Email cannot include spaces"
      }
      if(!ending.test(value) || !startingchar.test(value)){
        return this.emailValidationMessage = "Not a valid Email address"
      }
      if(this.partnerData.dbEmail != value && regex.test(value)) {
        return this.editIsDisabled = false;
      }
      return this.emailValidationMessage=""
    },

    isMobile() {
      return checklistMethods.isMobile()
    },
    cancelEdit() {
      this.editAddress = false;
    },
    cancelEditBusiness() {
      this.editBusinessAddress = false;
    },
    cancel() {
      this.editEmail=false;
      this.fetchPartnerData()
    },
    async saveEmail() {
      this.emailValidationMessage = "";
      if (this.partnerData.email != ""){
        const result = await signup.post("/checkEmail",encodeWithParam({ email: this.partnerData.email.toLowerCase() }))
        if(!SUCCESS_CODES.includes(result.data.code)){
          mutation.user.setEmail(this.partnerData.email);
          proxyApi
          .post(
            "/updateEmail",
            encodeWithParam({ email: this.partnerData.email })
          )
          .then(() => {
            gTagEvent("Save Email - Account Info", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
            this.editEmail = false;
            return this.partnerData.email;
          });
        }
        else{
          this.emailValidationMessage = 'This record already exists'
          this.editEmail = true;
        }

      }

    },
    fetchPartnerData() {
      proxyApi.get("/getFullProfileInfo").then(({ data }) => {
        if(data.businessAddress.length === 0){
          this.isBusinessRegistered = true;
        }
        
        if (data.profile.length) {
          this.partnerData.username = data.profile[0].username;
          this.partnerData.businessName = data.profile[0].businessName;
          this.partnerData.email = this.partnerData.dbEmail = data.profile[0].email;
          this.partnerData.phone = data.profile[0].phone;
          this.partnerData.firstName = data.profile[0].firstName;
          this.partnerData.middleName = data.profile[0].middleName || "";
          this.partnerData.lastName = data.profile[0].lastName;
          this.partnerData.address1 = this.addressData.address1 = data.profile[0].address1;
          this.partnerData.address2 = this.addressData.address2 = data.profile[0].address2;
          this.partnerData.city = this.addressData.city = data.profile[0].city;
          this.partnerData.state = this.addressData.state = data.profile[0].state;
          this.partnerData.county = this.addressData.county = data.profile[0].county;
          this.partnerData.postalCode = this.addressData.zipcode = data.profile[0].postalCode;
          this.partnerData.registeredEmail = data.profile[0].registeredEmail;
          this.partnerData.addressID = this.addressData.id = data.profile[0].addressID;
          this.partnerData.businessAddress1 = this.businessAddressData.address1 = data.businessAddress[0]?.address1;
          this.partnerData.businessAddress2 = this.businessAddressData.address2 = data.businessAddress[0]?.address2;
          this.partnerData.businesscity = this.businessAddressData.city = data.businessAddress[0]?.city;
          this.partnerData.businesspostalcode = this.businessAddressData.zipcode = data.businessAddress[0]?.postalCode;
          this.partnerData.businessstate = this.businessAddressData.state = data.businessAddress[0]?.state;
          this.partnerData.businessCounty = this.businessAddressData.county = data.businessAddress[0]?.county;
          this.partnerData.businessAddressID = this.businessAddressData.id = data.businessAddress[0]?.id;
        }
      });
    },
    saveAddress() {
      this.fetchPartnerData();
    },
  },
  mounted() {
    this.fetchPartnerData();
    gTagEvent("View Account Info Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
  },
  setup() {
    let partnerData = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined | any;
    };
    const dateDisplay = (date: string) => {
      return DateTime.fromFormat(date, "yyyy-MM-dd").toFormat(
        "'Exp.' dd MMM yyyy"
      );
    };
    const bussDetail = computed(() => {
      if (partnerData?.businessName?.length > 0) {
        return true;
      }
      return false;
    });

    // onMounted(() => {
    //   proxyApi.get("/getFullProfileInfo").then(({ data }) => {
    //     console.log(data);
    //     if (data.profile.length) {
    //       partnerData.username = data.profile[0].username;
    //       partnerData.businessName = data.profile[0].businessName;
    //       partnerData.email = data.profile[0].email;
    //       partnerData.phone = data.profile[0].phone;
    //       partnerData.firstName = data.profile[0].firstName;
    //       partnerData.middleName = data.profile[0].middleName || "";
    //       partnerData.lastName = data.profile[0].lastName;
    //       partnerData.address1 = data.profile[0].address1;
    //       partnerData.address2 = data.profile[0].address2;
    //       partnerData.city = data.profile[0].city;
    //       partnerData.state = data.profile[0].state;
    //       partnerData.postalCode = data.profile[0].postalCode;
    //       partnerData.registeredEmail = data.profile[0].registeredEmail;
    //       partnerData.addressID = data.profile[0].addressID;
    //       partnerData.businessAddress1 = data.businessAddress[0]?.address1;
    //       partnerData.businessAddress2 = data.businessAddress[0]?.address2;
    //       partnerData.businesscity = data.businessAddress[0]?.city;
    //       partnerData.businesspostalcode = data.businessAddress[0]?.postalCode;
    //       partnerData.businessstate = data.businessAddress[0]?.state;
    //       partnerData.businessAddressID = data.businessAddress[0]?.id;
    //     }
    //   });
    // });

    return {
      partnerData,
      dateDisplay,
      bussDetail
    };
  },
});
