
import { computed, defineComponent, onMounted, reactive, ref, watchEffect } from "vue";
import authenticationService from "@/services/authentication.service";
import { Form, Field, validate } from "vee-validate";
import { FieldError, IconEyeClosed, IconEyeOpen,OtpScreen,OtpExpirationHelpText } from "@/components";
import * as yup from "yup";
import { useRoute, useRouter } from "vue-router";
import { decode, encodeWithParam } from "@/util/DataEncodeDecode";
import { mutation } from "@/harlem/mutation";
import { state } from "@/harlem/stores";
import { setStatus } from "@/services/stage.service";
import { signup } from "@/http/http.config";
import { proxyApi } from "@/http/http.config";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { getDataForAppState } from "./GetDataForAppState";
import { FunnelStatus } from "@/util/FunnelStatus";
import { setPaymentAgreementComplete } from "@/harlem/mutation/payment.mutation";
import { SUCCESS_CODES } from "./constants";
import micromodal from "micromodal";

export default defineComponent({
  name: "Login",
  components: { Form, Field, FieldError, IconEyeClosed, IconEyeOpen,OtpScreen ,OtpExpirationHelpText},
  setup() {
    var countLeft = ref<boolean | string>(false);
    var errorMessage = ref(false);
    const router = useRouter();
    const route = useRoute();
    const invalid = ref(false);
    const error = ref(false);
    const header = ref(true);
    const expiredPassword = ref(false);
    const passwordHidden = ref(true);
    const username = ref("");
    const password = ref("");
    const isLoginButtonDisabled = ref(true);
    const showsendotp=ref(false);
    const otp=ref("");
    let lastFourPhone=ref("")
    // const isVerifyButtonDisabled = ref(true);
    const isFreezingTime=ref(false);
    let invalidAcc=ref(false);
    const invalidOtp=ref(false)

    onMounted(() => {
      gTagEvent("Enter Login Page", "Login", true)
      localStorage.clear()
      sessionStorage.clear();
      // validations();

  })
    watchEffect(() => {
      isLoginButtonDisabled.value = (username.value?.length > 0 && password.value?.length > 7) ? false : true;
      // isVerifyButtonDisabled.value= (otpValues.value?.length == 6 ) ? false : true;
      // isVerifyButtonDisabled.value = otpValues?.value?.some(value => value=== '');
    })

    const signIn = async () => {
      invalidOtp.value=false;
      sessionStorage.setItem('notified', 'true')
      let obj = {
        username: username.value,
        password: password.value,
        otp:otpValue.value //otpValues.value.join('')
      };
      const beforeDocumentCompleteSteps = [
        "PERSONAL_DETAILS",
        "BUSINESS_DETAILS",
        "CONFIRMATION",
        "SIGNATURE",
        "DOCUMENTS",
        "PAYMENT_AGREEMENT",
      ]

      await authenticationService
        .login(encodeWithParam(obj))
        .then(async (data: any) => {
          if (data.data.success) {
            const userObj = decode(data.data.result);
          //  let resetpassword = decodeURIComponent(userObj.user.reset_password);
          //   if(resetpassword === 'true'){
          //     sessionStorage.setItem("partnerId", userObj.user.partnerID);
          //     sessionStorage.setItem("username", obj.username);
          //     sessionStorage.setItem("accesstoken", userObj.token);
          //     router.push({ name: "questionsscreen" });
          //   }
            // else{
            // showsendotp.value=true
            invalid.value = false;
            expiredPassword.value = false;
            localStorage.setItem("username", username.value);
            // const userObj = decode(data.data.result);
            userObj.user.firstName = decodeURIComponent(userObj.user.firstName);
            userObj.user.lastName = decodeURIComponent(userObj.user.lastName);
            
            //const paramObj = { username: state.user.username }
            //let res=await signup.post("/generateOTP", encodeWithParam(paramObj))
            //console.log(res,state.user.username,'res');
            gTagEvent("Login Successful", "Login", userObj?.user?.partnerID)
            if (route.query.service == "appeals") {
              singletonObject(userObj, obj);
              return router.push({
                name: "appeals",
              });
            } else if (userObj.user.candidateStatus == FunnelStatus.ENROLLMENT_COMPLETE || userObj.user.candidateStatus == FunnelStatus.ENROLLMENT_ON_HOLD){
              let thresholdCheck:any= await signup.post("/thresholdCheck", encodeWithParam({ clientLocationID: userObj.user.clientLocationID }))
                if(thresholdCheck.data.success && !thresholdCheck.data.allowSignup){
                  singletonObject(userObj, obj);
                  sessionStorage.setItem("EnrollmentonHold","true")
                  return router.push({ name: 'candidateDashboard' })
                }
                proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.PERSONAL_DETAILS_PENDING}));
                userObj.user.candidateStatus = FunnelStatus.PERSONAL_DETAILS_PENDING
            }   
            else if (userObj.user.partnerStatus=="DRIVER_DEACTIVATED") {
              singletonObject(userObj, obj);
              return router.push({ name: "dashboard"})
            }
            else if (userObj.user.payeeID) {
              singletonObject(userObj, obj);
              return router.push({ name: "dashboard" })
            }          
            
            if (isPasswordExpired(userObj.user.password_expire_on)) {
              authenticationService.logout();
              expiredPassword.value = true;
            } else if (beforeDocumentCompleteSteps.includes(userObj.user.candidateStep)) {
              singletonObject(userObj, obj);
              await findLocationStatus(
                userObj.user.clientLocationID,
                userObj.user.candidateStep,
                userObj.user.candidateStatus
              );
            } else {
              singletonObject(userObj, obj);
              findLocationStatus(
                userObj.user.clientLocationID,
                userObj.user.candidateStep,
                userObj.user.candidateStatus
              );
              verifyStatus(userObj.user);
              setStatus(
                userObj.user.candidateStep,
                userObj.user.candidateStatus
              );
            }
          // }
          } else {
            // const { data } = await signup.post("/checkFailedAttempts", encodeWithParam({ username: obj.username }));
            // if(!data.success){
            //   errorMessage.value = data.message;
            //   error.value = true;
            //   invalid.value = false;
            //   header.value = true;
            // }
            // if (data.accountLocked) {
            //   return router.push({
            //     name: "accountLocked",
            //   });
            // }else{
            //   countLeft.value = numToString(data.result[0].failed_login_attempts);
            //   invalid.value = true;
            //   header.value = false;
            //   error.value = false;
            // }

            if(data.data.accountLocked){
                  return router.push({
                    name: "accountLocked",
                    });
                }

              if(!data.data.success && !data.data.result){
                errorMessage.value = data.data.message;
                error.value = true;
                invalid.value = false;
                header.value = true;
              }else{
                invalidOtp.value=true;
                countLeft.value = data.data.result[0].failed_login_attempts;
                invalid.value = true;
                header.value = false;
                error.value = false;
              }
            
            if (data.data.forgotPassword) {
              invalid.value = true;
              return router.push({
                name: "forgotPassword",
                query: { username: username.value },
              });
            }
          }
        })
        .catch(({ response }) => {
          if (response?.data?.code === "InvalidCredentials") {
            invalid.value = true;
          }
        });
    };

    const isPasswordExpired = (date) => {
      var g1 = new Date();
      var g2 = new Date(date);
      if (g1.getTime() < g2.getTime()) return false;
      else if (g1.getTime() > g2.getTime()) return true;
    };

    const redirectToForgotPassword = () => {
      return router.push({ name: "forgotPassword" });
    };

    const viewPassword = computed(() => {
      return passwordHidden.value ? "password" : "text";
    });
    const togglePassword = () => (passwordHidden.value = !passwordHidden.value);
    const initialValues = reactive({
      username: "",
      password: "",
      
    });
    const schema = yup.object().shape({
      username: yup.string().trim().required().label("Username"),
      password: yup.string().required().min(8).label("Password"),
    });



    const singletonObject = (data, obj) => {
      mutation.user.setGarnishment(data.user.garnishment);
      mutation.user.setIsOneIntegrated(data.user.is_one_integration_enabled);
      mutation.user.setOneStatus(data.user.one_status);
      mutation.user.setIsOneActive(data.user.is_active);
      
      mutation.user.setOneStartDate(data.user.one_pay_start_date);

      mutation.user.setPartnerId(data.user.partnerID);
      mutation.user.setUserId(data.user.userID);
      mutation.user.setEmail(data.user.email);
      mutation.user.setCommEmail(data.user.comm_email);
      mutation.user.setFirstName(data.user.firstName);
      mutation.user.setLastName(data.user.lastName);
      mutation.user.setPhone(data.user.phone);
      mutation.user.setLocationId(data.user.clientLocationID);
      mutation.user.setLocationName(data.user.locationName as string);
      mutation.user.setLocationPartnerId(data.user.clientLocationPartnerID);
      mutation.user.setUsername(obj.username);
      if(data.user.electronicSignature &&  data.user.electronicSignature.includes('%'))
      {
      mutation.user.setSignature(decodeURIComponent(data.user.electronicSignature));
      mutation.mergeTags.setPartnerSignature(decodeURIComponent(data.user.electronicSignature));
      }
      else
      {
        mutation.user.setSignature(data.user.electronicSignature);
        mutation.mergeTags.setPartnerSignature(data.user.electronicSignature); 
      }
      mutation.mergeTags.setPartnerFullName(
        `${data.user.firstName} ${data.user.lastName}`
      );
      mutation.mergeTags.setPartnerFirstName(data.user.firstName);
      mutation.mergeTags.setPartnerLastName(data.user.lastName);
      mutation.mergeTags.setPartnerEmail(
        data.user.registeredEmail?.toLowerCase().trim()
      );
      if (data.user.current_payment_provider) {
        mutation.payment.setPaymentAgreementComplete(true)
      } else {
        mutation.payment.setPaymentAgreementComplete(false)
      }
      getDataForAppState()
    };

    const verifyStatus = (user) => {
      if (
        user.candidateStatus == "ON_HOLD" &&
        user.candidateStep == "COMPLETED"
      ) {
        gTagEvent("Partner Waitlisted", "Login", user?.partnerID)
      } else if (user.candidateStep == "APPROVED") {
        gTagEvent("Partner Approved", "Login", user?.partnerID)
      } else if (
        user.candidateStep == "SCREENING" &&
        ["MVR_DENIED", "CBC_DENIED", "DENIED"].includes(user.candidateStatus)
      ) {
        gTagEvent("Partner Rejected", "Login", user?.partnerID)
      }
    };

    const findLocationStatus = async (locationID, step, status) => {
      const allowedStatus = [
        "APPLICATION",
        "PERSONAL_DETAILS",
        "DOCUMENTS",
        "PAYMENT_AGREEMENT",
        "INSURANCE_VERIFICATION",
        "SCREENING",
        "COMPLETED",
        "APPROVED",
      ];

      const { data } = await signup.post("/validateLocationStatus", encodeWithParam({ clientLocationID: locationID }));
      if (data.success) {
        if (!step && !status) {
          return router.push({ name: "dashboard", query: { locationId: state.user.locationId } });
        }



        const { isRecruiting, isOnboarding, deleted } = data.result[0];
        if (!isRecruiting && (!isOnboarding || deleted)) {
          if (status === "Enrollment On-Hold") {
            return redirectToNoVacancy();
          } 
          else {
            return router.push({ name: "candidateDashboard" })
          }

        } 
        // else if (isRecruiting && step === "Account Creation" && status === "Enrollment On-Hold") {
        //   proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.ENROLLMENT_COMPLETE}));
        //   proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.PERSONAL_DETAILS_PENDING}));
        //   return router.push({ name: "personalDetails", query: { locationId: state.user.locationId } });
        // }

        return router.push({ name: "candidateDashboard" })
      }
    };
    
    const redirectToNoVacancy = () => {
      return router.push({
        name: "locationUpdate",
        query: { locationId: state.user.locationId },
      });
    };

    const numToString = (num: number) => {
      switch (num) {
        case 0: return "zero";
        case 1: return "one";
        case 2: return "two";
        case 3: return "three";
        default: return "";
      }
    }
    const  close=() => {
				showsendotp.value=false;
          return router.push({ name: "login" });
				}

    const  login=async () => {
      invalidAcc.value = false;
        const paramObj = { username: username.value }
        let obj = {
        username: username.value,
        password: password.value
      }
        signup.post("/validate-user", encodeWithParam(obj)).then((res)=> {
          const { data } =res
          if(data?.accountLocked){
            sessionStorage.setItem('notified', 'true')
              return router.push({name: "accountLocked"});
          }
          if(data.success){
            lastFourPhone.value = data?.result ? data.result:'';
            signup.post("/generateOTP", encodeWithParam(paramObj)).then((result) => {
            if(!SUCCESS_CODES.includes(result.data.code)) {
                isFreezingTime.value = true;
              }
            else{
            showsendotp.value=true;
            invalid.value=false;
            }
        })
          }
          else{
            invalidAcc.value=true;
          }
        })



			}

        const getOTP=async()=>{
          const paramObj = { username: username.value }
          await signup.post("/generateOTP", encodeWithParam(paramObj))
        .then((result) => {
          if(!SUCCESS_CODES.includes(result.data.code)) {
            isFreezingTime.value = true;
          }
        })
      }

    //    const checkAllFieldsFilled=()=> { 
    //   isVerifyButtonDisabled.value = otpValues.value.some(value => value === '');
    // }
    const otpValue = ref('');
    const isOtpComplete = computed(() => {
      return otpValue.value.length === 6; 
    });
    const handleOtpChange = (otp: string) => {
      otpValue.value = otp;
    };
    const validations=()=>{
      isFreezingTime.value=false;
      invalid.value=false;
    };

    const openHelpTextDoc=()=>{
      micromodal.show("otp-expiration-help-text");
    };
    const closeModal = ()=>{
            micromodal.close("otp-expiration-help-text");
        }
        
        
    return {
      signIn,
      togglePassword,
      initialValues,
      invalid,
      header,
      error,
      errorMessage,
      expiredPassword,
      schema,
      viewPassword,
      passwordHidden,
      redirectToForgotPassword,
      countLeft,
      username,
      password,
      isLoginButtonDisabled,
      //otpValues, handleInput, handleBackspace,
      showsendotp,close,
      // verify,
      otp,login,
      // initialValues1,
      // schema1,
      //isVerifyButtonDisabled,
      // checkAllFieldsFilled
      otpValue,
      isOtpComplete,
      handleOtpChange,
      getOTP,
      isFreezingTime,
      invalidAcc,
      invalidOtp,
      validations,
      openHelpTextDoc,
      closeModal,
      lastFourPhone
    };
  },
});
