/*
#B36A16: Orange
#E71742: Red
#2A8703: Green
*/
import { sendToOracle } from "@/components/OracleButtonHelper";
export const cardBody = {
    personalDetailsIncomplete: {
      title: "Enrollment Forms",
      titleColor: "#3B4459",
      subtitle: "Personal information incomplete",
      subtitleColor: "#B36A16",
      subtitleBkgdColor: "#FFF9E9",
      description: "",
      buttonText: "Resume",
      buttonLink: "personalDetails",
      icon: "IconIncomplete",
      isCandidateDashboard: true,
    },
    personalDetailsError: {
      title: "Enrollment Forms",
      titleColor: "#3B4459",
      subtitle: "Errors found in personal information",
      subtitleColor: "#B36A16",
      subtitleBkgdColor: "#FFF9E9",
      description: "",
      buttonText: "Review and Edit",
      buttonLink: "kycError",
      icon: "IconAppWarningTriangle",
      iconColor: "#3B4459",
      isCandidateDashboard: true
    },
    personalDetailsFailed: {
      title: "Enrollment Forms",
      titleColor: "#3B4459",
      subtitle: "Unable to verify personal information",
      subtitleColor: "#E71742",
      subtitleBkgdColor: "#FCE8E9",
      description: "Unfortunately, your enrollment can’t be processed at this time.",
      buttonText: null,
      buttonLink: "kycFailed",
      icon: "IconAppWarningTriangle",
      iconColor: "#3B4459",
      isCandidateDashboard: true,
    },
    signatureInProgress: {
      title: "Enrollment Forms",
      titleColor: "#3B4459",
      subtitle: "Signature needed",
      subtitleColor: "#B36A16",
      subtitleBkgdColor: "#FFF9E9",
      description: "",
      buttonText: "Sign",
      buttonLink: "signature",
      icon: "IconIncomplete",
      isCandidateDashboard: true,
    },
    documentsIncomplete: {
      title: "Enrollment Forms",
      titleColor: "#3B4459",
      subtitle: "Document review incomplete",
      subtitleColor: "#B36A16",
      subtitleBkgdColor: "#FFF9E9",
      description: "",
      buttonText: "Resume",
      buttonLink: "documents",
      icon: "IconIncomplete",
      isCandidateDashboard: true,
    },
    insuranceIncomplete: {
      title: "Enrollment Forms",
      titleColor: "#3B4459",
      subtitle: "Upload Auto Insurance Incomplete",
      subtitleColor: "#B36A16",
      subtitleBkgdColor: "#FFF9E9",
      description: "",
      buttonText: "Resume",
      buttonLink: "insuranceUpload",
      icon: "IconIncomplete",
      isCandidateDashboard: true,
    },
    chooseEarningsIncomplete: {
      title: "Enrollment Forms",
      titleColor: "#3B4459",
      subtitle: "Earnings Provider Selection Incomplete",
      subtitleColor: "#B36A16",
      subtitleBkgdColor: "#FFF9E9",
      description: "",
      buttonText: "Resume",
      buttonLink: "earningsAccount",
      icon: "IconIncomplete",
      isCandidateDashboard: true,
    },
    verificationInProgress: {
      title: "Enrollment Forms Complete",
      subtitle: "Verification in progress",
      subtitleBkgdColor: "#E6F1FC",
      subtitleColor: "#0C3C92",
      description: "We’ll get back to you after we review your personal information (approx. 24 hours).",
      buttonText: null,
      icon: "IconTime",
      isCandidateDashboard: true,
    },
    authInProgress: {
      title: "Background Check",
      titleColor: "#3B4459",
      subtitle: "In progress",
      subtitleColor: "#0C3C92",
      subtitleBkgdColor: "#E6F1FC",
      description: "Your background check may take 3–7 business days to complete.",
      buttonText: null,
      icon: "IconTime",
      isCandidateDashboard: true,
    },
    insuranceError: {
      title: "Authorization",
      titleColor: "#3B4459",
      subtitle: "Unable to verify",
      subtitleColor: "#B36A16",
      subtitleBkgdColor: "#FFF9E9",
      description: "",
      buttonText: "Upload",
      buttonLink: "editInsurance",
      icon: "IconAppWarningTriangle",
      iconColor: "#3B4459",
    },
    insuranceExpired: {
      title: "Authorization",
      titleColor: "#3B4459",
      subtitle: "Insurance expired",
      subtitleColor: "#B36A16",
      subtitleBkgdColor: "#FFF9E9",
      description: "Your auto insurance policy document is expired. You can try uploading a new one.",
      buttonText: "Upload Insurance",
      buttonLink: "editInsurance",
      icon: "IconAppWarningTriangle",
      iconColor: "#3B4459",
      includeInDashboard: true
    },
    authFailed: {
      title: "Enrollment status",
      titleColor: "#3B4459",
      subtitle: "Unable to authorize",
      subtitleColor: "#E71742",
      subtitleBkgdColor: "#FCE8E9",
      description: "Unfortunately, your Spark Driver™ enrollment couldn’t be authorized. You can contact Support for more details.",
      buttonText: "Contact Support",
      buttonLink: "oracle",
      icon: "IconAppWarningTriangle",
      iconColor: "#3B4459",
      includeInDashboard: true
    },
    locationDefaultPaymentFailedEarnings: {
      title: "Authorization",
      titleColor: "#3171FF",
      description: `<div style="margin-bottom: 10px">We are unable to link your Spark Driver™ account to an approved earnings account. </div><div>Without an active earnings account, you won't be able to participate on the Spark Driver platform at this time. Please take a look at our <a href="https://drive4spark.walmart.com/spark-driver-faq" target="_blank" style="color: #3171FF; text-decoration: underline;">FAQS</a> for more information.</div>`,
      buttonText: "Contact Support",
      buttonLink: "oracle",
      icon: "IconAppWarningTriangle",
      iconColor: "#3171FF",
      includeInDashboard: true
    },
    authWaitlist: {
      title: "Waitlist",
      titleColor: "#3B4459",
      subtitle: "Added to waitlist",
      subtitleColor: "#0C3C92",
      subtitleBkgdColor: "#E6F1FC",
      description: "This delivery zone is full. We’ll let you know when there’s an opening.",
      buttonText: null,
      icon: "IconAppWarningTriangle",
      iconColor: "#3B4459",
      includeInDashboard: true
    },
    authSuccess: {
      title: "Enrollment Status",
      titleColor: "#3B4459",
      subtitle: "Approved",
      subtitleColor: "#2A8703",
      subtitleBkgdColor: "#EAF3E6",
      description: "Congrats, you’re ready to get behind the wheel with the Spark Driver™ app!",
      buttonText: "Go To App",
      buttonLink: "sparkDriver",
      icon: "IconComplete",
      iconColor: "#3B4459",
      includeInDashboard: true,
    },
    dlExpired: {
      title: "Authorization",
      titleColor: "#3B4459",
      subtitle: "Driver's license expired",
      subtitleColor: "#B36A16",
      subtitleBkgdColor: "#FFF9E9",
      description: "Your driver’s license has expired. You can try uploading a new one.",
      buttonText: "Upload Driving License",
      buttonLink: "editDriversLicense",
      icon: "IconAppWarningTriangle",
      iconColor: "#3B4459",
      includeInDashboard: true
    },
    onePending: {
      title: "ONE Account Enrollment",
      titleColor: "#3B4459",
      subtitle: "In progress",
      subtitleColor: "#0C3C92",
      subtitleBkgdColor: "#E6F1FC",
      description: "Your ONE enrollment is being reviewed. ONE will reach out with next steps.",
      buttonText: null,
      icon: "IconTime",
    },
    oneNotApproved: {
      title: "ONE Account Enrollment",
      subtitle: "Not approved by ONE",
      subtitleColor: "#E71742",
      subtitleBkgdColor: "#FCE8E9",
      description: "You can continue using your current earnings account..",
      buttonText: 'ADD ACCOUNT',
      icon: "IconAppWarning",
    },
    branchUnclaimed: {
      title: "Finish Branch Wallet Setup",
      titleColor: "#3B4459",
      subtitle: "Needs attention",
      subtitleColor: "#E71742",
      subtitleBkgdColor: "#FCE8E9",
      description: "Please claim your Branch Wallet to continue with the application process.",
      buttonText: 'CLAIM BRANCH WALLET',
      buttonLink: "claimWallet",
      icon: "IconAppWarningTriangle",
      iconColor: "#3B4459",
    },
    noEarningSelected: {
      title: "Set Up Your Earnings Account",
      titleColor: "#3B4459",
      subtitle: "Needs attention",
      subtitleBkgdColor: "#FCE8E9",
      subtitleColor: "#E71742",
      description: "Choose how you’d like to receive your Spark Driver™ earnings.",
      buttonText: 'ADD ACCOUNT',
      buttonLink: 'chooseEarnings',
      icon: "IconAppWarningTriangle",
      iconColor: "#3B4459",
    },
    noEarningSelectedForApproved: {
      title: "Needs attention",
      titleColor: "#3B4459",
      description: "There’s an issue with your earnings account. Please set a new one now.",
      buttonText: 'ADD ACCOUNT',
      buttonLink: 'chooseEarnings',
      icon: "IconAppWarningTriangle",
      iconColor: "#E71742",
    },
    branchPending: {
      title: "Branch Account Application",
      titleColor: "#3B4459",
      subtitle: "In progress",
      subtitleColor: "#0C3C92",
      subtitleBkgdColor: "#E6F1FC",
      description: `Your Branch application is being reviewed. You can reach out to Branch at <a href="mailto:help@branchapp.com">help@branchapp.com</a> or <a href="tel:+18883349675">(888) 334-9675</a> for more details.`,
      buttonText: null,
      icon: "IconTime",
    },
    branchNotApproved: {
      title: "Branch Wallet Application",
      subtitle: "Not approved by Branch",
      subtitleColor: "#E71742",
      subtitleBkgdColor: "#FCE8E9",
      description: "You can continue using your current earnings account..",
      buttonText: 'ADD ACCOUNT',
      icon: "IconAppWarning",
    },
    allEarningMethodsNotApproved: {
      title: "Authorization",
      titleColor: "#3171FF",
      subtitleBkgdColor: "#FCE8E9",
      description: `<div style="margin-bottom: 10px">We are unable to link your Spark Driver™ account to an approved earnings account. </div><div>Without an active earnings account, you won't be able to participate on the Spark Driver platform at this time. Please take a look at our <a href="https://drive4spark.walmart.com/spark-driver-faq" target="_blank" style="color: #3171FF; text-decoration: underline;">FAQS</a> for more information.</div>`,
      buttonText: null,
      icon: "IconAppWarningTriangle",
      iconColor: "#3171FF",
    },

    currentlyZoneIsFull: {
      title: "Thanks for your interest",
      titleColor: "#3B4459",
      subtitle: null,
      subtitleColor: "#0C3C92",
      subtitleBkgdColor: "#E6F1FC",
      description: `<div style="margin-bottom: 12px ;padding:4px 12px 4px 0px">Your zone is currently full. When there’s an opening, we’ll let you know so you can continue the enrollment process.</div>`,
      buttonText: null,
      icon: "IconLocation",
    },

  statusDeactivated: {
      title: "Your Spark Driver™ account has been deactivated",
      titleColor: "#3B4459",
      subtitle: `Please take a look at our FAQ for more information.`,
      subtitleColor: "#3B4459",
      description: `<div style="margin-bottom: 10px;cursor: pointer;display: block;-webkit-appearance: none;background: #0c3c92;border: 1px solid #bfc2cd;color: #ffffff;border-radius: 44px;padding: 13px 18px;text-decoration: none;text-align: center;width: 100%;font-family: 'Rubik', sans-serif;font-style: normal;font-weight: normal;font-size: 16px;line-height: 19px;text-align: center;letter-spacing: 1px;text-transform: uppercase;"><a href='https://drive4spark.walmart.com/spark-driver-faq#appealing-a-deactivation' target="_blank" style="color:#ffffff;text-decoration: none;width:100%;display:block;height:100%">VIEW FAQ</a></div>`,
      icon: "IconAppWarningTriangle",
      iconColor: "#ff0000",
    },

  };