
import { computed, defineComponent, reactive, Ref, ref, onMounted } from "vue";
// import StateSelector from "@/components/forms/StateSelector.vue";
import { useRouter } from "vue-router";
import UploadModal from "@/components/modals/UploadModal.vue";
import { FilePreview } from "@/modules/signup/composables/documents.composable";
import micromodal from "micromodal";
import PreviewImageModal from "@/modules/signup/documents/PreviewImageModal.vue";
import imageCompression from "browser-image-compression";
import {
  ProfileUpdateForm,
  UpdateAddressForm,
  UpdateUserProfileForm,
} from "@/modules/profile/FormModels";
import { api, fileApi } from "@/http/http.config";
import FileViewer from "@/components/files/FileViewer.vue";
import { state } from "@/harlem/stores";
import TextFieldValidated from "@/components/forms/TextFieldValidated.vue";
import StateSelectorValidated from "@/components/forms/StateSelectorValidated.vue";
import * as yup from "yup";
import { Field, Form, ErrorMessage } from "vee-validate";
import Autocomplete from "vue3-autocomplete";
import FieldError from "@/components/forms/FieldError.vue";
import { isCountyRequired } from "@/util/AddressUtilities";
import { proxyApi } from "@/http/http.config";
import { encode,decode,encodeWithParam } from "@/util/DataEncodeDecode";
import { IconWarningOctagon } from "@/components";

import maska from "maska";

export default defineComponent({
  name: "EditProfile",
  components: {
    FieldError,
    Field,
    Form,
    IconWarningOctagon,
    ErrorMessage,
    StateSelectorValidated,
    TextFieldValidated,
    FileViewer,
    PreviewImageModal,
    UploadModal,
    Autocomplete,
    // StateSelector,
  },
  setup() {
    const router = useRouter();
    const selectedFileId = ref("");
    const selectedFileLabel = ref("");
    const previewFile: Ref<File | null> = ref(null);
    const selectedState = ref("");
    const restrictedAddress = ref(["PO","BOX","PO B","PO BO","P.O. BOX", "P.O BOX","PO. BOX", "POBOX" ,"PO BOX","PO    BOX","P.O BO","PO. B","P O Box"," PO  BOX","P.O. BOX","PO BOX","PO  B","PO  BO","P O BOX","P O B","P OB","P O B","P.O. B","PO B","P O","P O"])

    const profileForm: ProfileUpdateForm = reactive({
      addressID: "",
      username: "",
      email: "",
      businessName: "",
      profileImage: "",
      oldPass: "",
      password: "",
      phone: "",
      firstName:"",
      lastName:"",
      partnerBusinessID:"",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        county: "",
        country:"",
      },
    });

    const saveEdits = (data) => {
      let params ={
          username: profileForm.username,
          email:profileForm.email,
          phone:profileForm.phone,
          addressID:profileForm.addressID,
          address1:data.address1,
          address2:data.address2,
          state:data.state,
          city:data.city,
          postalCode:data.zip,
          country:profileForm.address.country,
          partnerBusinessID:profileForm.partnerBusinessID,
          businessName:profileForm.businessName
      }
      proxyApi.post('/updPartnerProfile',encodeWithParam(params)).then(()=>{
        router.push({ name: "profile" })
      })
    };

    const autoComplete = ref<InstanceType<typeof Autocomplete>>();
    const stateSelected = ref<InstanceType<typeof StateSelectorValidated>>();

    let addressSuggestions: any = ref([]);
    let addressSelectedFromSmarty = ref(false);
    const selectedAddress = (
      selectedAddress: any,
      validate: any,
      setFieldValue: any
    ) => {
      setFieldValue("address2", "");
      setFieldValue("city", "");
      setFieldValue("county", "");
      setFieldValue("state", "");
      setFieldValue("zip", "");
      if (Object.keys(selectedAddress.item).length > 0) {
        setFieldValue("address1", selectedAddress.item.streetLine);
        setFieldValue("address2", selectedAddress.item.secondary);
        setFieldValue("city", selectedAddress.item.city);
        setFieldValue("state", selectedAddress.item.state);
        setFieldValue("zip", selectedAddress.item.zipcode);
        profileForm.address.address1 = selectedAddress.item.streetLine;
        profileForm.address.address2 = selectedAddress.item.secondary;
        profileForm.address.city = selectedAddress.item.city;
        profileForm.address.state = selectedAddress.item.state;
        profileForm.address.zip = selectedAddress.item.zipcode;
        autoComplete.value?.setText(selectedAddress.item.streetLine);
        // addressDisable.value = false;
        stateSelected.value?.onChangeState(selectedAddress.item.state);
        selectedState.value = selectedAddress.item.state;
        addressSelectedFromSmarty.value = true;
        validate();
      } else {
        profileForm.address.address1 = "";
        profileForm.address.address2 = "";
        profileForm.address.city = "";
        profileForm.address.state = "";
        profileForm.address.zip = "";
        profileForm.address.county = "";
        // profileForm.address.countyRegistered = "";
      }
    };
    // let timeout = ref();
    // watch(
    //   () => profileForm.address.address1,
    //   (newText, oldText) => {
    //     if (newText !== oldText) {
    //       clearTimeout(timeout.value);
    //     }
    //     timeout.value = setTimeout(() => {
    //       const value = newText as string;
    //       getAddressSuggestion(value);
    //     }, 1000);
    //   }
    // );
    const getAddressSuggestion = (searchText: string) => {
            const regex = new RegExp(/^(PO.*BOX*)/);
      if (!restrictedAddress.value.includes(searchText.trim().toUpperCase()) && searchText.trim().toUpperCase().search(regex) === -1) {
          let searchParams = encodeWithParam({search: searchText})
        proxyApi
          .post("/getAddressSuggestions", searchParams)
          .then(({ data }: any) => {
            if (data.result.length > 0) {
              // addressSuggestions.value = [];
              addressSuggestions.value = data.result.map((item) => {
                return {
                  name: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
                  item,
                };
              });
            } else {
              addressSuggestions.value = [
                {
                  name: "Please enter a valid address",
                  item: {},
                },
              ];
            }
          })
          .catch(() => {
            addressSuggestions.value = [
              {
                name: "Please enter a valid address",
                item: {},
              },
            ];
          });
        } else {
          addressSuggestions.value = [];
          if (searchText.length === 0 && addressSelectedFromSmarty.value) {
            document.getElementById('address1')?.blur();
            profileForm.address.address1 = "";
            profileForm.address.address2 = "";
            profileForm.address.city = "";
            profileForm.address.state = "";
            profileForm.address.zip = "";
            profileForm.address.county ="";
            profileForm.address.countyRegistered = "";
            selectedState.value = '';
            stateSelected.value?.onChangeState(" ");
          }
        }
    };
    const autoCompleteError = (errors: any) => {
      return errors.address1 ? ["has-error"] : [];
    };
    const addressSuggestionComputed = computed(() => addressSuggestions.value);
    const onFileSelected = (filePreview: FilePreview) => {
      previewFile.value = filePreview.file;
      selectedFileLabel.value = filePreview.file.name || "";
      micromodal.show("image-preview", {});
    };
    const onSubmitUpload = async () => {
      const formData = new FormData();
      if (previewFile.value != null) {
        const compressedFile = await imageCompression(previewFile.value, {
          maxSizeMB: 4,
        });
        formData.append("filename", previewFile.value?.name || "");
        formData.append("userID", state.user.userId);
        formData.append("file", compressedFile as Blob);
      }

      fileApi
        .post("/files", formData, {
          headers: {
            withCredential: true,
            "Access-Control-Allow-Origin": "*",
            Accept: "*/*",
          },
        })
        .then(() => {
          previewFile.value = null;
          selectedFileId.value = "";
          selectedFileLabel.value = "";
        })
        .catch((err: any) => console.error(err));
    };
    const onCancelUpload = () => {
      previewFile.value = null;
      selectedFileId.value = "";
      selectedFileLabel.value = "";
    };
    const editProfileImage = () => {
      micromodal.show("modal-upload-image");
    };

    const onChangeImage = () => {
      onCancelUpload();
      editProfileImage();
    };

    const assignAddressState = (event: string) => {
      profileForm.address.state = event;
    };
    const userImage = computed(() => profileForm?.profileImage);
    const updateStateValue = (e) => {
      selectedState.value = e.target.value;
    };
    const schema = yup.object().shape({
      email: yup.string().required().label("Email"),
      phone: yup
        .string()
        .required()
        .label("Phone Number"),
      address1: yup.string().trim().required().label("Street Address"),
      address2: yup.string().trim().label("Address"),
      city: yup
        .string()
        .trim()
        .matches(/^[aA-zZ' ]+$/g, "Invalid character in the name")
        .required()
        .label("City"),
      state: yup.string().trim().required().label("State"),
      county: yup
        .string()
        .trim()
        .matches(/^[aA-zZ ]+$/g,"Invalid character in the county")
        .when("state", {
          is: (val: string) => isCountyRequired(val),
          then: yup.string().min(4).required().label("County"),
          otherwise: yup.string().notRequired().label("County"),
        }),
      zip: yup
        .string()
        .trim()
        .required()
        .min(5, "Zip Code must be 5 digits")
        .label("Zip Code"),
    });

    onMounted(() => {
      proxyApi.get("/getPartnerProfile").then(({data})=>{
       if(data.result.length) {
          profileForm.username = data.result[0].username;
          profileForm.firstName = data.result[0].firstName;
          profileForm.lastName = data.result[0].lastName;
          profileForm.businessName = data.result[0].businessName;
          profileForm.email = data.result[0].email;
          profileForm.phone = data.result[0].phone;
          profileForm.address.address1 = data?.result[0]?.address1;
          profileForm.address.address2 = data?.result[0]?.address2;
          profileForm.address.city = data?.result[0]?.city;
          profileForm.address.state = data?.result[0]?.state;
        //  profileForm.address.county = data?.result[0]?.county;
          profileForm.address.postalCode = data?.result[0]?.postalCode;
          profileForm.addressID = data?.result[0]?.addressID;
          profileForm.partnerBusinessID=data?.result[0]?.partnerBusinessID,
          profileForm.businessName=data?.result[0]?.businessName,
          profileForm.address.country =data?.result[0]?.country

       }
     })
    })

    return {
      profileForm,
      previewFile,
      selectedFileId,
      selectedFileLabel,
      userImage,
      selectedState,
      schema,
      addressSuggestionComputed,
      addressSelectedFromSmarty,
      selectedAddress,
      autoCompleteError,
      getAddressSuggestion,
      updateStateValue,
      onCancelUpload,
      onSubmitUpload,
      onChangeImage,
      saveEdits,
      onFileSelected,
      editProfileImage,
      assignAddressState,
    };
  },
});
