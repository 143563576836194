
import { defineComponent } from "vue";
import { IconAbout } from "@/components"
export default defineComponent({
    name: "ConfirmChangeAccount",
    components: {
        IconAbout
    },
    emits: ['continue', 'exit'],
    setup(props, {emit}) {
        const closeModal = () => {
            emit('exit')
        }
        const confirmChangeAccount = () => {
            emit('continue')
        }
        return {closeModal, confirmChangeAccount}
    }
});
