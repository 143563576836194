
import { defineComponent } from "vue";
import { DateTime } from "luxon";
export default defineComponent({
  name: "DateDropDown",
  components: {},
  emits: ["change"],
  props: {
    date: {
      type: String,
      required: false,
      default: "",
    },
    errorStyles: {
      type: Boolean,
      required: false,
      default: false,
    },
    futureDates: {
      type: Boolean,
      required: false,
      default: false,
    },
    pastDates: {
      type: Boolean,
      required: false,
      default: false,
    },
    prefilledDay: {
      type: String,
      required: false,
      default: "Day",
    },
    prefilledMonth: {
      type: String,
      required: false,
      default: "Month",
    },
    prefilledYear: {
      type: String,
      required: false,
      default: "Year",
    }
  },
  data() {
    return {
      day: "",
      month: "",
      year: "",
      selectedYear: "Year",
      selectedMonth: "Month",
      selectedDay: "Day",
      isLeapYear: false,
      months: [
        { text: "Jan", value: "01" },
        { text: "Feb", value: "02" },
        { text: "Mar", value: "03" },
        { text: "Apr", value: "04" },
        { text: "May", value: "05" },
        { text: "Jun", value: "06" },
        { text: "Jul", value: "07" },
        { text: "Aug", value: "08" },
        { text: "Sep", value: "09" },
        { text: "Oct", value: "10" },
        { text: "Nov", value: "11" },
        { text: "Dec", value: "12" },
      ],
      days: [] as any,
      years: [],
    };
  },
  watch: {
    date(oldValue) {
      this.year = DateTime.fromISO(oldValue).year.toString();
      this.checkLeapYear(this.year)
      this.month = ("0" + DateTime.fromISO(oldValue).month).slice(-2);
      this.onMonthChange(this.month);
      this.day = ("0" + DateTime.fromISO(oldValue).day).slice(-2);
      this.onDayChange(this.day)
    },
  },
  computed: {
    // errorClass: function(){
    //    let styleClass = this.$props.errorStyles ? "error" : "";
    //   return styleClass;
    // },
    renderYear: function () {
      let yearsObj: any = [];
      let date = new Date().getFullYear();
      if (this.$props.futureDates) {
        let startYear = date;
        let endYear = date + 100;
        for (let i = startYear; i <= endYear; i++) {
          let yString = i.toString();
          yearsObj.push({ text: yString, value: yString });
        }
      } else if (this.$props.pastDates) {
        let startYear = 1930;
        let endYear = date - 18;
        for (let i = endYear; i >= startYear; i--) {
          let yString = i.toString();
          yearsObj.push({ text: yString, value: yString });
        }
      }
      return yearsObj;
    },
  },
  methods: {
    onMonthChange: function (month) {
      this.selectedMonth = month;
      this.days = [
        { text: "01", value: "01" },
        { text: "02", value: "02" },
        { text: "03", value: "03" },
        { text: "04", value: "04" },
        { text: "05", value: "05" },
        { text: "06", value: "06" },
        { text: "07", value: "07" },
        { text: "08", value: "08" },
        { text: "09", value: "09" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
        { text: "13", value: "13" },
        { text: "14", value: "14" },
        { text: "15", value: "15" },
        { text: "16", value: "16" },
        { text: "17", value: "17" },
        { text: "18", value: "18" },
        { text: "19", value: "19" },
        { text: "20", value: "20" },
        { text: "21", value: "21" },
        { text: "22", value: "22" },
        { text: "23", value: "23" },
        { text: "24", value: "24" },
        { text: "25", value: "25" },
        { text: "26", value: "26" },
        { text: "27", value: "27" },
        { text: "28", value: "28" },
      ];
      switch (month) {
        case "01":
        case "03":
        case "05":
        case "07":
        case "08":
        case "10":
        case "12":
          this.days.push(
            { text: "29", value: "29" },
            { text: "30", value: "30" },
            { text: "31", value: "31" }
          );
          break;
        case "04":
        case "06":
        case "09":
        case "11":
          this.days.push(
            { text: "29", value: "29" },
            { text: "30", value: "30" }
          );
          break;
        case "02":
          if (this.isLeapYear) {
            this.days.push({ text: "29", value: "29" });
          }
      }
      this.emitData();
    },
    checkLeapYear: function (year) {
      this.selectedYear = year;
      if ((0 == year % 4 && 0 != year % 100) || 0 == year % 400) {
        this.isLeapYear = true;
        console.log("leap year");
      } else {
        this.isLeapYear = false;
      }
      if (this.selectedMonth !== "Month") {
        this.onMonthChange(this.selectedMonth);
      }
      this.emitData();
    },
    onDayChange: function (day) {
      this.selectedDay = day;
      this.emitData();
    },

    emitData: function () {
      let dob = `${this.selectedYear}-${this.selectedMonth}-${this.selectedDay}`;
      this.$emit("change", dob);
    },
  },
});
