interface ChosenField {
  name: string,
  value: string,
}

const toggleObject = {
	field_type: 'radio',
	key: 'showSignupQuestion',
	questionText: 'Do you want to register as a Business?',
	options: [
		{key: 'NO', optionText: 'No'}, 
		{key: 'YES', optionText: 'Yes'},
	],
}

const showQuestions = (field: ChosenField): boolean => 
	field.value === 'YES' ? true : false

export { ChosenField, toggleObject, showQuestions }