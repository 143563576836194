
import { DateTime } from 'luxon'
import { state } from "@/harlem/stores";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { defineComponent, onMounted, ref, reactive } from "vue";
import { cardBody } from "./FunnelStatus.js"
import FunnelIcon from "./FunnelIcon.vue";
import { useRouter } from "vue-router";
import { proxyApi } from "@/http/http.config";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import PendingEarningMessage from '@/modules/earnings/PendingEarningMessage.vue'
import FunnelToast from "./FunnelToast.vue";
import { gTagEvent } from "@/util/GoogleAnalytics";
import {
  getRawBranchCount,
  getPartnerInsuranceDetails,
  getProgressStatus,
  getDLExpiry,
  getHiddenEarningAccounts,
  setOneStatusRef,
  getKycStatus,
  getONETransactionsCount,
  getTransactionsCount,
  getWalletInfo,
  checkACHReachedMaxAttempts,
  isAllPaymentMethodsFailed
} from "./Helpers";
import { IconCelebrate } from "@/components"
import banners from "../dashboard/Banners.vue"
import Loader from "@/components/modals/Loader.vue";
import { FunnelStatus } from "@/util/FunnelStatus";
import { multiplePayDisabled } from "../earnings/Helper";
import { sendToOracle } from "@/components/OracleButtonHelper";
import { mutation } from "@/harlem/mutation";
import GettingStartedResources from "../dashboard/GettingStartedResources.vue";
import OnHoldFaq from "../faq/OnHoldFaq.vue";
export default defineComponent({
  name: "FunnelStatusWidget",
  components: {
    FunnelIcon,
    FunnelToast,
    IconCelebrate,
    banners,
    PendingEarningMessage,
    Loader,
    GettingStartedResources,
    OnHoldFaq
  },
  props: {
    sharedFunnelStatus: {
      default: "",
    }
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(true)
    const firstName = ref(state.user.firstName)
    const signature = ref(state.user.signature)
    const partnerLocation = state.user.locationName
    const currentPayment = ref('')
    const showPaymentSetUp = ref(false)
    const showPaymentFailed = ref(false)
    const pendingPayment = ref('')
    const payStartDate:any = ref(null)
    const funnelProgress: any = ref([])
    const funnelProgressEmpty = ref(false)
    const payeeID = sessionStorage.getItem('payeeID')
    const showWalletLink = ref({ show: false, url: "" })
    const oneStatus:any = ref("")
    const activeAccounts = ref<Array<string>>([])
    const noActiveEarnings = ref(false)
    const hasACHReachedMaxAttempts:any = ref(false)
    const kycStatus = ref("");
    const isMultiplePayDisabled:any = ref(null)
    const isShowCongratsStatus = ref(false);
    const showrFaq=ref(false)
    const partnerStatus=ref("");

    const drive4sparkLinks = {
      APPLE_APP_STORE: 'https://apps.apple.com/us/app/spark-driver/id1483998235',
      ANDROID_APP_STORE: 'https://play.google.com/store/apps/details?id=com.walmart.sparkdriver&hl=en_US&gl=US',
      DESKTOP_WEBSITE: 'https://drive4spark.walmart.com/'
    }

    //responsible for displaying multiple cards
    const cardBodyArray: any = []

    //see if user is on dashboard or candidateDashboard
    const dashboardURL = "/dashboard"
    const currentURL = router.currentRoute.value.path

    const candidate:any = reactive({
      progress: reactive({
        status: "",
        statusDetail: "",
        description: "",
        name: "",
        stage: "",
      }),
      branchRetryCount: 0,
      branchStatus: "",
      insuranceExpiry: "",
      dlExpiry: "",
      section: "",
      isEarning: false,
      isEarningThroughONE: false,
      earningAccountAttempts: []
    })

    const card = reactive({
      title: "",
      titleColor: "",
      subtitle: "",
      subtitleColor: "",
      subtitleBkgdColor: "",
      description: null,
      buttonText: null,
      buttonLink: "",
      icon: "null",
      iconColor: "",
      isCandidateDashboard: false,
    })

    const {
      MVR_CLEAR,
      MVR_PENDING,
      MVR_DENIED,
      MVR_SUSPENDED,
      CBC_CLEAR,
      CBC_PENDING,
      CBC_SUSPENDED,
      CBC_DENIED,
      APPROVED_AUTO,
      APPROVED_MANUAL,
      OCR_FAILED,
      OCR_CLEAR,
      INSURANCE_IMAGE_INVALID,
      OCR_MANUAL_CLEAR,
      IDV_PENDING,
      IDV_FAIL,
      PERSONAL_DETAILS_PENDING,
      DOCUMENTS_PENDING,
      DOCUMENTS_COMPLETE,
      PAYMENT_AGREEMENT_PENDING,
      WITHDRAWN,
      ENROLLMENT_ON_HOLD,
      ENROLLMENT_COMPLETE
    } = FunnelStatus

    onMounted(async () => {   
      await getPartnerStatus();
      await callEndpoints()
      await isDashboardPage()
      proxyApi.get('/getActiveEarningAccounts').then(async (data) => {
        if (data.data?.paymentProvider?.pending) {
          pendingPayment.value = data.data?.paymentProvider?.pending
        }
        if (data.data?.paymentProvider?.payStartDate) {
          payStartDate.value = DateTime.fromISO(data.data?.paymentProvider?.payStartDate).toFormat('MM/dd/yy')
        }
        isLoading.value = false
      })
    })

    const getPartnerStatus =() =>{
      proxyApi.get('/getPartnerStatus').then(async ({ data }) => {
        partnerStatus.value = data.result[0]?.partnerStatus
      })  
    }

    //logic for which status card to appear based on funnelprogress
    const handleProgressStatus = () => {
      if (currentURL == "/candidate/dashboard") {
        const { status } = candidate.progress
        const onboardingDriverStatus = {
          personalDetailsIncomplete: status === PERSONAL_DETAILS_PENDING,
          personalDetailsError: status === IDV_PENDING,
          personalDetailsFailed: status === IDV_FAIL,
          verificationInProgress: status == MVR_PENDING || status == CBC_PENDING,
          signatureIncomplete: status == IDV_PENDING && !funnelProgress?.value.includes(DOCUMENTS_PENDING),
          documentsIncomplete: status == DOCUMENTS_PENDING,
          insuranceIncomplete: status == DOCUMENTS_COMPLETE,
          chooseEarningsIncomplete: status == PAYMENT_AGREEMENT_PENDING,
          currentlyZoneIsFull : status == ENROLLMENT_ON_HOLD || ENROLLMENT_COMPLETE,
        }

        const {
          personalDetailsIncomplete,
          personalDetailsError,
          personalDetailsFailed,
          signatureIncomplete,
          documentsIncomplete,
          insuranceIncomplete,
          chooseEarningsIncomplete,
          verificationInProgress,
          currentlyZoneIsFull
        } = onboardingDriverStatus;

        //status cards to only appear in onboarding in-progress candidates dashboard page
        if (personalDetailsIncomplete) {
          setCard(cardBody.personalDetailsIncomplete)
        } else if (personalDetailsError) {
          setCard(cardBody.personalDetailsError)
        } else if (personalDetailsFailed) {
          setCard(cardBody.personalDetailsFailed)
        } else if (verificationInProgress) {
          setCard(cardBody.verificationInProgress)
        } else if (signatureIncomplete) {
          setCard(cardBody.signatureInProgress)
        } else if (documentsIncomplete) {
          setCard(cardBody.documentsIncomplete)
        } else if (chooseEarningsIncomplete) {
          setCard(cardBody.chooseEarningsIncomplete)
        } else if (insuranceIncomplete) {
          setCard(cardBody.insuranceIncomplete)
        }
        else if (currentlyZoneIsFull) {
          setCard(cardBody.currentlyZoneIsFull)
          showrFaq.value=true
        }
         
      }
    }

    const isDashboardPage = async () => {
      const isPaymentAgreementComplete = funnelProgress.value.some(obj => obj["candidateStatus"] == "Payment Agreement Complete")
      mutation.payment.setPaymentAgreementComplete(isPaymentAgreementComplete); 

      const {
        insuranceExpiry,
        dlExpiry,
        isEarning,
        isEarningThroughONE,
        branchStatus
      } = candidate

      const {
        status,
      } = candidate.progress

      const isStatusInFunnelProgress = {
        isMVRClear: funnelProgress.value.some(obj => obj["candidateStatus"] == MVR_CLEAR),
        isCBCClear: funnelProgress.value.some(obj => obj["candidateStatus"] == CBC_CLEAR),
        isOCRclear: funnelProgress.value.some(obj => [OCR_CLEAR, OCR_MANUAL_CLEAR].includes(obj["candidateStatus"])),
        isOCRFailed: funnelProgress.value.some(obj => obj["candidateStatus"] == OCR_FAILED),
        isInsuranceInvalid: funnelProgress.value.some(obj => obj["candidateStatus"] == INSURANCE_IMAGE_INVALID),

        branchPending: ['CREATED', 'REVIEW'].includes(branchStatus),
        branchUnclaimed: branchStatus == 'UNCLAIMED',
        onePending: ['SUBMITTED', 'PENDING'].includes(oneStatus.value),

        isApprovedAuto: funnelProgress.value.some(obj => obj["candidateStatus"] == APPROVED_AUTO),
        isApprovedManual: funnelProgress.value.some(obj => obj["candidateStatus"] == APPROVED_MANUAL),

        allPaymentMethodsFailed: await isAllPaymentMethodsFailed(),
        locationDefaultPaymentFailedEarnings: branchStatus == "FAILED" && oneStatus.value == "DENIED" && isMultiplePayDisabled.value
      }

      const { branchPending, branchUnclaimed, onePending, isApprovedAuto, isApprovedManual, allPaymentMethodsFailed, locationDefaultPaymentFailedEarnings } = isStatusInFunnelProgress

      const sortByDate = (array) => {
          return array?.slice().sort((a, b) => {
              const dateA:any = new Date(a["date"]);
              const dateB:any = new Date(b["date"]);
              return dateB - dateA;
          });
      };
      
      const sortedRes = await sortByDate(funnelProgress.value)
      const latestFunnelStatus = await sortedRes[0].candidateStatus
      const driverWaitlisted = latestFunnelStatus == FunnelStatus.APPLICATION_WAITLIST

      const existingDriverStatus = {
        insuranceExpired: isExpired(insuranceExpiry) && (payeeID != 'null' || driverWaitlisted),
        dlExpired: isExpired(dlExpiry),
        authSuccess: ((status === APPROVED_AUTO || status == APPROVED_MANUAL) && (isEarning == false) && isEarningThroughONE == false),
        authFailed: [CBC_SUSPENDED, CBC_DENIED, MVR_DENIED, MVR_SUSPENDED].includes(status) || status == WITHDRAWN,
        //no earning selected: will not show for all failed payments methods and pending statuses
        noEarningSelected: 
          noActiveEarnings.value && 
          branchPending == false && 
          onePending == false && 
          branchUnclaimed == false && 
          status != WITHDRAWN &&
          ((allPaymentMethodsFailed) == false ||
          locationDefaultPaymentFailedEarnings == false),
        noEarningSelectedForApproved: (
          noActiveEarnings.value && 
          (isApprovedAuto || isApprovedManual) && 
          branchPending == false && 
          onePending == false && 
          branchUnclaimed == false &&
          status != WITHDRAWN &&
          ((allPaymentMethodsFailed) == false ||
          locationDefaultPaymentFailedEarnings == false)
        )
      }
      const { insuranceExpired, dlExpired, authSuccess, authFailed, noEarningSelected, noEarningSelectedForApproved } = existingDriverStatus
      //status cards to only appear in partner dashboard page
      if (currentURL == dashboardURL) {
        isShowCongratsStatus.value = false;
        if (onePending) {
          addCardBodyToArray(cardBody.onePending)
        }
        if (branchPending) {
          addCardBodyToArray(cardBody.branchPending)
        } else if (branchUnclaimed) {
          addCardBodyToArray(cardBody.branchUnclaimed)
        }
        if(partnerStatus.value=="DRIVER_DEACTIVATED")
        {
          setCard(cardBody.statusDeactivated)
        }
        else if (allPaymentMethodsFailed) {
          setCard(cardBody.allEarningMethodsNotApproved)
        } else if (noEarningSelectedForApproved) {
          setCard(cardBody.noEarningSelectedForApproved)
        } else if (noEarningSelected) {
          setCard(cardBody.noEarningSelected)
        } else if (insuranceExpired) {
          setCard(cardBody.insuranceExpired)
        } else if (dlExpired) {
          setCard(cardBody.dlExpired)
        } else if (authFailed) {
          setCard(cardBody.authFailed)
        } else if (locationDefaultPaymentFailedEarnings) {
          setCard(cardBody.locationDefaultPaymentFailedEarnings)
        } else if (authSuccess) {
          isShowCongratsStatus.value = true;
          setCard(cardBody.authSuccess)
        }
        //since isDashboard function expects a boolean to see if it is on dashboard page, this is used to display dashboard cards
        return true
      } else if (currentURL == "/candidate/dashboard") {
        const isAttemptedEarningAccount = candidate.earningAccountAttempts
        if (isAttemptedEarningAccount) {
          return router.push({ name: 'dashboard' })
        } else if (payeeID != 'null') {
          return router.push({ name: 'dashboard' })
        } else {
          return true
        }
      }
    }

    //status card button on where to route based on current status
    const handleButtonLink = async (buttonLink) => {
      if (buttonLink == "personalDetails") {
        // in some senarios Personal details pending status not inserting into db so added extra check here
        proxyApi.post('/addApplicationStatus', encodeWithParam({ status: FunnelStatus.PERSONAL_DETAILS_PENDING}));
        router.push({ name: "personalDetails" });
      } else if (buttonLink == ("kycError" || "kycFailed")) {
        router.push({ name: "kycResponse" });
      } else if (buttonLink == "documents" && signature.value === null) {
        router.push({ name: "createsignature" });
      } else if (buttonLink == "signature") {
        router.push({ name: "signature" });
      } else if (buttonLink == "documents" && signature.value != null) {
        router.push({ name: "documents" });
      } else if (buttonLink == "insuranceUpload" && signature.value != null) {
        router.push({ name: "insuranceUpload" });
      } else if (buttonLink == "earningsAccount" && signature.value != null) {
        router.push({ name: "earningsAccount" });
      } else if (buttonLink == "editInsurance") {
        router.push({ name: "editInsurance" });
      } else if (buttonLink == "editDriversLicense") {
        router.push({ name: "editDriversLicense" });
      } else if (buttonLink == "claimWallet") {
        gTagEvent("Click Wallet Link", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
        window.open(showWalletLink.value.url, '_blank');
      } else if (buttonLink == "chooseEarnings") {
        router.push({ name: "earningsAccount" });
      } else if (buttonLink == "oracle") {
        const res = await sendToOracle();
        if (res) {
            window.open(res, "_blank");
        }
      } else if (buttonLink == "sparkDriver") {
        sendToSparkDriveApp()
      }
    }

    const sendToSparkDriveApp = () => {
      const {ANDROID_APP_STORE, APPLE_APP_STORE, DESKTOP_WEBSITE} = drive4sparkLinks
      //if user is on mobile, send to app store depending on OS. if user on desktop, send to website.
      if (isMobile()) {
        if (/Android/i.test(navigator.userAgent)) {
          gTagEvent("Clicked Go To App: Android Play Store", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
          window.location.href = ANDROID_APP_STORE
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          gTagEvent("Clicked Go To App: iOS App Store", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
          window.location.href = APPLE_APP_STORE
        } else {
          gTagEvent("Clicked Go To App: Spark Driver Desktop Website", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
          window.location.href = DESKTOP_WEBSITE
        }
      } else {
        window.location.href = DESKTOP_WEBSITE
      }
    }

    const isExpired = (expiryDate) => {
      const date = new Date(expiryDate);
      const now = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
      const pstNow = new Date(now);
      return date < pstNow;
    }

    const isMobile = () => {
      return checklistMethods.isMobile()
    }

    const addCardBodyToArray = (cardBody) => {
      const isUnique = cardBodyArray.every(item => item.title !== cardBody.title);
      if (isUnique) {
        cardBodyArray.push(cardBody)
      }
    }

    const setCard = (status) => {
      card.title = status.title
      card.titleColor = status.titleColor
      card.subtitle = status.subtitle
      card.subtitleColor = status.subtitleColor
      card.subtitleBkgdColor = status.subtitleBkgdColor
      card.description = status.description
      card.buttonText = status.buttonText
      card.buttonLink = status.buttonLink
      card.icon = status.icon
      card.iconColor = status.iconColor
      card.isCandidateDashboard = status.isCandidateDashboard
    }


    const getActiveEarningAccounts = () => {
      proxyApi.get('/getActiveEarningAccounts').then(({ data }) => {
        activeAccounts.value = data.activeAccounts
        if (data.message) {
          noActiveEarnings.value = true;
        }
      })
    }

    //endpoints required to get all the info to display correct status card
    const callEndpoints = async () => {
      await getProgressStatus(candidate, funnelProgress, funnelProgressEmpty).then(() => {
        return getRawBranchCount(candidate)
      }).then(() => {
        return getPartnerInsuranceDetails(candidate)
      }).then(() => {
        return getDLExpiry(candidate)
      }).then(() => {
        return getTransactionsCount(candidate)
      }).then(() => {
        return getONETransactionsCount(candidate)
      }).then(() => {
        return getKycStatus(kycStatus)
      }).then(async () => {
         return await getWalletInfo(candidate, showWalletLink)
      }).then(() => {
        return getActiveEarningAccounts()
      }).then(async () => {
        return hasACHReachedMaxAttempts.value = await checkACHReachedMaxAttempts()
      }).then(async () => {
        return oneStatus.value = await setOneStatusRef()
      }).then(async () => {
        return candidate.earningAccountAttempts = await getHiddenEarningAccounts()
      }).then(async () => {
        return isMultiplePayDisabled.value = await multiplePayDisabled()
      })

      await handleProgressStatus()
    }

    return {
      card,
      firstName,
      handleButtonLink,
      isLoading,
      isMobile,
      isDashboardPage,
      currentPayment,
      showPaymentSetUp,
      currentURL,
      dashboardURL,
      partnerLocation,
      showPaymentFailed,
      payStartDate,
      pendingPayment,
      payeeID,
      cardBodyArray,
      funnelProgressEmpty,
      isShowCongratsStatus,
      showrFaq,
      getPartnerStatus,
      partnerStatus
    }
  },
});
