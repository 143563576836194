
import IconArrowRight from "@/components/icons/IconArrowRight.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import { defineComponent } from "vue";
import checklistMethods from "./Helpers/Index";
import { state } from "@/harlem/stores";
import "../../../../assets/css/components/checklist.scss"

const getStep = JSON.parse(localStorage.getItem("progress") || "{}").step;
const getStepStatus = JSON.parse(localStorage.getItem("progress") || "{}").status;
const getFarthestStep = JSON.parse(localStorage?.getItem("step") || "{}");

export default defineComponent({
  name: "Checklist",
  components: {
    IconCheck,
    IconArrowRight
  },
  props: ['step', 'stepIndex'],
  data() {
    return {
      state,
      lock1Step: getFarthestStep > 1 ? true : false,
      farthestStep: getFarthestStep,
      currentStep: {
        label: getStep,
        stepIndex: checklistMethods.getIndex(getStep),
        status: getStepStatus
      },
      cardGroupData: [
        {
          id: 1,
          title: "Personal Information",
          destination: ({ name: "personalDetails", query: { ...this.$route.query } }),
          style: "checklist-row_num first-step",
          typename: "PERSONAL_DETAILS",
          status: "INCOMPLETE"
        },
        {
          id: 2,
          title: "Documents",
          destination: ({ name: "documents", query: { ...this.$route.query } }),
          style: "checklist-row_num",
          typename: "DOCUMENTS",
          status: "INCOMPLETE"
        },
        {
          id: 3,
          title: "Upload Auto Insurance",
          destination: ({ name: "insuranceUpload", query: { ...this.$route.query } }),
          style: "checklist-row_num",
          typename: "INSURANCE_UPLOAD",
          status: "INCOMPLETE"
        },
        {
          id: 4,
          title: "Earnings Account",
          destination: ({ name: "earningsAccount", query: { ...this.$route.query } }),
          style: "checklist-row_num",
          typename: "EARNINGS_ACCOUNT",
          status: "INCOMPLETE"
        },
      ]
    }
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
    isBtnDisabled(card) {
      return checklistMethods.isBtnDisabled(card, this.currentStep, this.farthestStep);
    },
    nextRouteOnMobile() {
      
      return checklistMethods.nextRouteOnMobile(this.$route.query);
    }
  },
  mounted() {
    checklistMethods.checklistRouter(this.currentStep)
  },
})
