
import { defineComponent, onMounted, ref, watch } from "vue";
import { Field } from "vee-validate";
import BaseModal from "@/components/modals/BaseModal.vue";
import micromodal from "micromodal";
import IconWarning from "@/components/icons/IconWarning.vue";
import { mutation } from "@/harlem/mutation";
import { setStatus } from '@/services/stage.service'

export default defineComponent({
  name: "Signature",
  components: { IconWarning, BaseModal, Field },
  emits: ["signature"],
  props: {
    oldSignature: String,
  },
  setup(props, { emit }) {
    const signature = ref("");
    const signatureError = ref("");
    const closeModal = () => {
      signature.value = props.oldSignature!;
      micromodal.close("modal-signature");
    };
    const saveSignature = () => {
      const value = signature?.value?.trim();
      if (value.length >= 2) {
        if(signature.value.includes('%'))
        {
          emit("signature", decodeURIComponent(signature.value));
        mutation.mergeTags.setPartnerSignature(decodeURIComponent(signature.value));
      }
        else{
          emit("signature", signature.value);
        mutation.mergeTags.setPartnerSignature(signature.value);
        }
        micromodal.close("modal-signature");
      } else {
        signature.value = "";
        signatureError.value = "Signature must be 2 or more characters";
      }
    };

    watch(signature, (newVal) => {
      if (newVal?.trim().length > 0) {
        signatureError.value = "";
      }
    });

    onMounted(() => {
      setStatus("SIGNATURE", "INCOMPLETE")
      signature.value = props.oldSignature!;
      micromodal.init({
        onShow: function () {
          document.body.classList.add("no-scroll");
        },
        onClose: function () {
          document.body.classList.remove("no-scroll");
        },
      });
    });

    return { closeModal, signature, signatureError, saveSignature };
  },
});
