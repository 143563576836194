<template>
    <div id="otp-expiration-help-text" class="modal" aria-hidden="true">
        <div class="modal-window_overlay policy" tabindex="-1">
            <div class="modal-window_container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
                <header class="modal-header">
                    <div class="close-modal close-modal-header" aria-label="Close modal">
                    </div>
                </header>
                <div class="modal-content">
                    <slot>
                    <div class="OTP-Help-div">

                            <span class="OTP-Help-text">OTP Help Doc</span>
                            <span class="Icon-Close">
                            <svg @click="close" width="24" height="24" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 1L1 15M1 1L15 15" stroke="#3171ff" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                    </div>
                        <br />

                        <p class="c45">
                            <span class="c1 c3 headertext">If you'd like, make sure you're signed up to get the one-time password
                                (OTP) verification by texting “start” to <a>1-424-433-4334</a>.</span>
                            <br />
                        </p>
                        <br />
                        <div class="consentP">
                            <div>
                                <p class="c45">
                                    If that still doesn't work, here are some extra steps you can try:
                                </p>
                            </div>
                            <div>
                                <ul class="flowsteps list">
                                    <li class="liststyles">Go to <a href="my.ddiwork.com" target="_blank">my.ddiwork.com</a></li>
                                    <li class="liststyles">Click <b>Forgot Username.</b></li>
                                    <li class="liststyles">Enter the email address associated with your account.</li>
                                    <li class="liststyles">You'll get a username in a text message on the phone number associated with your
                                        account.</li>
                                     <ul class="textwithdot">   
                                        <li class="textphone">If you don't get the text, then you'll likely
                                        need to update the phone number on your account.</li>
                                    </ul>
                                    <li class="liststyles">Enter the username and password on the sign-in page.</li>
                                    <li class="liststyles">Follow these steps on the screen:</li>
                                    <ul class="textwithdot">
                                        <li class="textphone">Complete the OTP.</li>
                                        <li class="textphone">Set security questions.</li>
                                        <li class="textphone">Choose a new password.</li>
                                    </ul>
                                </ul>
                            </div>
                            <div>
                                <p class="c45">
                                    You can now sign in with the username and the new password you just set.
                                </p>
                            </div>
                            <div>
                                <p class="c45">
                                    If this doesn't fix the sign-in error, then your account may need a contact
                                    information update.
                                </p>
                            </div>
                            <div>
                                <p class="c45">
                                    Feel free to contact us either by:
                                </p>
                            </div>
                            <div>
                                <p class="c45">
                                <ul>
                                    <li  class="textphone">Email: at <a>sparksupport@custhelp.com</a></li>
                                    <li  class="textphone">Phone: at 855-743-0457, 5 AM - 12 AM, / 7 days a week.</li>
                                </ul>
                                </p>
                            </div>
                        </div>
                        <div>
                            <p class="c98">Thanks,</p>
                            <p class="c98">
                                Spark Driver Team
                            </p>
                        </div>
                        <br />
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "../../assets/css/components/modals.scss";

export default {
    name: "OtpExpirationHelpText",
    emits: ["close-helptext"],

    methods: {

        close() {
            this.$emit("close-helptext", true);

        },
    },
};
</script>

<style scoped>
.c45 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

.consentP div {
    display: inline-flex;
    padding-bottom: 2%;
}

.consentP span {
    padding: 4px 4px 0px 0px;
    font-size: 18px;
}

div,
span,
p {
    color: #6A7183;
}

.Icon-Close {
    margin-right: 5%;
    margin-left: 90%;
    margin-top: -24px;
}
/* .OTP-Help-text{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
} */
.OTP-Help-text{
font-size: 26px !important;
/*font-weight: bold !important;*/
font-family: "Rubik" !important;
/*text-align: center !important;*/
}
.OTP-Help-div{
    margin-top: 20px;
    display: flex;
    /* flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: center */
    flex-direction: column;
}
.c98 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0px !important;
}
.headertext{
    font-weight: bold;
}
.flowsteps {
    margin-left: 10px;
    /* list-style-type: none; */
    font-family: 'Rubik' !important;
    margin-bottom: 10px !important;
    /* list-style-type: decimal; */
}
.liststyles{
list-style-type: decimal;
}
.textphone{
    margin-left: 10px;
}
.textwithdot{
    list-style-type: disc;
}
</style>