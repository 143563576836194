
import { defineComponent, onMounted, ref, reactive, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { proxyApi, restApi, signup } from "@/http/http.config";
import { state } from "@/harlem/stores";
import { reset } from "@harlem/plugin-reset";
import { setStatus } from "@/services/stage.service";
import Base64 from "base-64";
import micromodal from "micromodal";
// import PhoneNumber from "awesome-phonenumber";
import "../../../assets/css/components/formInput.scss";
import checklistMethods from "../components/Checklist/Helpers/Index";
import { encode, encodeWithParam } from "@/util/DataEncodeDecode";
import walmartAssociateModal from '@/modules/signup/signup/walmartAssociateModal.vue';
import { Field, Form,configure } from "vee-validate";
import * as yup from "yup"
import TextFieldValidated from "@/components/forms/TextFieldValidated.vue";
import { gTagEvent } from "@/util/GoogleAnalytics";
import CommonService from "@/services/common.service";
import { SUCCESS_CODES } from "@/modules/login/constants";

export default defineComponent({
  name: "Signup",
  components: {
    Form,
    walmartAssociateModal,
    TextFieldValidated,
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
    isSendCodeDisabled(errors) {
      if (this.initialValues.approvedConsent && this.initialValues.approvedConsentCheck && this.initialValues.isOver18 && this.initialValues.isEmployee && !("phone" in errors) && this.initialValues.phone != "") {
        return false
      }
      return true
    },
    isValidateOTPDisabled(errors) {
      if (!("OTP" in errors) && this.initialValues.otp != "") {
        return false
      }
      return true
    },
    hoverShow() {
      this.active = !this.active
    },
    hoverShowYes() {
      this.active = !this.active
    },
    hoverShowNo() {
      this.active = !this.active
    },
    hoverGo() {
      this.active = !this.active
    },
    resendOTP() {
      this.handleResend()
      let phone = this.initialValues.phone.replace(/\D/g, "");
      this.generateOTP(phone);
    },
    handleResend() {
      let seconds = 5 * 60
      this.updateTime('5:00')
      let timer = setInterval(() => {
        if (seconds <= 0) {
          clearInterval(timer)
          this.updateTime('')
        } else {
          seconds--
          let time = Math.floor(seconds/60) + ':' + (seconds%60).toString().padStart(2, '0')
          this.updateTime(time)
        }
      }, 1000)
    },
    resetCheckboxResponse () {
      this.initialValues["isOver18"] = false
      this.initialValues["isNotOver18"]= false
      this.initialValues["approvedConsent"] = false
      this.initialValues["approvedConsentCheck"] = false
      this.initialValues["isEmployee"] = false
    },
    updateOtp(otp) {
     this.initialValues.otp = otp;
     this.resetCheckboxResponse()
    },
    updatePhone(phone) {
      this.initialValues.phone = phone;
    },
    updateTime(time) {
      this.initialValues.time = time
    },
    generateOTP(phone) {
      let obj = { phone: phone.replace(/\D/g, "") };
      this.initialValues["incorrectNumber"] = false
      signup.post("/generateOTP", encodeWithParam(obj)).then((data) => {
        gTagEvent("Request OTP", "Verification", true)
        this.otpText = "";
        this.isOtpReceived = true;
        if (SUCCESS_CODES.includes(data?.data?.code)) {
          this.otpText = "Successfully Sent OTP";
        } else {
          this.initialValues["incorrectNumber"] = true
          this.isOtpReceived = false;
        }
      });
    },
    validateToken() {
      if (!this.isOtpReceived) {
        if (!this.initialValues.time) {
          this.handleResend()
        }
        this.generateOTP(this.initialValues.phone.replace(/\D/g, ""));
      }
      else {
        this.validateOTP(this.initialValues.phone.replace(/\D/g, ""), this.initialValues.otp);
      }
    },
    async validateOTP(phone, otp) {
      this.initialValues["incorrectOTP"] = false
      let obj = {
        phone: phone,
        otp: otp,
        checkDuplicate: true
      };
      sessionStorage.setItem("signupData", encode(phone));
      // sessionStorage.setItem("oValue",encode(otp));
      signup.post("/validateOnboardingOTP", encodeWithParam(obj)).then((data) => {
        if (data.data.success) {
          // gTagEvent("Correct OTP Entered", "Verification", true)
          // gTagEvent("Complete Verification", "Verification", true)
          // setStatus('VERIFICATION', 'INCOMPLETE')
          // this.showEmailVerify=true;

          this.router.push({
            name: "signupaccount",
            query: {
              locationId: this.locationId,
              name: this.locationName,
              campaign: this.campaign,
              medium: this.medium,
            },
          })
          
        } else {
          setTimeout(() => { this.initialValues["incorrectOTP"] = false }, 3000)
          this.initialValues["incorrectOTP"] = true
        }
      }).catch((e) => {
        if (e?.response?.status === 500) {
          return this.router.push({ name: "login" })
        }
      });
    },
    triggerHyphenCheck: function(e) {
      if ((e.key === "Backspace") && e.target.value.length > 0) {
        var str = e.target.value;
        if ("-" == str.charAt(str.length - 1)) {
          str = str.substring(0, str.length - 1);
        } else if ("(" == str.charAt(str.length - 1)) {
          str = str.substring(0, str.length - 1);
        } else if (" " == str.charAt(str.length - 1)) {
          str = str.substring(0, str.length - 2);
        }
        e.target.value = str;
      }
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const locationId = route.query.locationId;
    const locationName = route.query.name;
    const campaign = route.query?.campaign || null;
    const medium = route.query?.medium || null;
    sessionStorage.removeItem("signupData");
    const initialValues = reactive({
      phone: "",
      isOver18: false,
      isNotOver18: false,
      approvedConsent: false,
      approvedConsentCheck: false,
      otp: "",
      time: '',
      incorrectOTP: false,
      incorrectNumber: false,
      isEmployee:false,
      isNotEmployee:false,
    });
    configure({
      validateOnInput: true,
    });
    const schema = yup.object().shape({
      phone: yup
        .string()
        .strict(true)
        .min(14, "Invalid phone number")
        .required("Phone number is required")
        // .test("phone", function (value: any) {
        //   let plainPhone = value.replace(/\D/g, "");
        //   const pn = new PhoneNumber(plainPhone, "US");
        //   if (!pn.isValid()) {
        //     return this.createError({
        //       message: "Invalid phone number",
        //     });
        //   } else return true;
        // })
        .label("Phone"),
      OTP: yup
        .string()
        .required("OTP is required")
        .label("OTP")
        // .min(5, "Invalid OTP")
    })

    const urlCheck=()=>{
      if(!route.query['locationId'] && !route.query['name']){
       return router.push({ name: "login" });
      }
      if(!route.query['name']){
        return router.push({ name: "login" });
      }
    }

    onMounted(() => {
      urlCheck();
      CommonService.triggerRecruticsScript()
      const progress: any = localStorage.getItem("progress")
      const progressObj = JSON.parse(progress)
      if (progressObj?.step === 'VERIFICATION') {
        router.push({
          name: "verification",
          query: {
            locationId: locationId,
            name: locationName,
            campaign: campaign,
            medium: medium,
          },
        });
      } else {
        localStorage.clear();
        reset("user");
        reset("application");
        reset("signup");
        CommonService.triggerRecruticsScript()
      }
      gTagEvent("Enter Verification Page", "Verification", true)
    });

    const handleIsOver18Checkbox = () => {
      initialValues.isOver18 = !initialValues.isOver18
      if (initialValues.isOver18) {
        gTagEvent("isOver18 Eligibility", "Eligibility", true)
      }
    }
    const handleIsNotOver18Checkbox = () => {
      initialValues.isNotOver18 = !initialValues.isNotOver18
      if (initialValues.isNotOver18) {
        gTagEvent("isOver18 Eligibility", "Eligibility", false)
      }
    }
    
    
    const handleisEmployeeCheckbox = () =>{
      initialValues.isEmployee = !initialValues.isEmployee
      if(initialValues.isEmployee){
        gTagEvent("isEmployee Eligibility" , "Eligibility" , true)
      }
    }

    const handleisNotEmployeeCheckbox = () =>{
      initialValues.isNotEmployee = !initialValues.isNotEmployee
      if(!initialValues.isNotEmployee){
        gTagEvent("isEmployee Eligibility" , "Eligibility" , false)
      }
    }



    const handlePhoneConsentCheckbox = () => {
      initialValues.approvedConsent = !initialValues.approvedConsent
      if (initialValues.approvedConsent) {
        gTagEvent("phoneConsent Eligibility", "Eligibility", true)
      }
    }

    const handleNewPhoneConsentCheckbox = () => {
      initialValues.approvedConsentCheck = !initialValues.approvedConsentCheck
      if (initialValues.approvedConsentCheck) {
        gTagEvent("phoneConsent Eligibility", "Eligibility", true)
      }
    }

    return {
      initialValues, 
      schema,       
      handlePhoneConsentCheckbox,
      handleNewPhoneConsentCheckbox,
      handleIsOver18Checkbox,
      handleIsNotOver18Checkbox,
      handleisEmployeeCheckbox,
      handleisNotEmployeeCheckbox,
    }
  },
  data() {
    const route = useRoute();
    const router = useRouter();
    const locationId = route.query.locationId;
    const locationName = route.query.name;
    const campaign = route.query?.campaign || null;
    const medium = route.query?.medium || null;

    sessionStorage.setItem("location_name", route.query.name as string);
    sessionStorage.setItem("location_id", route.query.locationId as string);
    sessionStorage.setItem("campaign", route.query.campaign as string);
    sessionStorage.setItem("medium", route.query.medium as string);
    return {
      state,
      otpText: "",
      isOtpReceived: false,
      router,
      locationId,
      locationName,
      campaign,
      medium,
      active: false,
      is18Check: false,
      isNot18Check: false,
      isEmployeeChecked: false,
      isNotEmployeeChecked: false,
      // showEmailVerify:false,
      // isEmailOtpReceived:false,
      // validEmail:false,
      // emailError:"",
      // checkEmail:"",
      // confirmEmailError:"",
      // validConfirmEmail:false,
      // confirmEmail:"",
      // email:"",
    };
  }
});
