import Base64 from "base-64";
import { proxyApi } from "../http/http.config";
import CryptoJS from 'crypto-js';

  export const encodeWithParam=(data:any)=> {
    const toStr= JSON.stringify(data)
    return {"param":Base64.encode(toStr)}
   };

   export const encodeWithParamutf = (data: any) => {
    const toStr = JSON.stringify(data);
    const encodedStr = Buffer.from(toStr, 'utf-8').toString('base64');
    return { "param": encodedStr };
  };

  export const decodeWithParamutf = (encodedData: any) => {
    const decodedStr = Buffer.from(encodedData, 'base64').toString('utf-8');
    return JSON.parse(decodedStr);
  };

   export const encode=(data:any)=> {
    const toStr= JSON.stringify(data)
    return Base64.encode(toStr)
   };

   export const decode=(data:any)=> {
    const str=Base64.decode(data)
    return JSON.parse(str)
   };

   export const encrypt =  async (val) => {
    const secret = await getKey()
    const key = CryptoJS.enc.Utf8.parse(secret.key);
    const iv = CryptoJS.enc.Utf8.parse(secret.iv_key.slice(0,16));
    const encrypted = CryptoJS.AES.encrypt(val, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC
    });
    const output = encrypted.ciphertext.toString();
    return iv+":"+output;
}

export const decrypt = async (val) => {
  try {
    const secret = await getKey()
    const key = CryptoJS.enc.Utf8.parse(secret.key);
    const keyVal = val.split(":");
    const iv = CryptoJS.enc.Hex.parse(keyVal[0]);
    const cipherText = CryptoJS.enc.Hex.parse(keyVal[1]);
    const options = { mode: CryptoJS.mode.CBC, iv: iv };
    const decrypted = CryptoJS.AES.decrypt({ciphertext: cipherText, iv: iv, salt: undefined }, key, options);
    const retVal = decrypted.toString(CryptoJS.enc.Utf8);
    return retVal
  } catch (err) {
    return val;
  }
}

async function getKey(){
  const { key, iv_key} = (await proxyApi.get('/getPPIKey')).data
  return {
    key: Base64.decode(key),
    iv_key: Base64.decode(iv_key)
    }
  }

// since there is no ENV file to store the secretes, hard coding the secrete key, consider moving it to env file  
export function generatePublicAuthToken(){
  return CryptoJS.AES.encrypt(JSON.stringify({issuedAt:Date.now()}), '8b9779f25da8474c9fa87fa17278c6fd').toString()
}