
import {
  defineComponent,
  nextTick,
  onBeforeMount,
  onMounted,
  Ref,
  ref,
  watch,
  computed,
} from "vue";
import micromodal from "micromodal";
import { BaseModal, IconWarningOctagon } from "@/components";
import { DateTime } from "luxon";
import VuePdfEmbed from 'vue-pdf-embed';
import * as yup from "yup";
import FileViewer from "@/components/files/FileViewer.vue";
import { maska, create } from "maska";
import DateDropDown from "@/components/forms/DateDropDown.vue";
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";

export default defineComponent({
  name: "PreviewImageModal",
  components: { FileViewer, BaseModal, DateDropDown, IconWarningOctagon, VuePdfEmbed },
  directives: { maska },
  emits: ["submit-file", "cancel-upload", "change-image"],
  props: {
    id: {
      type: String,
      required: false,
      default: "image-preview",
    },
    title: {
      type: String,
      required: true,
    },
    file: {
      type: Blob,
      required: true,
    },
    type: {
      type: Boolean,
      required: true,
      default:false
    },
    existingId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isExpiryError: false,
    };
  },
  setup(props, { emit }) {
    const imagePreview: Ref<string | ArrayBuffer | null> = ref("");
    const date = ref("");
    const calendar: Ref<Record<string, any> | null> = ref(null);
    const modalRef = ref(null);
    const dateMask = ref("##-##-####");
    const dateValidation = ref(false);
    const calendarConfig = {
      allowInput: true,
      minDate: DateTime.local().toFormat("yyyy-MM-dd"),
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "d-m-Y",
      clickOpens: false,
    };

    onMounted(() => {
      nextTick(() => {
        let previewDateContainer = document.getElementById(
          "preview-image-modal-date"
        );
        if (previewDateContainer) {
          let inputs = previewDateContainer.getElementsByTagName("input");
          create(inputs[1], { mask: "##-##-####" });
        }
      });
    });

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      imagePreview.value = reader.result;
    });

    watch(
      () => props?.file,
      (file) => {
        if (file?.size <= 5242880) {
          reader.readAsDataURL(props.file as Blob);
        }
      }
    );

    const expirationValidation = yup
      .date()
      .required()
      .min(new Date(), "Date Cannot be Expired")
      .typeError("Insurance Expiration Date Invalid");

    const cancel = () => {
      imagePreview.value = null;
      micromodal.close(props.id);
      emit("cancel-upload");
    };

    const submit = () => {
      dateValidation.value = false;
        if (date.value) {
          emit("submit-file", date.value);
          gTagEvent("Submit Document: Auto Insurance", "Documents", `partner_id: ${state.user.partnerId}`, true)
          micromodal.close(props.id);
        } else {
          dateValidation.value = true;
        }
      

      // if (props.expiration) {
      //   const badDate =
      //     DateTime.fromFormat(date.value, "yyyy-LL-dd") <= DateTime.now();
      //   if (badDate) {
      //     return;
      //   }
      // }
      // imagePreview.value = null;
      // micromodal.close(props.id);
      // if (props.expiration) {
      //   emit("submit-file", date.value);
      // } else {
      //   emit("submit-file");
      // }
    };

    const changeImage = () => {
      micromodal.close(props.id);
      emit("change-image");
    };

    const toggleCalendar = () => {
      console.log(calendar);
      if (calendar.value) {
        const fp = calendar.value.getElem()._flatpickr;
        console.log(fp);
        fp.toggle();
      }
    };

    return {
      dateValidation,
      imagePreview,
      state,
      cancel,
      submit,
      changeImage,
      toggleCalendar,
      calendar,
      modalRef,
      date,
      calendarConfig,
      expirationValidation,
      dateMask,
    };
  },
  methods: {
    closeModal() {
      micromodal.close(this.$props.id);
    },
    expirydateValidate: function (data) {
      this.isExpiryError = false;
      let futuredate = this.isFutureDate(data);
      const isDateEntered = (futuredate != undefined)
      if (isDateEntered) {
        if (futuredate) {
          this.date = data;
        } else {
          this.isExpiryError = true;
        }
      }
    },

    isFutureDate: function (dateVal) {
      var g1 = new Date();
      var g2 = new Date(dateVal);
      if (g1.getTime() < g2.getTime()) return true;
      else if (g1.getTime() > g2.getTime()) return false;
    },
  },
});
