<template>
  <div class="header-close" @click="close"><span>X</span></div>
    <div class="desktop-container">
      <div class="desktop-wrapper">
        <div class="desktop-module_form">
          <h4>Frequently Asked Questions</h4>
          <div class="accordian-container">
            <div>
              <div class="mobile-accordian_row" v-for="(row, id) in accordian" v-bind:key="id">
                <div class="accordian-title_container" v-on:click="row.open = !row.open">
                  <div :class="!row.open ? 'accordian-title_text' : 'accordian-title_text open'">{{ row.question }}</div>
                  <div v-if="!row.open">
                    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 1L10 10L1 1" stroke="#3B4459" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div v-else>
                    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 1L10 10L1 1" stroke="#3B4459" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
                <div v-show="row.open" class="accordian-body_text">{{ row.answer }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { proxyApi } from "@/http/http.config";
import { gTagEvent } from "@/util/GoogleAnalytics";
  export default {
    mounted() {
      proxyApi.get('/getOneFaq').then(async (data) => {
        this.accordian = data.data.result
      })
      gTagEvent("View ONE FAQ Page", "ONE", true)
    },
    data() {
      return {
        accordian: [],
      }
    },
    methods: {
      close(){
        window.close()
      }
    },
  }
  </script>
  <style src="./styles.css" scoped></style>