
	import { computed, onMounted, ref } from 'vue'
	import { state } from '@/harlem/stores'
	import { useRouter } from 'vue-router'
	import { setStatus } from '@/services/stage.service'
	import { kycApi } from '@/http/http.config'
	import PulseLoader from "vue-spinner/src/PulseLoader.vue";
	import { gTagEvent } from '@/util/GoogleAnalytics'
	import checklistMethods from "../components/Checklist/Helpers/Index";
	import InitChecklist from "../components/Checklist/InitChecklist.vue";

	export default {
		name: 'Confirmation',
		components: {
			PulseLoader,
			InitChecklist
		},
		methods: {
			isMobile() {
				return checklistMethods.isMobile();
			},
		},
		setup() {
			const router = useRouter()
			const loading = ref(false)

			let ssnHidden = ref(true);
			const toggleEyeSsn = () => { ssnHidden.value = !ssnHidden.value }
			const viewSsn = computed(() => ssnHidden.value ? "password" : "text");
			const ssnInputMask = computed(() => ssnHidden.value ? "#########" : "###-##-####")

			const partnerName = state.mergeTags.partner.name;
			const partnerSSN = state.mergeTags.partner.ssn;
			const partnerDOB = state.mergeTags.partner.dateOfBirth;
			const partnerAddress = `
			${state.mergeTags.partner.address.street1}, 
			${state.mergeTags.partner.address.street2}${state.mergeTags.partner.address.street2 ? ',' : ''} 
			${state.mergeTags.partner.address.city}, ${state.mergeTags.partner.address.state} 
			${state.mergeTags.partner.address.zip}, United States
		`;
			const businessName = state.business.name;
			const eid = state.business.ein;
			const taxClassification = state.business.taxClassification;
			const businessAddress = `
			${state.business.address1}, 
			${state.business.address2}${state.business.address2 ? ',' : ''} 
			${state.business.city}, ${state.business.state} 
			${state.business.zipcode}, United State
		`;
			const isBusiness = state.business.isBusinessSelected;

			const goBackToPersonalDetails = () => {
				router.push({ name: 'personalDetails', query: { locationId: state.user.locationId , populate:'true' } })
			}

			onMounted(async () => {
				setStatus("CONFIRMATION", "INCOMPLETE")
				gTagEvent("Enter Detail Confirmation Page", "Confirm Details", `partner_id: ${state.user.partnerId}`)
			})

			const handleGTagWalletSuccess = (status) => {
				if (status === 'CREATED') {
					gTagEvent("Created Digital Wallet", "Create Wallet", `partner_id: ${state.user.partnerId}`)
				} else if (status === 'UNCLAIMED') {
					gTagEvent("Unclaimed Digital Wallet", "Create Wallet", `partner_id: ${state.user.partnerId}`)
				} else if (status === 'ACTIVE') {
					gTagEvent("Active Digital Wallet", "Create Wallet", `partner_id: ${state.user.partnerId}`)
				}
			}

			const handleConfirm = async () => {
				//router.push({ name: 'createsignature', query: { locationId: state.user.locationId, name: state.user.locationName } });
				loading.value = true
				localStorage.removeItem('taxClassificationOther')
				//  FOR LOAD TEST DELETE LATER
				// if (config.VUE_APP_ENV == "loadtest" || process.env.VUE_APP_ENV == "loadtest") {
				// 	await proxyApi.post('/personalDetailsComplete')
				// 	setStatus("CONFIRMATION", "COMPLETE")
				// 	return router.push({ name: 'backgroundCheck', query: { locationId: state.user.locationId } })
				// }
				//  FOR LOAD TEST DELETE LATER
				
				// const kycStatus = (await proxyApi.get("/getKycStatus"))?.data?.result?.kyc_status;
				// let incStatus;
				// const retrigStatus = [null, undefined, "needs review", "initiated", "db_retry"]
				// if (retrigStatus.includes(kycStatus)) {
				const incStatus = (await kycApi.post("/persona/createPersonaTxn"))?.data?.status;
				// }
				switch (incStatus) {
					case "approved":
						successRedirect();
						break;
					case "declined":
						failureRedirect();
						break;
					case "created":
					case "updated":
					case "tin_fallback":
						createRedirect();
						break;
					case "needs review":
					case "db_retry": 
					default:
						pendingRedirect()
						break;
				}
			}

			const successRedirect = async () => {
				setStatus("IDV", "IDV Pass");
				gTagEvent("Persona kyc approved", "Persona", `partner_id: ${state.user.partnerId}`);
				router.push({ name: 'createsignature', query: { locationId: state.user.locationId, name: state.user.locationName } });
			}

			const failureRedirect = async () => {
				setStatus("IDV", "IDV Failed");
				gTagEvent("Persona kyc declined", "Persona", `partner_id: ${state.user.partnerId}`);
				router.push({ name: 'kycResponse', query: { locationId: state.user.locationId } });
			}

			const pendingRedirect = async () => {
				setStatus("IDV", "IDV Pending");
				gTagEvent("Persona kyc pending", "Persona", `partner_id: ${state.user.partnerId}`);
				router.push({ name: 'kycResponse', query: { locationId: state.user.locationId } });
			}

			const createRedirect = async () => {
				setStatus("IDV", "IDV Pending");
				gTagEvent("Persona kyc created", "Persona", `partner_id: ${state.user.partnerId}`);
				router.push({ name: 'backgroundCheck', query: { locationId: state.user.locationId } });
			}

			return {
				ssnHidden,
				toggleEyeSsn,
				viewSsn,
				ssnInputMask,
				partnerName,
				partnerSSN,
				partnerDOB,
				partnerAddress,
				businessAddress,
				goBackToPersonalDetails,
				handleConfirm,
				loading,
				businessName,
				eid,
				taxClassification,
				isBusiness
			}
		},
	}
