
import { computed, defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import { FieldError, IconEyeClosed, IconEyeOpen,IconWarningOctagon } from "../../components";
import * as yup from "yup";
import { useRoute, useRouter } from "vue-router";
import { signup,proxyApi } from "@/http/http.config";
import { state } from "@/harlem/stores";
import Base64 from "base-64"
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { gTagEvent } from "@/util/GoogleAnalytics";
//query param is token need userID- actual stores id, token and password.

export default defineComponent({
  name: "ChangePassword",
  components: { Form, Field, FieldError, IconEyeClosed, IconEyeOpen,IconWarningOctagon },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const passwordHidden = ref(true);
    const confirmPasswordHidden = ref(true);
    const password = ref("");
    const confirmPassword = ref("");
    const togglePassword = () => (passwordHidden.value = !passwordHidden.value);
    const toggleConfirmPassword = () => (confirmPasswordHidden.value = !confirmPasswordHidden.value);
    const viewPassword = computed(() => {
      return passwordHidden.value ? "password" : "text";
    });
    const viewConfirmPassword = computed(() => {
      return confirmPasswordHidden.value ? "password" : "text";
    });
    const previousPasswordError=ref(false);
    onMounted(() => {
      if(!sessionStorage.getItem("username")){
        router.push({ name: "login" });
      }
    });
    const handleFormChange = () => {
      previousPasswordError.value=false;
    }

    const setNewPassword = (formData: Record<string, any>) => {
      let reset_password_flag=false
      //console.log(route.query['setsecurityQuesions'],'setsecurityQuesions');
      if(route.query['setsecurityQuesions']){
        reset_password_flag = true;
      }
      const passwordReset = {
        password: formData.password,
        reset_password_flag:reset_password_flag
      };
      let accesstoken=sessionStorage.getItem('accesstoken');
      if(accesstoken!=null){
        signup.post("/changeForgottenPassword",encodeWithParam(passwordReset),{headers:{"Authorization":accesstoken}}).then((data) => {
        const res = data.data
        if (res.success) {
        gTagEvent("Success Password Change", "Login", {username: sessionStorage.getItem("username")})
        sessionStorage.removeItem("username")
        // let param = {templateName: 'resetPassword',partnerID : sessionStorage.getItem("partnerId")}
        // proxyApi.post('/sendSMSEmailPartner', encodeWithParam(param),{headers:{Authorization: `Bearer ${sessionStorage.getItem("accesstoken")}`,
        // "Content-Type": "application/json",
        // withCredentials: true}});
        router.push({ name: "passwordSuccess" });
        }
        else{
          previousPasswordError.value=true;
        }
      });
      }
      else {
  signup.post("/changeForgottenPassword",encodeWithParam(passwordReset),{headers:{"Authorization":route.query.resource}}).then((data) => {
        const res = data.data
        if (res.success) {
          gTagEvent("Success Password Change", "Login", {username: sessionStorage.getItem("username")})
          sessionStorage.removeItem("username")
          router.push({ name: "passwordSuccess" });
        }
        else{
          previousPasswordError.value=true;
        }
      });
    }
    };
    const schema = yup.object().shape({
      password: yup
      .string()
      .required()
      .test(
        "Password",
        "Password must contain uppercase, lowercase, numbers and special characters.",
        function (value: any) {
          const regex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-\\+={}_|/:;"'<>,.?])[A-Za-z\d~`!@#$%^&*()\-\\+={}_|/:;"'<>,.? ]{8,}$/
          );
          return regex.test(value);
        }
      )
      .min(8).label("Password"),
      confirmPassword: yup
        .string()
        .required("Confirm Password is a required field")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    });
    return {
      setNewPassword,
      togglePassword,
      viewPassword,
      passwordHidden,
      schema,
      toggleConfirmPassword,
      confirmPasswordHidden,
      viewConfirmPassword,
      password,
      confirmPassword,
      previousPasswordError,
      handleFormChange
    };
  },
});
