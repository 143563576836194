
import { defineComponent, onMounted, ref } from "vue";
import authenticationService from "@/services/authentication.service";
import router from "@/router";
import { state } from "@/harlem/stores";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { proxyApi } from "@/http/http.config";

export default defineComponent({
  name: "FlyoutMenu",
  emits: ["close"],
  props: {
    paymentAgreementComplete: {
      default: false
    }
  },
  setup(_, { emit }) {
    const showNav = true;
    const isPartnerComplete = ref(false);
    const logout = () => {
      authenticationService.logout();
      emit("close");
      localStorage.removeItem("username");
      router.push({ name: "login" });
    };

    const goTo = async (val) => {
      emit("close");
      let data = await proxyApi.post('/getFunnelProgress', encodeWithParam({ candidateID: state.user.locationPartnerId }))
      const funnelProgress = data.data.result
      const isApprovedAuto = funnelProgress?.some(obj => obj["candidateStatus"] == "Approved Auto");
      const isApprovedManual = funnelProgress?.some(obj => obj["candidateStatus"] == "Approved Manual");
      const notYetApproved = ((isApprovedAuto || isApprovedManual) == false)
      const applicationWaitlist = funnelProgress?.some(obj => obj["candidateStatus"] == "Application Waitlist");

      const isPaymentAgreementPending = funnelProgress?.some(obj => obj["candidateStatus"] == "Payment Agreement Pending");
      const isApplicationOnhold = applicationWaitlist && notYetApproved

      let pathName = window.location.pathname;
      if (!isPartnerComplete.value && !isApplicationOnhold.value && pathName.substring(0, 22) == "/signup/locationUpdate") {
        return 0;
      } else if (!isPaymentAgreementPending) {
        return router.push({ name: 'candidateDashboard' })
      }

      switch (val) {
        case 'profile':
          return router.push({ name: 'profile' })
        case 'settings':
          return router.push({ name: 'settings' })
      }
    }

    return { showNav, logout, isPartnerComplete, goTo };
  },
  methods: {
    closeMenu() {
      this.$emit("close");
    },
  },
});
