import { object, ref, string } from 'yup'

const invalidRegex = /^[aA-zZ' 0-9 _-]+$/g
const invalidMatchMessage = (option: string) => 
	'Invalid character in ' + option + ' details'
const einConfirmMsg = 'Employer Identification Number does not match'
const hasSpace =  /(^|\s)($|\s)/
const hasSpecialChar = new RegExp(/\W|_/)

export default object().shape({
	businessName: string().strict(true).trim()
	.min(2, "Business name cannot be less than 2 characters")
	.test(
		"businessName",
		function (value: any) {
			if (/\s/.test(value)) {
				return this.createError({message: "Business name cannot include spaces"})
			} else if (hasSpecialChar.test(value)) {
				return this.createError({message: "Business name cannot include special characters"})
			} else return true;
		}
	)
	.required('Please enter Business Name'),
	ein: string().trim()
		.matches(/^[0-9]{2}-[0-9]{7}$/, 'EIN should be XX-XXXXXXX')
		.required('Please enter Employer Identification Number'),
	einConfirm: string().required(einConfirmMsg)
		.oneOf([ref('ein'), null], einConfirmMsg),
	streetLine: string().trim().required('Enter Street Address').matches(/^[-@./#&+\w\s]*$/,`No Emoji's`),
	secondary: string().trim().notRequired(),
	city: string().trim("City can't contain white space at the start or end").strict(true)
		.matches(/^[aA-zZ' ]+$/g, 'Invalid character in the name')
		.required('Enter City'),
	state: string().trim().required('Enter State'),
	zipcode: string().trim().min(5, 'Zip Code must be 5 digits')
		.matches(/^\d{5}(?:[-\s]\d{4})?$/g, 'Zip format invalid')
		.required('Enter Zip Code'),
	businesscounty:string().trim()
	.required('Please enter County'),
	taxClassification: string().trim()
		.required('Select Federal Tax Classification'),
	other: string().trim()
	.matches(invalidRegex, invalidMatchMessage("other")).when("taxClassification", {
		is: (val: string) => val === 'other',
		then: string().trim().required('Enter other details').strict(true)
		.test(
			"other",
			function (value: any) {
				if (hasSpace.test(value)) {
					return this.createError({message: "Other details cannot include spaces"})
				} else return true;
			}
		),
		otherwise: string().notRequired()
	}),
})