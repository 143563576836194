
import { defineComponent, ref } from "vue";
import PageActions from "@/components/PageActions.vue";
import VuePdfEmbed from 'vue-pdf-embed';
import Loader from "@/components/modals/Loader.vue";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { saveAs } from 'file-saver';


export default defineComponent({
  name: "W9Document",
  components: { PageActions, VuePdfEmbed, Loader },

  setup() {
    const url = ref()
    const errMsg = ref();
    const isLoading = ref(true)

    proxyApi.get("/getOnBoardingDocuments").then(({ data }) => {
      let fileId = data.result.filter((item: any) => {
        return ["W9"].includes(item.title)
      })
      let param = { fileID: fileId[0].fileID }
      proxyApi.post("/getW9Path", encodeWithParam(param)).then(({ data }) => {
        let buffer = Buffer.from(data.bufferInHex, "hex");
        let buf64 = buffer.toString("base64");
        url.value = "data:application/pdf;base64,"+buf64
        isLoading.value = false
      });
    });

    const downloadDocument = (() => {
      saveAs(url.value, "w9.pdf");
    })

    return {
      url,
      errMsg,
      isLoading,
      downloadDocument
    }
  }

});
