<template>
  <div class="otp-container">
    <input v-for="(value, index) in otpValues" :key="index" type="text" maxlength="1" class="otp-input"
           v-model="otpValues[index]"
           @input="handleInput(index, $event)"
           @keydown.backspace="handleBackspace(index, $event)"
           @paste="handlePaste($event)"
           ref="otpInput"
           :disabled="disabled"
           :placeholder="placeholder"/>
  </div>
</template>

<script>
export default {
  name:"OtpScreen",
  props: {
    otpCount: {
      type: Number,
      default: 6 
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "*"
    }
  },
  data() {
    return {
      otpValues: Array(this.otpCount).fill('')
    };
  },
  methods: {
    handleInput(index, event) {
      const value = event.target.value;
      if (value.trim() !== '') {
        this.otpValues[index] = value;
        if (index < this.otpCount - 1) {
          this.$refs.otpInput[index + 1].focus();
        }
        this.$emit('otp-change', this.otpValues.join('')); 
      } /*else if (index > 0 && value === '') {
        // Handle backspace case
        this.$refs.otpInput[index - 1].focus();
      }*/
    },
    handleBackspace(index, event) {
  if (event.key === 'Backspace') {
    if (this.otpValues[index] != '') {
      this.otpValues[index] = '';
      //this.$refs.otpInput[index - 1].focus();
    }
      else if(index > 0 && this.otpValues[index] == ''){
        this.otpValues[index-1] = '';
        this.$refs.otpInput[index - 1].focus();
      }
    }
    this.$emit('otp-change', this.otpValues.join(''));
},
    handlePaste(event) {
      event.preventDefault();
      const pasteData = event.clipboardData.getData('text/plain');
      const pasteValues = pasteData.split('').slice(0,this.otpCount);
      let index=pasteValues.length;
      const otpinputLength=this.$refs.otpInput.length;

      for(let i=0;i<otpinputLength;i++){
        const input=this.$refs.otpInput[i];
        if(i<pasteValues.length){

          input.value=pasteValues[i];
          this.otpValues[i]=pasteValues[i]
        }
        else{
          input.value='';
          this,this.otpValues[i]=''
        }
      }
      this.$emit('otp-change', this.otpValues.join('')); 
      if(pasteValues.length<otpinputLength){
        this.$refs.otpInput[pasteValues.length].focus();
      }
      else{
        this.$refs.otpInput[otpinputLength-1].focus();
      }
      
      // for (let i = 0; i < pasteValues.length && i < this.otpCount; i++) {
      //   this.$refs.otpInput[i].value = pasteValues[i];
      //   this.otpValues[i] = pasteValues[i];
      // }
      // this.$refs.otpInput[index - 1].focus();
      //this.$emit('otp-change', this.otpValues.join('')); // Emit the OTP value
    }
  }
};
</script>

<style scoped>
.otp-container {
  display: flex;
}

.otp-input {
  /*width: 2rem;
  height: 2rem;
  margin: 0 0.2rem;
  text-align: center;
  border: 1px solid #ccc;*/
            width: 40px;
            height: 40px;
            text-align: center;
            font-size: 24px;
            margin: 10px 4px;
            border: 2px solid rgb(238, 238, 241);
            /* border-bottom: 1px solid #ccc; */
            box-shadow: none;
            background-color: transparent;
            color: #333;
            transition: all 0.3s ease-in-out;
            font-family: r, Helvetica, sans-serif;
}
.otp-input:focus {
            outline: none;
            border-bottom-color: #007bff;
}

@media screen and (max-width: 400px) {
  .otp-input {
            width: 38px;
            height: 38px;
}
}

@media screen and (max-width: 360px) {
  .otp-input {
            width: 34px;
            height: 34px;
}

}
</style>
