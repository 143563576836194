import applicationValidationGuard from "@/router/applicationValidationGuard";
import { RouteRecordRaw } from "vue-router";
import locationValidationGuard from "@/router/locationValidationGuard";
import stepValidationGuard from "@/router/stepValidationGuard";
// import enroll from "src/modules/signup/EnrollmentRequirements/EnrollRequirements.vue"
let isFirstVisit = true;
export default [
  {
    path: "/enroll",
    name: "enroll",
    component: () => import("@/modules/signup/EnrollmentRequirements/EnrollRequirements.vue"),
    meta: {
      title: "Enroll Requirements",
      noHeader: true,
      noSignupNav: false,
      noSignupFooter: false,
      open: true,
      layout: "signup",
      step: "ENROLL",
      authgaurd: true
    },
  },
  {
    path: "/signup",
    name: "signup",
    // beforeEnter: locationValidationGuard,
    beforeEnter: (to, from,next) => {
      if (isFirstVisit) {
        isFirstVisit = false;
        next({
          name: "enroll",
          query: {
            locationId: to.query.locationId,
            name: to.query.name,
            campaign : to.query?.campaign || null,
            medium : to.query?.medium || null,
          },
        });
      } else {
        next();
      }
    },
    component: () => import("@/modules/signup/signup/Signup.vue"),
    meta: {
      title: "Signup | Account",
      noHeader: true,
      noSignupNav: false,
      noSignupFooter: false,
      open: true,
      order: 1,
      layout: "signup",
      step: "SIGNUP",
      authgaurd: true
    },
  },
  {
    path: "/signupaccount",
    name: "signupaccount",
    component: () => import("@/modules/signup/EnrollmentRequirements/SignupEmail.vue"),
    meta: {
      title: "Signup | Account",
      noHeader: true,
      noSignupNav: false,
      noSignupFooter: false,
      open: true,
      layout: "signup",
      step: "SIGNUP",
      authgaurd: true
    },
  },
  {
    path: "/signup/verification",
    name: "verification",
    component: () => import("@/modules/signup/verification/Verification.vue"),
    meta: {
      title: "Signup | Verification",
      noHeader: true,
      step: "VERIFICATION",
      order: 2,
      open: true,
      layout: "signup",
      authgaurd: true
    },
  },
  {
    path: "/signup/personalDetails",
    name: "personalDetails",
    beforeEnter: stepValidationGuard,
    component: () =>
      import("@/modules/signup/personalDetails/PersonalDetails.vue"),
    meta: {
      title: "Signup | Personal Details",
      noHeader: false,
      step: "PERSONAL_DETAILS",
      order: 3,
      layout: "dashboard",
      authgaurd:true
    },
  },
  {
    path: '/signup/confirmation',
    name: 'confirmation',
    beforeEnter: stepValidationGuard,
    component: () => import('@/modules/signup/confirmation/Confirmation.vue'),
    meta: {
      title: 'Signup | Confirmation',
      noHeader: false,
      layout: 'dashboard',
      authgaurd:false
    }
  },
  {
    path: "/signup/backgroundCheck",
    name: "backgroundCheck",
    beforeEnter: applicationValidationGuard,
    component: () => import("@/modules/signup/BranchFraudCheck.vue"),
    meta: {
      title: "Signup | Personal Details Background Check",
      noHeader: false,
      order: 3,
      layout: "dashboard",
      authgaurd:true
    },
  },
  {
    path: "/signup/personalDetailsStatus",
    name: "personalDetailsStatus",
    beforeEnter: applicationValidationGuard,
    component: () => import("@/modules/signup/BranchResponse.vue"),
    meta: {
      title: "Signup | Personal Details Status",
      noHeader: false,
      order: 3,
      layout: "dashboard",
      authgaurd:true
    },
  },
  {
    path: "/signup/earningsAccount",
    name: "earningsAccount",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/signup/earningsAccount/SignupEarningsAccount.vue"),
    meta: {
      title: "Signup | Earnings Account",
      noHeader: false,
      order: 4,
      layout: "dashboard",
      authgaurd: true
    },
  },
  {
    path: "/signup/directDeposit",
    name: "directDeposit",
    beforeEnter: applicationValidationGuard,
    component: () => import("@/modules/signup/directDeposit/DirectDeposit.vue"),
    meta: {
      title: "Signup | Direct Deposit",
      noHeader: false,
      order: 4,
      layout: "dashboard",
      authgaurd: true
    },
  },
  {
    path: "/signup/locationUpdate",
    name: "locationUpdate",
    component: () => import("@/modules/signup/locationUpdate.vue"),
    meta: { noHeader: false, step: "VERIFICATION", order: 2, layout: "dashboard",authgaurd:false,open: true },
  },
  {
    path: "/signup/documents",
    name: "documents",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/signup/documents/Documents.vue"),
    meta: {
      title: "Signup | Documents",
      noHeader: false,
      step: "DOCUMENTS",
      order: 4,
      layout: "dashboard",
      authgaurd: true
    },
  },
  {
    path: "/signup/insuranceUpload",
    name: "insuranceUpload",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/signup/documents/InsuranceUpload.vue"),
    meta: {
      title: "Signup | insuranceUpload",
      noHeader: false,
      step: "Insurance",
      order: 4,
      layout: "dashboard",
      authgaurd: true
    },
  },
  {
    path: "/signup/signature",
    name: "createsignature",
    component: () => import("@/modules/signup/signature/Signature.vue"),
    meta: { noSignupNav: true, noHeader: false, layout: "dashboard", authgaurd: true },
  },
  {
    path: "/signup/paymentinfo",
    name: "paymentinfo",
    component: () => import("@/modules/signup/documents/PaymentInfo.vue"),
    beforeEnter: applicationValidationGuard,
    meta: {
      title: "Signup | Payment Processor",
      noHeader: false,
      step: "DOCUMENTS",
      order: 4,
      layout: "dashboard",
      authgaurd: true
    },
  },
  {
    path: "/signup/documents/:id",
    name: "signDocument",
    component: () => import("@/modules/signup/documents/SignDocument.vue"),
    meta: {
      title: "Signup | Delivery Drivers Inc",
      noSignupNav: true,
      noHeader: false,
      authgaurd: false,
      layout: "dashboard",
    },
  },
  {
    path: "/signup/signature",
    name: "signature",
    component: () => import("@/modules/signup/signature/Signature.vue"),
    meta: {
      title: "Signup | Delivery Drivers Inc",
      noSignupNav: true,
      noHeader: false,
      authgaurd: true,
      layout: "dashboard",
    },
  },
  {
    path: "/signup/claimwallet",
    name: "claimwallet",
    beforeEnter: applicationValidationGuard,
    component: () => import("@/modules/signup/ClaimWallet.vue"),
    meta: {
      title: "Wallet | Delivery Drivers Inc",
      noSignupNav: true,
      noHeader: false,
      authgaurd: true,
      layout: "dashboard",
    },
  },
  {
    path: "/signup/signuperror",
    name: "signuperror",
    beforeEnter: applicationValidationGuard,
    component: () => import("@/modules/signup/SignupError.vue"),
    meta: {
      title: "Error | Delivery Drivers Inc",
      noSignupNav: true,
      noHeader: false,
      layout: "dashboard",
    },
  },
  {
    path: "/signup/complete",
    name: "completed",
    beforeEnter: applicationValidationGuard,
    component: () => import("@/modules/signup/SignupSuccess.vue"),
    meta: {
      title: "Signup | Complete",
      order: 5,
      layout: "signup",
      step: "COMPLETED",
      noSignupFooter: true,
      authgaurd:false
    },
  },
  {
    path: "/signup/checklist/:step",
    beforeEnter: applicationValidationGuard,
    name: "checklist",
    component: () => import("@/modules/signup/components/Checklist/Checklist.vue"),
    meta: {
      title: "Signup | Checklist",
      noHeader: false,
      layout: "dashboard",
      authgaurd: false
    },
  },
  {
    path: "/signup/inprogress",
    name: "inProgress",
    component: () => import("@/modules/signup/InProgress.vue"),
    meta: { title: "Delivery Drivers Inc | In Progress", noHeader: false },
  },
  {
    path: "/signup/pending",
    name: "pendingApplications",
    component: () => import("@/modules/signup/PendingApplications.vue"),
    meta: { title: "Delivery Drivers Inc | Pending", noHeader: false },
  },
  {
    path: "/signup/w9",
    name: "w9",
    component: () => import("@/modules/signup/w9/W9Form.vue"),
    meta: {
      title: "Delivery Drivers Inc | W9",
      noHeader: false,
      authgaurd: false,
      noSignupNav: true,
      layout: "dashboard",
    },
  },
  {
    path: "/signup/kycstatus",
    name: "kycResponse",
    beforeEnter: applicationValidationGuard,
    component: () => import("@/modules/signup/PersonaResponse.vue"),
    meta: {
      title: "Signup | Kyc Status",
      noHeader: false,
      order: 3,
      layout: "dashboard",
      authgaurd:true
    },
  },
] as Array<RouteRecordRaw>;
