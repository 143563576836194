import gql from "graphql-tag";

export const UpdatePartner = gql`
  mutation UpdatePartner($partnerId: uuid!, $partner: partners_set_input!) {
    update_partners_by_pk(pk_columns: { id: $partnerId }, _set: $partner) {
      id
    }
  }
`;

export const CreatePartner = gql`
  mutation CreatePartner($partner: partners_insert_input!) {
    partner: insert_partners_one(object: $partner) {
      id
      locations {
        id
        clientLocationID
      }
    }
  }
`;

export const CreatePartnerApplication = gql`
  mutation CreatePartnerApplication(
    $locationPartnerID: uuid
    $status: String
    $currentStep: String
  ) {
    insert_partnerApplications_one(
      object: {
        locationPartnerID: $locationPartnerID
        status: $status
        currentStep: $currentStep
      }
    ) {
      id
    }
  }
`;

export const UpdatePartnerApplication = gql`
  mutation UpdatePartnerApplication(
    $locationPartnerID: uuid!
    $status: String
    $currentStep: String
  ) {
    update_partnerApplications(
      where: { locationPartnerID: { _eq: $locationPartnerID } }
      _set: { currentStep: $currentStep, status: $status }
    ) {
      returning {
        id
        currentStep
        status
      }
    }
  }
`;

export const InsertCandidateProgress = gql`
  mutation InsertCandidateProgress(
    $locationPartnerID: uuid
    $status: String
    $step: String
    $createdBy: String
    $assignedOS: String
    $date: timestamptz
  ) {
    insert_candidateProgress_one(
      object: {
        candidateID: $locationPartnerID
        candidateStatus: $status
        candidateStep: $step
        createdBy: $createdBy
        assignedOS: $assignedOS
        date: $date
      }
      on_conflict: {
        constraint: candidateProgress_candidateID_candidateStep_candidateStatus_key
        update_columns: [candidateStatus]
      }
    ) {
      id
      candidateStep
      candidateStatus
    }
  }
`;

export const CreateAddress = gql`
  mutation CreateAddress($address: addresses_insert_input!) {
    insert_addresses_one(object: $address) {
      id
    }
  }
`;

export const AssignPartnerToLocation = gql`
  mutation CreateClientLocations_Partner(
    $clientLocationID: uuid!
    $partnerID: uuid!
  ) {
    insert_clientLocationPartners_one(
      object: { clientLocationID: $clientLocationID, partnerID: $partnerID }
    ) {
      clientLocationID
      partnerID
    }
  }
`;

export const InsertPartnerApplicationResponses = gql`
  mutation InsertPartnerApplicationResponses(
    $object: partnerApplicationResponses_insert_input!
  ) {
    insert_partnerApplicationResponses_one(
      object: $object
      on_conflict: {
        constraint: partnerApplicationResponses_applicationID_questionID_key
        update_columns: [answeredBy, answeredDate, response]
      }
    ) {
      id
    }
  }
`;

export const SavePersonalDetails = gql`
  mutation SavePersonalDetails(
    $id: uuid!
    $ssn: String
    $ein: String
    $dateOfBirth: date
    $addressID: uuid
  ) {
    update_partners(
      where: { id: { _eq: $id } }
      _set: {
        dateOfBirth: $dateOfBirth
        ssn: $ssn
        ein: $ein
        addressID: $addressID
      }
    ) {
      affected_rows
    }
  }
`;

export const SaveCriminalHistory = gql`
  mutation SaveCriminalHistory(
    $criminalHistory: partnerCriminalHistories_insert_input!
  ) {
    insert_partnerCriminalHistories_one(
      object: $criminalHistory
      on_conflict: {
        constraint: partnerCriminalHistories_applicationID_partnerID_key
        update_columns: [
          convictionDescription
          countyRegistered
          createdAt
          hasPendingCharges
          isSexOffender
          pendingChargesDescription
          stateRegistered
          stateRegisteredYear
          wasConvicted
          isFirstLevelOffender
        ]
      }
    ) {
      id
    }
  }
`;

export const SaveLicenseInformation = gql`
  mutation SaveLicenseInformation(
    $licenseInformation: driversLicenses_insert_input!
  ) {
    insert_driversLicenses_one(
      object: $licenseInformation
      on_conflict: {
        constraint: driversLicenses_pkey
        update_columns: [country, expiration, number, state, status]
      }
    ) {
      id
    }
  }
`;

export const SaveVehicleInformation = gql`
  mutation SaveVehicleInformation(
    $vehicleInformation: partnerVehicles_insert_input!
  ) {
    insert_partnerVehicles_one(
      object: $vehicleInformation
      on_conflict: {
        constraint: partnerVehicles_pkey
        update_columns: [make, model, status, year]
      }
    ) {
      id
    }
  }
`;

export const SaveSignature = gql`
  mutation SaveSignature($id: uuid!, $signature: String!) {
    update_clientLocationPartners_by_pk(
      pk_columns: { id: $id }
      _set: { electronicSignature: $signature }
    ) {
      id
    }
  }
`;

export const SaveFunnelDocuments = gql`
  mutation SaveFunnelDocuments($signedDocument: signedDocuments_insert_input!) {
    insert_signedDocuments_one(
      object: $signedDocument
      on_conflict: {
        constraint: partnerDocuments_pkey
        update_columns: [documentTemplateID, signature, signedFromIP, signedOn]
      }
    ) {
      id
    }
  }
`;

export const SaveFunnelFiles = gql`
  mutation SaveFunnelFiles($partnerFile: partnerFiles_insert_input!) {
    insert_partnerFiles_one(
      object: $partnerFile
      on_conflict: { constraint: partnerFiles_pkey, update_columns: [fileID] }
    ) {
      id
    }
  }
`;

export const SaveSignedDocument = gql`
  mutation SaveSignedDocument($object: signedDocuments_insert_input!) {
    insert_signedDocuments_one(object: $object) {
      id
    }
  }
`;