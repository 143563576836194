
import { defineComponent, onMounted, reactive, ref } from "vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { FunnelStatus } from "@/util/FunnelStatus";

export default defineComponent({
  name: "PendingApplication",
  components: { IconCheck },
  props: {
    pendingApplication: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // the pending status begins submitted, but unchecked
    let statuses = reactive({
      paymentAgreementInProgress: false,
      paymentAgreementCompleted: false,
      insuranceVerficationInprogress: false,
      insuranceVerficationCompleted: false,
      screening: false,
      screeningComplete: false,
      screeningDenied: false,
      completeOnHold: false,
      completed: false,
      approval: false,
    });


    onMounted(() => {
      const sessionData:any = sessionStorage.getItem("ddi:user");
      const candidate = JSON.parse(sessionData)
      getCandidateProgress(candidate.locationPartnerId)
    });


    const getCandidateProgress = (locationPartnerId:any) =>{
        proxyApi.post('/getFunnelProgress',encodeWithParam({candidateID: locationPartnerId})).then((data) =>{
        console.log("data",data.data);
        data.data.result.map((item)=>{
          if(item.candidateStatus === FunnelStatus.PAYMENT_AGREEMENT_PENDING) statuses.paymentAgreementInProgress = true;
          if(item.candidateStatus === FunnelStatus.PAYMENT_AGREEMENT_COMPLETE) statuses.paymentAgreementCompleted = true;
          if(item.candidateStatus === FunnelStatus.PAYMENT_AGREEMENT_COMPLETE) statuses.insuranceVerficationInprogress = true;
          if(item.candidateStatus === FunnelStatus.OCR_CLEAR || item.candidateStatus === FunnelStatus.OCR_MANUAL_CLEAR ) statuses.insuranceVerficationCompleted = true;
          if((item.candidateStatus === FunnelStatus.MVR_SUBMITTED || item.candidateStatus === FunnelStatus.MVR_CONSIDER || item.candidateStatus === FunnelStatus.CBC_SUBMITTED || item.candidateStatus === FunnelStatus.CBC_CONSIDER)) statuses.screening = true;
          if((item.candidateStatus ===  FunnelStatus.MVR_DENIED || item.candidateStatus === FunnelStatus.CBC_DENIED || item.candidateStatus === FunnelStatus.MVR_SUSPENDED || item.candidateStatus === FunnelStatus.CBC_SUSPENDED)) statuses.screeningDenied = true;
          if((item.candidateStatus === FunnelStatus.APPLICATION_WAITLIST)) statuses.screeningComplete = true;
          if(item.candidateStatus === FunnelStatus.APPLICATION_WAITLIST || item.candidateStatus === FunnelStatus.DMS_DATA_ISSUE) statuses.completeOnHold = true;
          if(item.candidateStatus === FunnelStatus.APPROVED_AUTO || item.candidateStatus === FunnelStatus.APPROVED_MANUAL ) statuses.completed = true;
          if(item.candidateStatus === FunnelStatus.APPROVED_AUTO || item.candidateStatus === FunnelStatus.APPROVED_MANUAL) statuses.approval = true;

        })
    })
  }

    return { state, statuses,getCandidateProgress };
  },
});
