
import { defineComponent, onMounted, ref } from "vue";
import { GetUnfinishedApplicationDetails } from "@/modules/signup/graphql/queries";
import {
  InsertCandidateProgress,
  UpdatePartnerApplication,
} from "@/modules/signup/graphql/mutations";
import { DateTime } from "luxon";
import router from "@/router";
import { state } from "@/harlem/stores";
export default defineComponent({
  name: "InProgress",
  components: { },
  setup() {
    const appToDelete = ref("");
    const unfinishedApps = ref([] as any);
    return { state, unfinishedApps };
  },
});
