import { mutation } from "@/harlem/mutation";
import { Constants } from "@/modules/login/constants";
import router from "@/router";
import { reset } from "@harlem/plugin-reset";
import axios, { AxiosError, AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import { VueCookieNext } from "vue-cookie-next";
import * as Sentry from "@sentry/browser";
import { encode,decode,encodeWithParam } from "@/util/DataEncodeDecode";

interface Token {
  accessToken: string;
  expiry?: number;
  refreshCookie?: string;
}

class AuthenticationService {
  /**
   * @param { Object } credentials
   * @param {String} credentials.username
   * @param {String} credentials.password
   * @returns {Promise<void>}
   */

    login = async (credentials): Promise<void> => {
    let data:any;
    const url=config.VUE_APP_PROXY_BASE_URL || process.env.VUE_APP_PROXY_BASE_URL;
    await axios.post(`${url}/login`,credentials).then((response: AxiosResponse) => {
      data=response;
      if(data.data.show_security_question){
        sessionStorage.setItem("partnerId", data.data.partnerID);
        sessionStorage.setItem("username", data.data.username);
        const accesstoken=decode(data.data.result)
        sessionStorage.setItem("accesstoken", accesstoken.resource);
        router.push({ name: "questionsscreen" });
      }
      else if(response.data.success){
        const userObj=decode(response.data.result);
        sessionStorage.setItem("payeeID",userObj?.user?.payeeID);
        AuthenticationService.storeAuth({accessToken: userObj.token});
        mutation.user.setUserId(userObj.user.userID);
        Sentry.setUser({ userId: userObj.user.userID });
      }  
      return response 
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
      return data;
  };





  // login = (credentials): Promise<void> => {
  //   const url=config.VUE_APP_PROXY_BASE_URL || process.env.VUE_APP_PROXY_BASE_URL;
  //   // return axios
  //   //   .post(Constants["TOKEN_URL"], credentials, {
  //   //     baseURL:
  //   //       config.VUE_APP_AUTH_BASE_URL || process.env.VUE_APP_AUTH_BASE_URL,
  //   //     headers: { "auth-type": "password" },
  //   //     withCredentials: true,
  //   //   })
  //   return axios.post(`${url}/login`,credentials)
  //     .then((response: AxiosResponse) => {
  //       const token = Base64.decode(response.data.access_token);
  //       const decodedToken: any = jwtDecode(token);
  //       const role =
  //         decodedToken["https://hasura.io/jwt/claims"]["x-hasura-default-role"];
  //       const userid =
  //         decodedToken["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
  //       mutation.user.setUserId(userid);
  //       Sentry.setUser({ userId: userid });

  //       if (role !== "PARTNER") {
  //         this.logout();
  //         return Promise.resolve({ code: "InvalidCredentials" }) as any;
  //       } else {
  //         AuthenticationService.storeAuth({
  //           accessToken: Base64.decode(response.data.access_token),
  //           expiry: response.data.expiry,
  //         });
  //       }
  //     })
  //     .catch((error: AxiosError) => {
  //       return Promise.reject(error);
  //     });
  // };

  isTokenValid = () => {
    const expiration = localStorage.getItem(Constants["EXPIRY"]) as string;
    return Date.now() <= Number(expiration) * 1000;
  };

  refreshToken = () => {
    return axios
      .post(
        Constants["TOKEN_URL"],
        {},
        {
          baseURL:
            config.VUE_APP_AUTH_BASE_URL || process.env.VUE_APP_AUTH_BASE_URL,
          withCredentials: true,
          headers: { "auth-type": "refresh" },
        }
      )
      .then((response: AxiosResponse) => {
        const decodedToken: any = jwtDecode(response.data.access_token);
        if (decodedToken.role !== "PARTNER") {
          this.logout();
          return router.push("/");
        } else {
          const token = response.data;
          token.accessToken = token.access_token;
          delete token.access_token;
          AuthenticationService.storeAuth(token);
        }
      });
  };

  logout = () => {
    VueCookieNext.keys().forEach((cookie) =>
      VueCookieNext.removeCookie(cookie)
    );
    reset("user");
    reset("signup");
    reset("application");
    reset("funnel");
    localStorage.clear();
    sessionStorage.clear();
    Sentry.configureScope((scope) => scope.setUser(null));
  };

  private static storeAuth(token: Token) {
    localStorage.setItem(Constants["ACCESS_TOKEN_KEY"], token.accessToken);
    // localStorage.setItem(Constants["EXPIRY"], token.expiry.toString());
  }
}

export default new AuthenticationService();
