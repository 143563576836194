
import { defineComponent, onMounted, reactive,ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { proxyApi, restApi, signup } from "@/http/http.config";
import { state } from "@/harlem/stores";
import { reset } from "@harlem/plugin-reset";
import { setStatus } from "@/services/stage.service";
import Base64 from "base-64";
import micromodal from "micromodal";
import "../../../assets/css/components/formInput.scss";
import { encode, encodeWithParam } from "@/util/DataEncodeDecode";
import walmartAssociateModal from '@/modules/signup/signup/walmartAssociateModal.vue';
import { Field, Form,configure } from "vee-validate";
import * as yup from "yup"
import TextFieldValidated from "@/components/forms/TextFieldValidated.vue";
import { gTagEvent } from "@/util/GoogleAnalytics";
import CommonService from "@/services/common.service";
import { SUCCESS_CODES } from "@/modules/login/constants";

export default defineComponent({
  name: "signupaccount",
  components: {
    Form,
    walmartAssociateModal,
    TextFieldValidated,
  },
  methods: {
    resendEmailOTP() {
      this.handleEmailResend();
      // let email = this.initialValues.email;
      this.generateEmailOTP();
    },
    handleEmailResend() {
      let seconds = 5 * 60
      this.updateEmailOtpTime('5:00')
      let timer = setInterval(() => {
        if (seconds <= 0) {
          clearInterval(timer)
          this.updateEmailOtpTime('')
        } else {
          seconds--
          let time = Math.floor(seconds/60) + ':' + (seconds%60).toString().padStart(2, '0')
          this.updateEmailOtpTime(time)
        }
      }, 1000)
    },
    updateEmailOtp(otp) {
     this.initialValues.emailotp = otp;
    },
    updateEmail(email) {
      this.initialValues.email = email;
    },
    
    updateEmailOtpTime(time) {
      this.initialValues.emailtime = time
    },
    generateEmailOTP() {
      let obj = { to: this.initialValues.email,emailOtp:true };
      // this.initialValues["incorrectEmail"] = false
      signup.post("/generateOTP", encodeWithParam(obj)).then((data) => {
        // this.otpText = "";
        if (SUCCESS_CODES.includes(data?.data?.code)) {
          // this.otpText = "Successfully Sent OTP";
          // this.validEmail=true;
          this.isEmailOtpReceived = true;
          this.handleEmailResend()
        } else {
          // this.initialValues["incorrectEmail"] = true
          this.isEmailOtpReceived = false;
        }
      });
    },
    async validateEmailToken(email, otp) {
      this.initialValues["incorrectEmailOtp"] = false
      let obj = {
        email: email,
        otp: otp,
        checkDuplicate: true,
        emailOtp:true
      };
      signup.post("/validateOnboardingOTP", encodeWithParam(obj)).then((data) => {
        if (data.data.success) {
          gTagEvent("Correct OTP Entered", "Verification", true)
          gTagEvent("Complete Verification", "Verification", true)
          setStatus('VERIFICATION', 'INCOMPLETE')
          this.router.push({
            name: "verification",
            query: {
              locationId: this.locationId,
              name: this.locationName,
              campaign: this.campaign,
              medium: this.medium,
            },
          });
        } else {
          setTimeout(() => { this.initialValues["incorrectEmailOtp"] = false }, 5000)
          this.initialValues["incorrectEmailOtp"] = true
        }
      }).catch((e) => {
        if (e?.response?.status === 500) {
          return this.router.push({ name: "login" })
        }
      });
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const locationId = route.query.locationId;
    const locationName = route.query.name;
    const campaign = route.query?.campaign || null;
    const medium = route.query?.medium || null;
    const initialValues = reactive({
      otp: "",
      email:"",
      emailotp:"",
      incorrectEmail:false,
      incorrectEmailOtp:false,
      emailtime:''
    });
    const responseEmail = ref({ email: "", boolean: true });
    const smileyRegex = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug
    const schema = yup.object().shape({
       email: yup
        .string()
        .trim()
        .required()
        .test(
          "Email",
          async function (value: any) {
           let prevEmailid = localStorage.getItem("emailid"); 
            let atposition = +value.indexOf("@");
            let dotposition = +value.lastIndexOf(".");
            if (value.length == 0) {
              return this.createError({ message: "Email is a required field" })
            } else if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z]+\.[a-zA-Z]{2,}$/.test(value)) {
              return this.createError({ message: "Not a valid email" })
            } else if (value.match(smileyRegex)) {
              return this.createError({ message: "Not a valid email" })
            } else if (atposition <= 1 || dotposition + 2 >= value.length) {
              return this.createError({ message: "Not a valid email" })
            } else if (atposition + 2 >= dotposition) {
              return this.createError({ message: "Not a valid email" })
            } else if  (prevEmailid!="" && prevEmailid!=value && !await validateEmailUserTable(value)) {
              return this.createError({ message: "Account already exists" })
            } else {
              localStorage.setItem("emailid", value);
              return true;   
            }     
        })
        .test("Emails", "Email cannot be null", (value: any) => {
          return resetEmail(value);
        })
        .label("Email"),
      confirmEmail: yup
        .string()
        .trim()
        .required("Confirm Email is required")
        .oneOf([yup.ref("email"), null], "Email must match"),

        // EmailOTP: yup
        // .string()
        // .required("OTP is required")
        // .label("OTP")

    });
    const resetEmail = async (value) => {
      if (value === "" || value === null) {
        localStorage.setItem("email", value);
        responseEmail.value.boolean = true;
      }
      return true;
    };

    const  validateEmailUserTable=async(value)=>{
      localStorage.clear();
       const userNameResponse = await signup.post("/checkUserName", {
         param: Base64.encode(JSON.stringify({ email: value.toLowerCase() })),
      })
      const emailResponse = await signup.post("/checkEmail", {
        param: Base64.encode(JSON.stringify({ email: value.toLowerCase() })),
      })
      return ( 
        !SUCCESS_CODES.includes(emailResponse.data.code) && !SUCCESS_CODES.includes(userNameResponse.data.code)
      );
    };


    const disableCreateAccount=(errors)=>{ 
  let confirmEmail = document.querySelector('input[name="confirmEmail"]') as HTMLInputElement;
  let email = document.querySelector('input[name="email"]') as HTMLInputElement;
          if(Object.keys(errors).length>0 || confirmEmail?.value=='' || email?.value=="")
          {
              return false;
          }
          else{
              return true;
          }
    }

    const urlCheck=()=>{
      if(!route.query['locationId'] && !route.query['name']){
       return router.push({ name: "login" });
      }
      if(!route.query['name']){
        return router.push({ name: "login" });
      }
    }

    onMounted(() => {
      let data = sessionStorage.getItem("signupData")
      if (!data || data === '') {
        localStorage.clear();
        sessionStorage.clear();
        router.push({ name: "login" });
      }
      urlCheck();
      CommonService.triggerRecruticsScript()
      const progress: any = localStorage.getItem("progress")
      const progressObj = JSON.parse(progress)
      if (progressObj?.step === 'VERIFICATION') {
        router.push({
          name: "verification",
          query: {
            locationId: locationId,
            name: locationName,
            campaign: campaign,
            medium: medium,
          },
        });
      } else {
        localStorage.clear();
        reset("user");
        reset("application");
        reset("signup");
        CommonService.triggerRecruticsScript()
      }
      gTagEvent("Enter Verification Page", "Verification", true)
    });

    return {
      initialValues, 
      schema,   
      disableCreateAccount    
    }
  },
  data() {
    const route = useRoute();
    const router = useRouter();
    const locationId = route.query.locationId;
    const locationName = route.query.name;
    const campaign = route.query?.campaign || null;
    const medium = route.query?.medium || null;
    return {
      state,
      router,
      locationId,
      locationName,
      campaign,
      medium,
      isEmailOtpReceived:false,
      checkEmail:"",
      email:"",
    };
  }
});
