
import micromodal from "micromodal";
import { Form } from "vee-validate";
import { useRouter } from "vue-router";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { Snackbar, TextFieldValidated } from "@/components";
import { multiplePayDisabled } from "@/modules/earnings/Helper";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import {
  schema,
  Loader,
  AchBankNumber,
  AchAccountType,
  AccountDetails,
  AccountCategory,
  IncorrectAccount,
  AchAccountCategory,
  DirectDepositHeader,
  ACHLocationDisabled,
  FailedDirectDeposit
} from "@/modules/signup/directDeposit";
export default defineComponent({
  name: "DirectDeposit",
  components: {
    Form,
    Loader,
    Snackbar,
    AchBankNumber,
    AchAccountType,
    AccountDetails,
    AccountCategory,
    IncorrectAccount,
    TextFieldValidated,
    AchAccountCategory,
    DirectDepositHeader,
    ACHLocationDisabled,
    FailedDirectDeposit
},
  setup() {
    onMounted(async () => {
      gTagEvent("Enter Direct Deposit Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
      isMultiplePayDisabled.value = await multiplePayDisabled()
      await proxyApi.get('/getAchOnboardingDetails').then(async (data) => {
        failedCount.value = data.data.attempts
        maxAttempts.value = data.data.maxAttempts
        if (failedCount.value >= maxAttempts.value) {
          router.push({ name: 'earningsAccount' })
        }
        account.firstName = data.data.result[0].firstName
        account.lastName = data.data.result[0].lastName
        account.category = data.data.result[0].accountType
        account.personalName = data.data.result[0].fullName
        account.businessName = data.data.result[0].businessName
        if (account.category === 'Personal') {
          account.disabled = true
        }
      })
    })
    const router = useRouter()
    const account = reactive({ 
      name: computed({
        get: () => account.category === "Business" ? account.businessName : account.personalName,
        set: () => null
      }),
      firstName: '',
      lastName: '',
      category: '',
      personalName: '',
      businessName: '',
      email: '',
      disabled: false
    })
    const failedCount = ref(0)
    const maxAttempts = ref(0)
    const showFailedMessage = ref(false)
    const isConfirmed = ref(false)
    const isMultiplePayDisabled = ref(false)
    const isLoading = ref(false)
    const routingNumber = ref()
    const accountNumber = ref()
    function displayFailedMessage() {
      showFailedMessage.value = true
      setTimeout(() => {
        showFailedMessage.value = false
      }, 2000)
    }
    function resetNumbers() {
      routingNumber.value.resetNumbers()
      accountNumber.value.resetNumbers()
    }
    function onSubmit(values, { resetForm }) {
      gTagEvent("Direct Deposit: Signup Attempt", "Direct Deposit", `partner_id: ${state.user.partnerId}`)
      isLoading.value = true;
      const achData = {
        firstName: account.firstName,
        lastName: account.lastName,
        name: account.name,
        isBusiness: account.category === "Business" ? true : false,
        isSavings: values.type === "savings" ? true : false,
        routingNumber: values.routing,
        accountNumber: values.account,
        communicationEmail: values.email.trim()
      }
      proxyApi.post('/ACHOnboard', encodeWithParam(achData)).then(async (data) => {
        if (data.data.success) {
          let param = {templateName: 'ach_approved',partnerID : state.user.partnerId}
         
          proxyApi.post('/sendSMSEmailForPartner', encodeWithParam(param))
          gTagEvent("Direct Deposit: Complete Signup", "Direct Deposit", `partner_id: ${state.user.partnerId}`)
          goToDashboard()
        } else {
          if (account.disabled) {
            resetForm({
              values: {
                category: account.category,
                name: account.personalName,
              }
            })
          } else {
            resetForm()
          }
          resetNumbers()
          failedCount.value++
          if (failedCount.value >= maxAttempts.value) {
          //let param = {templateName: 'ach_denied',partnerID : state.user.partnerId}
				  //proxyApi.post('/sendSMSEmailForPartner', encodeWithParam(param))
          let paramForACHFlag = {flag_name: 'is_ach_active',partnerId : state.user.partnerId}
				  proxyApi.post('/checkEarningMethodFlag', encodeWithParam(paramForACHFlag))
          micromodal.show('ach-failed')
          } else if (failedCount.value % 3 === 0 && failedCount.value < maxAttempts.value) {
            gTagEvent("Direct Deposit: Show Incorrect Account Error Popup", "Direct Deposit", `partner_id: ${state.user.partnerId}`)
            micromodal.show('incorrect-account')
          } else {
            gTagEvent("Direct Deposit: Show Failed Attempt Message", "Direct Deposit", `partner_id: ${state.user.partnerId}`)
            displayFailedMessage()
          }
        }
        isLoading.value = false;
      })
    }
    function goBack() {
      router.back()
    }
    function goToEarnings() {
      router.push({ name: 'earningsAccount' })
    }
    function goToDashboard() {
      router.push({ name: 'dashboard' })
    }
    return {
      schema,
      account,
      isLoading,
      micromodal,
      isConfirmed,
      routingNumber,
      accountNumber,
      showFailedMessage,
      isMultiplePayDisabled,
      onSubmit,
      goBack,
      goToEarnings,
      goToDashboard
    }
  },
})
