
import { useRouter } from "vue-router";
import { disableEarningsTab } from "@/router/Helpers";
import { defineComponent, onBeforeMount, ref, onMounted } from "vue";
import { IconArrowLeft, IconArrowRight } from "@/components";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { proxyApi, signup } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { FunnelStatus } from "@/util/FunnelStatus";
import { getBranchStatus } from "../funnelStatus/Helpers";
import Loader from "@/components/modals/Loader.vue";
export default defineComponent({
  components: {
    IconArrowLeft,
    IconArrowRight,
    Loader
},
  name: "Index",
  setup() {
    const isPaymentAgreementPending = ref(false);
    const isInsuranceInvalid = ref(false);
    const locationOnboarding = ref(true);
    const branchStatus:any = ref(null);
    const isEnrollmentOnhold: any = ref("")
    const isOnboarding:any = ref("")
    const isLoaded:any = ref(false)
    const isMobile = () => {
      return checklistMethods.isMobile();
    }
    const sessionData: any = sessionStorage.getItem("ddi:user");
    const candidate = JSON.parse(sessionData);
    onMounted(async () => {
      branchStatus.value = await getBranchStatus();
      await getLocationStatus();
      await checkPaymentAgreement();

      isLoaded.value = true;
    })
    //const isMVRClear = res.some(obj => obj.candidateStatus == MVR_CLEAR)
    const getLocationStatus = async () => {
        await signup.post("/validateLocationStatus", encodeWithParam({ clientLocationID: state.user.locationId })).then(async (data: any) => {
          const isOnboarding = await data?.data?.result[0].isOnboarding
          locationOnboarding.value = isOnboarding
        })
    }

    const checkPaymentAgreement = async () => {
      let data = await proxyApi.post('/getFunnelProgress', encodeWithParam({
        candidateID: candidate.locationPartnerId,
      })
      );
      let res = await data?.data?.result;
      const currentStep = res[0]?.candidateStatus;
      isEnrollmentOnhold.value = (currentStep == FunnelStatus.ENROLLMENT_ON_HOLD)
      let funnel_payment_complete = res.some(obj => obj.candidateStatus == FunnelStatus.PAYMENT_AGREEMENT_COMPLETE)
      let funnel_payment_pending = res.some(obj => obj.candidateStatus == FunnelStatus.PAYMENT_AGREEMENT_PENDING)
      let funnel_insurance_invalid = res.some(obj => obj.candidateStatus == FunnelStatus.INSURANCE_IMAGE_INVALID)
      isOnboarding.value = funnel_payment_pending == false;
      if (funnel_payment_pending && !funnel_payment_complete) {
        isPaymentAgreementPending.value = true;
      }
      else {
        isPaymentAgreementPending.value = false;
      }
      if (funnel_insurance_invalid) {
        isInsuranceInvalid.value = false;
      }
      else {
        isInsuranceInvalid.value = true;
      }
    }
    const backButtonNavigation = () => {
      if (isLoaded.value) {
        if (branchStatus.value == "UNCLAIMED") {
          return "/dashboard";
        } else if (isPaymentAgreementPending.value) {
          return "/signup/earningsAccount";
        } else if (locationOnboarding.value == false && isEnrollmentOnhold.value) {
          return `/signup/locationUpdate?${state.user.locationId}`
        } else if (isOnboarding.value) {
          return "/candidate/dashboard";
        } else {
          return "/dashboard";
        }
      } else return
    }

    const router = useRouter();
    const redirectSparkDriver = () => {
      gTagEvent("Open Drive4Spark FAQs from Support Page", "Dashboard", `partner_id: ${state.user.partnerId}`)
      window.open("https://drive4spark.walmart.com/spark-driver-faq", "_blank");
    } 
    return {
      isMobile,
      router,
      redirectSparkDriver,
      backButtonNavigation,
      isLoaded
    };
  },
});
