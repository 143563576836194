
import micromodal from "micromodal";
import { useRouter } from "vue-router";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { FunnelStatus } from "@/util/FunnelStatus";
import Loader from "@/components/modals/Loader.vue";
import { defineComponent, onMounted, ref } from "vue";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { cardsData, getEnabledPayments } from "./EarningTypes";
import OneShareDetails from "@/components/modals/OneShareDetails.vue";
import BranchShareDetails from "@/components/modals/BranchShareDetails.vue";
import { getBranchStatus, getOneStatus } from "@/modules/funnelStatus/Helpers";
import { PendingBackgroundCheck, PrimaryAccountProcessing } from "@/components"
export default defineComponent({
  name: "EarningsAccount",
  components: {
    Loader,
    OneShareDetails,
    BranchShareDetails,
    PendingBackgroundCheck,
    PrimaryAccountProcessing
  },
  setup() {
    const router = useRouter()
    const isLoading = ref(true)
    const payeeID = ref(null)
    const accountProcessing = ref(false)
    const pendingAccount = ref(false)
    const currentRoute = router.currentRoute.value.name;
    const isOnboarding = (currentRoute == "earningsAccount")
    const sessionData: any = sessionStorage.getItem("ddi:user");
    const { garnishment} = JSON.parse(sessionData);
    onMounted(async () => {
      await proxyApi.get('/getDriverPaymentProvider').then(({ data }) => {
        if (new Date(data?.result[0]?.pay_start_date) > new Date()) {
          pendingAccount.value = true
        }
      })
      const branchStatus:any = await getBranchStatus()
      const oneStatus = await getOneStatus()
      const isBranchPending = ['CREATED', 'REVIEW', 'UNCLAIMED'].includes(branchStatus)
      const isONEPending = ['SUBMITTED', 'PENDING'].includes(oneStatus)
      if (isBranchPending || isONEPending) {
        accountProcessing.value = true
      }
      await getPayeeID()
      if (isOnboarding) {
        gTagEvent("Show Onboarding Earning Account Options", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
      } else gTagEvent("Show Dashboard Earning Account Options", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
      await proxyApi.post("/addApplicationStatus", encodeWithParam({ status: FunnelStatus.PAYMENT_AGREEMENT_PENDING }))
      await proxyApi.get('/getLocationMultiplePay').then((data) => {
        const isAchEnabled = data.data.result[0].is_multiple_pay_enabled
        proxyApi.get('/getIsOneOptionEnabled').then((data) => {
          const isOneEnabled = data.data.result[0].show_one_pymt_provider
          const payments = getEnabledPayments(isAchEnabled, isOneEnabled)
          cards.value = cards.value.filter(item => {
            return payments.includes(item.type)
          })
          proxyApi.get('/getHiddenEarningAccounts').then((data) => {
            cards.value = cards.value.filter(item => {
              return !data.data.hiddenAccounts?.includes(item.type)
            })
            isLoading.value = false
          })
        })
      })
    })
    const cards = ref(cardsData)

    const cardFlow = (card) => {
      if (card.id == 1) {
        cardFlowGTag("ONE")
        document.body.style.overflow = 'hidden'
        micromodal.show("one-share-details");
      } else if (card.id == 2) {
        cardFlowGTag("Direct Deposit")
        router.push({ name: 'directDeposit' })
      } else if (card.id == 3) {
        cardFlowGTag("Branch")
        document.body.style.overflow = 'hidden'
        micromodal.show("branch-share-details");
      }
    }

    const cardFlowGTag = (card) => {
      isOnboarding ? gTagEvent(`Clicked ${card} Card from Onboarding Earnings`, "Earnings", `partner_id: ${state.user.partnerId}`) : gTagEvent(`Clicked ${card} Card from Dashboard Earnings`, "Earnings", `partner_id: ${state.user.partnerId}`)
    }

    const performAction = (card) => {
      if (pendingAccount.value || accountProcessing.value) {
        document.body.style.overflow = 'hidden'
        micromodal.show('primary-account-processing')
      } else {
        const hasPayeeID = payeeID.value != null;
        if (isOnboarding) {
          cardFlow(card)
        } else {
          if (hasPayeeID) {
            cardFlow(card)
          } else {
            document.body.style.overflow = 'hidden'
            micromodal.show('pending-background-check')
          }
        }
      }
    };

    const getPayeeID = async () => {
      return await proxyApi.get("getPayeeID").then(({data}) => {
        payeeID.value = data.result.externalID
      })
    } 
    
    return {
      cards,
      isLoading,
      micromodal,
      performAction,
      garnishment
    }
  }
});
