
import { useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import { IconArrowLeft } from "@/components";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";

export default defineComponent({
  components: {
    IconArrowLeft,
},
  name: "Index",
  setup() {
    const isMobile = () => {
      return checklistMethods.isMobile();
    }
    onMounted(() => {
      gTagEvent("View Resource Topic: Join Full Check", "Dashboard", `partner_id: ${state.user.partnerId}`)
    })
    const router = useRouter()
    return {
      isMobile,
      router,
    };
  },
});
