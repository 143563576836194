<template>
    <div v-if="icon == 'IconAppAuthSuccess'">
        <IconAppAuthSuccess />
    </div>
    <div v-else-if="icon == 'IconAppComplete'">
        <IconAppComplete />
    </div>
    <div v-else-if="icon == 'IconAppDocumentsIncomplete'">
        <IconAppDocumentsIncomplete />
    </div>
    <div v-else-if="icon == 'IconTime'" class="icon-time">
        <IconTime />
    </div>
    <div v-else-if="icon == 'IconAppInsuranceExp'">
        <IconAppInsuranceExp />
    </div>
    <div v-else-if="icon == 'IconAppKey'">
        <IconAppKey />
    </div>
    <div v-else-if="icon == 'IconAppUnath'">
        <IconAppUnath />
    </div>
    <div v-else-if="icon == 'IconAppWaitlist'">
        <IconAppWaitlist />
    </div>
    <div v-else-if="icon == 'IconAppWarning'">
        <IconAppWarning />
    </div>
    <div v-else-if="icon == 'IconAppWarningTriangle'">
        <IconWarningTriangle :color="color"/>
    </div>
    <div v-else-if="icon == 'IconIncomplete'">
        <IconIncomplete />
    </div>
    <div v-else-if="icon == 'IconComplete'">
        <IconCheckCircle />
    </div>
    <div v-else-if="icon == 'IconLocation'">
        <IconLocation />
    </div>
</template>
<script>
import {
    IconAppWarning,
    IconAppDocumentsIncomplete,
    IconAppComplete,
    IconAppKey,
    IconAppInsuranceExp,
    IconAppUnath,
    IconAppWaitlist,
    IconAppAuthSuccess,
    IconWarningTriangle,
    IconIncomplete,
    IconCheckCircle
} from "@/components";
import IconTime from "@/components/icons/IconTime.vue";
import IconLocation from "@/components/icons/IconLocation.vue";
import { defineComponent } from "vue";
export default defineComponent({
    name: "FunnelIcon",
    props: {
        icon: {
            required: false,
            default: null,
        },
        color: {
            required: false,
            default: "#3B4459",
        }
    },
    components: {
        IconAppWarning,
        IconAppDocumentsIncomplete,
        IconAppComplete,
        IconAppKey,
        IconAppInsuranceExp,
        IconAppUnath,
        IconAppWaitlist,
        IconAppAuthSuccess,
        IconTime,
        IconWarningTriangle,
        IconIncomplete,
        IconCheckCircle,
        IconLocation
    },
})
</script>
<style lang="css">
.icon-time>div>svg>path {
    d: path("M 12.8112 6.32373 H 11.1895 V 12.7046 L 16.8657 16.0545 L 17.6766 14.7464 L 12.8112 11.907 V 6.32373 Z");
    fill: rgb(59, 68, 89);
}

.icon-time>div>svg>circle {
    stroke: rgb(59, 68, 89);
    stroke-width: 2;
}
</style>