
import { Form, Field } from "vee-validate";
import { computed, defineComponent, ref, onMounted } from "vue";
import IconArrowRight from "@/components/icons/IconArrowRight.vue";
import Loader from "@/components/modals/Loader.vue";
import PageActions from "@/components/PageActions.vue";
import { useRoute } from "vue-router";
import { state } from "@/harlem/stores";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { proxyApi } from "@/http/http.config";
import { gTagEvent } from "@/util/GoogleAnalytics";
import router from "@/router";



export default defineComponent({
  name: "Documents",
  components: {
    IconArrowRight,
    PageActions,
    Loader,
    Form,
    Field
  },
  setup() {
    const route = useRoute();
    const docsToSign = ref(false);
    const taxApproved = ref(false);
    const isLoading = ref(false);
    const checkTaxConsent = ref();
    const showCurrentYearData = ref(false)
    const showPreviousYearsData = ref(false)
    const isDocsReady=ref(false)
    const verification =ref(true)
    const ssn:any = ref('')
    const errorMsg=ref('')
    const showLoader=ref(false);

    const selectedYear = ref()
    let years: any = []
    let date = new Date().getFullYear()-1;
    selectedYear.value = date;
    let startYear = 2022;
    for (let i = date; i >= startYear; i--) {
      let yString = i.toString();
      years.push(yString);
    }

    const getTaxInfo = ((event) => {
      getTaxApproval(event.target.value);
    })

    const submit = async () => {
      showLoader.value=true;
      errorMsg.value = "";
      var ssnPattern = /^\d{3}-?\d{2}-?\d{4}$/;

      if (!ssn.value.length) {
        errorMsg.value = "SSN is required";
      } else if (ssn.value.length < 9) {
        errorMsg.value = "Invalid SSN format";
      } else if (!ssnPattern.test(ssn.value)) {
        errorMsg.value = "Invalid SSN format";
      } else {
        let result = await proxyApi.post(
          "/validateSSNforTax",
          encodeWithParam({ ssn: ssn.value.replace(/-/g, "") })
        );
        if (result.data.message == "LOCKED") {
          errorMsg.value='Your entry could not be validated. You have been logged out of the account.';
          setTimeout(() => {
            return router.push({ name: "login" });
          }, 3000);
          
        } else if (result.data.success) {
          verification.value = false;
        } else {
          errorMsg.value = "Your entry could not be validated. Please try again";
        }
      }
      showLoader.value=false;
    };

    const getTaxApproval = (async (value) => {
      taxApproved.value = false;
      checkTaxConsent.value = false;
      
      const year = selectedYear.value ? selectedYear.value : value
      let data = await proxyApi.get('/enableTaxes')
      if (data.data.success) {
        showCurrentYearData.value = (data.data.data.currentYearNecPdf === "true")? true : false;
        showPreviousYearsData.value = (data.data.data.prevYearNecPdf === "true")? true : false;
      }
      if(enableTaxDocs(year)){
        isDocsReady.value = false
        let consetData: any = await proxyApi.get('/taxConsent');
          checkTaxConsent.value = consetData.data.result[0].tax_consent
          isLoading.value = true;
        let approvalData=await proxyApi.post('/taxApproval', encodeWithParam(year.toString()))
        console.log("approvalData",approvalData)
          taxApproved.value = approvalData.data.data[0].is_approved;
          isLoading.value = false;
      }
      else{
        isDocsReady.value = false;
      }

    })

    const enableTaxDocs = ((value) => {
      let year = new Date().getFullYear()-1;
      if (Number(value) < Number(year)) {
        return showPreviousYearsData.value
      }
      else {
        return showCurrentYearData.value
      }
    })
    getTaxApproval(selectedYear.value)
    onMounted(() => {
      gTagEvent("View Tax Documents Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
    })

    return { state, docsToSign, selectedYear, years, taxApproved, getTaxInfo, isLoading, checkTaxConsent, isDocsReady, verification,ssn,submit,errorMsg,showLoader };
  },
});
