
import { defineComponent, ref, onMounted } from "vue";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import checklistMethods from "../signup/components/Checklist/Helpers/Index";
import { IconArrowLeft, IconArrowRight } from "@/components";
import { DateTime } from "luxon";
import Loader from "@/components/modals/Loader.vue";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { useRouter } from "vue-router";


export default defineComponent({
  name: "DepositSummaryHome",
  components: { IconArrowLeft, IconArrowRight, Loader },
  setup() {
    const Filters: {
      LASTPAY: string;
      LAST30: string;
      YTD: string;
      ALL: string;
    } = {
      LASTPAY: "last_pay",
      LAST30: "last_30",
      YTD: "year_to_date",
      ALL: "all_time",
    };
    const router = useRouter();
    const selectedOption = ref("all_time");
    const isLoading = ref(false);
    const currentFilter: any = ref(Filters.ALL);
    const dropdownFilters = ref([
      {
        label: "Last Pay",
        value: "last_pay",
      },
      {
        label: "Last 30 Days",
        value: "last_30",
      },
      {
        label: "Year to Date",
        value: "year_to_date",
      },
      {
        label: "All Time",
        value: "all_time",
      },
    ]);
    const isEmpty = ref(true);
    const depositSummaries: any = ref([]);

    onMounted(() => {
      getEarnings({ endDate: "2010-01-01" });
      gTagEvent("View Deposit Summary Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
    });
    //Build test ignore this comment
    const setFilter = (event) => {
      let date = new Date();
      if (event.target.value === "last_pay")
        date.setMonth(date.getMonth() - 240);
      else if (event.target.value === "last_30")
        date.setMonth(date.getMonth() - 1);
      else if (event.target.value === "year_to_date")
        date.setMonth(date.getMonth() - 12);
      else if (event.target.value === "all_time")
        date.setMonth(date.getMonth() - 240);
      const formatteddate = date.toISOString().slice(0, 10);
      currentFilter.value = event.target.value;
      getEarnings({ endDate: formatteddate });
    };

    const earningsData = (earnings) => {
      depositSummaries.value = [];
      isEmpty.value = earnings.length == 0 ? true : false;
      if (currentFilter.value == "last_pay") {
        let summary;
        if(earnings?.paymentProcessor === 'ONE') {
          summary = {
            payPeriod: `${formatDate(earnings[0].startDate)} - ${formatDatePacific(earnings[0].endDate)}`,
            amount: earnings[0].amount,
            paymentProcessor: earnings[0].paymentProcessor,
            startDate: earnings[0].startDate,
            endDate: earnings[0].endDate
          }
        } else if(earnings?.paymentProcessor === 'ACH') { // this and above if condition is maybe wrong need to check earnings[0].paymentProccessor
            summary = {
               payPeriod: `${formatDate(earnings[0].startDate)} - ${formatDate(earnings[0].endDate)}`, // Dates timezone needs to checked and fixed
               startDate: earnings[0].startDate,
               endDate: earnings[0].endDate,
               amount: earnings[0].amount,
               depositSummaryID: earnings[0].depositSummaryID,
               paymentProcessor: 'ACH',
            }
          } else {
          summary = {
            payPeriod: `${formatDate(earnings[0].startDate)} - ${formatDatePacific(earnings[0].endDate)}`,
            amount: earnings[0].check.NET.amount ? earnings[0].check.NET.amount : "",
            depositSummaryID: earnings[0].depositSummaryID,
            paymentProcessor: 'BRANCH',
        };
        }
        depositSummaries.value.push(summary);
     } else {

        earnings.map((item)=>{
          let summary;
          if(item?.paymentProcessor === 'ONE') {
            summary = {
               payPeriod: `${formatDate(item.startDate)} - ${formatDatePacific(item.endDate)}`,
               amount: item.amount,
               paymentProcessor: item.paymentProcessor,
               startDate: item.startDate,
               endDate: item.endDate
            }
           
          }else if(item?.paymentProcessor === 'ACH') {
            summary = {
               payPeriod: `${formatDate(item.startDate)} - ${formatDate(item.endDate)}`, // Dates timezone needs to checked and fixed
               startDate: item.startDate,
               endDate: item.endDate,
               amount: item.amount,
               depositSummaryID: item.depositSummaryID,
               paymentProcessor: 'ACH',
            }
          } else {
            summary = {
               payPeriod: `${formatDate(item.startDate)} - ${formatDatePacific(item.endDate)}`,
               amount: item.check.NET.amount ? item.check.NET.amount : "",
               depositSummaryID: item.depositSummaryID,
               paymentProcessor: 'BRANCH',
            }
          }
          if(Object.keys(summary).length) {
            depositSummaries.value.push(summary);
          }
          
        })
        // const map = new Map(
        //   earnings.map((item) => [
        //     item.depositSummaryID,
        //     {
        //       payPeriod: `${formatDate(item.startDate)} - ${formatDatePacific(
        //         item.endDate
        //       )}`,
        //       amount: item.check.NET.amount,
        //       depositSummaryID: item.depositSummaryID
        //     },
        //   ]),
        // );
        // for (const [key, entry] of map.entries()) {
        //   console.log("eee",entry)
        //   depositSummaries.value.push(entry);
        // }
      }
      isLoading.value = false;
    };

    const getEarnings = (date) => {
      isLoading.value = true;
      proxyApi
        .post("/getEarnings", encodeWithParam(date))
        .then(({ data }) => {
          earningsData(data.result);
        })
        .catch((e: any) => {
          isLoading.value = false;
        });
    };

    const formatDate = (date) => {
      return DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("dd MMM yyyy");
    }
    const formatDatePacific = (date) => {
      return DateTime.fromFormat(date, "yyyy-MM-dd").setZone("America/Los_Angeles").toFormat("dd MMM yyyy");
    }

    const viewSummaries =(summary)=>{
      if(summary.paymentProcessor === 'ONE') {
        router.push({name:"oneDepositSummary",query:{startDate:summary.startDate,endDate:summary.endDate}})
      } else if(summary.paymentProcessor === 'ACH') {
        router.push({name: "ACHSummary", query:{startDate: summary.startDate,endDate: summary.endDate, type: "ACH"}})
      } else {
        router.push(`/earnings/deposit-summary/${summary?.depositSummaryID}`);
      }
      
    }

    return {
      setFilter,
      currentFilter,
      Filters,
      depositSummaries,
      isEmpty,
      selectedOption,
      dropdownFilters,
      isLoading,
      viewSummaries
    };
  },
  methods: {
    formatCurrency(amount, space) {
      amount == undefined || isNaN(amount) ? (amount = 0) : amount;
      if (!space) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
      } else {
        const formattedMoney = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
        return `${formattedMoney.substring(0, 1)} ${formattedMoney.substring(
          1,
          formattedMoney.length
        )}`;
      }
    },
    isMobile() {
      return checklistMethods.isMobile();
    },
  },
});
