
import {
  defineComponent, onMounted, reactive, ref
} from "vue";
import * as yup from "yup";
import { Form, configure } from "vee-validate";
import {
  TextFieldValidated,
  Snackbar
} from "@/components";
import SelectorValidated from "@/components/forms/SelectorValidated.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Loader from "@/components/modals/Loader.vue";
import { proxyApi } from "@/http/http.config";
import authenticationService from "@/services/authentication.service";
import router from "@/router";

export default defineComponent({
  name: "MakeAppeal",
  components: {
    Form,
    TextFieldValidated,
    SelectorValidated,
    PulseLoader,
    Loader,
    Snackbar
  },
  setup(props, { emit }) {
    const loadingSubmit = ref(false);
    const btnDisabled = ref(false);
    const issueTypeSelected = ref(false)
    const issueTypeValue = ref("");
    const isDataFetching = ref(true)

    const issueTypes = [
      { value: 'Failed identify verification', text: 'Failed identify verification' },
      { value: 'I have never received a reason', text: 'I have never received a reason' },
      { value: 'I do not understand the reason', text: 'I do not understand the reason' },
      { value: 'I disagree with the reason', text: 'I disagree with the reason' },
      { value: 'I was not aware of the ToU', text: 'I was not aware of the ToU' },
      { value: 'Other', text: 'Other' },
    ]
    
    const handleSourceSelect = (value) => {
      appealDetails["issueType"] = value;
    }

    configure({
      validateOnModelUpdate: true,
    });

    const appealDetails = reactive({}) as {
      [key: string]: string | boolean | number | Date | null | undefined;
    };

    appealDetails.username = "";
    appealDetails.email = "";
    appealDetails.firstName = "";
    appealDetails.middleName = "";
    appealDetails.lastName = "";
    appealDetails.issueType = "";
    appealDetails.reason = "";
    appealDetails.ip = ""

    const snackbarSettings = reactive({
      showSnackbar: false,
      position: 'bottom-center',
    })

    if (localStorage["issueType"]) {
      appealDetails.issueType = JSON.parse(localStorage["issueType"]);
    }

    if (localStorage["reason"]) {
      appealDetails.reason = JSON.parse(localStorage["reason"]);
    }

    const updateDetail = (key, e: any) => {
      if (appealDetails[key] !== e.target.value) {
        appealDetails[key] = e.target.value;
        emit("input", e.target.value);
      }

      if (key === "reason") {
        localStorage.setItem('reason', JSON.stringify(appealDetails.reason))
      } else if (key === "issueType") {
        localStorage.setItem('issueType', JSON.stringify(appealDetails.issueType))
      }
    };

    const submitAppeal = () => {
      loadingSubmit.value = true
      if (appealDetails["middleName"] == "-") {
        appealDetails["middleName"] = ""
      }

      proxyApi.post("/addPartnerAppealRequest", encodeWithParam(appealDetails)).then(() => {
        loadingSubmit.value = false
        btnDisabled.value = true
        if (!snackbarSettings.showSnackbar) {
          snackbarSettings.showSnackbar = true
          setTimeout(() => {
            authenticationService.logout();
            router.push("login");
            snackbarSettings.showSnackbar = false
          }, 3000);

        }
      })
    }


    const getProfile = () => {
      proxyApi.get("/getPartnerProfile").then(({ data }) => {
        const res = data.result[0];
        appealDetails.username = res.username
        appealDetails.email = res.email
        appealDetails.firstName = res.firstName
        appealDetails.middleName = (res.middleName != "" ? appealDetails.middleName : "-")
        appealDetails.lastName = res.lastName
        isDataFetching.value = false
      })
    }

    const schema = yup.object().shape({
      reason: yup
        .string()
        .required("Reason for reactivation is required")
        .max(500, "Reason for reactivation cannot be more than 500 characters")
        .min(25, "Reason for reactivation must be more than 25 characters")
        .label("Reason"),
      issueType: yup
        .string()
        .required("Issue type is required. Please select an option")
        .label("Issue Type"),
    });

    const encodeWithParam = (obj) => {
        const jsonString = JSON.stringify(obj)
        const utf8Encoded = encodeURIComponent(jsonString)
        const base64Encoded = window.btoa(utf8Encoded)
        return {
          "param": base64Encoded
        }
      }

    onMounted(async () => {
      await getProfile()
    });

    return { issueTypeSelected, issueTypeValue, appealDetails, updateDetail, submitAppeal, loadingSubmit, schema, btnDisabled, snackbarSettings, isDataFetching, issueTypes, handleSourceSelect }
  }
})
