
import { defineComponent } from "vue";
import InitChecklist from "../components/Checklist/InitChecklist.vue";
import checklistMethods from "../components/Checklist/Helpers/Index";
import EarningsAccount from "@/modules/signup/earningsAccount/EarningsAccount.vue";
export default defineComponent({
    name: "SignupEarningsAccount",
    components: {
      InitChecklist,
      EarningsAccount
    },
    setup() {
      function isMobile() {
        return checklistMethods.isMobile();
      }
      return {
        isMobile
      }
    }
  },
);
