import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { authApi } from "@/http/http.config";
import router from "@/router";
import { state } from "@/harlem/stores";

export default (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isAuthenticated = !!localStorage.getItem("access_token");
  const isPaymentMethodPending = (state.payment.branchResponse == "REVIEW" || state.user.oneStatus == "PENDING")

  if (from.name === "dashboard" && isAuthenticated && to.name == "login") {
    return router.push({ name: "dashboard" })
  }

  if (from.name === "candidateDashboard" && isAuthenticated && to.name == "login") {
    return router.push({ name: "candidateDashboard" })
  }

  if (from.name && (from.name as string).toLowerCase() === "/signup/signature" && to.name && (to.name as string).toLowerCase() == "/signup/backgroundCheck") {
    return router.push({ name: "/signup/signature" })
  }

  if (
    ((from.path && (from.path as string).toLowerCase() === "/signup/checklist/insuranceupload") || 
    (from.path && (from.path as string).toLowerCase() === "/signup/insuranceupload")) &&
    (to.name && (to.name as string).toLowerCase() == "/signup/documents")
  ) {
    return router.push({name: from.path})
  }

  if (
    ((from.path && (from.path as string).toLowerCase() === "/signup/checklist/earningsaccount") || 
    (from.path && (from.path as string).toLowerCase() === "/signup/earningsaccount")) &&
    (to.name && (to.name as string).toLowerCase() == "/signup/insuranceUpload")
  ) {
    return router.push({name: from.path})
  }

  if ((to.name == "earnings" || to.name == "deposit-summaries" || to.name == "manage-earnings") && isPaymentMethodPending) {
     next({name: "dashboard"})
     return
  }

  if (to.name && (to.name as string).toLowerCase() === "login") {
     const hasAppealsQuery = (to.query.service == "appeals")
    if (isAuthenticated && !hasAppealsQuery) {
      next("/");
    } else {
      next();
    }
  } else {
    if (!isAuthenticated && from.name && (from.name as string).toLowerCase() === "login" && to.name && (to.name as string).toLowerCase() === "signup") {
      router.push({ name: "login" });
    }

    if (to.meta.open) {
      next();
    } else {
      if (isAuthenticated) {
        if (to.meta.layout == 'signup' && to.name === 'completed') {
          return router.push({ name: "dashboard" })
        }
        next()
      } else {
        if (!isAuthenticated && from.name === "OneLandingPage" && to.name === "OneLogin") {
          next();
        }
        else {
          next("/login");
        }

      }
    }
  }
  return true;
};
