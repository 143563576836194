import { state } from "@/harlem/stores"
import { proxyApi } from "@/http/http.config"
import CryptoJS from "crypto-js";

export const sendToOracle = async () => {
  const isAuthenticated = !!localStorage.getItem("access_token");
  let ENC_KEY, url_builder;
  await proxyApi.get('getOracleConfig').then(async ({ data }) => {
    const decryptedData = await JSON.parse(Buffer.from(data.result, 'base64').toString('utf-8'))
    const { ORACLE_ENCRYPTION_KEY, VUE_ORACLE_BASE_URL } = decryptedData
    ENC_KEY = ORACLE_ENCRYPTION_KEY
    url_builder = VUE_ORACLE_BASE_URL;
  })

  let { firstName, lastName, email, phone, userId } = state.user;
  if (email == null) {
    let { comm_email } = state.user;
    email = comm_email;
  }

  const phrase = `p_title= &p_alt_name.first=${firstName}&p_alt_name.last=${lastName}&p_userid=${userId}&p_name.first=${firstName}&p_name.last=${lastName}&p_email.addr=${email}&p_ph_mobile=${phone}`;

  let IV = await genHexString(16);
  const encrypted_key = encrypt(phrase, ENC_KEY, IV);

  //Oracle team wants specific characters to be replaced after encryption
  const updatedEncryptedValue = encrypted_key.replace(
    /[+/=]/g,
    (m) => replaceParams[m]
  );

  //We do not want support button to show to any screen that unauthorized users will visit
  if (isAuthenticated) {
    return `${url_builder}${updatedEncryptedValue}`;
  }
}

const genHexString = (len) => {
  const hex = "0123456789ABCDEF";
  let output = "";
  for (let i = 0; i < len; ++i) {
    output += hex.charAt(Math.floor(Math.random() * hex.length));
  }
  return output;
}

const encrypt = ((val, ENC_KEY, IV) => {
  let cipher = CryptoJS.AES.encrypt(
    val,
    CryptoJS.enc.Utf8.parse(ENC_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(IV),
      mode: CryptoJS.mode.CBC,
    });
  return cipher.toString();
});

const replaceParams = {
  "+": "_",
  "/": "~",
  "=": "*",
};

const oracleConfig = {
  baseURL: process.env.VUE_ORACLE_BASE_URL,
};