import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { Constants } from "@/modules/login/constants";

export default (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isAuthenticated = localStorage.getItem(Constants["ACCESS_TOKEN_KEY"]);
  // const progress= sessionStorage.getItem("progress");
  // const STEPS_MAP = {
  //   SIGNUP: "signup",
  //   VERIFICATION: "verification",
  //   ELIGIBILITY: "eligibility",
  //   PERSONALDETAILS: "personalDetails",
  //   BUSINESS_DETAILS: "businessDetails",
  //   DOCUMENTS: "documents",
  //   PAYMENTAGREEMENT: "paymentinfo",
  //   VETTING: "vetting",
  //   INTERVIEW_1: "interview1",
  //   INTERVIEW_2: "interview2",
  //   SCREENING: "screening",
  //   APPROVED: "approved",
  //   COMPLETED: "completed",
  // };
  if(isAuthenticated){
    return next();
  } 
  else{
    return next("login");
  }
};
