
import { defineComponent, ref, computed, onMounted, reactive } from "vue";
import { signup } from "@/http/http.config";
import * as yup from "yup";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { IconWarningOctagon } from "@/components";
import { Form, Field, ErrorMessage } from "vee-validate";
import router from "@/router";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { useRoute } from "vue-router";
import { decode, encodeWithParam } from "@/util/DataEncodeDecode";
import { VueRecaptcha } from 'vue-recaptcha'
import { SUCCESS_CODES } from "./constants";

export default defineComponent({
  name: "ForgotPassword",
  components: {
    IconWarningOctagon,
    PulseLoader,
    Form,
    Field,
    ErrorMessage,
    VueRecaptcha
  },
  data() {
    return {
      maxOTPLen: 6,
      usernameRules: yup
        .string()
        .trim("Username can't contain white space at the start or end")
        .strict(true)
        .required("Username is required")
        .min(2, "Minimum length of 2 characters")
        .max(50, "Maximum length of 50 characters"),
      OTPRules:  yup
        .string()
        .trim("OTP can't contain white space at the start or end")
        .strict(true)
        .max(6)
        .required("OTP is required")
    }
  },
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const sent = ref(false);
    const resend = ref(false);
    const isFreezingTime = ref(false);

    const validationFailed = ref("");
    const verificationCode = ref("");
    const recaptchaSiteKey = ref("");
    const recaptchaCheck = ref("");
    const recaptcha: any = ref(null)

    const usernameMsg = ref("");
    const fields =ref({username: route.query.username, OTP: "",})

    const usernameVal = reactive({
      username: ""
    });
    const invalidOTPCount = ref(1);
    const resentTimeLeft = ref(0);

    const getOTP = async (username) => {
      const paramObj = { username: username }
      loading.value = true;
      await signup.post("/generateOTP", encodeWithParam(paramObj))
        .then((result) => {
          if(!SUCCESS_CODES.includes(result.data.code)) {
            isFreezingTime.value = true;
          }
          gTagEvent("Send OTP Reset Password", "Login", true)
          usernameMsg.value = '';
          sent.value = true;
          loading.value = false;
          setTimeout(() => {
            sent.value = false;
            resend.value = true;
            isFreezingTime.value = false;
          }, 9000);
        })
            .catch((e) => {
              sent.value = false;
              loading.value = false
            })
    }
    const otpFocusOut=()=>{
      validationFailed.value = "";
    }

    const validateOTP = async (username, OTP) => {
      validationFailed.value = "";
      const paramObj = { username: username, otp: OTP, recaptchaCheck: recaptchaCheck.value };
      await signup.post("/validateOTPWithToken", encodeWithParam(paramObj))
        .then((data) => { 
          const res = data.data
          if (res.resource) {
            let token=decode(res.resource)
            sessionStorage.setItem("partnerId", res.partnerID);
            sessionStorage.setItem("username", username);
            sessionStorage.setItem("accesstoken",token.resource);
            gTagEvent("Correct OTP Reset Password", "Login", true)
            if(res.show_security_question){
            return router.push({ name: "questionsscreen"})
          }
            else{
            return router.push({ name: "updatePassword", query: { resource: token.resource } });
            }
          }
          recaptcha.value.reset()
          recaptchaCheck.value = ''
          verificationCode.value = "";
          let finalCount = invalidOTPCount.value++;
          if(finalCount >= 3){
            validationFailed.value = "Your OTP has expired please request a new OTP";
          }else{
            validationFailed.value = "Invalid verification code";
          }
        })
    }

    const verifyMethod = async (response) => {
      recaptchaCheck.value = response
    }

    const verifyButton = computed(() => {
      if (sent.value) {
        return { label: "Sent!", class: "button-send", disabled: true };
      } else if (resend.value) {
        return {
          label: `Resend Code ${resentTimeLeft.value > 0 ? ('in 0:' + (resentTimeLeft.value > 10 ? resentTimeLeft.value : '0' + resentTimeLeft.value)) : ''}`,
          class: "button-send submit-btn",
          disabled: false,
        };
      } else {
        return { label: "Send OTP", class: "button submit-btn", disabled: false };
      }
    });

    const isSendCodeButtonDisabled = computed(() => {
      if(resentTimeLeft.value > 0) {
        return true;
      } else if (fields.value.username) {
        return Boolean(fields.value.username?.length < 2);
      } else{
        return true;
      }
    })

    onMounted(async () => {
      let username=route.query.username
      if(username){
        getOTP(username)
      }
      await signup.get("/recaptchaSiteKey")
        .then((data) => {
          recaptchaSiteKey.value = data.data.result[0].value
        })
      gTagEvent("View Forgot Password Page", "Login", true)
    });

    return {
      verifyButton,
      sent,
      resend,
      validationFailed,
      verificationCode,
      usernameMsg,
      loading,
      getOTP,
      fields,
      recaptchaSiteKey,
      recaptchaCheck,
      recaptcha,
      verifyMethod,
      validateOTP,
      usernameVal,
      otpFocusOut,
      invalidOTPCount,
      isFreezingTime,
      isSendCodeButtonDisabled
    };
  },
});
