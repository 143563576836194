const nullAddress = {
	streetLine: '',
	secondary: '',
	city: '',
	state: '',
	zipcode: '',
	entries: 0,
}

const taxClassifications = [
	{
		value: 'single-llc',
		text: 'Individual/sole proprietor or single-member LLC',
	},
	{ value: 'c-corporation', text: 'C Corporation' },
	{ value: 's-corporation', text: 'S Corporation' },
	{ value: 'partnership', text: 'Partnership' },
	{ value: 'trust', text: 'Trust/Estate' },
	{ value: 'llc', text: 'Limited Liability Company' },
	{ value: 'other', text: 'Other' },
]
	
export { nullAddress, taxClassifications }