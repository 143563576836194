import { createLogger } from "vue-logger-plugin";

// create logger with options
const logger = createLogger({
  enabled: true,
  level:
    config.VUE_APP_LOGGING_LEVEL ||
    process.env.VUE_APP_LOGGING_LEVEL ||
    "error",
});

export default logger;
