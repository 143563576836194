<template>
	<div class="header-close" @click="login"><span>X</span></div>
	<div class="one-value-prop">
		<div class="one-value-prop-cards">
			<div class="one-value-prop-card">
				<img class="head-banner" src="@/assets/images/logos/One_SparkDriver_heroimage_Feb2024.png" alt="One">
				<h1>Get your trip earnings after every trip, plus:</h1>
				<ul class="one-value-prop-check-list">
					<li><b>No monthly fees</b> or minimum balances</li>
					<li><b>Take out cash for free </b>with your ONE card at any Walmart Money Center, cashier, or Customer Service</li>
					<li><b>Earn 3% cash back at Walmart</b>, up to $50 a year, with eligible deposits* </li>
					<!-- <li><b>Deposit or take out cash for free </b> with your card at any Walmart Money Center, cashier, or Customer Service desk</li> -->
				</ul>
				<div class="one-value-prop-terms">
					<p><a target="_blank"
							href="https://hello.one.app/e/apy-disclosure"><u>See Annual Percentage Yield disclosure</u></a></p>
				</div>
				<p class="one-value-prop-learn-more"><a target="_blank" :href="`/one/faqs?name=${locationName}`"><u>Learn more about ONE</u></a></p>
			</div>
			<div class="one-value-prop-card">
				<div @click="toggleAccountInfo" class="arrow-down-props">
					<h1>Account Info</h1>
					<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9 1L5 5L1 1" stroke="#3B4459" stroke-width="2" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</div>

				<p>We’ll use these details from your Spark Driver™ account to create your personal ONE account.</p>
				<div class="one-confirmation-details" v-if="showAccountInfo">
					<div class="user-details">
						<div class="field">
							<div class="input-control">
								<label for="name">Legal Name</label>
								<p>{{ partnerObj.name }}</p>
							</div>
						</div>
						<div class="field">
							<label for="email">Email</label>
							<div class="editable">
								<div class="left-data">
									<p>{{ partnerObj.email }}</p>
								</div>
							</div>
						</div>
						<div class="field">
							<label for="email">Home Address</label>
							<div class="editable" v-if="!editAddress">
								<div class="left-data">
									<p>{{ address.address1 }}&nbsp;{{ address.address2 }}</p>
									<p>{{ address.city }}&nbsp;{{ address.state }}&nbsp;{{ address.county }}&nbsp;{{
										address.zip
									}}</p>
								</div>
								<div class="right-edit">
									<a @click="edit('address')">Edit</a>
								</div>
							</div>

							<div v-if="editAddress">
								<div id="edit-address" :class="show ? 'modal show' : 'modal hide'" aria-hidden="true">
									<div class="modal-window_overlay" tabindex="-1" data-micromodal-close>
										<div class="micro-modal_container" id="modal-fixed-width" role="dialog"
											aria-modal="true" aria-labelledby="modal-title">
											<div class="modal-content">
												<div class="head-icons">
													<div class="left">
														<svg width="44" height="38" viewBox="0 0 44 38" fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" clip-rule="evenodd"
																d="M37.446 12.5024L31.7822 5.50807L5.55348 26.7476L3.13969 34.4927L11.2174 33.7419L37.446 12.5024Z"
																stroke="#3171FF" stroke-width="1.7"
																stroke-linejoin="round" />
															<rect width="9" height="6.75"
																transform="matrix(-0.62932 -0.777146 -0.777146 0.62932 42.6919 8.25446)"
																stroke="#3171FF" stroke-width="1.7"
																stroke-linejoin="round" />
														</svg>
													</div>
													<div class="right" @click="cancelEdit">
														<svg width="18" height="18" viewBox="0 0 18 18" fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																d="M17.0936 2.71929C17.5942 2.21868 17.5942 1.40704 17.0936 0.906429C16.593 0.405822 15.7813 0.405822 15.2807 0.906429L9 7.18714L2.71929 0.906429C2.21868 0.405822 1.40704 0.405822 0.906429 0.906429C0.405822 1.40704 0.405822 2.21868 0.906428 2.71929L7.18714 9L0.906429 15.2807C0.405822 15.7813 0.405822 16.593 0.906429 17.0936C1.40704 17.5942 2.21868 17.5942 2.71929 17.0936L9 10.8129L15.2807 17.0936C15.7813 17.5942 16.593 17.5942 17.0936 17.0936C17.5942 16.593 17.5942 15.7813 17.0936 15.2807L10.8129 9L17.0936 2.71929Z"
																fill="#3171FF" />
														</svg>

													</div>
												</div>
												<div class="title" style="text-algin: left">
													<div class="edit-title">Edit home address</div>
													<address-fields-one @save-details="addressSaved"
														@cancel-edit="cancelEdit" :addressData="address" name="Address"
														style="text-align: left" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="field">
							<div class="input-control">
								<label for="name">Phone number</label>
								<p>{{ partnerObj.phone }}</p>
							</div>
						</div>
						<div class="field">
							<div class="input-control">
								<label for="name">Date of birth</label>
								<p>{{ partnerObj.dob }}</p>
							</div>
						</div>
						<div class="field">
							<div class="input-control">
								<label for="name">Last 4 digits of Social Security number</label>
								<p>{{ partnerObj.ssnlastfour }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="one-value-prop-confirm-checkbox">
			<input type="checkbox" name="confirm" id="confirm" v-model="isConfirmed">
			<label for="confirm">
				By continuing, I authorize Walmart to share profile information with ONE to apply for an account.
				Review
				 <a target="_blank" href="https://hello.one.app/e/privacy-notice"><u>Privacy Policies</u></a> and ONE’s <a
					target="_blank" href="https://hello.one.app/e/tos"><u>Terms of Service</u></a>.
			</label>
		</div>
		<p class="field-error" v-if="urlNotfound">Something went wrong</p>
		<button :class="isConfirmed ? 'button' : 'button disabled'" :disabled="!isConfirmed" @click="handleClick">ACTIVATE YOUR ONE ACCOUNT </button>
	</div>
</template>

<script>
import { proxyApi } from '@/http/http.config'
import AddressFieldsOne from "@/components/forms/AddressFieldsOne.vue";
import { state } from "@/harlem/stores";
import { gTagEvent } from '@/util/GoogleAnalytics';
import { decrypt, encodeWithParam } from '@/util/DataEncodeDecode';
import { FunnelStatus } from '@/util/FunnelStatus';

export default {
	components: {
		AddressFieldsOne
	},
	data() {
		return {
			partnerObj: { name: "", email: "", dob: "", phone: "", ssnlastfour: "", dlNumber: "", dlExpiry: "" },
			address: { address1: "", address2: "", city: "", state: "", county: "", zip: "", countyRegistered: "", id: "" },
			editAddress: false,
			showAccountInfo: false,
			isConfirmed: false,
			locationName: state.user.locationName,
			urlNotfound: false
		}
	},
	mounted() {
		if ( state.user.oneStatus !== 'STARTED' && (state.user.oneStatus || (!state.user.isOneIntegrated || !sessionStorage.getItem('payeeID') || sessionStorage.getItem('payeeID')=== 'null'))) {
			return this.$router.push({name: "dashboard"});
		}
		gTagEvent("View ONE Value Prop Page", "ONE", true)
		this.fetchData();
	},
	methods: {
		edit(val) {
			switch (val) {
				case 'address':
					this.show = true;
					this.editAddress = true;
					break
			}
		},
		save(val) {
			switch (val) {
				case 'address':
					this.saveAddress();
					break
			}
		},
		cancel(val) {
			this.fetchData();
			switch (val) {
				case 'address':
					this.editAddress = false;
					this.show = false;
					break;
			}
		},
		addressSaved() {
			this.editAddress = false;
			this.show = false;
			this.editBusinessAddress = false;
			this.fetchData();
		},
		cancelEdit() {
			this.editAddress = false;
			this.show = false;
		},
		fetchData() {
			proxyApi.get("/getPersonalDataForOne").then(async(data) => {
				this.partnerObj.name = `${data.data.result[0].firstName} ${data.data.result[0].lastName}`;
				this.partnerObj.email = data.data.result[0].email;
				this.partnerObj.phone = data.data.result[0].phone;
				this.partnerObj.dob = this.convertDate(data.data.result[0].dateOfBirth);
				this.partnerObj.ssnlastfour = this.ssnLastFourTrim(await decrypt(data.data.result[0].ssn));
				this.partnerObj.dlNumber = data.data.result[0].dlNumber;
				this.partnerObj.dlExpiry = this.convertDate(data.data.result[0].dlExpiry);
				this.address.address1 = data.data.result[0].address1;
				this.address.address2 = data.data.result[0].address2;
				this.address.city = data.data.result[0].city;
				this.address.county = data.data.result[0].county;
				this.address.state = data.data.result[0].state;
				this.address.zip = data.data.result[0].postalCode;
				this.address.id = data.data.result[0].addressID
			})
		},
		toggleAccountInfo() {
			this.showAccountInfo = !this.showAccountInfo;
		},
		saveDetails() {
			console.log("saveDetails")
		},
		ssnLastFourTrim(val) {
			return `${val.slice(-4)}`
		},
		convertDate(date) {
			if (date) {
				let fullDate = date.split('T');
				return fullDate[0]
			}
		},
		handleClick() {
			this.urlNotfound = false;
			if (this.isConfirmed) {
				proxyApi.get("/activateOneAccount").then((data) => {	
					if (data.data.redirect_url) {
						gTagEvent("Clicked Button to Apply for ONE Account - /one/oneValueProp", "ONE", true)
						document.location.href = data.data.redirect_url
					} else{
						this.urlNotfound=true
					}
				})
			}
		},
		login() {
			if(localStorage['access_token']){
				return this.$router.push({name: "dashboard"});
			}
			return this.$router.push({ name: 'login' })
		}
	}
}
</script>
<style scoped src="./styles.css">

</style>