<template>
    <div class="desktop-container">
      <div class="desktop-wrapper">
        <div class="desktop-module_form">
          <h4 class="title-faq">FAQ</h4>
          <div class="accordian-container">
            <div>
              <div class="mobile-accordian_row">
                <div class="accordian-title_container" v-on:click="faqClose=!faqClose">
                  <div :class="faqClose ? 'accordian-title_text faqText' : 'accordian-title_text open faqText'">Why am I unable to continue with my enrollment?</div>
                  <div v-if="!faqClose">
                    <svg width="16" height="10" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 1L10 10L1 1" stroke="#3B4459" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div v-else>
                    <svg width="16" height="10" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 1L10 10L1 1" stroke="#3B4459" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              <div v-show="faqClose" class="accordian-body_text" >We appreciate your patience. If you’re unable to continue with your enrollment it’s because we are temporarily not adding new drivers to your geographic area (known as a zone). We'll notify you once there are openings in your area. For now, no action is required.<br /><br />

If you would like to withdraw from the enrollment process, <a v-on:click="contactSupport">please click here to submit a request.</a></div>
            </div>
          </div>
            <div>
              <div class="mobile-accordian_row" v-for="(row, id) in accordian" v-bind:key="id">
                <div class="accordian-title_container" v-on:click="row.open = !row.open">
                  <div :class="!row.open ? 'accordian-title_text' : 'accordian-title_text open'" v-html="row.question"></div>
                  <div v-if="!row.open">
                    <svg width="16" height="10" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 1L10 10L1 1" stroke="#3B4459" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div v-else>
                    <svg width="16" height="10" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 1L10 10L1 1" stroke="#3B4459" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              <div v-show="row.open" class="accordian-body_text" v-html="row.answer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { proxyApi } from "@/http/http.config";
import { sendToOracle } from "../../components/OracleButtonHelper";
export default {
  mounted() {
    proxyApi.get('/getOnHoldFaqs').then(async (data) => {
      this.accordian = data.data.result
    })
  },
  data() {
    return {
      accordian: [],
      faqClose :false
    }
  },
  methods: {
    close(){
      window.close()
    },
    async contactSupport() {
      const res =  await sendToOracle();
      if (res) {
          window.open(res, "_blank");
        };
    }
  },
}
</script>
<style>
.faqText{
  color: #333333 !important;
  font-weight: 700 !important;
}
.title-faq{
  font-size: 16px !important;
}
@media only screen and (max-width: 768px) {
a{
  color: blue;
  text-decoration: underline;
}
}
</style>
<style src="@/modules/one/styles.css" scoped>
</style>
