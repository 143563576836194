<template>
  <transition name="fade">
    <div>
      <div v-if="isInsuranceLoading" class="text-center">
        <loader />
      </div>
      <div v-else-if="isPDF" :style="{ height: imageHeight, 'max-height': maxHeight, overflow: 'auto' }">  
        <vue-pdf-embed  :source="profileImage" />
      </div>
      <img
        v-else
        :src="profileImage"
        :key="profileImage"
        :alt="imageAlt"
        :class="classes"
        :style="{ height: imageHeight, 'max-height': maxHeight }"
      />
    </div>
  </transition>
</template>
<script>
import { computed, defineComponent, reactive, ref, watch } from "vue";
import VuePdfEmbed from 'vue-pdf-embed';
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import {convertBufferToContent} from "@/util/BufferContentConverter";
import Loader from "@/components/modals/Loader.vue";

export default defineComponent({
  name: "FileViewer",
  components: { VuePdfEmbed, Loader },
  props: {
    imageId: {
      type: String,
      required: false,
    },
    classes: { type: String, required: false },
    height: {
      type: String,
      required: false,
      default: "100%",
    },
    maxHeight: {
      type: String,
      required: false,
      default: "100%",
    },
    isDownloadInsuranceImg: {
      type: Boolean,
      required: false
    },
  },
  setup(props) {
    const profileImage = ref("");
    const isPDF = ref(false);
    const isInsuranceLoading = ref(false);
      if(props.imageId){
        isInsuranceLoading.value = true;
        proxyApi.post('/getUploadedFile', encodeWithParam({ fileID : props.imageId}))
        .then(({data}) => {
            isPDF.value =  data.contentType?.includes("pdf");
            profileImage.value =  data.bufferInHex ? convertBufferToContent(data.bufferInHex,data.contentType) : null;
            isInsuranceLoading.value = false;
          }).catch(() => {
            isInsuranceLoading.value = false;
          })

      }
    const fileDetail = reactive({
      id: "",
      name: "",
      path: "",
      public: false,
    });

    const imageHeight = computed(() => props.height);
    const imageAlt = computed(() => {
      if (fileDetail && fileDetail.name) {
        return fileDetail.name;
      } else {
        return "Image";
      }
    });

    const downloadImage = (src, name) => {
      const link = document.createElement('a');
      link.href = src;
      link.download = name ?? `insurance.${isPDF.value ? 'pdf' : 'jpg'}`; 
      link.click(); 
    }

    watch(() => props.isDownloadInsuranceImg, () => {
      if(profileImage.value) {
        downloadImage(profileImage.value, `insurance.${isPDF.value ? 'pdf' : 'jpg'}`);
      } 
    })

    return { fileDetail, imageHeight, imageAlt, profileImage, isPDF, isInsuranceLoading };
  },
});
</script>

<style>
.vue-pdf-embed div {
  display: flex;
  justify-content: center;
}
</style>
