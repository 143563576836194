
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
} from "vue";
import { Form, Field, configure } from "vee-validate";

import {
  IneligibleModal,
  FieldError,
  WalmartTaxConsentPolicy,
  StateSelectorValidated,
  TextFieldValidated,
} from "@/components";
import * as yup from "yup";
import micromodal from "micromodal";
import { DriversLicenseValidation } from "@/util/DriverLicenseValidation";
import { DateTime } from "luxon";
import { state } from "@/harlem/stores";
import { mutation } from "@/harlem/mutation";
import { useRoute, useRouter } from "vue-router";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DateDropDown from "@/components/forms/DateDropDown.vue";
import Autocomplete from "vue3-autocomplete";
import { isCountyRequired } from "@/util/AddressUtilities";
import Base64 from "base-64";
import { proxyApi, restApi, kycApi } from "@/http/http.config";
import { decrypt, encodeWithParam, encrypt } from "@/util/DataEncodeDecode";
import InitChecklist from "../components/Checklist/InitChecklist.vue";
import checklistMethods from "../components/Checklist/Helpers/Index";
import { setStatus } from "@/services/stage.service";
import SsnModal from '@/modules/signup/personalDetails/SsnModal.vue';
import employerIdentificationModal from '@/modules/signup/personalDetails/employerIdentificationModal.vue';
import employerRegisterBusinessModal from '@/modules/signup/personalDetails/employerRegisterBusinessModal.vue';
// import SelectorValidated from "@/components/forms/SelectorValidated.vue";
import { IconWarningOctagon } from "@/components";
import {
  nullAddress,
  taxClassifications,
} from '@/modules/signup/personalDetails/constants'
import {
  AddressData,
  Suggestion
} from '@/modules/signup/personalDetails/interfaces'
import { validations } from "@/util/Validations";
import { gTagEvent } from "@/util/GoogleAnalytics";
import CommonService from "@/services/common.service";
import { setBusinessFlag } from "@/harlem/mutation/business.mutation";

export default defineComponent({
  name: "PersonalDetails",
  components: {
    IconWarningOctagon,
    FieldError,
    IneligibleModal,
    StateSelectorValidated,
    TextFieldValidated,
    WalmartTaxConsentPolicy,
    Field,
    Form,
    PulseLoader,
    DateDropDown,
    Autocomplete,
    InitChecklist,
    SsnModal,
    employerIdentificationModal,
    employerRegisterBusinessModal,
    // SelectorValidated
  },
  data() {
    return {
      isDobError: false,
      isExpiryError: false,
      cssnError: false,
    };
  },
  setup() {
    configure({
      validateOnModelUpdate: true,
    });

    let ssnHidden = ref(true);
    let einHidden = ref(true);
    let cssnHidden = ref(true);
    const toggleEyeSsn = () => { ssnHidden.value = !ssnHidden.value }
    const toggleEyeEin = () => { einHidden.value = !einHidden.value }
    const ctoggleEyeSsn = () => { cssnHidden.value = !cssnHidden.value }

    const viewSsn = computed(() => ssnHidden.value ? "password" : "text");
    const viewEin = computed(() => einHidden.value ? "password" : "text");
    const cviewSsn = computed(() => cssnHidden.value ? "password" : "text");
    const ssnInputMask = computed(() => ssnHidden.value ? "#########" : "###-##-####")
    const cssnInputMask = computed(() => cssnHidden.value ? "#########" : "###-##-####")
    const einMask = computed(() => einHidden.value ? "##-#######" : "##-#######")

    let licenseHidden = ref(true)
    let clicenseHidden = ref(true)
    const toggleEyeLicense = () => { licenseHidden.value = !licenseHidden.value }
    const viewLicense = computed(() => licenseHidden.value ? "password" : "text");
    const ctoggleEyeLicense = () => { clicenseHidden.value = !clicenseHidden.value }
    const cviewLicense = computed(() => clicenseHidden.value ? "password" : "text");
    const selectedBusinessState = ref('')
    const selectedState = ref('');
    const oldestAllowed = DateTime.utc()
      .minus({ years: 18, days: 1 })
      .toJSDate();
    const currentDate = DateTime.utc().toJSDate();
    const valid = ref(false);
    const loading = ref(false);
    const addressDisable = ref(true);
    const autoComplete = ref<InstanceType<typeof Autocomplete>>();
    const statePersonalSelected = ref<InstanceType<typeof StateSelectorValidated>>();
    const stateBusinessSelected = ref<InstanceType<typeof StateSelectorValidated>>();
    const showQuestions = ref(true);
    const suggestionText = ref(true);
    const warningText = ref(false);
    const responseSSN = ref({ ssn: "", boolean: true });
    const responseLicense = ref({ license: "", boolean: true });
    const router = useRouter();
    const isBusiness = ref(state.business.isBusinessSelected);
    const suggestions = ref<Suggestion[]>([])
    const businessaddressDetails = ref<AddressData>(nullAddress)
    const otherTaxClass = ref(false)
    const einValidmess = ref('')
    const isStateSelected = ref(false)
    const businessaddressDisable = ref(true)
    const eTaxErr = ref(false)
    const eTaxOpen = ref(false);
    const newOne = ref(false);
    const eTaxError = ref('')
    const disableAdd2 = ref(false);
    const restAdd2 = ref(["pobo", "pobox"]);
    const restrictedAddress = ref(["pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"])
    const tworestrictedAddress = ref(["p", "pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"])



    if (sessionStorage.getItem("white_label") === "customCss") {
      showQuestions.value = false;
    }
    // const { updatePartnerApplication, saveCandidateProgress } = signupComposable();
    const route = useRoute();
    const locationId = route.query.locationId;
    const populate = route.query.populate
    const locationName = route.query.name;
    let addressSuggestions: any = ref([]);
    let businessaddressSuggestions: any = ref([]);
    const personalDetails = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined;
    };
    personalDetails.dateOfBirth = "";
    personalDetails.license = "";
    personalDetails.licenseState = "";
    personalDetails.licenseExpiry = "";
    personalDetails.ssn = "";
    personalDetails.address1 = "";
    personalDetails.address2 = "";
    personalDetails.city = "";
    personalDetails.state = "";
    personalDetails.county = "";
    personalDetails.zip = "";
    personalDetails.businessName = "";
    personalDetails.ein = "";
    personalDetails.businessaddress1 = "";
    personalDetails.businessaddress2 = "";
    personalDetails.businesscity = "";
    personalDetails.businessstate = "";
    personalDetails.businesscounty = "";
    personalDetails.businesszip = "";
    personalDetails.taxClassification = "";
    personalDetails.taxClassificationOther = "";
    const initialTouched = reactive({}) as { [key: string]: boolean };

    onMounted(async () => {
      const progress: any = localStorage.getItem("progress")
      const progressObj = JSON.parse(progress)

      await proxyApi.post('/addApplicationStatus', encodeWithParam({ stepName: 'PERSONAL_DETAILS', stepStatus: 'INCOMPLETE' }));
      setStatus("PERSONAL_DETAILS", "INCOMPLETE")

      if (state.signup.personalDetails) {
        for (const [key, value] of Object.entries(
          state.signup.personalDetails
        )) {
          personalDetails[key] = value as any;
          initialTouched[key] = true;
        }
      }
      proxyApi.get('/getPersonalDetails').then(async (data) => {
        personalDetails.dateOfBirth = data.data.result[0]?.dateOfBirth?.substr(0, 10) || '';
        personalDetails.license = data.data.result[0]?.number || '';
        personalDetails.licenseState = data.data.result[0]?.dlState || '';
        personalDetails.licenseExpiry = data.data.result[0]?.expiration?.substr(0, 10) || '';
        personalDetails.ssn = await decrypt(data.data.result[0]?.ssn) || '';
        personalDetails.address1 = data.data.result[0]?.address1 || '';
        personalDetails.address2 = data.data.result[0]?.address2 || '';
        personalDetails.city = data.data.result[0]?.city || '';
        personalDetails.state = data.data.result[0]?.state || '';
        personalDetails.county = data.data.result[0]?.county || '';
        personalDetails.zip = data.data.result[0]?.postalCode || '';
        if (populate === 'true') {
          personalDetails.cssn = personalDetails.ssn
          newOne.value = true;

        }
      })

      if (JSON.parse(localStorage.getItem("isBusiness") || "false")) {
        isBusiness.value = true
      }

      proxyApi.get('/getBusinessDetails').then((data) => {
        if (data.data.result.length != 0) {
          isBusiness.value = true;
          personalDetails.businessName = data.data.result[0].businessName,
            (personalDetails.ein = data.data.result[0].ein
              ? data.data.result[0].ein.includes("-")
                ? data.data.result[0].ein
                : (data.data.result[0].ein.slice(0, 2) +
                  "-" +
                  data.data.result[0].ein.slice(2))
              : ""),
            personalDetails.businessaddress1 = data.data.result[0]?.address1 || '';
          personalDetails.businessaddress2 = data.data.result[0]?.address2 || '';
          personalDetails.businesscity = data.data.result[0]?.city || '';
          personalDetails.businessstate = data.data.result[0]?.state || '';
          personalDetails.businesszip = data.data.result[0]?.postalCode || '';
          personalDetails.taxClassification = data.data.result[0]?.federalTaxClassification || ''
        }
        if (personalDetails.taxClassification == 'other') {
          otherTaxClass.value = true
          const getTaxClassificationOther = localStorage.getItem('taxClassificationOther');
          personalDetails.taxClassificationOther = getTaxClassificationOther
        }
      });
      selectedState.value = '';
      setStatus("PERSONAL_DETAILS", "INCOMPLETE")
      appendRecruiticsScript();

      gTagEvent("Show SSN Tooltip", "Personal Details", `partner_id: ${state.user.partnerId}`)
    });
    const appendRecruiticsScript = () => {
      CommonService.triggerRecruticsScript();
      CommonService.triggerJobCaseScript();
    }
    const openModal = (modal: string) => {
      newOne.value = true;
      micromodal.show(modal);

    };

    const getTaxConsent = () => {
      proxyApi.get('/taxConsent').then(({ data }) => {
        const hasTaxConsentRes = data.result[0]
        if (!hasTaxConsentRes) {
          eTaxErr.value = true
          eTaxError.value = 'Going through e-Tax Consent Terms is mandatory'
        }
        else {
          eTaxErr.value = false
          loading.value = true;
          setTimeout(() => {
              loading.value = false;
             
            }, 2000);
        }
      })

    }

    const walmartTaxConfirmation = (val) => {
      proxyApi.post('/taxConsent', encodeWithParam({
        taxConsent: val,
        signedOn: new Date(),
      })).then((res) => {
        if (res.data.result.length == 0) {
          eTaxErr.value = true
            eTaxError.value = 'Going through e-Tax Consent Terms is mandatory'
          gTagEvent("Update Paperless Tax Consent", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
          if(!newOne.value){
            eTaxErr.value = true
            eTaxError.value = 'Going through e-Tax Consent Terms is mandatory'
          }else {
            eTaxErr.value = false
          }
        }
        micromodal.close("walmart-tax-consent-policy")

      })
    }


    const toggleBusiness = () => {
      isBusiness.value = !isBusiness.value;
      setBusinessFlag(isBusiness.value);
      localStorage.setItem("isBusiness", JSON.stringify(isBusiness.value))
      gTagEvent("Toggle Business", "Business Details", `(Toggled: ${isBusiness.value}) (Partner id: ${state.user.partnerId})`)
      if (isBusiness.value) {
        proxyApi.post('/addApplicationStatus', encodeWithParam({
          stepName: 'BUSINESS_DETAILS',
          stepStatus: 'INCOMPLETE',
        }))
      }
      else {
        proxyApi.post('/addApplicationStatus', encodeWithParam({
          stepName: 'BUSINESS_DETAILS',
          stepStatus: 'INCOMPLETE',
        }))
      }
    }

    const validateSSN = async (value) => {
      value = value.split("-").join("");
      if (
        value.toLowerCase() &&
        responseSSN.value.ssn != value.toLowerCase() &&
        value.length == 9
      ) {
        const encryptedSsn = await encrypt(value.split("-").join(""))
        const result = await proxyApi.post("/validateSSN", {
          param: Base64.encode(
            // JSON.stringify({ ssn: value.toLowerCase() })
            JSON.stringify({ ssn: encryptedSsn })
          )
        });
        responseSSN.value.ssn = value.toLowerCase();
        if (
          result.data.result.length > 0 &&
          result.data.result[0].partnerID != null
        ) {
          responseSSN.value.boolean = false;
          gTagEvent("Duplicate SSN", "Personal Details", `partner_id: ${state.user.partnerId}`)
          // micromodal.show('duplicate-user');
          return false;
        }
        responseSSN.value.boolean = true;
        return true;
      } else if (responseSSN.value.ssn == value.toLowerCase()) {
        return responseSSN.value.boolean;
      }
      return true;
    };
    const validateLicense = async (value) => {
      if (responseLicense.value.license != value) {
        const result = await proxyApi.post("/validateDL", {
          param: Base64.encode(
            JSON.stringify({ dl: value })
          )
        });
        responseLicense.value.license = value;
        if (
          result.data.result.length > 0 &&
          result.data.result[0].partnerID != null
        ) {
          gTagEvent("Duplicate Drivers License", "Personal Details", `partner_id: ${state.user.partnerId}`)
          responseLicense.value.boolean = false;
          return false;
        }
        responseLicense.value.boolean = true;
        return true;
      } else if (responseLicense.value.license == value) {
        return responseLicense.value.boolean;
      }
      return true;
    };

    const schema = yup.object().shape({
      dateOfBirth: yup
        .date()
        .required("Date of Birth is required")
        .label("Date of Birth")
        .typeError("Date of birth is required") // D
        .max(oldestAllowed, "Age should not be less than 18 years"),
      businessaddress2: yup.string().trim().label("Address"),
      // .test("businessaddress2",
      //         "Please enter a valid address other than PO box",
      //         function (value: any) {

      //           return !restrictedAddress.value.some((v)=> value.indexOf(v) >= 0);
      //         }
      //       ),
      ssn: yup
        .string()
        .trim()
        .matches(
          /^(?!219-09-9999|078-05-1120|111-11-1111|000-00-0000)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}|(?!219099999|078051120|111111111|000000000)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/g,
          "SSN format invalid"
        )
        .typeError("SSN must be a number")
        // .notOneOf([yup.ref("ein")], "SSN and EIN should not be the same")
        .required("Social security number is required")
        // .test("SSN", "SSN is already in use", (value: any) => {
        //   // Please change the approch here it is hiiting n number of time 
        //   return validateSSN(value);
        // })
        // .min(9, "Social security number is required")
        .label("SSN"),
      cssn: yup
        .string()
        .required("Confirm SSN is a required field")
      // .oneOf([yup.ref("ssn"), null], "SSN mismatched")
      ,
      confirmssn: yup
        .string()
        .trim()
        .required("Social security numbers do not match") //confirm social security number is required(doubt:should be changed in all .?)
        .min(9, "Social security numbers do not match")
        .oneOf([yup.ref("ssn"), null], "Confirm social security number is required"),
      license: yup
        .string()
        .trim("License number can't contain white space at the start or end")
        .strict(true)
        .required("Driver's license number is required")
        .when("licenseState", {
          is: (val: string) => !!val,
          then: yup
            .string()
            .test(
              "license",
              function (value: any) {
                const state = this.parent["licenseState"];
                const regex = DriversLicenseValidation(state);
                const isLicenseValid = regex.test(value.toUpperCase())
                const hasSpace = new RegExp(/[\s]/)
                const hasSpecialChar = new RegExp(/[^((0-9)|(a-z)|(A-Z)|\s'-]/)

                if (hasSpace.test(value)) {
                  return this.createError({ message: "Driver's license cannot include spaces" })
                } else if (hasSpecialChar.test(value)) {
                  return this.createError({ message: "Driver's license cannot include special characters" })
                } else if (!isLicenseValid) {
                  return this.createError({ message: "Driver's license number is not valid" })
                } else return true;
              }
            ),
          otherwise: yup.string().notRequired().label("Enter correct driver license"),
        }),
      confirmlicense: yup
        .string()
        .trim("Driver's license cannot include spaces")
        .strict(true)
        .required("Driver's license number is required")
        // .oneOf([yup.ref("license"), null], "Confirm Driver License do not match")
        .when("licenseState", {
          is: (val: string) => !!val,
          then: yup
            .string()
            .test(
              "confirmlicense",
              function (value: any) {
                const state = this.parent["licenseState"];
                const regex = DriversLicenseValidation(state);
                const isLicenseValid = regex.test(value.toUpperCase())
                const hasSpace = new RegExp(/[\s]/)
                const hasSpecialChar = new RegExp(/[^((0-9)|(a-z)|(A-Z)|\s'-]/)

                if (hasSpace.test(value)) {
                  return this.createError({ message: "Confirm Driver's license cannot include spaces" })
                } else if (hasSpecialChar.test(value)) {
                  return this.createError({ message: "Confirm Driver's license cannot include special characters" })
                }else if (value.trim().length==0) {
                  return this.createError({ message: "Driver's license number is required" })
                }
                 else if (!isLicenseValid) {
                  return this.createError({ message: "Confirm Driver's license number is not valid" })
                } else if(value !=this.parent["license"] ){
                 return this.createError({message :"Confirm Driver License do not match"})
                } else return true;
              }
            ),
          otherwise: yup.string().notRequired().label("Enter correct driver license"),
        }),
      licenseState: yup
        .string()
        .trim()
        .required(
          "State is a required field"
        ),
      licenseExpiry: yup
        .date()
        .required("License expiration date is required")
        .label("License Expiration")
        .min(new Date(), "License cannot be expired")
        .typeError("License cannot be expired"),
      address1: yup.string().trim().required("Street address is required").label("Street Address")
        .test("address1",
          "Please enter a valid home address other than PO box",
          function (value: any) {
            const bool = value.toLowerCase().split(' ').some((strItem: string) => restrictedAddress.value.includes(strItem));
            disableAdd2.value = bool;
            return !bool
          }
        ),
      address2: yup.string().trim().label("Address")
        .test("address2",
          "Please enter a valid home address other than PO box",
          function (value: any) {
            const bool = value.toLowerCase().split(' ').some((strItem: string) => tworestrictedAddress.value.includes(strItem));
            disableAdd2.value = bool;
            return !bool
          }
        )
        .test("address2",
          "Please enter a valid home address other than PO box",
          function (value: any) {
            return !restAdd2.value.some((v) => value.indexOf(v) >= 0);
          }
        )
        .test("address2", "Address cannot include special characters",
          function (value: any) {
            const hasSpecialChar = /[^0-9a-zA-Z\s'/-]/
            if (hasSpecialChar.test(value)) {
              disableAdd2.value = true
              return false

            } return true;
          }
        ),
      city: yup.string().trim("City can't contain white space at the start or end").strict(true).matches(/^[aA-zZ' ]+$/g, "Enter a valid city").required("City is required").label("City"),
      state: yup.string().trim().required("State is required").label("State"),
      county: yup
        .string()
        .trim("County can't contain white space at the start or end")
        .strict(true)
        .matches(/^[aA-zZ ]+$/g, "Enter a valid county")
        .when("state", {
          is: (val: string) => isCountyRequired(val),
          then: yup.string().min(4, "Enter a valid county ").required().label("County"),
          otherwise: yup.string().notRequired().label("County"),
        }),
      zip: yup
        .string()
        .trim()
        .required("ZIP code is required")
        .min(5, "Zip Code must be 5 digits")
        .matches(/^(?!00000)\d{5}(?:[-\s]\d{4})?$/g, "Zipcode is required") //Zipcode is required
        .label("Zip Code"),

      businessName: yup.string().required("Business name is required").label("Business Address")
        .matches(/^[aA-zZ ]+$/g, "Business Name cannot include Spaces, Numbers and Special Characters")
        // .matches(/\s/,"Business Name should not contain spaces")
        .test("businessName", function (value: any) {
          const space = /^\s|\s$/
          if (!space.test(value)) {
            return true
          } else {
            return this.createError({
              message: "Business Name cannot include Spaces, Numbers and Special Characters",
            });
          }
        })
      ,
      ein: yup
        .string()
        .required()
        .test("ein", function (value: any) {
          const isvalidein = new RegExp(/^\d{2}-\d{7}$/);

          if (
            personalDetails.ein &&
            isvalidein.test(personalDetails.ein.toString())
          ) {
            return true;
          } else if (personalDetails.ein == "" || personalDetails.ein == null) {
            return this.createError({
              message: "EIN is required",
            });
          } else {
            return this.createError({
              message: "EIN is invalid",
            });
          }
        })
        .label("EIN"),
      businessaddress1: yup.string().trim().required("Business address street is required").label("Street Address"),
      businesscity: yup.string().trim("City can't contain white space at the start or end").strict(true).matches(/^[aA-zZ' ]+$/g, "Enter a valid city").required("Business city is required").label("City"),
      businessstate: yup.string().trim().required("Business state is required").label("State"),
      businesszip: yup
        .string()
        .trim()
        .required("ZIP code is required")
        .min(5, "Zip Code must be 5 digits")
        .matches(/^(?!00000)\d{5}(?:[-\s]\d{4})?$/g, "Business zipcode is required") //Zipcode is required
        .label("Zip Code"),
      businesscounty: yup
        .string()
        .trim("County can't contain white space at the start or end")
        .strict(true)
        .matches(/^[aA-zZ ]+$/g, "Enter a valid county")
        .when("state", {
          is: (val: string) => isCountyRequired(val),
          then: yup.string().min(4, "Enter a valid county ").required().label("County"),
          otherwise: yup.string().notRequired().label("County"),
        }),
      taxClassification: yup.string().trim().required("Tax Classification is required").label("Tax Classification Other"),
      taxClassificationOther: yup
        .string()
        .when("taxClassification", {
          is: () => otherTaxClass.value,
          then: yup.string().required("Other tax classification is required").test("taxClassificationOther", function (value: any) {
            if (validations.verification.hasSpecialChar.test(value) || validations.verification.hasNum.test(value) || validations.verification.hasWhitespace.test(value)) {
              return this.createError({
                message: "Other tax classification is invalid",
              });
            } else return true
          }),
          otherwise: yup.string().label("taxClassificationOther"),
        }),
    });

    let timeout = ref();
    const findSuggestions =
      (
        setFieldValue: (key: string, value: string) => void,
        searchBusinessText: string
      ) => {
        businessaddressDisable.value = true
        if (searchBusinessText === '') {
          suggestions.value = []
          businessaddressDetails.value = nullAddress
          for (const key in nullAddress) {
            setFieldValue(key, nullAddress[key])
          }
          return
        }
        const regex = new RegExp(/^(PO.*BOX*)/);
        if (!restrictedAddress.value.includes(searchBusinessText.trim().toUpperCase()) && searchBusinessText.trim().toUpperCase().search(regex) === -1) {
          let searchParams = encodeWithParam({ search: searchBusinessText })
          proxyApi.post("/getAddressSuggestions", searchParams)
            .then(({ data }) => {
              if (data.result.length <= 0) {
                //businessaddressDisable.value = false
                suggestions.value = [
                  {
                    name: 'Please enter a valid address',
                    address: nullAddress,
                  }
                ]
                return
              }
              suggestions.value = data.result.map(
                (address: AddressData) => (
                  {
                    name:
                      `${address.streetLine}, 
                  ${address.city}, 
                  ${address.state}, 
                  ${address.zipcode}`,
                    address,
                  }
                )
              )
            })
        }
      }

    const selectSuggestion =
      (
        setFieldValue: (key: string, value: string) => void,
        validate: () => void,
        selection: any
      ) => {
        suggestionText.value = false;
        warningText.value = false;
        setFieldValue('businessaddress2', '');
        setFieldValue('businesscity', '');
        setFieldValue('businesscounty', '');
        setFieldValue('businessstate', '');
        setFieldValue('businesszip', '');
        personalDetails.businessaddress1 = selection.address.streetLine;
        personalDetails.businessaddress2 = selection.address.secondary;
        personalDetails.businesscity = selection.address.city;
        personalDetails.businessstate = selection.address.state;
        personalDetails.businesszip = selection.address.zipcode;
        setFieldValue('businessaddress1', selection.address.streetLine);
        setFieldValue('businessaddress2', selection.address.secondary);
        setFieldValue('businesscity', selection.address.city);
        setFieldValue('businessstate', selection.address.state);
        setFieldValue('businesszip', selection.address.zipcode);
        addressDisable.value = true;
        addressSelectedFromSmarty.value = true;
        if (Object.keys(selection).length > 0) {
          setFieldValue('businessaddress1', selection.address.streetLine);
          setFieldValue('businessaddress2', selection.address.secondary);
          setFieldValue('businesscity', selection.address.city);
          setFieldValue('businessstate', selection.address.state);
          setFieldValue('businesszip', selection.address.zipcode);
          personalDetails.businessaddress1 = selection.address.streetLine;
          personalDetails.businessaddress2 = selection.address.secondary;
          personalDetails.businesscity = selection.address.city;
          personalDetails.businessstate = selection.address.state;
          personalDetails.businesszip = selection.address.zipcode;
          autoComplete.value?.setText(selection.address.streetLine);
          stateBusinessSelected.value?.onChangeState(selection.address.state);
          selectedBusinessState.value = selection.address.state;
          addressDisable.value = true;
          addressSelectedFromSmarty.value = true;
        } else {
          //addressDisable.value = false;
          personalDetails.businessaddress1 = "";
          personalDetails.businessaddress2 = "";
          personalDetails.businesscity = "";
          personalDetails.businessstate = "";
          personalDetails.businesszip = "";
          personalDetails.businesscounty = "";
        }
      }

    const handleStateSelect = (state: string) => (
      isStateSelected.value = state === '' ? false : true
    )

    const einValid = (event) => {
      const regex = /[0]{2}-[0]{7}/
      if (regex.test(event.target.value)) {
        einValidmess.value = "not all zeroes"
      }
      else {
        einValidmess.value = ""
      }

      return einValidmess.value

    }

    const handleTaxSelect = (taxClassification: string) => {
      otherTaxClass.value = (taxClassification === 'other' ? true : false)
      personalDetails.taxClassification = taxClassification
    }

    const handleOtherTaxSelect = (taxClassificationOther: string) => {
      if (otherTaxClass.value) {
        personalDetails.taxClassificationOther = taxClassificationOther
      }
    }

    const handleOpenSSNModal = () => {
      gTagEvent("Show SSN Tooltip", "Personal Details", `partner_id: ${state.user.partnerId}`)
      micromodal.show('modal-ssn')
    }

    const handleOpenBusinessModal = () => {
      gTagEvent("Show Register Business Tooltip", "Business Details", `partner_id: ${state.user.partnerId}`)
      micromodal.show('employer-register-business-modal')
    }

    const updateDetails = (detail, e) => {
      personalDetails[detail] = e;
    }

    let addressSelectedFromSmarty = ref(false);
    const getAddressSuggestion = (searchText: string) => {
      if (searchText.length === 0) {
        suggestionText.value = true;
        warningText.value = false;
      } else {
        suggestionText.value = false;
        warningText.value = true;
      }
      const regex = new RegExp(/^(PO.*BOX*)/);
      if (!restrictedAddress.value.includes(searchText.trim().toUpperCase()) && searchText.trim().toUpperCase().search(regex) === -1) {
        let searchParams = encodeWithParam({ search: searchText })
        proxyApi
          .post("/getAddressSuggestions", searchParams)
          .then(({ data }: any) => {
            if (data.result.length > 0) {
              addressSuggestions.value = data.result.map((item) => {
                return {
                  name: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
                  item,
                };
              });
            } else {
              addressSuggestions.value = [
                {
                  name: "Please enter a valid address",
                  item: {},
                },
              ];
              //addressDisable.value = false;
            }
          }).catch(() => {
            addressSuggestions.value = [
              {
                name: "Please enter a valid address",
                item: {},
              },
            ];
            //addressDisable.value = false;
          });
      } else {
        addressSuggestions.value = [];
        if (searchText.length === 0 && addressSelectedFromSmarty.value) {
          document.getElementById('address1')?.blur();
          personalDetails.address1 = "";
          personalDetails.address2 = "";
          personalDetails.city = "";
          personalDetails.state = "";
          personalDetails.zip = "";
          personalDetails.county = "";
          personalDetails.countyRegistered = "";
          selectedState.value = '';
          statePersonalSelected.value?.onChangeState(" ");
        }
      }
    };
    const autoCompleteError = (errors: any) => errors.address1 ? ['has-error'] : []
    const addressSuggestionComputed = computed(() => addressSuggestions.value);
    const selectedAddress = (selectedAddress: any, validate: any, setFieldValue: any) => {
      suggestionText.value = false;
      warningText.value = false;
      setFieldValue('address2', '');
      setFieldValue('city', '');
      setFieldValue('county', '');
      setFieldValue('state', '');
      setFieldValue('zip', '');
      if (Object.keys(selectedAddress.item).length > 0) {
        setFieldValue('address1', selectedAddress.item.streetLine);
        setFieldValue('address2', selectedAddress.item.secondary);
        setFieldValue('city', selectedAddress.item.city);
        setFieldValue('state', selectedAddress.item.state);
        setFieldValue('zip', selectedAddress.item.zipcode);
        personalDetails.address1 = selectedAddress.item.streetLine;
        personalDetails.address2 = selectedAddress.item.secondary;
        personalDetails.city = selectedAddress.item.city;
        personalDetails.state = selectedAddress.item.state;
        personalDetails.zip = selectedAddress.item.zipcode;
        autoComplete.value?.setText(selectedAddress.item.streetLine);
        // addressDisable.value = false;
        statePersonalSelected.value?.onChangeState(selectedAddress.item.state);
        selectedState.value = selectedAddress.item.state;
        addressDisable.value = true;
        addressSelectedFromSmarty.value = true;
      } else {
        //addressDisable.value = false;
        personalDetails.address1 = "";
        personalDetails.address2 = "";
        personalDetails.city = "";
        personalDetails.state = "";
        personalDetails.zip = "";
        personalDetails.county = "";
        personalDetails.countyRegistered = "";
      }
    };


    const saveDetails = async (data: any) => {
      // getTaxConsent();
      await walmartTaxConfirmation(newOne.value);
      let isValidSSN = await validateSSN(data.ssn);
      let isValidLicense = await validateLicense(data.license);
      if (isValidSSN && isValidLicense) {
        loading.value = true;
        for (const [key, value] of Object.entries(data)) {
          personalDetails[key] = value as any;
        }
        const ssnFour = responseSSN.value.ssn.slice(responseSSN.value.ssn.length - 4);
        const encryptedSsn = await encrypt(responseSSN.value.ssn.split("-").join(""))
        let obj = {
          ssn: encryptedSsn,
          ssnFour: ssnFour,
          dob: personalDetails.dateOfBirth ? personalDetails.dateOfBirth : null,
          dl: {
            dlCountry: personalDetails.county,
            dlState: personalDetails.licenseState,
            dlNumber: personalDetails.license,
            dlExpiry: personalDetails.licenseExpiry,
          },
          address: {
            address1: personalDetails.address1,
            address2: personalDetails.address2,
            address3: null, // personalDetails.address3,
            city: personalDetails.city,
            state: personalDetails.state,
            postalCode: personalDetails.zip,
            country: "US",
            county: personalDetails.county
          }
        };
        if (personalDetails.taxClassificationOther && typeof personalDetails.taxClassificationOther === 'string') {
          localStorage.setItem("taxClassificationOther", personalDetails.taxClassificationOther);
        }
        proxyApi.post("/partnerPersonalDetails", encodeWithParam(obj)).then(async ({ data }) => {
          if (data.success) {

            mutation.signup.setPersonalDetails(JSON.stringify(obj));
            mutation.mergeTags.setPartnerSSN(responseSSN.value.ssn.split("-").join(""));

            let dob = personalDetails.dateOfBirth + '';
            mutation.mergeTags.setDateOfBirth(dob);
            let address = {
              street1: personalDetails.address1,
              street2: personalDetails.address2,
              city: personalDetails.city,
              state: personalDetails.state,
              zip: personalDetails.zip,
            };
            mutation.mergeTags.setPartnerAddress(address);
            setStatus("PERSONAL_DETAILS", "COMPLETE");
            gTagEvent("Complete Personal Details", "Personal Details", `partner_id: ${state.user.partnerId}`)
            mutation.mergeTags.setlicenseMergeTags({
              license_state: personalDetails.licenseState,
              license_expiry: personalDetails.licenseExpiry,
              license_number: personalDetails.license,
            });
            if (!isBusiness.value) {
              proxyApi.get('/deleteBusinessDetails')
              gTagEvent("Withdraw Business Registration", "Business Details", `partner_id: ${state.user.partnerId}`)
              await proxyApi.post('/addApplicationStatus', encodeWithParam({ stepName: 'BUSINESS_DETAILS', stepStatus: 'WITHDRAWN' }));
            }
            else {
              gTagEvent("Complete Business Registration", "Business Details", `partner_id: ${state.user.partnerId}`)
              await proxyApi.post('/addApplicationStatus', encodeWithParam({ stepName: 'BUSINESS_DETAILS', stepStatus: 'INCOMPLETE' }));
              let einValue = personalDetails.ein
                  ? personalDetails.ein?.toString()?.includes("-")
                    ? personalDetails.ein
                    : (personalDetails.ein.toString().slice(0, 2) +
                      "-" +
                      personalDetails.ein.toString().slice(2))
                  : "";
              let businessPayload = {
                businessName: personalDetails.businessName,
                ein: einValue,
                federalTaxClassification: personalDetails.taxClassification,
                businessaddress: {
                  address1: personalDetails.businessaddress1,
                  address2: personalDetails.businessaddress2,
                  address3: null, // personalDetails.address3,
                  city: personalDetails.businesscity,
                  state: personalDetails.businessstate,
                  postalCode: personalDetails.businesszip,
                  country: "US",
                  county: personalDetails.businesscounty
                }
              };
              proxyApi.post('/partnerBusinessDetails', encodeWithParam({
                businessPayload
              }))
                .then(async ({ data }) => {
                  if (data.success) {

                    await proxyApi.post('/addApplicationStatus', encodeWithParam({ stepName: 'BUSINESS_DETAILS', stepStatus: 'COMPLETE' }));
                    setStatus("BUSINESS_DETAILS", "COMPLETE");

                    mutation.business.setBusinessFlag(isBusiness.value);
                    mutation.business.setName(personalDetails.businessName ? personalDetails.businessName.toString() : "");
                    mutation.business.setEin(einValue ? einValue.toString() : ""),
                    mutation.business.setAddress1(personalDetails.businessaddress1 ? personalDetails.businessaddress1.toString() : "");
                    mutation.business.setAddress2(personalDetails.businessaddress2 ? personalDetails.businessaddress2.toString() : "");
                    mutation.business.setCity(personalDetails.businesscity ? personalDetails.businesscity.toString() : "");
                    mutation.business.setCounty(personalDetails.businesscounty ? personalDetails.businesscounty.toString() : "");
                    mutation.business.setState(personalDetails.businessstate ? personalDetails.businessstate.toString() : "");
                    mutation.business.setZipcode(personalDetails.businesszip ? personalDetails.businesszip.toString() : "");
                    mutation.business.setTaxClassification(personalDetails.taxClassification ? personalDetails.taxClassification.toString() : "");
                  }
                })
            }
          }
          if (!eTaxErr.value) {
            setTimeout(() => {
              loading.value = false;
              router.push({
                name: 'confirmation',
                query: {
                  locationId: locationId,
                  name: locationName
                }
              })
            }, 2000);
          }

        })
      }
       else {
        loading.value = false;
        micromodal.show('modal-ineligible')
      }
    };

    const updateStateValue = (category, e: any, setFieldError: any) => {
      if (category == "personal") {
        selectedState.value = e.target.value
        if (selectedState.value === 'CA') {
          setFieldError('county', 'County is required')
        } else {
          setFieldError('county', '')
        }
      } else if (category == "business") {
        selectedBusinessState.value = e.target.value
        if (selectedBusinessState.value === 'CA') {
          setFieldError('county', 'County is required')
        } else {
          setFieldError('county', '')
        }
      }
    }

    return {
      suggestions,
      taxClassifications,
      ssnHidden,
      toggleEyeSsn,
      viewSsn,
      licenseHidden,
      clicenseHidden,
      toggleEyeLicense,
      ctoggleEyeLicense,
      viewLicense,
      cviewLicense,
      ssnInputMask,
      einMask,
      saveDetails,
      initialTouched,
      personalDetails,
      valid,
      state,
      schema,
      currentDate,
      oldestAllowed,
      loading,
      showQuestions,
      addressSuggestions,
      getAddressSuggestion,
      selectedAddress,
      addressDisable,
      autoComplete,
      stateBusinessSelected,
      statePersonalSelected,
      updateStateValue,
      selectedState,
      selectedBusinessState,
      autoCompleteError,
      addressSuggestionComputed,
      suggestionText,
      warningText,
      handleTaxSelect,
      handleOtherTaxSelect,
      handleStateSelect,
      updateDetails,
      micromodal,
      isBusiness,
      handleOpenSSNModal,
      handleOpenBusinessModal,
      toggleBusiness,
      walmartTaxConfirmation,
      getTaxConsent,
      findSuggestions,
      selectSuggestion,
      otherTaxClass,
      businessaddressDisable,
      openModal,
      eTaxError,
      eTaxErr,
      disableAdd2,
      viewEin,
      einHidden,
      toggleEyeEin,
      cssnHidden,
      ctoggleEyeSsn,
      cviewSsn,
      cssnInputMask,
      eTaxOpen,
      newOne
    };
  },
  methods: {
    dateValidate: function (data) {
      this.isDobError = false;
      let age = this.underAgeValidate(data);
      if (data.length === 10) {
        this.personalDetails.dateOfBirth = data
      }
      if (age <= 18) {
        this.isDobError = true;
      }
    },

    changeCSSN: function (e) {
      let ssnValue = document.querySelector('input[name="ssn"]') as HTMLInputElement;
      if (e.target.value.replace(/-/g, '') != ssnValue?.value.replace(/-/g, '')) {
        // gTagEvent("Update Address - Account Info", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
        this.cssnError = true;
        // }
      } else this.cssnError = false;
      if (e.target.value.length === 9) {
        if (e.target.value.replace(/-/g, '') != ssnValue?.value.replace(/-/g, '')) {
          gTagEvent("SSN Mismatch", "Personal Details", `partner_id: ${state.user.partnerId}`)
        }
      }
    },
    changeSSN: function (e) {
      let cssnValue = document.querySelector('input[name="cssn"]') as HTMLInputElement;
      if (e.target.value.replace(/-/g, '').length === 9 && cssnValue.value.length > 2) {
        if (e.target.value.replace(/-/g, '') != cssnValue?.value.replace(/-/g, '')) {
          return this.cssnError = true;
        } else this.cssnError = false;
      }
    },

    expirydateValidate: function (data) {
      this.isExpiryError = false;
      let futuredate = this.isFutureDate(data);
      if (data.length === 10) {
        this.personalDetails.licenseExpiry = data
      }
      if (!futuredate) {
        this.isExpiryError = true;
      }
    },
    underAgeValidate: function (birthday) {
      const end = DateTime.now();
      const start = DateTime.fromISO(birthday);
      const diffInMonths = end.diff(start, "months");
      let age = diffInMonths.months / 12;
      return age;
    },
    isFutureDate: function (dateVal) {
      const g1 = new Date();
      const g2 = new Date(dateVal);
      if (g1.getTime() < g2.getTime()) return true;
      else if (g1.getTime() > g2.getTime()) return false;
    },
    displayAddressSuggestion: function (item) {
      return item.streetLine;
    },
    isMobile() {
      return checklistMethods.isMobile();
    },
    triggerHyphenCheck: function (e) {
      if ((e.key === "Backspace") && e.target.value.length > 0) {
        var str = e.target.value;
        if ("-" == str.charAt(str.length - 1)) {
          str = str.substring(0, str.length - 1);
        }
        e.target.value = str;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
  },
});
