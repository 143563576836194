import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f338f14a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "earnings_subheader"
}
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = { class: "logo" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "bullet-points" }
const _hoisted_7 = { class: "action-button" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "link" }
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_one_share_details = _resolveComponent("one-share-details")!
  const _component_branch_share_details = _resolveComponent("branch-share-details")!
  const _component_pending_background_check = _resolveComponent("pending-background-check")!
  const _component_primary_account_processing = _resolveComponent("primary-account-processing")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.cards.length > 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Explore more"))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
            return (_openBlock(), _createElementBlock("div", {
              key: card.id
            }, [
              (!(card.type=='one' && _ctx.garnishment))
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(card.logo)))
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", null, _toDisplayString(card.header), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(card.bulletPoints, (bullet) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: bullet,
                            class: "bullet-point"
                          }, _toDisplayString(bullet), 1))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("button", {
                        class: "button",
                        onClick: ($event: any) => (_ctx.performAction(card))
                      }, _toDisplayString(card.buttonText), 9, _hoisted_8)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("a", {
                        target: "_blank",
                        href: card.faqsLink
                      }, _toDisplayString(card.faqsText), 9, _hoisted_10)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_Loader, { key: 1 })),
    _createVNode(_component_one_share_details, {
      onAccept: _cache[0] || (_cache[0] = ($event: any) => (_ctx.micromodal.close('one-share-details')))
    }),
    _createVNode(_component_branch_share_details, {
      onAccept: _cache[1] || (_cache[1] = ($event: any) => (_ctx.micromodal.close('branch-share-details')))
    }),
    _createVNode(_component_pending_background_check, {
      onExit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.micromodal.close('pending-background-check')))
    }),
    _createVNode(_component_primary_account_processing, {
      onExit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.micromodal.close('primary-account-processing')))
    })
  ], 64))
}