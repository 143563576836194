
import { defineComponent, ref, onMounted } from "vue";
import { DateTime } from "luxon";
import PageActions from "@/components/PageActions.vue";
import { IconLicense, IconEdit, IconCar, IconDownload2 } from "@/components";
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import {convertBufferToContent} from "@/util/BufferContentConverter";
import { proxyApi } from "@/http/http.config";
import { useRoute } from 'vue-router';
import Dialog from 'primevue/dialog';
export default defineComponent({
  name: "PersonalDocuments",
  components: { IconCar, IconEdit, IconLicense, PageActions,Dialog, IconDownload2 },
  setup() {
    const driverLicenseDisplay = ref("");
    const driverLicenseExpirationDisplay = ref("");
    const insuranceExpirationDisplayDate = ref("");
    const insuranceExpirationDisplay = ref("");
    const InsurancefileId = ref("");
    const route = useRoute();
    const showDialog = ref(false)
    const isPDF = ref(false);
    const insuranceImage = ref<string | null>(null);
      const closeDialog = () => {
      showDialog.value = false;
    };

    	  
    const payeeID = sessionStorage.getItem('payeeID')

      proxyApi.get("/getPartnerInsuranceDetails").then(({data})=>{
          if(data.success){
            driverLicenseDisplay.value = data.result[0].state + " ****" + data.result[0].number.substring(data.result[0].number.length - 4);
            driverLicenseExpirationDisplay.value = dateDisplay(data.result[0].expiration);
            insuranceExpirationDisplay.value = dateDisplay(data.result[0].insuranceExpiry);
          }            
    });

    proxyApi.get("/getUpdatedInsuranceDocuments").then(({data})=>{
          if(data.success){
            insuranceExpirationDisplayDate.value = dateDisplay(data.result[0].insurance_expiry);
          }            
    });
    
    const dateDisplay = (date: string) => {
      return date ? DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat("'Expires: ' dd MMM yyyy")
        : "";
    };

    const downloadInsurance = () => {
      if(insuranceImage.value) {
        const link = document.createElement('a');
        link.href = insuranceImage.value;
        link.download = `insurance.${isPDF.value ? 'pdf' : 'jpg'}`;
        link.click();
      }
    }

    onMounted(() => {
      if(route.query.insuranceUploaded == 'true') {
        showDialog.value = true;
        proxyApi.get("/getPartnerInsuranceDetails").then(({data})=>{
          if(data.success){
            driverLicenseDisplay.value = data.result[0].state + " ****" + data.result[0].number.substring(data.result[0].number.length - 4);
            driverLicenseExpirationDisplay.value = dateDisplay(data.result[0].expiration);
            insuranceExpirationDisplay.value = dateDisplay(data.result[0].insuranceExpiry);
          }            
        });
      } 
      gTagEvent("View Personal Documents Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
      proxyApi.get("/getOnBoardingDocuments").then(({ data }) => {
        const docDeatils = data.result.filter(item => {
          return ["Insurance"].includes(item.title)
        })
	
        if(docDeatils[0]) {
          proxyApi.post('/getUploadedFile', encodeWithParam({ fileID : docDeatils[0].fileID}))
            .then(({data}) => {
            insuranceImage.value =  data.bufferInHex ? convertBufferToContent(data.bufferInHex,data.contentType) : null
            isPDF.value =  data.contentType?.includes("pdf");
          })
        }
      });
    })
    
    return {
      driverLicenseExpirationDisplay,
      driverLicenseDisplay,
      insuranceExpirationDisplay,
      insuranceExpirationDisplayDate,
      InsurancefileId,
      showDialog,
      downloadInsurance,
      closeDialog,
      payeeID
    };
  },
});
