
import { ref, onMounted, reactive, computed } from 'vue'
import { Form, Field } from 'vee-validate'
import Autocomplete from 'vue3-autocomplete'
import micromodal from 'micromodal'
import { mutation } from '@/harlem/mutation'
import { useRoute, useRouter } from 'vue-router'
import { state } from "@/harlem/stores";
import schema from '@/modules/signup/businessDetails/yupSchema'
import * as yup from "yup"
import { proxyApi } from '@/http/http.config'
import { encodeWithParam } from '@/util/DataEncodeDecode'
import { IconArrowLeft } from "@/components";


import {
  FieldError,
  TextFieldValidated,
  StateSelectorValidated,
} from '@/components'
import SelectorValidated from "@/components/forms/SelectorValidated.vue"
import {
  toggleObject,
} from '@/modules/signup/businessDetails/showSignupQuestion'
import EinModal from '@/modules/signup/businessDetails/EinModal.vue'
import {
  nullAddress,
  taxClassifications,
} from '@/modules/signup/businessDetails/constants'
import {
  AddressData,
  BusinessDetailsForm,
  Suggestion
} from '@/modules/signup/personalDetails/interfaces'
import { validations } from '@/util/Validations'
import { isCountyRequired } from '@/util/AddressUtilities'

export default {
  name: 'BusinessDetails',
  components: {
    Form,
    Field,
    FieldError,
    TextFieldValidated,
    StateSelectorValidated,
    SelectorValidated,
    EinModal,
    Autocomplete,
    IconArrowLeft
  },
  setup() {

    const route = useRoute()
    const router = useRouter()
    const locationID = route.query.locationID
    const isBusiness = ref(false)
    const applicationId = route.query.applicationId
    const suggestions = ref<Suggestion[]>([])
    const addressDetails = ref<AddressData>(nullAddress)
    const otherTaxClass = ref(false)
    const einValidmess = ref('')
    const noEmojimess = ref('')
    const stateSelected = ref('')
    const addressDisable = ref(true)
    const ssnHidden = ref(true);
    //const firstAdd = ref(" ")
    const viewSsn = computed(() => ssnHidden.value ? "password" : "text");
    const toggleEyeSsn = () => { ssnHidden.value = !ssnHidden.value };
    const restrictedAddress = ref(["PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"])

    const businessDetails = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined;
    };
    // const schema = yup.object().shape({
    //   secondary:yup.string().trim()
    //   .test("secondary",
    //           "Please enter a valid address other than PO box",
    //           function (value: any) {
                
    //             return !restrictedAddress.value.includes(value) ;
    //           }
    //         ),
    //         businesscounty: yup
    //     .string()
    //     .trim("County can't contain white space at the start or end")
    //     .strict(true)
    //     .matches(/^[aA-zZ ]+$/g, "Enter a valid county")
        
    //       })
    const firstAdd = ref(" ")
    
  
    // const schema = yup.object().shape({
    //   businesscounty:yup.string()
    //   .matches(/^[aA-zZ ]+$/g, "Invalid character in the county")
      

    //   secondary:yup.string().trim()
    //   .test("secondary",
    //           "Please enter a valid address other than PO box",
    //           function (value: any) {
                
    //             return !restrictedAddress.value.some((v)=> value.indexOf(v) >= 0);
    //           }
    //         ),
          // })


    onMounted(() => {
      proxyApi.get("/getFullProfileInfo").then(({ data }) => {
        if (data.businessAddress.length > 0) {
          router.push({ name: 'dashboard' });
        }

        else {
          isBusiness.value = true
        }
      });

      proxyApi.get("/getFullProfileInfo").then(({ data }) => {
        if (data.profile.length) {
          businessDetails.businessaddress1 = data.profile[0].address1;
        }
      })

    });

    businessDetails.businessName = "";
    businessDetails.ein = "";
    businessDetails.businessaddress1 = "";
    businessDetails.businessaddress2 = "";
    businessDetails.businesscity = "";
    businessDetails.businessstate = "";
    businessDetails.businesscounty = "";
    businessDetails.businesszip = "";
    businessDetails.taxClassification = "";

    businessDetails.businessName = state.business.name;
    businessDetails.ein = state.business.ein.replace(/(\d{2})(\d{7})/, "$1-$2")
    businessDetails.streetLine = state.business.address1;
    businessDetails.secondary = state.business.address2;
    businessDetails.city = state.business.city;
    businessDetails.state = state.business.state;
    businessDetails.businesscounty = state.business.county;
    businessDetails.zipcode = state.business.zipcode;
    businessDetails.taxClassification = state.business.taxClassification
    if (businessDetails.taxClassification === 'other') {
      otherTaxClass.value = true
      businessDetails.other = state.business.taxClassificationOther
    }



    const handleForm = (formData: BusinessDetailsForm) => {

      mutation.business.setName(formData.businessName)
      mutation.business.setEin(formData.ein)
      mutation.business.setAddress1(formData.streetLine)
      mutation.business.setAddress2(formData.secondary)
      mutation.business.setCity(formData.city)
      mutation.business.setState(formData.state)
      mutation.business.setZipcode(formData.zipcode)
      mutation.business.setCounty(formData.businesscounty)
      mutation.business.setTaxClassification(formData.taxClassification)
      if (formData.other) {
        mutation.business.setTaxClassificationOther(formData.other)
      }
      router.push({
        name: 'profile-w9', query: {
          applicationId: applicationId,
          locationID: locationID
        }
      })
    }

    const findSuggestions =
      (
        setFieldValue: (key: string, value: string) => void,
        searchText: string
      ) => {
        addressDisable.value = true
        if (searchText === '') {
          suggestions.value = []
          addressDetails.value = nullAddress
          for (const key in nullAddress) {
            setFieldValue(key, nullAddress[key])
          }
          return
        }
        const regex = new RegExp(/^(PO.*BOX*)/);
        if (!restrictedAddress.value.includes(searchText.trim().toUpperCase()) && searchText.trim().toUpperCase().search(regex) === -1) {
          let searchParams = encodeWithParam({ search: searchText })
          proxyApi.post("/getAddressSuggestions", searchParams)
            .then(({ data }) => {
              if (data.result.length <= 0) {
                //addressDisable.value = false
                suggestions.value = [
                  {
                    name: 'Please enter a valid address',
                    address: nullAddress,
                  }
                ]
                return
              }
              suggestions.value = data.result.map(
                (address: AddressData) => (
                  {
                    name:
                      `${address.streetLine}, 
                    ${address.city}, 
                    ${address.state}, 
                    ${address.zipcode}`,
                    address,
                  }
                )
              )
            })
        }
      }

    const selectSuggestion =
      (
        setFieldValue: (key: string, value: string) => void,
        validate: () => void,
        selection: Suggestion
      ) => {
        addressDetails.value = selection.address
        for (const key in selection.address) {
          setFieldValue(key, selection.address[key])
        }
        validate()
      }

    const handleBackClick = () => {
      router.push({ name: 'profile' })
    }

    stateSelected.value = state.business.state

    const handleStateSelect = (event) => {
      stateSelected.value = event.address.state

    }


    const noEmoji = (event) => {
      const regex = /^[-@./#&+\w\s]*$/
      if (!regex.test(event.target.value)) {
        noEmojimess.value = "No Emoji's"
      }
      else {
        noEmojimess.value = ""
      }
      return noEmojimess.value
    }

    const handleTaxSelect = (taxClassification: string) => {
      otherTaxClass.value = taxClassification === 'other' ? true : false
    }

    return {
      taxClassifications,
      toggleObject,
      schema,
      state,
      suggestions,
      addressDetails,
      micromodal,
      otherTaxClass,
      stateSelected,
      handleForm,
      findSuggestions,
      selectSuggestion,
      handleBackClick,
      handleStateSelect,
      handleTaxSelect,
      addressDisable,
      einValidmess,
      noEmoji,
      noEmojimess,
      businessDetails,
      toggleEyeSsn,
      ssnHidden,
      viewSsn,
      isBusiness,
      firstAdd

    }
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z ]+$/.test(char)) return true;
      else e.preventDefault();
    },
  }
}
