
import { onMounted, ref } from "vue"
import { state } from '@/harlem/stores'
import { useRouter } from 'vue-router'
import { proxyApi } from '@/http/http.config';

export default {
	setup() {
		onMounted(async () => {
			const progress: any = localStorage.getItem("progress")
			const progressObj = JSON.parse(progress)
			if (progressObj?.step === 'PERSONAL_DETAILS') {
				goBackToPersonalDetails();
			}
            const kycStatus = (await proxyApi.get("/getKycStatus"))?.data?.result?.kyc_status;
			if (["needs review", "db_retry", "initiated"].includes(kycStatus)) {
				isPending.value = true;
			} else if (kycStatus === "declined") {
				isPending.value = false;
			}
            waiting.value = false;
		})
		const router = useRouter();
		let waiting = ref(true);
		let isPending = ref(true);

		const goBackToPersonalDetails = () => {
			router.push({ name: 'personalDetails', query: { locationId: state.user.locationId , populate:'true' } })
		}

		return {
			waiting,
			isPending,
			goBackToPersonalDetails
		}
	}
}
