
import { defineComponent, ref } from 'vue';
import { IconInfoWalmart } from "@/components";
export default defineComponent({
  name: "PendingEarningMessage",
  components: {
    IconInfoWalmart
  },
  props: ['payStartDate', 'pendingPayment'],
  setup(props) {
    const payment = ref('')
    if (props.pendingPayment === 'ach') {
      payment.value = 'ACH'
    } else if (props.pendingPayment === 'branch') {
      payment.value = 'Branch'
    } else if (props.pendingPayment === 'one') {
      payment.value = 'ONE'
    }
    return {
      payment
    }
  }
})
