<template>
  <div class="header-close" @click="close"><span>X</span></div>
    <div class="desktop-container">
      <div class="desktop-wrapper">
        <div class="desktop-module_form">
          <h4>Frequently Asked Questions</h4>
          <div class="accordian-container">
            <div>
              <div class="mobile-accordian_row" v-for="(row, id) in accordian" v-bind:key="id">
                <div class="accordian-title_container" v-on:click="row.open = !row.open">
                  <div :class="!row.open ? 'accordian-title_text' : 'accordian-title_text open'">{{ row.question }}</div>
                  <div v-if="!row.open">
                    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 1L10 10L1 1" stroke="#3B4459" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div v-else>
                    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 1L10 10L1 1" stroke="#3B4459" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              <div v-show="row.open" class="accordian-body_text">{{ row.answer }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { proxyApi } from "@/http/http.config";
export default {
  mounted() {
    // update the call to getAchFaq
    proxyApi.get('/getAchFaq').then(async (data) => {
      this.accordian = data.data.result
      this.accordian = [
        {
          "question": "What is DD?",
          "answer": "ONE is a financial app available to eligible drivers on the Spark DriverTM platform. ONE helps you save, spend, and grow your money — all in one place."
        },
        {
          "question": "How?",
          "answer": ""
        },
        {
          "question": "When?",
          "answer": ""
        },
        {
          "question": "Why?",
          "answer": ""
        },
      ]
    })
  },
  data() {
    return {
      accordian: [],
    }
  },
  methods: {
    close(){
      window.close()
    }
  },
}
</script>
<style src="@/modules/one/styles.css" scoped></style>
