
import { defineComponent, ref, onMounted, reactive } from 'vue';
import { IconCheck } from "@/components"
import { state } from "@/harlem/stores";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { proxyApi,signup } from "@/http/http.config";
import "../../../assets/css/components/checklist.scss"
import { FunnelStatus } from '@/util/FunnelStatus';
import Loader from '@/components/modals/Loader.vue';
import { cardBody as FunnelDetails } from '@/modules/funnelStatus/FunnelStatus';
import checklistMethods from '@/modules/signup/components/Checklist/Helpers/Index';
import { sendToOracle } from "@/components/OracleButtonHelper";
import router from '@/router';

export default defineComponent({
    name: "DriverJourneyMap",
    components: {
        IconCheck,
        Loader
    }, 
    props: {
        isShowCheckrLink: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const currentStep: any = ref(null)
        const currentIndex: any = ref(null)
        const locationOnboarding = ref(true)
        const isLoading = ref(true)
        
        const stepTitles = {
            enrollmentForms: "Enrollment forms",
            insuranceVerification: "Insurance verification",
            backgroundCheck: "Background check",
            waitlist: "Waitlist",
            approval: "Enrollment status"
        }

        const checklistSteps = reactive([
             {
                id: 1,
                title: stepTitles.enrollmentForms,
                complete: false,
            },
            {
                id: 2,
                title: stepTitles.insuranceVerification,
                complete: false,
                error: false,
            },
            {
                id: 3,
                title: stepTitles.backgroundCheck,
                complete: false,
            },
            {
                id: 4,
                title: stepTitles.approval,
                complete: false,
            }
        ]);

        const cardBody = reactive({
            title: "",
            titleColor: "",
            subtitle: "",
            subtitleColor: "",
            subtitleBkgdColor: "",
            description: null,
            buttonText: null,
            buttonLink: "",
            icon: "null",
            iconColor: "",
            isCandidateDashboard: false,
        })

        const {
            MVR_PENDING, 
            MVR_SUBMITTED, 
            CBC_SUBMITTED, 
            CBC_PENDING, 
            MVR_CONSIDER,
            MVR_CLEAR,
            MVR_DENIED,
            MVR_SUSPENDED,
            CBC_CLEAR, 
            CBC_CONSIDER,
            CBC_SUSPENDED,
            CBC_DENIED,
            INSURANCE_IMAGE_INVALID, 
            OCR_CLEAR, OCR_FAILED, 
            OCR_MANUAL_CLEAR,
            WITHDRAWN,
            INSURANCE_UPLOAD_COMPLETE
        } = FunnelStatus;
        
        const getLatestFunnelStatus = () => {
            return proxyApi.post('/getFunnelProgress', encodeWithParam({ candidateID: state?.user?.locationPartnerId })).then(async ( data ) => {
                const res = await data?.data?.result;
                const sortedRes = await sortByDate(res)
                const latestFunnelStatus = await sortedRes[0].candidateStatus
                const isMVRClear = sortedRes.some(obj => obj.candidateStatus == MVR_CLEAR)
                const isCBCClear = sortedRes.some(obj => obj.candidateStatus == CBC_CLEAR)
                const isOCRFailed = sortedRes.some(obj => obj["candidateStatus"] == OCR_FAILED)
                const isOCRclear = sortedRes.some(obj => [OCR_CLEAR, OCR_MANUAL_CLEAR].includes(obj["candidateStatus"]))
                const isInsuranceInvalid = sortedRes.some(obj => {return obj["candidateStatus"] == INSURANCE_IMAGE_INVALID})
                const isInsuranceUploaded = sortedRes.some(obj => [INSURANCE_UPLOAD_COMPLETE].includes(obj["candidateStatus"]))
                const isInsurancePending = isInsuranceUploaded && (!isOCRFailed && !isOCRclear && !isInsuranceInvalid)
                const isInsuranceError = (!isOCRclear && (isOCRFailed || isInsuranceInvalid))
                const isInsuranceComplete = isInsuranceUploaded && !isInsuranceError && isOCRclear
                const isEnrollmentComplete = await sortedRes.some((obj) => { return obj.candidateStatus == FunnelStatus.PAYMENT_AGREEMENT_COMPLETE })
                const isBackgroundPending = await sortedRes.some(obj => [MVR_PENDING, MVR_SUBMITTED, CBC_SUBMITTED, CBC_PENDING, MVR_CONSIDER, CBC_CONSIDER].includes(obj["candidateStatus"]) && (isMVRClear == false || isCBCClear == false));
                const isBackgroundComplete = (isMVRClear && isCBCClear)
                const driverWaitlisted = latestFunnelStatus == FunnelStatus.APPLICATION_WAITLIST

                await checklistSteps.forEach((step) => {
                    switch (step.title) {
                        case stepTitles.enrollmentForms:
                            if (isEnrollmentComplete) {
                                step.complete = true
                                break
                            }
                            break
                        case stepTitles.insuranceVerification:
                            step.error = false
                            if (isInsuranceError) {
                                setCardBody(FunnelDetails.insuranceError)
                                step.complete = false
                                step.error = true
                                break
                            } else if (isInsurancePending) {
                                step.complete = false
                                break
                            } else if (isInsuranceComplete) {
                                step.complete = true
                                break
                            }
                            break
                        case stepTitles.backgroundCheck:
                            if ([CBC_SUSPENDED, CBC_DENIED, MVR_DENIED, MVR_SUSPENDED, WITHDRAWN].includes(latestFunnelStatus) && !isBackgroundComplete) {
                                setCardBody(FunnelDetails.authFailed)
                                break
                            } else if (isBackgroundPending) {
                                setCardBody(FunnelDetails.authInProgress)
                                break
                            } else if (isBackgroundComplete) {
                                step.complete = true
                            }
                            break
                        case stepTitles.approval:
                            console.log(checklistSteps)
                            if ((locationOnboarding.value == false && isBackgroundComplete) || driverWaitlisted) {
                                setCardBody(FunnelDetails.authWaitlist)
                                break
                            } else if (isBackgroundComplete) {
                                step.complete = true
                                break
                            }
                    }
                })
            })
        }

        //If prev step was unfinished while next step has been finished, then have current step to prev step
        const updateChecklist = (checklistSteps) => {
            let previousCompleted = true;
            let earliestIncompleteStep = null;
            let earliestIncompleteIndex = null;
            for (let i = 0; i < checklistSteps.length; i++) {
                const item = checklistSteps[i];
                if (!item.complete) {
                    previousCompleted = false;
                    currentStep.value = item.title
                    currentIndex.value = item.id
                    if (earliestIncompleteIndex === null) {
                        earliestIncompleteStep = item.title;
                        earliestIncompleteIndex = item.id
                    }
                } else if (!previousCompleted) {
                    item.complete = false;
                }
            }
            if (earliestIncompleteIndex !== null) {
                currentStep.value = earliestIncompleteStep;
                currentIndex.value = earliestIncompleteIndex
            }
        }

        const getLocationStatus = async () => {
            await signup.post("/validateLocationStatus", encodeWithParam({ clientLocationID: state.user.locationId })).then((data: any) => {
              const isOnboarding = data.data.result[0].isOnboarding
              locationOnboarding.value = isOnboarding
            })
        }

        const isMobile = () => {
            return checklistMethods.isMobile()
        }

        const setCardBody = (status) => {
            cardBody.title = status.title
            cardBody.titleColor = status.titleColor
            cardBody.subtitle = status.subtitle
            cardBody.subtitleColor = status.subtitleColor
            cardBody.subtitleBkgdColor = status.subtitleBkgdColor
            cardBody.description = status.description
            cardBody.buttonText = status.buttonText
            cardBody.buttonLink = status.buttonLink
            cardBody.icon = status.icon
            cardBody.iconColor = status.iconColor
            cardBody.isCandidateDashboard = status.isCandidateDashboard
        }

        const handleButtonLink = async (buttonLink) => {
            if (buttonLink == "editInsurance") {
                router.push({ name: "editInsurance" });
            } else if (buttonLink == "oracle") {
                const res = await sendToOracle();
                if (res) {
                    window.open(res, "_blank");
                }
            }
        }

        const handleSupportLink = async () => {
            return router.push({ name: "support" });
        }

        const sortByDate = (array) => {
            return array?.slice().sort((a, b) => {
                const dateA:any = new Date(a["date"]);
                const dateB:any = new Date(b["date"]);
                return dateB - dateA;
            });
            };

        onMounted(async () => {
            await getLocationStatus()
            await getLatestFunnelStatus()
            await updateChecklist(checklistSteps)
            isLoading.value = false
        })
        
        return {checklistSteps, currentStep, isLoading, cardBody, isMobile, setCardBody, sendToOracle, handleButtonLink, handleSupportLink, currentIndex}
    }
})
