<template>
    <div class="signin authentication no-navbar-screen">
    <div class="content">
  <div>
  <div class="close-icon">
    <svg @click="close" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1L1 15M1 1L15 15" stroke="#3171ff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
    <h2>Having Issues/Help</h2>
    <p>select your issue...</p>
    <div class="radio-option">
      <input
        type="radio"
        id="changeUsername"
        value="changeUsername"
        v-model="selectedOption"
      />
      <label for="changeUsername">Change Username</label>
    </div>
    <div class="radio-option">
      <input
        type="radio"
        id="changePassword"
        value="changePassword"
        v-model="selectedOption"
      />
      <label for="changePassword">Change Password</label>
    </div>
    <div class="radio-option">
      <input
        type="radio"
        id="changePhoneNumber"
        value="changePhoneNumber"
        v-model="selectedOption"
      />
      <label for="changePhoneNumber">Change Phone Number</label>
    </div>
    <div class="flex space-between">
          <p class="">
            <router-link :to="{ name: 'forgotUsername' }"
              >other issues</router-link
            >
          </p>
    </div>
    <div class="button-group button-group-center space-top">
      <button @click="performAction" :disabled="!selectedOption" class="button mobile-block margin-right-0 submit-button">Continue</button>
    </div>
  </div>
    </div>
    </div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent,ref } from "vue";
export default defineComponent({ 
  name: "HavingIssues",
  setup() {
    const router = useRouter();
    const selectedOption= ref(null);
    const performAction=() =>{
      switch (selectedOption.value) {
        case "changeUsername":
          router.push({ name: "forgotUsername"  });
          break;
        case "changePassword":
          router.push({ name: "forgotPassword"  });
          break;
        case "changePhoneNumber":
          router.push({ name: "updatePassword"  });
          break;
        default:
          break;
      }
    }
    const  close=() => {
          router.push({ name: "login" });
				}
    return {
      router,selectedOption,performAction,close
    }
},
})
</script>
<style>
.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-option input[type="radio"] {
  width: 20px; 
  height: 20px; 
  margin-right: 10px;
}

.radio-option label {
  white-space: nowrap;
}
.close-icon{
  margin-top: 20px;
  margin-left: 5px;
}
</style>