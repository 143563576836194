import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "banner_wrapper" }
const _hoisted_4 = { class: "banner-title" }
const _hoisted_5 = {
  key: 0,
  class: "banner-subtitle"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoActivePayments = _resolveComponent("NoActivePayments")!
  const _component_IconWarningTriangle = _resolveComponent("IconWarningTriangle")!
  const _component_IconXBlack = _resolveComponent("IconXBlack")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noEarningAccounts)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_NoActivePayments)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersFailed, (banner) => {
              return (_openBlock(), _createElementBlock("div", {
                key: banner.title,
                class: "banner-failed"
              }, [
                _createElementVNode("div", null, [
                  _createVNode(_component_IconWarningTriangle, { color: "#E71742" })
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_4, "Your application was not approved by " + _toDisplayString(banner.title), 1),
                  (_ctx.hasFailedAllEarningMethods())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, "You can continue using your current earnings account."))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", {
                  class: "banner-close",
                  onClick: ($event: any) => (_ctx.closeBanner(banner.type))
                }, [
                  _createVNode(_component_IconXBlack)
                ], 8, _hoisted_6)
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}