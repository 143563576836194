
import { defineComponent } from "vue";
import Dialog from 'primevue/dialog';
export default defineComponent({
  name: "InsuranceUploadIneligible",
  components: { Dialog, },
  props: {
    showDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    closeDialog(){
      this.$emit('close-dialog');
    }
  }
});
