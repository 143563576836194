
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
} from "vue";
import { Form, Field, configure } from "vee-validate";
import {
  FieldError,
  StateSelectorValidated,
  TextFieldValidated,
} from "@/components";
import * as yup from "yup";
import { state } from "@/harlem/stores";
import Autocomplete from "vue3-autocomplete";
import { isCountyRequired } from "@/util/AddressUtilities";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { gTagEvent } from "@/util/GoogleAnalytics";
export default defineComponent({
  name: "AddressFields",
  components: {
    FieldError,
    StateSelectorValidated,
    TextFieldValidated,
    Field,
    Form,
    Autocomplete,
  },
  emits: ["save-details", "cancel-edit"],
  props: {
    businessaddressData: {
      type: Boolean,
      required: false,
    },
    addressData: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: "",
          address1: "",
          address2: "",
          state: "",
          city: "",
          county: "",
          zipcode: ""
        }
      }
    },
  },
  setup(props, { emit }) {
    configure({
      validateOnModelUpdate: true,
    });

    const selectedState = ref('');
    let disableAdd2 = ref(false);
    let disableAdd1 = ref(false);
    const addressDisable = ref(true);
    const autoComplete = ref<InstanceType<typeof Autocomplete>>();
    const stateSelected = ref<InstanceType<typeof StateSelectorValidated>>();
    const suggestionText = ref(true);
    const warningText = ref(false)
    let addressSuggestions: any = ref([]);
    const restAdd2 = ref(["pobo","pobox"]);
    const restrictedAddress = ref(["p", "pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"])
    const partnerData = reactive({}) as {
      [key: string]: string | boolean | Date | null | undefined;
    };
    partnerData.address1 = "";
    partnerData.address2 = "";
    partnerData.city = "";
    partnerData.state = "";
    partnerData.county = "";
    partnerData.zip = "";
    partnerData.addressID = "";
    const initialTouched = reactive({}) as { [key: string]: boolean };

    onMounted(async () => {
      partnerData.address1 = props.addressData.address1 || '';
      partnerData.address2 = props.addressData.address2 || '';
      partnerData.city = props.addressData.city || '';
      partnerData.state = props.addressData.state || '';
      partnerData.county = props.addressData?.county || '';
      partnerData.zip = props.addressData.zipcode || '';
      partnerData.addressID = props.addressData.id || '';
      selectedState.value = props.addressData?.state || '';

    });
    const schema = yup.object().shape({
      address1: yup.string().trim().required("Street address is required").label("Street Address")
        .test("address1",
          "",
          function (value: any) {
            const bool = value.split(' ').some((strItem: string) => restrictedAddress.value.includes(strItem));
            disableAdd1.value = bool;
            return !bool
          }
        ),
      address2: yup.string().trim().label("Address")
        .max(99, "Maximum length of 100 characters")
        .test("address2",
          "Please enter a valid home address other than PO box",
          function (value: any) {
            if(!props.businessaddressData) {
              const bool = value.toLowerCase().split(' ').some((strItem: string) => restrictedAddress.value.includes(strItem));
              disableAdd2.value = bool;
              return !bool
            }
            return true;
          }
        )
        .test("address2",
          "Please enter a valid home address other than PO box",
          function (value: any) {                
                return !restAdd2.value.some((v)=> value.indexOf(v) >= 0);
                } 
        )
        .test("address2", "Address cannot include special characters",
          function (value: any) {
            const hasSpecialChar = /[^0-9a-zA-Z\s'/-]/
            if (hasSpecialChar.test(value)) {
              disableAdd2.value = true
              return false

            } return true;
          }
        )
        ,
      city: yup.string().trim("City can't contain white space at the start or end").strict(true).matches(/^[aA-zZ' ]+$/g, "Enter a valid city").required("City is required").label("City"),
      state: yup.string().trim().required("State is required").label("State"),
      county: yup
        .string()
        .trim("County can't contain white space at the start or end")
        // .strict(true)
        .matches(/^[aA-zZ ]+$/g, "Invalid character in the county")
        .when("state", {
          is: (val: string) => isCountyRequired(val),
          then: yup.string().min(4, "Enter a valid county ").required().label("County"),
          otherwise: yup.string().notRequired().label("County"),
        }),
      zip: yup
        .string()
        .trim()
        .required("ZIP code is required")
        .min(5, "Zip Code must be 5 digits")
        .matches(/^(?!00000)\d{5}(?:[-\s]\d{4})?$/g, "Zipcode is required") //Zipcode is required
        .label("Zip Code"),
    });

    let addressSelectedFromSmarty = ref(false);
    const getAddressSuggestion = (searchText: string) => {
      addressSelectedFromSmarty.value = false;
      if (searchText.length === 0) {
        suggestionText.value = true;
        warningText.value = false;
      } else {
        suggestionText.value = false;
        warningText.value = true;
      }
      const regex = new RegExp(/^(PO.*BOX*)/);
      if (!restrictedAddress.value.includes(searchText.trim().toUpperCase()) && searchText.trim().toUpperCase().search(regex) === -1) {
        let searchParams = encodeWithParam({ search: searchText })
        proxyApi
          .post("/getAddressSuggestions", searchParams)
          .then(({ data }: any) => {
            if (data.result.length > 0) {
              addressSuggestions.value = data.result.map((item) => {
                return {
                  name: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
                  item,
                };
              });
            } else {
              addressSuggestions.value = [
                {
                  name: "Please enter a valid home address other than PO box",
                  item: {},
                },
              ];
            }
          }).catch(() => {

            addressSuggestions.value = [
              {
                name: "Please enter a valid home address other than PO box",
                item: {},
              },
            ];
          });
      } else {
        addressSuggestions.value = [];
        addressSuggestions.value = [
          {
            name: "Please enter a valid home address other than PO box",
            item: {},
          },
        ];

        if (searchText.length === 0 && addressSelectedFromSmarty.value) {
          document.getElementById('address1')?.blur();
          partnerData.address1 = "";
          partnerData.address2 = "";
          partnerData.city = "";
          partnerData.state = "";
          partnerData.zip = "";
          partnerData.county = "";
          partnerData.countyRegistered = "";
          selectedState.value = '';
          stateSelected.value?.onChangeState(" ");
        }
      }
    };
    const autoCompleteError = (errors: any) => errors.address1 ? ['has-error'] : []
    const updateStateValue = (e: any, setFieldError: any) => {
      selectedState.value = e.target.value
      if (selectedState.value === 'CA') {
        setFieldError('county', 'County is required')
      } else {
        setFieldError('county', '')
      }
      partnerData.state = selectedState.value;
    }
    const addressSuggestionComputed = computed(() => addressSuggestions.value);
    const selectedAddress = (selectedAddress: any, validate: any, setFieldValue: any) => {
      suggestionText.value = false;
      warningText.value = false;
      setFieldValue('address2', '');
      setFieldValue('city', '');
      setFieldValue('county', '');
      setFieldValue('state', '');
      setFieldValue('zip', '');
      if (Object.keys(selectedAddress.item).length > 0) {
        setFieldValue('address1', selectedAddress.item.streetLine);
        setFieldValue('address2', selectedAddress.item.secondary);
        setFieldValue('city', selectedAddress.item.city);
        setFieldValue('state', selectedAddress.item.state);
        setFieldValue('zip', selectedAddress.item.zipcode);
        partnerData.address1 = selectedAddress.item.streetLine;
        partnerData.address2 = selectedAddress.item.secondary;
        partnerData.city = selectedAddress.item.city;
        partnerData.state = selectedAddress.item.state;
        partnerData.zip = selectedAddress.item.zipcode;
        autoComplete.value?.setText(selectedAddress.item.streetLine);
        stateSelected.value?.onChangeState(selectedAddress.item.state);
        selectedState.value = selectedAddress.item.state;
        addressDisable.value = true;
        addressSelectedFromSmarty.value = true;
        validate();
      } else {
        partnerData.address1 = "";
        partnerData.address2 = "";
        partnerData.city = "";
        partnerData.state = "";
        partnerData.zip = "";
        partnerData.county = "";
        partnerData.countyRegistered = "";
      }
    };
    const formData = ref({})
    const saveDetails = (data) => {
      formData.value = data
    }

    const cancel = () => {

      emit("cancel-edit");
    }

    const address2 = (event) => {
      addressSelectedFromSmarty.value = true
      partnerData.address2 = event.target.value;
    }

    const updateCity = (event) => {
      partnerData.city = event.target.value;
    }

    const updateCounty = (event) => {
      addressSelectedFromSmarty.value = true
      partnerData.county = event.target.value;
    }

    const updateZip = (event) => {
      partnerData.zip = event.target.value;
    }

    const saveAddressDetails = () => {
      if (validateAddress(partnerData)) {
        let obj = {
          address1: partnerData.address1,
          address2: partnerData.address2,
          state: partnerData.state,
          city: partnerData.city,
          postalCode: partnerData.zip,
          country: "US",
          addressID: props.addressData.id,
          county: partnerData.state == "CA" ? allTrim(partnerData.county) : null
        }
        let isCountyNull = false;
        if (obj.state == "CA" && obj.county == "") {
          isCountyNull = true;
        }
        if (!isCountyNull) {
          proxyApi.post("/updateAddress", encodeWithParam(obj)).then(() => {
            gTagEvent("Update Address - Account Info", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
            emit("save-details");
          })
        }
      }
    }

    const letterOnly = (e) => {
      if (e.key === 'Backspace') return true
      if (e.key === ' ') return true
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    }

    const validateAddress = (data) => {
      if (data?.state == "CA")
        return data.address1 && data.city && data.county && data.zip && data.state
      return data.address1 && data.city && data.zip && data.state
    }

    const allTrim = (data) => {
      return data.trim();
    }
    return {
      saveDetails,
      address2,
      saveAddressDetails,
      cancel,
      initialTouched,
      partnerData,
      state,
      schema,
      addressSuggestions,
      getAddressSuggestion,
      selectedAddress,
      addressDisable,
      autoComplete,
      stateSelected,
      selectedState,
      autoCompleteError,
      addressSuggestionComputed,
      suggestionText,
      warningText,
      updateStateValue,
      updateZip,
      updateCounty,
      updateCity,
      letterOnly,
      addressSelectedFromSmarty,
      disableAdd2,
      disableAdd1
    };
  },
});
