
import { defineComponent, onMounted, ref, watch } from "vue";
import { Field } from "vee-validate";
// import BaseModal from "@/components/modals/BaseModal.vue";
import IconWarning from "@/components/icons/IconWarning.vue";
import { mutation } from "@/harlem/mutation";
import { useRouter } from "vue-router";
import { encodeWithParam,encodeWithParamutf } from "@/util/DataEncodeDecode";
import { proxyApi } from "@/http/http.config";
import { state } from "@/harlem/stores";
import checklistMethods from "../components/Checklist/Helpers/Index";
import { setStatus } from "@/services/stage.service";
import { gTagEvent } from "@/util/GoogleAnalytics";
import InitChecklist from "../components/Checklist/InitChecklist.vue";

export default defineComponent({
  name: "Signature",
  components: { IconWarning, Field, InitChecklist },
  emits: ["signature"],
  props: {
    oldSignature: String,
  },
  created(){
    window.history.pushState(null, window.location.href)
    window.onpopstate = function () {
      window.history.pushState(null , window.location.href)
    }
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const signature = ref("");
    const signatureError = ref("");
    const closeModal = () => {
      signature.value = props.oldSignature!;
    };
    onMounted(() => {
      gTagEvent("Enter Signature Page", "Signature", `partner_id: ${state.user.partnerId}`)
      // setStatus("SIGNATURE", "INCOMPLETE");

      setStatus("DOCUMENTS", "INCOMPLETE");
              proxyApi.post(
                "/addApplicationStatus",
                encodeWithParam({
                  stepName: "DOCUMENTS",
                  stepStatus: "INCOMPLETE",
                })
              );
    })
    const saveSignature = () => {
      emit("signature", signature.value);
      proxyApi
        .post(
          "/updateSignature",
          encodeWithParamutf({ signature: signature.value })
        )
        .then(({ data }) => {
          if (data.success) {
            gTagEvent("Complete Signature", "Signature", `partner_id: ${state.user.partnerId}`)
            mutation.user.setSignature(signature.value.trim());
            if(!sessionStorage.getItem("payeeID")) {
              gTagEvent("Insurance Needed", "Signature", `partner_id: ${state.user.partnerId}`)
              setStatus("INSURANCE_VERIFICATION", "Insurance Needed");
              proxyApi.post(
              "/addApplicationStatus",
              encodeWithParam({
                stepName: "INSURANCE_VERIFICATION",
                stepStatus: "Insurance Needed",
              })
            );
            }
    
            // setTimeout(() => {
            //   setStatus("DOCUMENTS", "INCOMPLETE");
            //   proxyApi.post(
            //     "/addApplicationStatus",
            //     encodeWithParam({
            //       stepName: "DOCUMENTS",
            //       stepStatus: "INCOMPLETE",
            //     })
            //   );
            // }, 1000);
            
            if (checklistMethods.isMobile()) {
              router.push({
                name: "checklist",
                params: { step: "documents" },
                query: {
                  locationId: state.user.locationId,
                },
              });
            } else {
              router.push({
                name: "documents",
                query: {
                  locationId: state.user.locationId,
                },
              });
            }
          }
        });
    };

    watch(signature, (newVal) => {
      if (newVal?.trim().length > 0) {
        signatureError.value = "";
      }
    });

    onMounted(() => {
      signature.value = props.oldSignature!;
      proxyApi.get("/candidateFullName").then((data) => {
        const name = data.data.result[0];
        if (name.firstName) {
          signature.value = name.firstName;
        }
        if (name.middleName) {
          signature.value += " " + name.middleName;
        }
        if (name.lastName) {
          signature.value += " " + name.lastName;
        }
      });
    });

    return { closeModal, state, signature, signatureError, saveSignature };
  },
});
