
import { computed, defineComponent, onMounted, onUnmounted, ref, Ref, watch } from "vue";
import router from "@/router";
import {
  IconCheckWarning,
  IconDocument,
 // IconImage,
  IconWarningOctagon,
  Signature,
  //UploadModal,
} from "@/components";
import { FilePreview } from "@/modules/signup/composables/documents.composable";
// import PreviewImageModal from "@/modules/signup/documents/PreviewImageModal.vue";
import micromodal from "micromodal";
import { proxyApi } from "@/http/http.config";
import { state } from "@/harlem/stores";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import InitChecklist from "../components/Checklist/InitChecklist.vue";
import checklistMethods from "../components/Checklist/Helpers/Index";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { setStatus } from "@/services/stage.service";
import { useRoute, useRouter } from "vue-router";
//import Loader from "@/components/modals/Loader.vue";
import { restApi } from "@/http/http.config";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { FunnelStatus } from "@/util/FunnelStatus"

export default defineComponent({
  name: "SignupDocuments",
  components: {
    IconWarningOctagon,
    //UploadModal,
    IconDocument,
    //IconImage,
    IconCheckWarning,
    //PreviewImageModal,
    PulseLoader,
    InitChecklist,
   // Loader,
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
  },
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const w9available = ref(false);
    const InsureAvaialble = ref(false);
    const selectedID = ref("");
    const previewFile: Ref<File | null> = ref(null);
    const showDocsIncompleteErr = ref(false);
    const locTemplates = ref([]);
    const w9DocTempId = ref("");
    const insureTempId = ref("");
    const fileName = ref("Upload Insurance");
    const fileId = ref("");
    const locationID = route.query.locationID;
    const applicationId = route.query.applicationId;
    const retryMessage = ref(false);
    const insuranceExpiration = ref(false);
    const insuranceLoader = ref(false);
    const uploadModelLoader = ref(false);
    const insuranceVerifiedFlag = ref(false);
    const pageTimeStart = ref(0)
    const sessionData: any = sessionStorage.getItem("ddi:user");
    const candidateID = JSON.parse(sessionData);

    ///////////////// DISPLAY ALL THE DOCUMENTS //////////////////////
    const getOnBoardingDocs = (() => {
      proxyApi.get("/getOnBoardingDocuments").then(({ data }) => {
        if (data.success) {
          locTemplates.value = data.result.filter((item) => {
            if (item.title == "W9") {
              w9DocTempId.value = item.id;
              if (item.signature !== null) {
                w9available.value = true;
              }
            }
            if (item.title == "Insurance") {
              insureTempId.value = item.id;
              fileId.value = item.fileID;
              proxyApi
                .post("/getUploadedFile", encodeWithParam({ fileID: fileId.value }))
                .then(({ data }) => {
                  if (data.success && data.bufferInHex) {
                    InsureAvaialble.value = true;
                    fileName.value = data.fileName;
                  }
                });
            }
            return !["W9", "Insurance"].includes(item.title);
          });
        }
      });
    })();

    const docsToSign = computed(() => {
      return locTemplates.value;
    });

    ////////// TO CHECK IF THE DOC IS SUBMITTED OR NOT //////////
    const isSubmitted = (document: Record<string, any>) => {
      return document.signature != null;
    };

    ///////////INSURANCE FILE UPLOAD/////////
    const openUploadModal = () => {
      if (InsureAvaialble.value) {
        selectedID.value = fileId.value;
        micromodal.show("image-preview");
      } else {
        micromodal.show("modal-upload-image", {});
      }
    };

    const onFileSelected = (filePreview: FilePreview) => {
      previewFile.value = filePreview.file;
      //onSubmitUpload();
       micromodal.show("image-preview", {});
    };

    const updateInsuranceExpiry = (date: string) => {
      proxyApi.post(
        "/updateInsurance",
        encodeWithParam({
          insuranceExpiry: date,
        })
      );
    };
    const candidateProgressUpdate = async (step: string, status: string) => {
      setStatus(step, status);
      await proxyApi.post(
        "/addApplicationStatus",
        encodeWithParam({ stepName: step, stepStatus: status })
      );
    };

    const insuranceSelected = (date: string) => {
      InsureAvaialble.value = true;
      if (date) {
        //updateInsuranceExpiry(date);
        insuranceVerifiedFlag.value = false;
        // candidateProgressUpdate(
        //   "INSURANCE_VERIFICATION",
        //   "Insurance Incomplete"
        // );
        onSubmitUpload(date);
      }
    };

    const loaderComputed = computed(() => {
      return uploadModelLoader.value;
    });
    const onSubmitUpload = async (date) => {
      micromodal.close("modal-upload-image");
      uploadModelLoader.value = true;
      let type = "ocr";
      const formData = new FormData();
      formData.append("file", previewFile.value || "");
      formData.append("documentTemplateID", insureTempId.value);
      formData.append("signature", state.user.signature);
      formData.append("type", type);
      formData.append(
        "originalname",
        previewFile.value?.name || "insurance image"
      );
      formData.append("acl", "public");
      formData.append("insuranceExpiry",date)
      insuranceLoader.value = true;
      retryMessage.value = false;
      proxyApi.post("/uploadFile", formData).then(() => {
        insuranceLoader.value = false;
        uploadModelLoader.value = false;
        

        // micromodal.close("modal-upload-image");
        // if (type == "manual") {
        //   insuranceExpiration.value = true;
        //   micromodal.close("modal-upload-image");
        //   micromodal.show("image-preview");
        // } else {
        //   if (data.success) {
        //     if (data?.result[0].expiry_date) {
        //       retryMessage.value = false;
        //       insuranceExpiration.value = false;
        //       updateInsuranceExpiry(data?.result[0].expiry_date);
        //       micromodal.close("modal-upload-image");
        //       micromodal.show("image-preview");
        //     } else {
        //       retryMessage.value = true;
        //       micromodal.show("modal-upload-image", {});
        //     }
        //   } else {
        //     retryMessage.value = true;
        //     micromodal.show("modal-upload-image", {});
        //   }
        // }
        //  window.location.reload()
      });
    };

    watch(fileName, (newVal, oldVal) => {
      if (oldVal != newVal) fileName.value = newVal;
    });

    const onCancelUpload = () => {
      previewFile.value = null;
    };

    const onChangeImage = () => {
      selectedID.value = "";
      micromodal.show("modal-upload-image", {});
    };

    const documentsComplete = async () => {
      const signatureCheck = locTemplates.value.filter((item: any) => {
        return !item.optional && item.signature == null;
      });
      if (signatureCheck.length > 0 ||!w9available.value) {
        showDocsIncompleteErr.value = true;
      } else {
        loading.value = true;
        // setStatus("DOCUMENTS", "COMPLETE");
        await proxyApi
          .post(
            "/addApplicationStatus",
            encodeWithParam({status: FunnelStatus.DOCUMENTS_COMPLETE})
          )
          // .then(async () => {
          //   let candidateSteps = await restApi.post(
          //     "/api/getFunnelProgress",
          //     encodeWithParam({ candidateID:candidateID.locationPartnerId })
          //   );
          //      let mvrSubmitted = candidateSteps.data.result.filter((progress) => {
          //     return (
          //       (progress.name == "SCREENING" &&
          //         progress.candidateStatus == "MVR Submitted")
          //     );
          //   });

          //   let steps = candidateSteps.data.result.filter((progress) => {
          //     return (
          //       (progress.name == "INSURANCE_VERIFICATION" &&
          //         progress.candidateStatus == "Insurance Complete") ||
          //       (progress.name == "PAYMENT_AGREEMENT" &&
          //         progress.candidateStatus == "COMPLETE") ||
          //       (progress.name == "DOCUMENTS" &&
          //         progress.candidateStatus == "COMPLETE")
          //     );
          //   });
          //   if(steps.length >=3 && mvrSubmitted.length === 0) {
          //     await restApi.post('/api/triggerMVR', encodeWithParam({
          //       locationID: state.user.locationId, 
          //       id: state.user.partnerId,
          //     }))
          //   }
          //   let stepCheck = candidateSteps.data.result.filter((progress) => {
          //     return (
          //       (progress.name == "INSURANCE_VERIFICATION" &&
          //         progress.candidateStatus == "Insurance Complete") ||
          //       (progress.name == "SCREENING" &&
          //         progress.candidateStatus == "COMPLETE") ||
          //       (progress.name == "DOCUMENTS" &&
          //         progress.candidateStatus == "COMPLETE") ||
          //          (progress.name == "PAYMENT_AGREEMENT" &&
          //         progress.candidateStatus == "COMPLETE")
          //     );
          //   });

          //   if (stepCheck.length > 3) {
          //     proxyApi.post(
          //       "/addApplicationStatus",
          //       encodeWithParam({
          //         stepName: "COMPLETED",
          //         stepStatus: "ON_HOLD",
          //       })
          //     );
          //   }
          // });
        gTagEvent("Complete Documents", "Documents", `partner_id: ${state.user.partnerId}`, true, `${Math.round((performance.now() - pageTimeStart.value)/1000)} seconds`)
        setStatus("UPLOAD_AUTO_INSURANCE","INCOMPLETE")
        if (checklistMethods.isMobile()) {
          router.push({
            name: 'checklist',
            params: { step: "insuranceUpload" },
            query: {
              locationId: state.user.locationId,
              name: state.user.locationName
            },
          });
        } else {
          router.push({
            name: "insuranceUpload",
            query: {
              applicationId: applicationId,
              locationID: locationID,
            },
          });
        }
        loading.value = false;
      }
    };

    onMounted(() => {
      pageTimeStart.value = performance.now()
      const progress = localStorage.getItem("progress")
			const progressObj = JSON.parse(progress || "")
      if (progressObj.step === 'PAYMENT_AGREEMENT') {
        router.push({
          name: "earningsAccount",
          query: {
            locationId: route.query.locationId,
          },
        })
      } else {
        setStatus("DOCUMENTS", "INCOMPLETE")
        proxyApi.post('/addApplicationStatus', encodeWithParam({
        stepName: 'DOCUMENTS',
        stepStatus: 'INCOMPLETE',
      })).then(({data}) => console.log('Documents INCOMPLETE: ', data))
      }
    });
      
    return {
      state,
      selectedID,
      fileName,
      InsureAvaialble,
      previewFile,
      showDocsIncompleteErr,
      w9available,
      documentsComplete,
      openUploadModal,
      onFileSelected,
      onCancelUpload,
      onSubmitUpload,
      onChangeImage,
      isSubmitted,
      insuranceSelected,
      updateInsuranceExpiry,
      candidateProgressUpdate,
      uploadModelLoader,
      insuranceVerifiedFlag,
      loading,
      docsToSign,
      w9DocTempId,
      retryMessage,
      insuranceExpiration,
      insuranceLoader,
    };
  },
});
