import { mutation } from "@/harlem/stores/payment.store";

export const setBranchResponse = mutation<string, any>(
  "branchResponse",
  (state, payload) => {
    state.branchResponse = payload;
  }
);

export const setActiveEarningAccounts = mutation<string, any>(
  "activeEarningAccounts",
  (state, payload) => {
    state.activeEarningAccounts = payload;
  }
);

export const setPaymentAgreementComplete = mutation<boolean, any>(
  "paymentAgreementComplete",
  (state, payload) => {
    state.paymentAgreementComplete = payload;
  }
);