import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "site-masthead" }
const _hoisted_2 = { class: "container_header site-header" }
const _hoisted_3 = { class: "content_header" }
const _hoisted_4 = { class: "account-control" }
const _hoisted_5 = { class: "right-header-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSparkDriverLogo = _resolveComponent("IconSparkDriverLogo")!
  const _component_oracle_button = _resolveComponent("oracle-button")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_IconSparkDriverLogo)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_oracle_button),
          _withDirectives(_createElementVNode("div", {
            id: "profileImage",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMenu()))
          }, null, 512), [
            [_vShow, !_ctx.route.path.includes('support')]
          ])
        ])
      ])
    ])
  ]))
}