<template>
<div class="signin authentication no-navbar-screen">
<div  class="content"  >
<div v-if="!showsendotp1" class="questions-screen">
      <div class="icon-close">
          <svg @click="close" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1L1 15M1 1L15 15" stroke="#3171ff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      <div>
      <div class="verification-Header"><span class="verification-text">We’ve made some changes for your security</span></div>
      <p>To protect personal information, we’re requesting all users to set security questions and reset their password.</p>
      </div>
      <div class="button-group button-group-center space-top click-continue">
        <button class="button mobile-block margin-right-0 submit-button click-continue" @click="Clickcontinue">
          continue
        </button>
      </div>
  </div>
  <div v-else>
    <!-- <div class="action-left">
          <a class="back" @click="router.go(-1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" fill="#3171ff" stroke="#3171ff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
             <svg v-svg="'icon-arrow-left'" size="0 0 18 18" /> 
          </a> 
     </div> -->
    <div class="Icon-Close-Option">
            <svg @click="close" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1L1 15M1 1L15 15" stroke="#3171ff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
    </div>
  <div class="securityHeader"><span class="questionHeader">Set your security questions</span></div>
  <div class ="security-questions"><p>These questions will help us verify your identity in the future.</p></div>
    <div><security-questions v-if="loadQuestions" :questions="questions" :noOfQuestions="5" @input="setSecurityAnswer" :showsubmit="true" /></div>
    <div class="help-text"><p>Having issues? You can call Driver Support at <a>(855) 743-0457</a> and select option 4.</p></div>
    <div class ="errormessage" v-if="showerrormessage"><p class="error">Something Went Wrong,Please Try Again</p></div>
  </div>
  </div>
</div>
</template>
<script>
import { useRouter } from "vue-router";
import {  SecurityQuestions } from "@/components";
import { defineComponent, ref,onMounted } from "vue";
import { decode, encodeWithParam } from "@/util/DataEncodeDecode";
import { proxyApi,basicAuth } from "@/http/http.config";
// import {IconClose } from "@/components";
export default defineComponent({
  name: "QuestionsScreen",
  components: {SecurityQuestions },
  setup() {
    const router = useRouter();
    const showsendotp1=ref(false);
    const showsendotp2=ref(false);
    const loadQuestions=ref(false);
    const questions=ref([]);
    const showerrormessage=ref(false);
    const accesstoken = ref('')

    // onMounted=()=>{
    //       console.log("onmounted");
    //       let accesstoken=sessionStorage.getItem("accesstoken")
    //       if(!accesstoken){
    //         router.push({name:"login"})
    //       }
    //     };

        onMounted(() => {
          accesstoken.value=sessionStorage.getItem("accesstoken")
          if(!accesstoken.value){
            router.push({ name: "login" });
          }
      });

    const setSecurityAnswer=async(data)=>{
      let param=data;
      let partnerId=sessionStorage.getItem("partnerId");
      let result=await basicAuth.post("/setSecurityAnswers",encodeWithParam({param}),{headers:{Authorization: `${sessionStorage.getItem("accesstoken")}`,
        "Content-Type": "application/json",
        withCredentials: true}})
      let accesstoken=sessionStorage.getItem("accesstoken");
      if(result.data.success){
        router.push({ name: "updatePassword" ,query: { resource: accesstoken.value,setsecurityQuesions:true } });
      }
      else{
        showerrormessage.value=true;
      }
    };
  const  Clickcontinue=async() => {
    showsendotp1.value=true;
    await proxyApi.get("/getSecurityQuestions").then((data)=>{
      questions.value=data.data.result
      loadQuestions.value=true;
  })
router.push({ name: "questionsscreen" });
};
const  close=() => {
          return router.push({ name: "login" });
				} 

        const  submitAnswers=() => {
        let accesstoken=sessionStorage.getItem("accesstoken");
      router.push({ name: "updatePassword" ,query: { resource: accesstoken } });
				}

  return{router,showsendotp1,showsendotp2,loadQuestions,questions,setSecurityAnswer,Clickcontinue,close,submitAnswers,showerrormessage}
},

});
</script>
<style>
.questions-screen{
  margin-left: 10%;
  margin-right: 10%;
  /* text-align: center; */
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.icon-close{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0%;

}
.Icon-Close-Option{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 1%;
}
.action-left{
  margin-top: 20px;
  margin-bottom: 20px;
}
.security-questions{
  margin-bottom: 20px;
  margin-left: 1%;
  margin-right: 1%;
}

.error{
    color: #E71742;
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
}
.errormessage{
  margin: 20px 10px 20px 10px;
  display: ruby-text;
}
.help-text{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2%;
  margin-right: 2%;
}
.questionHeader{
  font-size: 18px;
  color: #3b4459;
  font-weight: bold;
}
.securityHeader{
  margin-bottom: 10px;
  margin-left: 1%;
}
.verification-text{
font-family: "Rubik";
font-size: 20px;
color: #3B4457;
font-weight: 600;
}
.verification-Header{
  margin-bottom: 10px;
}
</style>