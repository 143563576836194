export default [
  {
    path: "/support",
    name: "support",
  component: () => import("@/modules/support/Index.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false, layout: "support", authgaurd: true}
  },
  {
    path: "/support/bgcheck",
    name: "bgcheck",
    component: () => import("@/modules/support/BGCheck.vue"),
    meta: { title: "Delivery Drivers Inc | BG Check", noHeader: false, layout: "support" ,authgaurd: true}
  },
  {
    path: "/support/joinfull",
    name: "joinfull",
    component: () => import("@/modules/support/JoinFull.vue"),
    meta: { title: "Delivery Drivers Inc | Join Full", noHeader: false, layout: "support" ,authgaurd: true}
  },
  {
    path: "/support/sparkDriverPlatform",
    name: "sparkDriverPlatform",
    component: () => import("@/modules/support/SparkDriverPlatform.vue"),
    meta: { title: "Delivery Drivers Inc | Spark Driver Platform ", noHeader: false, layout: "support" ,authgaurd: true}
  },
  {
    path: "/support/driverInsurance",
    name: "driverInsurance",
    component: () => import("@/modules/support/DriverInsurance.vue"),
    meta: { title: "Delivery Drivers Inc | Driver Insurance ", noHeader: false, layout: "support" ,authgaurd: true}
  },
  {
    path: "/support/downloadSparkDriver",
    name: "downloadSparkDriver",
    component: () => import("@/modules/support/DownloadSparkDriver.vue"),
    meta: { title: "Delivery Drivers Inc | Download Sark Driver", noHeader: false, layout: "support" ,authgaurd: true}
  },
  {
    path: "/support/sparkNow",
    name: "sparkNow",
    component: () => import("@/modules/support/SparkNow.vue"),
    meta: { title: "Delivery Drivers Inc | Spark Now", noHeader: false, layout: "support" ,authgaurd: true}
  },
  {
    path: "/support/acceptingOffer",
    name: "acceptingOffer",
    component: () => import("@/modules/support/AcceptingOffer.vue"),
    meta: { title: "Delivery Drivers Inc | Accepting Offer", noHeader: false, layout: "support" ,authgaurd: true}
  },
];
