
import FailedDirectDeposit from "@/modules/signup/directDeposit/FailedDirectDeposit.vue";
import { defineComponent, onMounted, ref,computed } from "vue";
export default defineComponent({
    name: "SecurityQuestions",
    emits: ["input","signupque","validateQuestions"],
    props: {
        questions: {
            type: Array,
            required: true,
        },
        noOfQuestions: {
            type: Number,
            required: true,
        },
        showsubmit:{
            type:Boolean,
            required:true,
        }

    },
    setup(props, { emit }) {
        const answers = ref([
            { id: null, answer: "" },
            { id: null, answer: "" },
            { id: null, answer: "" },
            { id: null, answer: "" },
            { id: null, answer: "" },
            { id: null, answer: "" },
            { id: null, answer: "" }
        ]);

        const showError = ref(new Array(props.noOfQuestions));;
        const submitAnswers = () => {
            const formattedAnswers = answers.value.reduce<{ id: string | null; answers: string }[]>((acc, answer) => {
                if (answer.id) {
                    const formattedValue:any = answer.answer ? answer.answer.toString().trim().toLowerCase() : null;
                    acc.push({ id: answer.id, answers: formattedValue });
                }
                return acc;
            }, []);
            emit("input", formattedAnswers);
        };

        const selectedQuestions = ref(new Array(props.noOfQuestions).fill(false));
        const selectedAnswers = ref(Array.from({ length: props.noOfQuestions }, () => false));
        const duplicateQuestions = ref(new Array(props.noOfQuestions).fill(false));
        const checkAnswerslength= ref(Array.from({ length: props.noOfQuestions }, () => false));
    const validateSelection = (index) => {

      const selectedQuestionIds = answers.value.map(answer => answer.id);
      selectedQuestions.value[index] = answers.value[index].id !== null;
      selectedAnswers.value[index] = answers.value[index].answer.trim() !== '';
    //   checkAnswerslength.value[index]=answers.value[index].answer.trim().length>3;
    //   duplicateQuestions.value[index] = selectedQuestionIds.indexOf(answers.value[index].id) !== selectedQuestionIds.lastIndexOf(answers.value[index].id);
    //   submitAnswers();
    if(answers.value[index].answer.trim().length<3){
        checkAnswerslength.value[index]=true; 
    }
    else{
        checkAnswerslength.value[index]=false;
    }
    if (answers.value[index].id === null) {
    duplicateQuestions.value[index] = false; 
    return; 
  }
    for (let i = 0; i < selectedQuestionIds.length; i++) {
    if (i !== index && answers.value[i].id === answers.value[index].id) {
      duplicateQuestions.value[index] = true;
      return; 
    }
  }
  duplicateQuestions.value[index] = false;

    if(props.showsubmit==false){
        const selectedquestionssignup = answers.value.map(answer => ({
        id: answer.id,
        answer: answer.answer
        }));
        emit("signupque",selectedquestionssignup);
        emit('validateQuestions',isValid.value);
    }
    };
    const isValid = computed(() => {
      return (selectedQuestions.value.every(selected => selected) && selectedAnswers.value.every(selected => selected) && !duplicateQuestions.value.includes(true) && !checkAnswerslength.value.includes(true))
    });

    const handleFocus = (index) => {
      showError.value[index] = false;
    };

    const handleBlur=(index) =>{
        if (!answers.value[index].answer) {
            showError.value[index] = true;
        }
    }

    const removeSpaces=(event,index) =>{
    //   answers.value[index].answer= event.target.value.replace(/[*]/g, '');
        answers.value[index].answer= event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    
    }

        return { answers, 
            submitAnswers,
            selectedQuestions,duplicateQuestions,validateSelection,isValid,selectedAnswers,handleFocus,handleBlur,showError,removeSpaces,checkAnswerslength
        };
    },
});
