import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44b22db2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pending-earning-message"
}
const _hoisted_2 = { class: "double-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconInfoWalmart = _resolveComponent("IconInfoWalmart")!

  return (_ctx.pendingPayment !== '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_IconInfoWalmart)
        ]),
        _createTextVNode(" On Monday, " + _toDisplayString(_ctx.payStartDate) + ", " + _toDisplayString(_ctx.payment) + " will become your primary earnings account ", 1)
      ]))
    : _createCommentVNode("", true)
}