
import { defineComponent, Ref, ref, reactive, onMounted, computed } from "vue";
import micromodal from "micromodal";
import UploadModal from "@/components/modals/UploadModal.vue";
import InsuranceUploadIneligible from "@/components/modals/InsuranceUploadIneligible.vue";
import PreviewImageModal from "@/modules/signup/documents/PreviewImageModal.vue";
import { DateTime } from "luxon";
import { useRouter } from "vue-router";
import { FilePreview } from "@/modules/signup/composables/documents.composable";
import FileViewer from "@/components/files/FileViewer.vue";
import { state } from "@/harlem/stores";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import documentMethods from "./Helpers/Index";
import { gTagEvent } from "@/util/GoogleAnalytics";

export default defineComponent({
  name: "EditInsurance",
  components: {
    FileViewer,
    UploadModal,
    PreviewImageModal,
    InsuranceUploadIneligible,
  },

  setup() {
    const router = useRouter();
    const toastMessage = ref("");
    const insuranceFilePreview: Ref<File | null> = ref(null);
    const fileType: Ref<boolean | undefined> = ref(false);
    const insuranceFileId = ref("");
    const insuranceFileIdImage=ref("");
    const insureTempId = ref("");
    const insuranceExp = ref("")
    const isExpiryError = ref(false)
    const errorMsg = ref("");
    const retryMessage = ref(false);
    const insuranceExpiration = ref(false);
    const insuranceLoader = ref(false);
    const uploadModelLoader = ref(false);
    const insuranceExpirationDisplay = ref("");
    const insuranceExpirationDisplayDate = ref("");
    const isDownloadInsuranceImg = ref(false);
    const payeeID = sessionStorage.getItem('payeeID');
    const loading = ref(true);
    const insuranceStatus = ref("");
    const canUploadInsurance = computed(() => insuranceStatus?.value?.length ? ['Insurance Image Invalid', 'OCR Clear', 'Manual Clear'].includes(insuranceStatus.value)  : true);
    const showDialog = ref(false);

    proxyApi.get('/getPartnerInsuranceDetails').then(({data})=>{
      if(data.success){
        insuranceExpirationDisplay.value= DateDisplay(data.result[0].insuranceExpiry);
      }
    })
    const initialValues = reactive({
      fullInsuranceExp: "",
      insuranceExp: {
        day: "Day",
        month: "Month",
        year: "Year"
      }
    })

    onMounted(() => {
      fetchOnboardingDocuments()
      fetchInsuranceExpDetails()
      fetchInsuranceDocuments()
    })

    function fetchOnboardingDocuments() {
      proxyApi.get("/getOnBoardingDocuments").then(({ data }) => {

        const docDeatils = data.result.filter(item => {
          return ["Insurance"].includes(item.title)
        })
        insuranceFileId.value = docDeatils[0].fileID
        insureTempId.value = docDeatils[0].id
      });
    }

    function closeDialog(){
      showDialog.value = false;
    }

    function fetchInsuranceExpDetails() {
      proxyApi.get("/getPartnerDashboardDLDetails").then(({ data }) => {
        const {expiration} = data.result[0]
        if (data.success) {
          initialValues.fullInsuranceExp = expiration
          documentMethods.splitExpDate(expiration, initialValues.insuranceExp)
        }
      });
    }

    function handleUploadFile(formData) {
      proxyApi.post('/uploadFile', formData)
        .then(({ data }) => {
          if (data.success) {
            gTagEvent("Update Auto Insurance", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
          }
        })
    }
    
    function fetchInsuranceDocuments() {
      proxyApi.get("/getUpdatedInsuranceDocuments").then(({ data }) => {
        if(data?.result?.length > 0) {
          insuranceFileIdImage.value = data.result[0].file_id;
          insuranceExpirationDisplayDate.value=DateDisplay(data.result[0].insurance_expiry);
          insuranceStatus.value = data.result[0]?.status;
        }
      }).finally(() => {
        loading.value = false;
      });
    }

    const DateDisplay = (date: string) => {
      return date ? DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat("MMM dd yyyy")
        : "";
    };

    const dateDisplay = (date: string) => {
      return date ? DateTime.fromFormat(date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'').toFormat('yyyy-mm-dd')
        : "";
    };

    const downloadImage = () => {
      gTagEvent("Download Insurance Image", "Dashboard", `partner_id: ${state.user.partnerId}`)
      isDownloadInsuranceImg.value = !isDownloadInsuranceImg.value;
    };
    const onFileSelected = (filePreview: FilePreview) => {
      insuranceFilePreview.value = filePreview.file;
      fileType.value = filePreview.isPdf
      micromodal.show("image-insurance-preview", {});
    };

    const updateInsuranceExpiry = (date: string) => {
      proxyApi.post('/updateInsurance', encodeWithParam({
        insuranceExpiry: date
      }))
    }

    const onChangeImage = () => {
      micromodal.show("modal-upload-image", {});
    };

    const insuranceSelected = (date: string) => {
      micromodal.close("image-insurance-preview");
      micromodal.close("modal-upload-image");
      if (date) {
        onSubmitUpload(date);
      }
      if(payeeID!='null')
      {
        let param = {templateName: 'insurance_upload',partnerID : state.user.partnerId}
        proxyApi.post('/sendSMSEmailForPartner', encodeWithParam(param));
      }
    };

    const onSubmitUpload = async (date) => {
      uploadModelLoader.value = true;
      let type = "ocr";
      let isUploadInsurance="true"
      const formData = new FormData();
      formData.append("file", insuranceFilePreview.value || "");
      formData.append("documentTemplateID", insureTempId.value);
      formData.append("signature", state.user.signature);
      formData.append("type", type);
      formData.append(
        "originalname",
        insuranceFilePreview.value?.name || "insurance image"
      );
      formData.append("clientLocationPartnerID",state.user.locationPartnerId)
      formData.append("acl", "public");
      formData.append("insuranceExpiry", date)
      formData.append("addInsuranceInfo",isUploadInsurance);
      console.log(formData)
      insuranceLoader.value = true;
      retryMessage.value = false;
      proxyApi.post("/uploadFile", formData).then(() => {
        insuranceLoader.value = false;
        uploadModelLoader.value = false;
        proxyApi.post("/updateVerifiedinsurance",encodeWithParam({insuranceVerified:false}))
        router.push({ name: 'personalDocuments',query:{insuranceUploaded: 'true'} })
      });
    };

    const onCancelUpload = () => {
      insuranceFilePreview.value = null;
      fileType.value = false;
    };

    const submitDocs = () => {
      micromodal.close("modal-upload-image");
      const formData = new FormData();
      formData.append("file", insuranceFilePreview.value || "");
      formData.append("documentTemplateID", insureTempId.value);
      formData.append("signature", state.user.signature);
      formData.append("insuranceExpiry", insuranceExp.value);
      formData.append("acl", "public");
      formData.append("clientLocationPartnerID",state.user.locationPartnerId)
      handleUploadFile(formData)
    };

    const openUploadModal = () => {
      if(canUploadInsurance.value) {
        micromodal.show("modal-upload-image", {});
      } else {
        showDialog.value = true;
      }
    };

    const monthNames = ["January", "February", "March", "April", "May","June","July", "August", "September", "October", "November","December"];

    function readableDate() {
      return monthNames[parseInt(initialValues.insuranceExp.month) - 1] + " " + initialValues.insuranceExp.day + " " + initialValues.insuranceExp.year
    }
    const expirydateValidate = (data) => {
      if (data) {
        isExpiryError.value = false;
        let futuredate = isFutureDate(data);
        if (futuredate) {
          insuranceExp.value = data;
        } else {
          isExpiryError.value = true;
          errorMsg.value = " License cannot be expired"
        }
      } else {
        errorMsg.value = "Please prove insurance expiry data"
      }
    }
    const isFutureDate = (dateVal) => {
      var g1 = new Date();
      var g2 = new Date(dateVal);
      if (g1.getTime() < g2.getTime()) return true;
      else if (g1.getTime() > g2.getTime()) return false;
    }

    const minDate = DateTime.local().toFormat("yyyy-MM-dd");
    return {
      toastMessage,
      insuranceFilePreview,
      fileType,
      insuranceFileId,
      insuranceFileIdImage,
      submitDocs,
      openUploadModal,
      onFileSelected,
      onCancelUpload,
      onSubmitUpload,
      expirydateValidate,
      updateInsuranceExpiry,
      onChangeImage,
      insuranceSelected,
      minDate,
      insuranceExp,
      retryMessage,
      insuranceExpiration,
      insuranceLoader,
      uploadModelLoader,
      initialValues,
      monthNames,
      readableDate,
      insuranceExpirationDisplay,
      insuranceExpirationDisplayDate,
      downloadImage,
      isDownloadInsuranceImg,
      loading,
      canUploadInsurance,
      showDialog,
      closeDialog,
    };
  },

});
