
import { defineComponent, ref, onMounted } from "vue";
import PageActions from "@/components/PageActions.vue";
// import VuePdfEmbed from 'vue-pdf-embed';
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { state } from "@/harlem/stores";
import { useRoute } from "vue-router";
import Loader from "@/components/modals/Loader.vue";
import { gTagEvent } from "@/util/GoogleAnalytics";
import {convertBufferToContent} from "@/util/BufferContentConverter";
import { proxyApi } from "@/http/http.config";
import { saveAs } from 'file-saver';




export default defineComponent({
  name: "NECDocument",
  components: {
    PageActions, 
    // VuePdfEmbed,
     Loader
     },

  setup() {

    const route = useRoute()
    const url = ref()
    const errMsg = ref()
    const isLoading = ref(true)
    const isTaxDoc = ref(false)
    const fileName = ref("")

    const getFilePath = (() =>{
      isLoading.value = true
         const param = {
                  partnerID: state.user.partnerId,
                  taxType: "1099NEC",
                  taxYear: route.query.year,
                  organization:"WALMART"
                }
       proxyApi.post('/taxDocument', encodeWithParam(param)).then(({data}) => {
        proxyApi.post('/getUploadedFile',encodeWithParam({fileID: data.path})).then((content)=>{
          //  url.value =  content.data.bufferInHex ? convertBufferToContent(content.data.bufferInHex,content.data.contentType) : null;
           let buffer = Buffer.from(content.data.bufferInHex, "hex");
        let buf64 = buffer.toString("base64");
        url.value = "data:application/pdf;base64,"+buf64
            fileName.value = `${state.user.partnerId}_${route.query.year}_1099Nec`
            isTaxDoc.value = true
        })
       
          if(data.error || data.success == false){
            errMsg.value = data.error ? data.error: "No Data Found"
            isTaxDoc.value = false;
        }
        isLoading.value = false;
      })
    })();

    const downloadDocument = (() => {
      saveAs(url.value, `${fileName.value}.pdf`);
    })


    onMounted(() => {
      gTagEvent("View NEC Documents Page", "Partner Dashboard", `partner_id: ${state.user.partnerId}`)
    })

    return {url, errMsg, isLoading, getFilePath, isTaxDoc,fileName, downloadDocument}

  }
 
});
