
import { proxyApi } from '@/http/http.config';
import { encodeWithParam } from '@/util/DataEncodeDecode';
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { IconWarningTriangle, IconXBlack } from '@/components';
import { noActiveEarningAccounts } from '@/modules/earnings/Helper';
import { isAllPaymentMethodsFailed, defaultLocationPaymentsFailed } from '../funnelStatus/Helpers';
import { useRouter } from 'vue-router';
import NoActivePayments from '@/components/popups/NoActivePayments.vue';

export default defineComponent({
    name: "Dashboard",
    components: {
        IconWarningTriangle,
        IconXBlack,
        NoActivePayments
    },
    setup() {
        const route = useRouter();
        const failedBranchAndLocationDefaultPayment = ref(false)
        const noEarningAccounts:any = ref(false)
        const isLoading = ref(true)
        const banner = reactive({
            ach: {
                type: 'ach',
                title: "Direct Deposit",
                status: "",
                show: false
            },
            one: {
                type: 'one',
                title: "ONE",
                status: "",
                show: false
            },
            branch: {
                type: 'branch',
                title: "Branch",
                status: "",
                show: false
            }
        })
        const bannersFailed: any = ref([])

        const getClosedNotificationBanners = () => {
            proxyApi.get('/getClosedNotificationBanners').then(({ data }) => {
                bannersFailed.value = []

                const closedNotificationBanners = data.closedNotificationBanners[0]
                const failedAccounts = data.failedAccounts

                for (const [key, value] of Object.entries(closedNotificationBanners)) {
                    banner[key].show = !value;
                }

                failedAccounts.forEach((item) => {
                    if (banner[item].show) {
                        banner[item].status = "Failed";
                        bannersFailed.value.push(banner[item])
                    }
                })
            })
        }

        const hasFailedAllEarningMethods = () => {
            return (failedBranchAndLocationDefaultPayment.value || (noEarningAccounts.value == false))
        }

        const showAddAccountIfNoActive = async () => {
            const defaultLocationAllPaymentsFailed = await defaultLocationPaymentsFailed()
            const allEarningsFailed = await isAllPaymentMethodsFailed()
            if ((defaultLocationAllPaymentsFailed || allEarningsFailed) == false) {
                noEarningAccounts.value = await noActiveEarningAccounts();
            } else if (defaultLocationAllPaymentsFailed || allEarningsFailed) {
                return failedBranchAndLocationDefaultPayment.value = true
            }
        }

        const navigateUser = () => {
            const currentRoute = route.currentRoute.value.name
            if (currentRoute == "earnings") {
                route.push('manage-earnings')
            } else if (currentRoute == "dashboard") {
                route.push('earnings')
            }
        }

        function closeBanner(type) {
            proxyApi.post('/updateClosedNotificationBanner', encodeWithParam({
                paymentProvider: type
            })).then(({ data }) => {
                getClosedNotificationBanners()
            })
        }

        onMounted(async () => {
            await getClosedNotificationBanners()
            await showAddAccountIfNoActive()
            isLoading.value = false
        })

        return {
            bannersFailed,
            banner,
            closeBanner,
            showAddAccountIfNoActive,
            navigateUser,
            failedBranchAndLocationDefaultPayment,
            noEarningAccounts,
            isLoading,
            hasFailedAllEarningMethods
        }
    }
})
