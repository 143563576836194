
import { state } from '@/harlem/stores'
import { useRouter } from 'vue-router'
import { proxyApi } from "@/http/http.config";
import { setStatus } from '@/services/stage.service'
import checklistMethods from "./components/Checklist/Helpers/Index";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { onMounted, onUnmounted } from 'vue';
import { gTagEvent } from '@/util/GoogleAnalytics';
import InitChecklist from "./components/Checklist/InitChecklist.vue";

export default {
	name: 'backgroundCheck',
	components: {
		FadeLoader,
		InitChecklist
	},
	methods: {
		isMobile() {
			return checklistMethods.isMobile();
		},
	},
	setup() {
		const router = useRouter()

		const sendToCreateSignature  = () => {
			gTagEvent("Complete Payment Agreement", "Branch Fraud Check", `partner_id: ${state.user.partnerId}`)
			router.push({ 
				name: 'createsignature', 
				query: { 
					locationId: state.user.locationId, 
					name: state.user.locationName,
				}
			})
		}

		const processStatus = async (kycStatus) => {
			const statuses = ["needs review", "db_retry", "declined", "initiated"]
			if (kycStatus === 'approved') {
				sendToCreateSignature()
			} else if (statuses.includes(kycStatus)) {
				router.push({ 
					name: 'kycResponse', 
					query: { 
						locationId: state.user.locationId
					}
				})
			}
		}
		
		let timeout: ReturnType<typeof setTimeout>
		// let baseTimeout: ReturnType<typeof setTimeout>

		const checkStatus = () => {
			proxyApi.get('/getKycStatus')
				.then(async ({data}) => {
					if (data.result.kyc_status !== 'created') {
						clearTimeout(timeout);
						await processStatus(data.result.kyc_status);
					}
				})
				.finally(() => timeout = setTimeout(checkStatus, 2000))
		}

		onMounted(() => {
			const progress: any = localStorage.getItem("progress")
			const progressObj = JSON.parse(progress)
			if (progressObj.step === 'PERSONAL_DETAILS') {
				return router.push({
					name: 'personalDetails',
					query: {
						locationId: state.user.locationId,
						name: state.user.locationName,
					}
				})
			}
			gTagEvent("Enter IDV Step", "Kyc check", `partner_id: ${state.user.partnerId}`);
			timeout = setTimeout(checkStatus, 2000)
			// baseTimeout = setTimeout(() => {
			// 	sendToCreateSignature();
			// }, 15000)
		})

		onUnmounted(() => {
			clearTimeout(timeout)
			// clearTimeout(baseTimeout)
		})
	},
}
