
import { defineComponent, inject } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "FAQOverview",
  setup() {
    const router = useRouter();
    const fullFaq = inject("FullFAQ", []);
    const quick = inject("FeaturedFAQ", []);
    return { quick, fullFaq, router };
  },
});
