<template>
  <!-- Switch layout if partner is on desktop or mobile -->
  <div v-if="waitingForResponse">
  <h4>We are waiting for Branch's response. <br> Please wait for few minutes and reload the page again</h4>
  </div>

  <div :class="isMobile() ? '' : 'desktop-container'" v-else>
    <div :class="isMobile() ? '' : 'desktop-wrapper'">
      <InitChecklist />
      <div :class="isMobile() ? '' : 'desktop-module_form'">
        <div v-show="!isMobile()" class='desktop-module_title'>
        </div>
        
        <!-- Header is rendered twice based on condition. On mobile, they want
            header to be on top of image while desktop to be under image. -->
        <div v-if="isError">
          <div class="claim-digital-wallet-header">
            <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.1" width="118" height="118" rx="59" fill="#E71742"/>
              <path d="M59 79C70.0457 79 79 70.0457 79 59C79 47.9543 70.0457 39 59 39C47.9543 39 39 47.9543 39 59C39 70.0457 47.9543 79 59 79Z" stroke="#E71742" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M59 67H59.02" stroke="#E71742" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M59 51V59" stroke="#E71742" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="digital-wallet_header mobile" v-show="isMobile()">Sorry, your application can't be completed!</div>
            <div class="digital-wallet_header" v-show="!isMobile()">Sorry, your application can't be completed!</div>
            <div class="claim-digital-wallet-header-subtext">
              <p>At this time we are unable to complete your application, please <b>contact support</b> for assistance</p>
            </div>
          </div>
          <div :class="isMobile() ? 'digital-wallet_btn-container mobile' : 'digital-wallet_btn-container'">
            <button class="button next-button" @click="login">CLOSE</button>
          </div>
        </div>
        <div v-if="needAdditinolInfo && !isError">
          <div class="claim-digital-wallet-header">
            <svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.1" x="4" y="4" width="118" height="118" rx="59" fill="#FA6400" stroke="#FA6400" stroke-width="7"/>
              <path d="M55 49H51C49.9391 49 48.9217 49.4214 48.1716 50.1716C47.4214 50.9217 47 51.9391 47 53V77C47 78.0609 47.4214 79.0783 48.1716 79.8284C48.9217 80.5786 49.9391 81 51 81H62.394M75 67V75H83M75 61V53C75 51.9391 74.5786 50.9217 73.8284 50.1716C73.0783 49.4214 72.0609 49 71 49H67" stroke="#FA6400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M63 45H59C56.7909 45 55 46.7909 55 49C55 51.2091 56.7909 53 59 53H63C65.2091 53 67 51.2091 67 49C67 46.7909 65.2091 45 63 45Z" stroke="#FA6400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M75 83C79.4183 83 83 79.4183 83 75C83 70.5817 79.4183 67 75 67C70.5817 67 67 70.5817 67 75C67 79.4183 70.5817 83 75 83Z" stroke="#FA6400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M55 61H63M55 69H61" stroke="#FA6400" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="digital-wallet_header mobile" v-show="isMobile()">Thanks for submitting your Spark Driver™ enrollment forms!</div>
            <div class="digital-wallet_header" v-show="!isMobile()">Thanks for submitting your Spark Driver™ enrollment forms!</div>
            <div class="claim-digital-wallet-header-subtext">
              <p v-if="!over24Hours">We're verifying your information and will get back to you within 24 hours.</p>
              <p v-else>We're still working on verifying your information. Contact us for more information.</p>
              <div v-if="over24Hours" class="digital-wallet-24-hours-review-contact">
                <div>
                  <p>Email us at</p>
                  <p>
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 1H15C15.825 1 16.5 1.675 16.5 2.5V11.5C16.5 12.325 15.825 13 15 13H3C2.175 13 1.5 12.325 1.5 11.5V2.5C1.5 1.675 2.175 1 3 1Z" stroke="#8D95AA" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.5 2.5L9 7.75L1.5 2.5" stroke="#8D95AA" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <a href="mailto:help@branchapp.com">help@branchapp.com</a>
                  </p>
                </div>
                <div>
                  <p>Call us at</p>
                  <p>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.5001 12.6901V14.9401C16.5009 15.1489 16.4581 15.3557 16.3745 15.5471C16.2908 15.7385 16.168 15.9103 16.0141 16.0515C15.8602 16.1927 15.6785 16.3002 15.4806 16.3671C15.2828 16.434 15.0731 16.4589 14.8651 16.4401C12.5572 16.1893 10.3403 15.4007 8.39257 14.1376C6.58044 12.9861 5.04407 11.4497 3.89257 9.63757C2.62506 7.68098 1.83625 5.45332 1.59007 3.13507C1.57133 2.92767 1.59598 2.71864 1.66245 2.52129C1.72892 2.32394 1.83575 2.14259 1.97615 1.98879C2.11654 1.83499 2.28743 1.7121 2.47792 1.62796C2.6684 1.54382 2.87433 1.50027 3.08257 1.50007H5.33257C5.69655 1.49649 6.04942 1.62538 6.32539 1.86272C6.60137 2.10006 6.78163 2.42966 6.83257 2.79007C6.92754 3.51012 7.10366 4.21712 7.35757 4.89757C7.45848 5.16602 7.48032 5.45776 7.4205 5.73823C7.36069 6.01871 7.22172 6.27616 7.02007 6.48007L6.06757 7.43257C7.13524 9.31023 8.68991 10.8649 10.5676 11.9326L11.5201 10.9801C11.724 10.7784 11.9814 10.6395 12.2619 10.5796C12.5424 10.5198 12.8341 10.5417 13.1026 10.6426C13.783 10.8965 14.49 11.0726 15.2101 11.1676C15.5744 11.219 15.9071 11.4025 16.145 11.6832C16.3828 11.9639 16.5092 12.3223 16.5001 12.6901Z" stroke="#8D95AA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <a href="tel:+18883349675">1 (888)-334-9675</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!over24Hours" :class="isMobile() ? 'digital-wallet_btn-container mobile' : 'digital-wallet_btn-container'">
            <button class="button next-button" @click="login">OKAY</button>
          </div>
        </div>
        <div v-if="!needAdditinolInfo && !isError">
          <div class="claim-digital-wallet-header">
            <svg width="202" height="126" viewBox="0 0 202 126" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.1" x="4" y="4" width="194" height="118" rx="59" fill="#00CBB7" stroke="#00CBB7" stroke-width="7"/>
              <path d="M156 55V49C156 48.4696 155.789 47.9609 155.414 47.5858C155.039 47.2107 154.53 47 154 47H134C132.939 47 131.922 47.4214 131.172 48.1716C130.421 48.9217 130 49.9391 130 51C130 52.0609 130.421 53.0783 131.172 53.8284C131.922 54.5786 132.939 55 134 55H158C158.53 55 159.039 55.2107 159.414 55.5858C159.789 55.9609 160 56.4696 160 57V63M160 71V77C160 77.5304 159.789 78.0391 159.414 78.4142C159.039 78.7893 158.53 79 158 79H134C132.939 79 131.922 78.5786 131.172 77.8284C130.421 77.0783 130 76.0609 130 75V51" stroke="#00CBB7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M162 63V71H154C152.939 71 151.922 70.5786 151.172 69.8284C150.421 69.0783 150 68.0609 150 67C150 65.9391 150.421 64.9217 151.172 64.1716C151.922 63.4214 152.939 63 154 63H162Z" stroke="#00CBB7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M101.917 69.5834L106.5 65L101.917 60.4167" stroke="#00CBB7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M95.5 69.5834L100.083 65L95.5 60.4167" stroke="#00CBB7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M63.333 48.3333H66.9997C67.9721 48.3333 68.9048 48.7196 69.5924 49.4073C70.28 50.0949 70.6663 51.0275 70.6663 52V77.6666C70.6663 78.6391 70.28 79.5717 69.5924 80.2594C68.9048 80.947 67.9721 81.3333 66.9997 81.3333H44.9997C44.0272 81.3333 43.0946 80.947 42.4069 80.2594C41.7193 79.5717 41.333 78.6391 41.333 77.6666V52C41.333 51.0275 41.7193 50.0949 42.4069 49.4073C43.0946 48.7196 44.0272 48.3333 44.9997 48.3333H48.6663" stroke="#00CBB7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M61.5003 44.6667H50.5003C49.4878 44.6667 48.667 45.4875 48.667 46.5V50.1667C48.667 51.1792 49.4878 52 50.5003 52H61.5003C62.5128 52 63.3337 51.1792 63.3337 50.1667V46.5C63.3337 45.4875 62.5128 44.6667 61.5003 44.6667Z" stroke="#00CBB7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M63.3337 59.5L53.2503 69.5833L48.667 65" stroke="#00CBB7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="digital-wallet_header mobile" v-show="isMobile()">Your application is being reviewed and your insurance has been received. Once you are approved you will be given access to the app. Log in to your Spark Driver profile from the home screen to keep track of your status.
               </div>
            <div class="digital-wallet_header" v-show="!isMobile()">Your application is being reviewed and your insurance has been received. Once you are approved you will be given access to the app. Log in to your Spark Driver profile from the home screen to keep track of your status. </div>
            <p>We'll let you know when you're approved. You can also keep track of your status on the home screen.</p>
          </div>
          <div :class="isMobile() ? 'digital-wallet_btn-container mobile' : 'digital-wallet_btn-container'">
            <button class="button next-button" @click="login">Go To Home</button>
          </div>
        </div>
      </div>
    </div>
  </div>
<branch-message :url="url"/>
</template>

<script>
import authenticationService from "@/services/authentication.service";
import router from "@/router";
import checklistMethods from './components/Checklist/Helpers/Index'
import InitChecklist from '../signup/components/Checklist/InitChecklist'
import "../../assets/css/components/accordian.scss"
import { branchMessage } from "@/components";
import micromodal from "micromodal";
import { proxyApi } from "@/http/http.config";
import { state } from "@/harlem/stores";
import { setStatus } from "@/services/stage.service";
import { encodeWithParam } from '@/util/DataEncodeDecode'
import { gTagEvent } from "@/util/GoogleAnalytics";
export default {
  components: {
    InitChecklist,
    branchMessage
  },
  mounted() {
    setStatus("PAYMENT_AGREEMENT", "INCOMPLETE")
      proxyApi.post('/addApplicationStatus', encodeWithParam({
      stepName: 'PAYMENT_AGREEMENT',
      stepStatus: 'INCOMPLETE',
    }))
    this.createWallet()
    gTagEvent("View Claim Wallet Page", "Claim Wallet", `partner_id: ${state.user.partnerId}`)
  },
  methods: {
    login() {
      gTagEvent("Complete Claim Wallet", "Claim Wallet", `partner_id: ${state.user.partnerId}`)
      authenticationService.logout();
      router.push({ name: "login" });
    },
    isMobile() {
      return checklistMethods.isMobile()
    },
    createWallet() {
      this.walletCreated=false;
      this.needAdditinolInfo=false;
      proxyApi.get('/getWalletStatus').then(({ data }) => {
        if (data.success) {
          if (data.result.length > 0){
            this.filterRequest(data.result[0].status)
            const sentTime = new Date(data.result[0].last_sent_date)
            const hoursDifference = Math.abs(new Date() - sentTime) / 36e5;
            this.over24Hours = hoursDifference > 24 ? true : false
            if (data.result[0].magicLink) {
              this.url = data.result[0].magicLink;
            }  
          } else {
            this.waitingForResponse = true;
          } 
        }
      })
    },

    filterRequest(status) {
      switch (status) {
        case "ACTIVE":
        case "UNCLAIMED":
          gTagEvent("Wallet Created", "Claim Wallet", `partner_id: ${state.user.partnerId}`)
          this.walletCreated=true;
          this.activeStatus=true;
          this.setStatus()
          break;
        case "CREATED":
        case "REVIEW":
          this.walletCreated=false;
          this.needAdditinolInfo=true;
          break;
        case "CLOSED":
        case "FAILED":
          this.isError=true;
          this.setWithdrwan();
          break;
      }
    },

    async setStatus(){
      await proxyApi.post('/addApplicationStatus', encodeWithParam({
        stepName: 'DOCUMENTS',
        stepStatus: 'COMPLETE',
      })).then(()=>{
        proxyApi.post('/addApplicationStatus', encodeWithParam({
          stepName: 'SCREENING',
          stepStatus: 'NEEDED',
        })
        );
        setStatus("SCREENING", "NEEDED")
        return this.$router.push({ name: 'completed', query: { locationId: state.user.locationId }})
      })
    },

    setWithdrwan() {
      gTagEvent("Payment Agreement Withdrawn", "Claim Wallet", `partner_id: ${state.user.partnerId}`)
      proxyApi.post('/addApplicationStatus', encodeWithParam({
        stepName: 'PAYMENT_AGREEMENT',
        stepStatus: 'WITHDRAWN',
      }))
    }
  },
  data() {
    return {
      over24Hours: false,
      needAdditinolInfo: false,
      walletCreated: false,
      url: "",
      waitingForResponse: false,
      activeStatus: false,
      isError: false,
    }
  }
}
</script>

<style>
.claim-digital-wallet-header {
  text-align: center;
}

.claim-digital-wallet-header-subtext > p {
  margin-bottom: 0px;
}

.digital-wallet-24-hours-review-contact {
  margin: 10px auto;
  text-align: left;
  width: 267px;
  background: #EBEBEB;
  color: #8D95AA;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.digital-wallet-24-hours-review-contact > div > p {
  margin: 0;
}

.digital-wallet-24-hours-review-contact > div > p:first-child {
  font-size: 13px;
  opacity: 0.5
}

.digital-wallet-24-hours-review-contact > div > p:last-child {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 4px;
}

.digital-wallet-24-hours-review-contact > div > p:last-child > a { 
  color: #3171FF;
  font-size: 16px;
  line-height: 19px;
}
</style>
