
import { onMounted, ref } from "vue"
import { state } from '@/harlem/stores'
import { useRouter } from 'vue-router'
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import checklistMethods from "./components/Checklist/Helpers/Index";

const ssnErrorMessage = 'Please check your SSN and try again!'
const reasonCodeMessage = {
	'KYC_SSN_INVALID': ssnErrorMessage,
	'KYC_PII_SSN_MISMATCH': ssnErrorMessage,
	'KYC_DECEASED': ssnErrorMessage,
	'EMPLOYEE_UNDER_AGE': 'Please check your Date of Birth and try again!',
	'EMPLOYEE_ALREADY_LINKED': 'EMPLOYEE_ALREADY_LINKED',
	'ERROR': 'We are currently experiencing some difficulties in proceeding with the application. Please check back again later.'
}

export default {
	setup() {
		onMounted(() => {
			const progress: any = localStorage.getItem("progress")
			const progressObj = JSON.parse(progress)
			if (progressObj.step === 'PERSONAL_DETAILS') {
				goBackToPersonalDetails();
			}

			const contactSupportReasonCodes = ['CONFIRMED_FRAUD', 'ACCOUNT_SUSPENDED', 'ACCOUNT_WITH_EMAIL_EXISTS']
			proxyApi.get('/getWalletStatus').then(async (data) => {
				const branchResponse = data.data.result[0]
				if (branchResponse.status === 'ACTIVE' ||
					branchResponse.status === 'UNCLAIMED' ||
					branchResponse.status === 'REVIEW') {
					goBackToPersonalDetails();
				} else if (
					branchResponse.status === 'FAILED' ||
					branchResponse.status === 'CLOSED') {
					const data = await proxyApi.get('/getBranchRawCount')
					if (data.data.result[0].count >= 3) {
						isPending.value = false
						let param = {templateName: 'branch_failed'}
				        proxyApi.post('/sendSMSEmailForPartner', encodeWithParam(param))
					}
					if (branchResponse.response.reason_code === null || contactSupportReasonCodes.indexOf(branchResponse.response.reason_code) !== -1) {
						isPending.value = false
					} else {
						reasonCode.value = reasonCodeMessage[branchResponse.response.reason_code]
					}
					waiting.value = false
				}
			})
		})
		const router = useRouter()
		let waiting = ref(true)
		let isPending = ref(true)
		let reasonCode = ref('')
		let domainUrl = `${location.origin}/login`

		const goBackToPersonalDetails = () => {
			router.push({ name: 'personalDetails', query: { locationId: state.user.locationId } })
		}

		const goToBusinessDetails = () => {
			if (checklistMethods.isMobile()) {
				router.push({
					name: 'checklist',
					params: { step: "businessDetails" },
					query: {
						locationId: state.user.locationId,
						name: state.user.locationName
					},
				})
			} else {
				router.push({
					name: 'businessDetails',
					query: {
						locationId: state.user.locationId,
						name: state.user.locationName
					}
				})
			}
		}

		return {
			waiting,
			isPending,
			reasonCode,
			goBackToPersonalDetails,
			domainUrl,
		}
	}
}
