
  import { computed, defineComponent, onMounted, reactive, ref } from "vue";
  import authenticationService from "@/services/authentication.service";
  import { Form, Field } from "vee-validate";
  import { FieldError, IconEyeClosed, IconEyeOpen } from "@/components";
  import * as yup from "yup";
  import { useRoute, useRouter } from "vue-router";
  import { decode, encodeWithParam } from "@/util/DataEncodeDecode";
  import { mutation } from "@/harlem/mutation";
  import { event } from "vue-gtag";
  import micromodal from "micromodal";
import { proxyApi,signup } from "@/http/http.config";
import { state } from "@/harlem/stores";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { SUCCESS_CODES } from "../login/constants";
import { OtpScreen,OtpExpirationHelpText } from "@/components";

  
  export default defineComponent({
    name: "Login",
    components: { Form, Field, FieldError, IconEyeClosed, IconEyeOpen,OtpScreen,OtpExpirationHelpText },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const invalid = ref(false);
      const expiredPassword = ref(false);
      const passwordHidden = ref(true);
      let lastFourPhone=ref("");
      const isFreezingTime=ref(false);
      const showsendotp=ref(false);
      let invalidAcc=ref(false);
      var countLeft = ref<boolean | string>(false);
      const invalidOtp=ref(false)
      const error = ref(false);
      var errorMessage = ref(false);
      const username = ref("");
      const password = ref("");
      const otpValue = ref('');
      onMounted(() => {
        localStorage.clear()
        gTagEvent("View ONE Login Page", "ONE", true)
      })
  
      const signIn = async (values: any) => {
        invalidAcc.value = false;
        const paramObj = { username: values.username }
        let obj = {
          username: values.username,
          password: values.password,
        };
        signup.post("/validate-user", encodeWithParam(obj)).then((res)=> {
          const { data } =res
          if(data?.accountLocked){
            sessionStorage.setItem('notified', 'true')
              return router.push({name: "accountLocked"});
          }
          if(data.success){
            lastFourPhone.value = data?.result ? data.result:'';
            signup.post("/generateOTP", encodeWithParam(paramObj)).then((result) => {
            if(!SUCCESS_CODES.includes(result.data.code)) {
                isFreezingTime.value = true;
              }
            else{
            showsendotp.value=true;
            invalid.value=false;
            }
        })
          }
          else{
            invalidAcc.value=true;
          }
        })

        /*authenticationService.login(encodeWithParam(obj)).then((data: any) => {
            if (data.data.success) {
              invalid.value = false;
              expiredPassword.value = false;
              localStorage.setItem("username", values.username);
              const userObj = decode(data.data.result);
              event("Login", {
                event_category: "Login",
                event_label: "login",
                value: `${userObj?.user?.partnerID}`,
              });
              if (isPasswordExpired(userObj.user.password_expire_on)) {
                authenticationService.logout();
                expiredPassword.value = true;
              } else {
                singletonObject(userObj, obj);
                proxyApi.get("/oneStatus").then((data) => {
                  if (!userObj.user.payeeID || !userObj.user.is_one_integration_enabled || userObj.user.garnishment) {
                    gTagEvent("ONE Login Sent to Dashboard", "ONE", true)
                    return router.push({
                      name: "dashboard",
                      query: { oneSignUp: 'false' }
                      });
                  }

                  if(data.data.rowCount > 0 ){
                    return router.push({name: "dashboard"});
                  }
                  gTagEvent("ONE Login Sent to Create ONE Account", "ONE", true)
                  if ((route.query.fromSparkLandingPage) == "true") {
                    gTagEvent("View ONE Value Prop Page from Spark Landing Page", "ONE", true)
                  }
                  return router.push({name: "oneValueProp"});
                })
                
              }
            } else {
              invalid.value = true;
              if (data.data.forgotPassword) {
                return router.push({
                  name: "forgotPassword",
                  query: { username: values.username },
                });
              }
            }
          })
          .catch(({ response }) => {
            if (response.data.code === "InvalidCredentials") {
              invalid.value = true;
            }
          });*/
      };
  
      const isPasswordExpired = (date) => {
        var g1 = new Date();
        var g2 = new Date(date);
        if (g1.getTime() < g2.getTime()) return false;
        else if (g1.getTime() > g2.getTime()) return true;
      };
  
      const redirectToForgotPassword = () => {
        return router.push({ name: "forgotPassword" });
      };
  
      const viewPassword = computed(() => {
        return passwordHidden.value ? "password" : "text";
      });
      const togglePassword = () => (passwordHidden.value = !passwordHidden.value);
      const initialValues = reactive({
        username: "",
        password: "",
      });
      const schema = yup.object().shape({
        username: yup.string().trim().required().label("Username"),
        password: yup.string().required().min(8).label("Password"),
      });

      const singletonObject = (data, obj) => {
      mutation.user.setGarnishment(data.user.garnishment);
      mutation.user.setIsOneIntegrated(data.user.is_one_integration_enabled);
      mutation.user.setOneStatus(data.user.one_status);
      mutation.user.setIsOneActive(data.user.is_active);
      mutation.user.setOneStartDate(data.user.one_pay_start_date);
      mutation.user.setPartnerId(data.user.partnerID);
      mutation.user.setUserId(data.user.userID);
      mutation.user.setEmail(data.user.registeredEmail);
      mutation.user.setFirstName(data.user.firstName);
      mutation.user.setLastName(data.user.lastName);
      mutation.user.setLocationId(data.user.clientLocationID);
      mutation.user.setLocationName(data.user.locationName as string);
      mutation.user.setLocationPartnerId(data.user.clientLocationPartnerID);
      mutation.user.setUsername(obj.username);
      mutation.user.setSignature(data.user.electronicSignature);
      mutation.mergeTags.setPartnerFullName(
        `${data.user.firstName} ${data.user.lastName}`
      );
      mutation.mergeTags.setPartnerFirstName(data.user.firstName);
      mutation.mergeTags.setPartnerLastName(data.user.lastName);
      mutation.mergeTags.setPartnerEmail(
        data.user.registeredEmail?.toLowerCase().trim()
      );
      mutation.mergeTags.setPartnerSignature(data.user.electronicSignature);
    };
  
      const login=()=>{
        router.push({ name: 'login'})
      }

      const closeModal = ()=>{
            micromodal.close("otp-expiration-help-text");
        }
  
        const getOTP=async()=>{
          const paramObj = { username: username.value }
          await signup.post("/generateOTP", encodeWithParam(paramObj))
        .then((result) => {
          if(!SUCCESS_CODES.includes(result.data.code)) {
            isFreezingTime.value = true;
          }
        })
      }

      const isOtpComplete = computed(() => {
      return otpValue.value.length === 6; 
    });

    const validations=()=>{
      isFreezingTime.value=false;
      invalid.value=false;
    };

    const  close=() => {
				showsendotp.value=false;
          return router.push({ name: "OneLogin" });
				}

        const handleOtpChange = (otp: string) => {
      otpValue.value = otp;
    };


    const oneLogin = async () => {
      invalidOtp.value=false;
        let obj = {
          username: username.value,
          password: password.value,
          otp:otpValue.value,
        };
        authenticationService.login(encodeWithParam(obj)).then((data: any) => {
            if (data.data.success) {
              invalid.value = false;
              expiredPassword.value = false;
              localStorage.setItem("username", username.value);
              const userObj = decode(data.data.result);
              event("Login", {
                event_category: "Login",
                event_label: "login",
                value: `${userObj?.user?.partnerID}`,
              });
              if (isPasswordExpired(userObj.user.password_expire_on)) {
                authenticationService.logout();
                expiredPassword.value = true;
              } else {
                singletonObject(userObj, obj);
                proxyApi.get("/oneStatus").then((data) => {
                  if (!userObj.user.payeeID || !userObj.user.is_one_integration_enabled || userObj.user.garnishment) {
                    gTagEvent("ONE Login Sent to Dashboard", "ONE", true)
                    return router.push({
                      name: "dashboard",
                      query: { oneSignUp: 'false' }
                      });
                  }

                  if(data.data.rowCount > 0 ){
                    return router.push({name: "dashboard"});
                  }
                  gTagEvent("ONE Login Sent to Create ONE Account", "ONE", true)
                  if ((route.query.fromSparkLandingPage) == "true") {
                    gTagEvent("View ONE Value Prop Page from Spark Landing Page", "ONE", true)
                  }
                  return router.push({name: "oneValueProp"});
                })
                
              }
            } else {
              if(data.data.accountLocked){
                  return router.push({
                    name: "accountLocked",
                    });
              }
              if(!data.data.success && !data.data.result){
                errorMessage.value = data.data.message;
                error.value = true;
                invalid.value = false;
              }else{
                invalidOtp.value=true;
                countLeft.value = data.data.result[0].failed_login_attempts;
                invalid.value = true;
                error.value = false;
              }
              if (data.data.forgotPassword) {
                invalid.value = true;
                return router.push({
                  name: "forgotPassword",
                  query: { username: username.value },
                });
              }
            }
          })
          .catch(({ response }) => {
            if (response.data.code === "InvalidCredentials") {
              invalid.value = true;
            }
          });
      };
    

      return {
        signIn,
        togglePassword,
        initialValues,
        invalid,
        expiredPassword,
        schema,
        viewPassword,
        passwordHidden,
        redirectToForgotPassword,
        login,
        lastFourPhone,
        closeModal,
        isFreezingTime,
        showsendotp,
        invalidAcc,
        countLeft,
        invalidOtp,
        error,
        errorMessage,
        getOTP,
        isOtpComplete,
        otpValue,
        validations,
        close,
        handleOtpChange,
        oneLogin,
        username,
        password
      };
    },
  });
  