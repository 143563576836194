<template>
  <div class="container">
    <div class="content">
      <div class="page-actions">
        <div class="action-left">
          <router-link class="back" :to="{ name: 'signedDocuments' }">
            <svg v-svg="'icon-arrow-left'" size="0 0 18 18" />
            Contractual Docs
          </router-link>
        </div>
      </div>
      <div class="inline-links text-right menu-item">
        <a @click="downloadPDF" class="action-link">
          <svg v-svg="'icon-download-2'" size="0 0 18 18" />
          Download
        </a>
        <router-link
          v-if="versions.value.length > 0"
          :to="{
            name: 'documentVersions',
            params: { id: originalID },
          }"
          class="action-link"
        >
          <svg v-svg="'icon-history'" size="0 0 18 18" />
          versions
        </router-link>
      </div>
      <h2>{{ title }}</h2>
      <div v-if="html" class="resub-doc">
        <div
          ref="pdfContent"
          :style="{
            backgroundColor: 'white',
          }"
        >
          <div v-html="html"></div>
        </div>
        <a @click="signature">
          <div class="signature-container margin-top-20">
            <div v-if="state.user.signature" class="signature">
              {{ state.user.signature }}
            </div>
            <p v-else>Sign Document</p>
          </div>
        </a>
        <div class="button-group">
          <button
            class="button"
            :disabled="!state.user.signature"
            @click="saveDocument"
          >
            Submit Signed
          </button>
          <button class="button button-ghost" @click="$router.back()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <signature @signature="storeSignature" />
</template>
<script>
import { defineComponent, inject, onMounted, reactive, ref } from "vue";
import {
  GetResubDocumentByPk,
  GetDashboardSignedDocVersionsByOriginalID,
} from "@/modules/documents/graph/queries";
import { useRoute, useRouter } from "vue-router";
import { StoreStateSymbol } from "@/store/providers/state.provider";
import { SaveSignedDocument } from "@/modules/signup/graphql/mutations";
import { DateTime } from "luxon";
import { RemoveResubDocFromDocTemplateUseByPK } from "./graph/mutations";
import { Signature } from "@/components";
import micromodal from "micromodal";
import handlebars from "handlebars";
import { state } from "@/harlem/stores";
import { mutation } from "@/harlem/mutation";
import html2pdf from "html2pdf.js";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from '@/util/DataEncodeDecode';



export default defineComponent({
  name: "ResubmitDocument",
  components: { Signature },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = inject(StoreStateSymbol);
    const user = reactive(store.user);
    const title = ref("");
    const userIP = ref("");
    const html = ref("");
    const fullDoc = ref({});
    const pdfContent = ref("");
    const originalID = ref("");
    const versions = ref("");



    let docId = {id: route.params.id}
    proxyApi.post("/getDocHtml", encodeWithParam(docId))
    .then(({ data }) => {
      if (data.success) {
        let htmlDoc = data.result[0].html
        let refinedHtml = htmlDoc.replace(/(<style[\w\W]+style>)/g, "");
        refinedHtml = refinedHtml.replaceAll('{{partner.signature}}', `<span  style="font-family: La Belle Aurore; font-style: italic; font-weight: 900; font-size: 20px;">${data.result[0]?.signature}</span>`);
        let partner={
        date:data.result[0]?.signedOn,
        partner:{
          signature:data.result[0]?.signature
        }
      }
        html.value = handlebars.compile(refinedHtml)(partner);
      }
    })

    const downloadPDF = () => {
      html2pdf(pdfContent.value, {
        margin: [0.3, 0.4],
        filename: title.value.replaceAll(" ", "_") + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
    };
    onMounted(() => {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          userIP.value = ip;
        });
    });
    const saveDocument = async () => {
         let nameOfTheFile = ""
      let contentOfTheFile = ""
      let obj = {
        documentTemplateID: route.params.id,
        signature: state.user.signature,
        documentContents: html.value,
        uploadedFileContent: {fileName: nameOfTheFile ?  nameOfTheFile : null, 
        fileContent: contentOfTheFile ? contentOfTheFile  : null}
      }
      proxyApi.post("/insSignedDocuments", obj).then(({ data }) => {
        if (data.success) { 
          router.push({ name: 'signed' })
        }
      })
      return router.back();
    };

    const signature = () => {
      micromodal.show("modal-signature");
    };
    const storeSignature = (data) => {
      const signature = data.trim();
      if (signature.length > 2) {
        mutation.user.setSignature(signature);
      }
    };
    
    return {
      user,
      router,
      title,
      html,
      downloadPDF,
      saveDocument,
      signature,
      storeSignature,
      state,
      pdfContent,
      originalID,
      versions,
    };
  },
});
</script>
