// import applicationValidationGuard from "@/router/applicationValidationGuard";
import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/one/signup",
    name: "OneLandingPage",
    // beforeEnter: locationValidationGuard,
    component: () => import("@/modules/one/OneLandingPage.vue"),
    meta: {
      title: "Signup | One",
      layout: "one",
      step: "SIGNUP",
      open:true
    },
  },
  {
    path: "/one/login",
    name: "OneLogin",
    // beforeEnter: locationValidationGuard,
    component: () => import("@/modules/one/OneLogin.vue"),
    meta: {
      title: "Login | One",
      layout: "one",
    },
  },
  {
    path: "/one/oneValueProp",
    name: "oneValueProp",
    // beforeEnter: applicationValidationGuard,
    component: () => import("@/modules/one/OneValueProp.vue"),
    meta: {
      title: "Signup | One Value Prop",
      order: 3,
      layout: "one",
      authgaurd:false
    },
  },
  {
    path: "/one/faqs",
    name: "oneFaqs",
    // beforeEnter: applicationValidationGuard,
    component: () => import("@/modules/one/OneFaqs.vue"),
    meta: {
      title: "FAQS | One",
      order: 3,
      layout: "one",
      authgaurd:false
    },
  },
] as Array<RouteRecordRaw>;
