import ApplicationRoot from "@/ApplicationRoot.vue";
import "@/assets/css/walmartStyle.css";
import "@/assets/css/theme.css";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_green.css";
import logger from "@/util/logger";
import Harlem from "@harlem/core";
import createDevtoolsPlugin from "@harlem/plugin-devtools";
import createResetPlugin from "@harlem/plugin-reset";
import createStoragePlugin from "@harlem/plugin-storage";
import Maska from "maska";
import { createApp } from "vue";
import { VueCookieNext } from "vue-cookie-next";
import { svgSpriteDirectivePlugin } from "vue-svg-sprite";
import SignupLayout from "./components/layout/SignupLay.vue";
import router from "./router";
import VueFlatPickr = require("vue-flatpickr-component");
import Popper from "vue3-popper";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag";
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';

const devtoolsPlugin = createDevtoolsPlugin({
  label: "State",
});
const storagePlugin = createStoragePlugin("*", {
  type: "session",
  prefix: "ddi",
  sync: true,
});

VueCookieNext.config({ expire: "7d" });

const app = createApp(ApplicationRoot as any)
  .use(Harlem, {
    plugins: [devtoolsPlugin, storagePlugin, createResetPlugin()],
  })
  .use(VueCookieNext)
  .use(router)
  .use(logger)
  .use(PrimeVue)
  .component("signup-layout", SignupLayout)
  .use(Maska)
  .component("flat-pickr", VueFlatPickr)
  .component("Popper", Popper)
  .use(svgSpriteDirectivePlugin, {
    url: require("./assets/images/svg-sprite.svg"),
  })
  .use(VueGtag, {
    config: {
      id: config.GOOGLE_ANALYTICS_ID
    }
  }, router);


Sentry.init({
  app,
  dsn: "https://6a1630fd6315483ba8a7890e35932d51@o251564.ingest.sentry.io/6060635",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        config.VUE_APP_ENV,
        config.VUE_APP_PARTNER_PORTAL_BASE_URL,
        /^\//,
      ],
    }),
  ],
  logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: config.VUE_APP_ENV === "production" ? 0.25 : 1.0,
});
app.config.errorHandler = (err: any, instance: any, info: string) => {
  console.info(info);
  console.error(err);
  console.log(instance);
};
// return router.push({ name: "errorPage" });

app.mount("#app");
