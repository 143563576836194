import gql from "graphql-tag";

export const UpdatePartnerDocInformation = gql`
  mutation UpdatePartnerDocInformation(
    $userId: uuid!
    $updateObject: partners_set_input
  ) {
    update_partners(where: { userID: { _eq: $userId } }, _set: $updateObject) {
      affected_rows
    }
  }
`;

export const UpdatePartnerDriversLicense = gql`
  mutation UpdatePartnerDriversLicense(
    $objects: [driversLicenses_insert_input!]!
  ) {
    insert_driversLicenses(
      objects: $objects
      on_conflict: {
        constraint: driversLicenses_pkey
        update_columns: [country, expiration, number, state]
      }
    ) {
      affected_rows
    }
  }
`;

export const RemoveResubDocFromDocTemplateUseByPK = gql`
  mutation RemoveResubDocFromDocTemplateUseByPK($docTemplateUseId: uuid!) {
    delete_documentTemplateUse_by_pk(id: $docTemplateUseId) {
      id
    }
  }
`;
