
import { defineComponent, ref,onMounted, computed } from "vue";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { signup } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";

export default defineComponent({
  name: "ForgotUsername",
  setup() {
    const emailSent = ref(false);
    const emailAddress = ref("");

    const fetchpartnerInfo = () => {
      let obj={
        email: emailAddress.value.toLowerCase()
      }
      signup.post("/sendUserNameForEmailBySMS", encodeWithParam(obj))
        .then(() => {
          emailSent.value = true
          gTagEvent("Send Username By SMS", "Login", emailAddress.value.toLowerCase())
        })

    }
        

    onMounted(() => {
      gTagEvent("View Forgot Username Page", "Login", true)
    });

    const isValidEmail = computed(() => {
      return emailAddress.value?.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi)
    });

    
    return { emailSent, emailAddress, fetchpartnerInfo, isValidEmail };
  },
});
