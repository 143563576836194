
import { defineComponent, computed, ref, watchEffect, watch } from "vue";
import PortalHeader from "@/components/PortalHeader.vue";
import PortalFooter from "./components/Footer.vue";
import OracleButton from "./components/OracleButton.vue";
import { storeSetup } from "@/store";
import { useRoute } from "vue-router";
import FlyoutMenu from "@/components/FlyoutMenu.vue";
import router from "@/router";
import authenticationService from "./services/authentication.service";
import { state } from "@/harlem/stores";
import IconSparkDriverLogo from "./components/icons/IconSparkDriverLogo.vue";
import { IconClose } from "@/components";

export default defineComponent({
  name: "ApplicationRoot",
  components: { FlyoutMenu, PortalHeader, PortalFooter, OracleButton, IconSparkDriverLogo, IconClose },
  setup() {
    storeSetup();
    const route = useRoute();
    const showWhiteLabeHead = ref(false);
    const showLoginBtn = ref(false)
    const showNav = ref(false);
    const locationName = ref("");
    const paymentAgreementComplete = ref(state.payment.paymentAgreementComplete)
    const showHeader = computed(
      () => route.meta.noHeader !== undefined && !route.meta.noHeader
    );
    const showCloseBtn = computed(
      () => ((route.name === 'forgotPassword') || (route.name === 'updatePassword'))
    );

    let containerHeight = computed(
      () => {
        if(route.meta.layout === "signup" || route.meta.layout === "one") return 'bigger-navbar-screen';
        else if(showHeader.value || showWhiteLabeHead.value) return 'normal-navbar-screen';
        else return 'no-navbar-screen';
      }
    )

    const setMenuState = (menuState: boolean) => {
      showNav.value = menuState;
    };
    const login = () => { 
      authenticationService.logout();
      localStorage.removeItem("username");
      router.push({ name: "login" }); 
    }
    const layout = computed(() => {
      if (route.meta.layout) {
        return route.meta.layout + `-layout`;
      } else {
        return "div";
      }
    });

    watchEffect(() => {

      if (route.meta.layout === "signup" || route.meta.layout === "one" || route.name === "forgotPassword" || route.name === 'updatePassword' ) {
        showWhiteLabeHead.value = true;
        if(state.user.locationName!){
          locationName.value = state.user.locationName!
        }
        else if(route.query['name']){
          locationName.value=route.query['name'].toString()
        }        
        if(route.meta.layout === "signup"){
          showLoginBtn.value=true;
        }
      } else {
        showWhiteLabeHead.value = false;
      }
    });

    watch(
      () => route.query.name,
      (name) => {
        if (name) {
          locationName.value = name as string
        }
      },
    )

    watch(
      () => state.payment.paymentAgreementComplete,
      (newValue) => {
        paymentAgreementComplete.value = newValue
      }
    )

    return {
      layout,
      login,
      showHeader,
      showNav,
      setMenuState,
      showWhiteLabeHead,
      locationName,
      showLoginBtn,
      showCloseBtn,
      containerHeight,
      paymentAgreementComplete
    };
  },
});
