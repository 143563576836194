import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { Constants } from "@/modules/login/constants";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import jwtDecode from "jwt-decode";

export default async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isAuthenticated = localStorage.getItem(Constants["ACCESS_TOKEN_KEY"]);
  if(isAuthenticated) {
    try {
        const decodedToken: any = jwtDecode(isAuthenticated!);
        const progress = (await proxyApi.post('/getFunnelProgress', encodeWithParam({ candidateID: decodedToken?.clientLocationPartnerID })))?.data?.result;
        let [idvCompleted, docCompleted, insCompleted, earningsCompleted, personalDetailsCompleted] =  Array(5).fill(false);

        progress.forEach((el) => {
            if (el.name === "IDV" && el.candidateStatus === "IDV Pass")
                idvCompleted = true;
            else if (el.name === "Documents" && el.candidateStatus === "Documents Complete")
                docCompleted = true;
            else if (el.name === "Insurance Upload" && el.candidateStatus === "Insurance Upload Complete")
                insCompleted = true;
            else if (el.name === "Payment Agreement" && el.candidateStatus === "Payment Agreement Complete")
                earningsCompleted = true;
            else if (el.name === "Personal Details" && el.candidateStatus === "Personal Details Complete")
                personalDetailsCompleted = true;
        })

        if (to.path === "/signup/earningsAccount") {
            const earningMethodActive = (await proxyApi.post('/isEarningMethodActive'))?.data?.active;
            earningsCompleted = earningMethodActive ? true : earningsCompleted;
        }
        
        switch (true) {
            case (to.path === "/signup/documents" && docCompleted):
            case (to.path === "/signup/insuranceUpload" && insCompleted):
            case (to.path === "/signup/earningsAccount" && earningsCompleted):
            case (to.path === "/signup/confirmation" && idvCompleted):
            case (to.path === "/signup/personalDetails" && personalDetailsCompleted):
                return next("/login");
            case (to.path === "/signup/personalDetails"):
            case (to.path === "/signup/confirmation"):
            case (to.path === "/signup/documents" && idvCompleted):
            case (to.path === "/signup/insuranceUpload" && docCompleted):
            case (to.path === "/signup/earningsAccount" && insCompleted):
            case (["/settings", "/profile", "/documents/personalDocuments", "/documents/tax", "/earnings"].includes(to.path) && earningsCompleted):
                return next();
            default:
                return next("login");
        }
    } catch (error) {
        return next("login");
    }
    
  } else{
    return next("login");
  }
};
