<template>
  <div v-if="html" style="padding: 15px">
    <div v-if="html" class="inline-links text-right">
      <a @click="exportToPDF" class="action-link">
        <svg v-svg="'icon-download-2'" size="0 0 18 18"></svg>
        Download
      </a>
    </div>
    <h2>
      <strong>{{ title }}</strong>
    </h2>
    <div v-if="html">
      <div
        ref="pdfContent"
        :style="{
          backgroundColor: 'white',
          padding: '15px',
          marginBottom: '10px',
        }"
      >
        <div v-html="html"></div>
      </div>
    </div>
  </div>
  <div v-else class="sign-loader">
    <Loader />
  </div>
  <div class="module" style="padding: 15px">
    <div v-if="state.user.signature" class="signature-wrap">
      <div class="signature-container">
        <div class="signature">{{ state.user.signature }}</div>
      </div>
      <!-- <p>
        <a @click="createSignature(values, validate)">Edit Signature </a>
      </p> -->
    </div>
    <div v-else class="signature-wrap">
      <p class="signature-container">
        <a class="full-width text-center" @click="createSignature(values, validate)">
          Create Signature
        </a>
      </p>
    </div>
    <p v-if="signatureError">Signature is required</p>

    <div class="button-group">
      <button class="button button-ghost btnL" @click="$router.back()">
        BACK
      </button>
      <button class="button" style="margin-left:2%" @click="saveDocument">
        SIGN
      </button>
    </div>
    <signature @signature="storeSignature" />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import html2pdf from "html2pdf.js";
import { Signature } from "@/components";
import { state } from "@/harlem/stores";
import Loader from "@/components/modals/Loader.vue";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from '@/util/DataEncodeDecode';
import handlebars from "handlebars";
import { gTagEvent } from "@/util/GoogleAnalytics";
import { setStatus } from "@/services/stage.service";

export default defineComponent({
  name: "SignDocument",
  components: { Signature, Loader },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const pdfOptions = reactive({});
    const isSigned = ref(false);
    const userIP = ref("");
    const documentContents = ref("");
    const documentTemplateID = ref("");
    const title = ref("");
    const html = ref("");
    const signatureError = ref(false);
    const signature = ref("")
    const pageTimeStart = ref(0)

    let docId = {id: route.params.id}
    proxyApi.post("/getDocHtmlOnboard", encodeWithParam(docId)).then(({ data }) => {
      if (data.success) {
        let sign=""
        if(state.user.signature){
          sign=state.user.signature
        }
        else if(state.mergeTags.partner.signature){
          sign =state.mergeTags.partner.signature
        }
        else{
          sign=""
        }
        let htmlDoc = data.result[0].html
        let myHtml = htmlDoc.replace(/(<style[\w\W]+style>)/g, "");
        myHtml = myHtml.replaceAll('{{partner.signature}}', `<span  style="font-family: La Belle Aurore; font-style: italic; font-weight: 900; font-size: 20px;">${sign}</span>`);
        html.value = handlebars.compile(myHtml)(state.mergeTags);
      //   let partner={
      //   date:data.result[0]?.signedOn,
      //   partner:{
      //     signature:data.result[0]?.signature
      //   }
      // }
      //   html.value = handlebars.compile(htmlDoc)(partner);
      }
    })

    // Get Signature
    const storeSignature = (data) => {
       signature.value = data.trim();
    };

    // Save Signed Document
    const saveDocument = async () => {
      let nameOfTheFile = ""
      let contentOfTheFile = ""
      let obj = {
        documentTemplateID: route.params.id,
        signature: state.user.signature,
        documentContents: html.value,
        uploadedFileContent: {fileName: nameOfTheFile ?  nameOfTheFile : null, 
        fileContent: contentOfTheFile ? contentOfTheFile  : null}
      }
      proxyApi.post("/insSignedDocuments",obj).then(({ data }) => {
        if (data.success) { 
          router.push({ name: 'documents' })
        }
      })
      gTagEvent(`Submit Document: ${route.params.id}`, "Documents", `partner_id: ${state.user.partnerId}`, true, `${Math.round((performance.now() - pageTimeStart.value)/1000)} seconds`)
    };
    
    const pdfContent = ref("");
    const exportToPDF = () => {
      html2pdf(pdfContent.value, {
        margin: [0.3, 0.4],
        filename: "document.pdf",
        image: { type: "pdf", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
    };
    onMounted(() => {
      pageTimeStart.value = performance.now()
      // refetch();
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          userIP.value = ip;
        });
    });

   
    const createSignature = () => {
      setStatus("SIGNATURE", "INCOMPLETE")
      router.push({name: 'createsignature'})
    };
    return {
      state,
      signatureError,
      createSignature,
      storeSignature,
      userIP,
      documentTemplateID,
      exportToPDF,
      pdfContent,
      pdfOptions,
      documentContents,
      // ToSignDocument,
      saveDocument,
      isSigned,
      html,
      title,
    };
  },
});
</script>

<style scoped>
.button-group{
  display: flex;
  justify-content: center;
}
.sign-loader {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 290px) and (max-width: 599px) {
	.btnL{
    margin-left: 1% !important;
  }
}
</style>
