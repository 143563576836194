import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid-spaceBetween dashboard_ONE-status-card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWalmartX = _resolveComponent("IconWalmartX")!

  return (!_ctx.isLoading && !_ctx.isClosed)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(_ctx.toastBody), 1),
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeToast && _ctx.closeToast(...args)))
        }, [
          _createVNode(_component_IconWalmartX)
        ])
      ]))
    : _createCommentVNode("", true)
}