import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "dashboard-progress_header welcomeText" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "dashboard_update-status" }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { class: "card_body-group" }
const _hoisted_8 = { class: "funnel-progress_card" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "funnel-progress_card" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 5 }
const _hoisted_16 = { key: 6 }
const _hoisted_17 = {
  key: 1,
  class: "loader-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCelebrate = _resolveComponent("IconCelebrate")!
  const _component_PendingEarningMessage = _resolveComponent("PendingEarningMessage")!
  const _component_banners = _resolveComponent("banners")!
  const _component_funnel_toast = _resolveComponent("funnel-toast")!
  const _component_funnel_icon = _resolveComponent("funnel-icon")!
  const _component_getting_started_Resources = _resolveComponent("getting-started-Resources")!
  const _component_on_hold_faq = _resolveComponent("on-hold-faq")!
  const _component_loader = _resolveComponent("loader")!

  return (_ctx.isLoading == false)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, [
          (_ctx.isShowCongratsStatus)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createTextVNode(" Congrats, " + _toDisplayString(_ctx.firstName) + " ", 1),
                _createVNode(_component_IconCelebrate)
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, " Welcome, " + _toDisplayString(_ctx.firstName), 1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, " Zone: " + _toDisplayString(_ctx.partnerLocation), 1)
        ]),
        _createVNode(_component_PendingEarningMessage, {
          payStartDate: _ctx.payStartDate,
          pendingPayment: _ctx.pendingPayment
        }, null, 8, ["payStartDate", "pendingPayment"]),
        (_ctx.payeeID == 'null')
          ? (_openBlock(), _createBlock(_component_banners, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.showPaymentSetUp)
          ? (_openBlock(), _createBlock(_component_funnel_toast, {
              key: 1,
              "toast-body": `Your ${_ctx.currentPayment} is set up.`
            }, null, 8, ["toast-body"]))
          : _createCommentVNode("", true),
        (_ctx.showPaymentFailed)
          ? (_openBlock(), _createBlock(_component_funnel_toast, {
              key: 2,
              "toast-body": `Your ${_ctx.currentPayment} is set up.`
            }, null, 8, ["toast-body"]))
          : _createCommentVNode("", true),
        (_ctx.isDashboardPage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardBodyArray, (cardBody) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: cardBody.title,
                  class: "card_body-group"
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_funnel_icon, {
                          icon: cardBody.icon
                        }, null, 8, ["icon"])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.isMobile() ? 'card_body' : 'card_body grid-row grid-spaceBetween')
                      }, [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", {
                            class: "card_title",
                            style: _normalizeStyle({ 'color': cardBody.titleColor })
                          }, _toDisplayString(cardBody.title), 5),
                          (_ctx.card.subtitle != null)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "card_tag",
                                style: _normalizeStyle({
                  'color': cardBody.subtitleColor,
                  'background': cardBody.subtitleBkgdColor
                })
                              }, _toDisplayString(cardBody.subtitle), 5))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", {
                            class: "card_inner_text",
                            innerHTML: cardBody.description
                          }, null, 8, _hoisted_9)
                        ]),
                        (cardBody.buttonText != null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createElementVNode("button", {
                                onClick: ($event: any) => (_ctx.handleButtonLink(cardBody.buttonLink)),
                                class: _normalizeClass(_ctx.isMobile() ? 'card_inner button status-btn_margin' : 'card_inner button')
                              }, _toDisplayString(cardBody.buttonText), 11, _hoisted_11)
                            ]))
                          : _createCommentVNode("", true)
                      ], 2)
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.isDashboardPage && _ctx.card.title != '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 4,
              class: _normalizeClass(_ctx.partnerStatus!='DRIVER_DEACTIVATED' ? 'card_body-group' : 'card_body-group-deactivated')
            }, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", null, [
                  _createVNode(_component_funnel_icon, {
                    icon: _ctx.card.icon,
                    color: _ctx.card.iconColor ? _ctx.card.iconColor : null
                  }, null, 8, ["icon", "color"])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.isMobile() ? 'card_body' : 'card_body grid-row grid-spaceBetween')
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      class: "card_title",
                      style: _normalizeStyle({ 'color': _ctx.card.titleColor })
                    }, _toDisplayString(_ctx.card.title), 5),
                    (_ctx.card.subtitle != null)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "card_tag",
                          style: _normalizeStyle({
              'color': _ctx.card.subtitleColor,
              'background': _ctx.card.subtitleBkgdColor
            })
                        }, _toDisplayString(_ctx.card.subtitle), 5))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "card_inner_text",
                      innerHTML: _ctx.card.description
                    }, null, 8, _hoisted_13)
                  ]),
                  (_ctx.card.buttonText != null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createElementVNode("button", {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleButtonLink(_ctx.card.buttonLink))),
                          class: _normalizeClass(_ctx.isMobile() ? 'card_inner button status-btn_margin' : 'card_inner button')
                        }, _toDisplayString(_ctx.card.buttonText), 3)
                      ]))
                    : _createCommentVNode("", true)
                ], 2)
              ])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.showrFaq)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_getting_started_Resources)
            ]))
          : _createCommentVNode("", true),
        (_ctx.showrFaq)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createVNode(_component_on_hold_faq)
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_ctx.funnelProgressEmpty == false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createVNode(_component_loader)
        ]))
      : _createCommentVNode("", true)
}