
import { defineComponent, provide, reactive, readonly } from "vue";
import csv from "csvtojson";
import { FAQModel, QAEntry } from "@/modules/faq/FAQModel";
const testString = `section_code,section_title,question,answer,link,feature
general,General FAQ,What does DDI do for me?,"DDI will refer you to a Marketing Partner in your local geographic area. DDI will manage your weekly accounting, itemizing your income and expenses while administering weekly wire deposits into your bank account. DDI technology will manage the administrative duties of your business – the enrollments process, auto-insurance and drivers’ license renewals, motor vehicle reports, etc. Most importantly, our Entrepreneurial Academy will systematically educate you to the ins and outs of running your own business.",,TRUE
general,,What does my Marketing Partner do for me?,"Your Marketing Partner will source customers for your business via print, online, social media and email marketing campaigns. Its state of the art technology will send you delivery jobs during the hours you’re available straight to your smart phone. Accept the jobs you want and execute them.",,TRUE
general,,What happens if I'm in an accident while working?,"Occupational accident insurance is available for driver-partners in the event driver is injured on the job. To submit a claim, fill out the driver incident report form, which is also available inside the driver portal.",,TRUE
general,,How do I make money?,"For each job you accept and complete, your customer will compensate you with a delivery or service fee and a gratuity.",,TRUE
general,,What does it cost to use DDI?,DDI charges its clients a percentage of their gross delivery fees. Rates range from 10-15% depending on market.,,TRUE
general,,Are my rates negotiable?,"Yes, The rates you pay your marketing firm are always negotiable!",,TRUE`;
export default defineComponent({
  name: "FAQHome",
  setup() {
    const fullFAQ: FAQModel[] = reactive([]);
    const featuredFAQ: QAEntry[] = reactive([]);
    //do not use from file! use from string or from stream!!
    csv()
      .fromString(testString)
      .then((json: any) => {
        json.forEach((line: any) => {
          const categoryObj = fullFAQ.find(
            (item: any) => item.sectionCode === line.section_code
          );
          if (categoryObj) {
            categoryObj.faqs.push({
              question: line.question,
              answer: line.answer,
              link: line.link,
              feature: line.feature,
            });
          } else {
            fullFAQ.push({
              sectionCode: line.section_code,
              sectionTitle: line.section_title,
              faqs: [
                {
                  question: line.question,
                  answer: line.answer,
                  link: line.link,
                  feature: line.feature,
                },
              ],
            });
          }
          if (line.feature.toUpperCase() === "TRUE") {
            featuredFAQ.push({
              question: line.question,
              answer: line.answer,
              link: line.link,
              feature: line.feature,
            });
          }
        });
      });
    provide("FullFAQ", readonly(fullFAQ));
    provide("FeaturedFAQ", readonly(featuredFAQ));
    return {};
  },
});
