
import { defineComponent, ref, watch, onMounted } from "vue";
import { IconSupport, IconSupportCircle } from "@/components";
import checklistMethods from "../modules/signup/components/Checklist/Helpers/Index";
import { useRoute, useRouter } from "vue-router";
import { sendToOracle } from "./OracleButtonHelper";
import { proxyApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { FunnelStatus as driverFunnelStatus } from "@/util/FunnelStatus";

export default defineComponent({
  name: "OracleButton",
  components: { IconSupport, IconSupportCircle },
  setup() {
    let currentRoute = useRoute()
    const isAuthenticated = !!localStorage.getItem("access_token");
    const isDisabled = ref(false);
    const router = useRouter();
    const flag = ref(false);
    const iconFlag = ref(false);
    const funnelProgress = ref();
    const sessionData: any = sessionStorage.getItem("ddi:user");
    const candidate = JSON.parse(sessionData);
    const supportButton = async () => {
      isDisabled.value = true;
      const res = await sendToOracle();
      if (res) {
        setTimeout(() => {
          isDisabled.value = false;
          window.open(res, "_blank");
        });
      }
    };
    const reRouteSupport = async () => {
      if (!checkIsShowResourcesSection()) {
        supportButton();
      } else {
        const { path } = currentRoute;
        if (path.includes("support")) {
          supportButton();
        }
        else {
          router.push({ name: 'support' });
        }
      }
    };
     onMounted(async ()=>{
      if(currentRoute.meta.step == 'branch' || currentRoute.meta.step == 'DD'){
        flag.value = true;
        iconFlag.value = true;
      }
      await setFunnelProgressData();
      await checkIsShowResourcesSection();
     })
   
    const setFunnelProgressData = async () => {
      let data = await proxyApi.post('/getFunnelProgress', encodeWithParam({
          candidateID: candidate?.locationPartnerId,
        })
      );
      funnelProgress.value = data?.data?.result;

    }

    const checkIsShowResourcesSection = () => {
      const hasNotApprovedOrWithdrawn = funnelProgress?.value?.filter(data =>
        [driverFunnelStatus.APPROVED_MANUAL, driverFunnelStatus.APPROVED_AUTO, driverFunnelStatus.WITHDRAWN].includes(data.candidateStatus)
      ).length == 0;
      return hasNotApprovedOrWithdrawn
    }
    const loginPages = () => {
      const { path } = currentRoute
      const isLoginPages = (path == "/login" || path == "/" || path.substring(0, 7) == "/forgot" || path.substring(0, 6) == "/reset" || path == "/password-success" || path == "/link-expired" || path == "/otp-verify")
      return isLoginPages
    }

    const onboardingPages = () => {
      const { path } = currentRoute
      const isOnboardingPages = (path == "/signup" || path == "/signup/verification" || path=="/signup/locationUpdate" || path=="/faq/OnHoldFaq")
      return isOnboardingPages
    }

    const onePages = () => {
      const { path } = currentRoute
      const isOnePages = (path.substring(0, 4) == "/one" && path.toLowerCase() !== "/one/onevalueprop")
      return isOnePages
    }

    const showContactSupport = () =>{
     const showContactSupport= (sessionStorage.getItem("EnrollmentonHold")=="true");
     return showContactSupport
     }

    const isShownInPage = () => {
      return (loginPages() || onePages() || onboardingPages() || showContactSupport()) == false
    }

    const isMobile = () => {
      return checklistMethods.isMobile();
    };

    const btnLocationBasedOnPage = () => {
      let className = "";
      const { path } = currentRoute
      if (path == "/login" || path == "/" || path.substring(0, 7) == "/forgot" || path.substring(0, 6) == "/reset" || path == "/password-success" || path == "/link-expired" || path == "/otp-verify") {
        className = 'oracle-login'
      } else if (path.substring(0, 7) == "/signup" && isAuthenticated) {
        className = 'oracle-onboarding'
      } else if (path == "/dashboard") {
        className = 'oracle-dashboard'
      } else if (path.toLowerCase() == "/one/onevalueprop") {
        className = 'oracle-one'
      }

      return className;
    }

    return { iconFlag,flag,isMobile, btnLocationBasedOnPage, currentRoute, sendToOracle, isAuthenticated, isShownInPage, supportButton, isDisabled, router, reRouteSupport, checkIsShowResourcesSection,showContactSupport }
  },
})
