
import { computed, defineComponent, inject } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "FAQSubdir",
  setup() {
    const route = useRoute();
    const fullFaq = inject("FullFAQ", []);
    const filteredFaqs = computed(() =>
      fullFaq.filter(
        (category: any) => category.section_code === route.params["subdir"]
      )
    );
    return { filteredFaqs };
  },
});
