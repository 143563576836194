<template>
  <div id="one-share-details" class="modal" aria-hidden="true">
    <div
      class="modal-window_overlay policy"
      tabindex="-1"
    >
      <div
        class="modal-window_container share-details-wrapper"
        role="dialog"
        aria-modal="true"
        aria-labelledby="aria-labelledby"
      >
        <div class="icon-group">
          <div>
            <IconBlueInfo />
          </div>
          <div @click="agreeContinue" class="icon-blue-close">
            <IconBlueClose />
          </div>
        </div>
        <share-details type="ONE" />
        <div class="confirm">
          <input type="checkbox" name="confirmOne" id="confirmOne" v-model="isConfirmed">
          <label for="confirmOne">I authorize Walmart to initiate credit entries, and, if necessary, to initiate debit entries to correct any erroneous entries for direct deposits or other amounts to the ONE Account on a recurring basis until I notify Walmart that I revoke the authorization for the ONE account.<br><br> If approved, ONE will be set as my primary earnings account, and earnings will be deposited in the next earnings cycle.</label>
        </div>
        <p class="field-error" v-if="urlNotfound">Something went wrong</p>
        <button class="button" @click="handleClick" :disabled="isConfirmed ? false : true" :class="isConfirmed ? '' : 'disabled'">CONTINUE</button>
      </div>
    </div>
  </div>
</template>

<script>
import { proxyApi } from '@/http/http.config'
import "../../assets/css/components/modals.scss"
import { IconBlueInfo, IconBlueClose } from "@/components";
import ShareDetails from "@/components/modals/ShareDetails.vue";
import { gTagEvent } from '@/util/GoogleAnalytics';
import { state } from '@/harlem/stores';

export default {
  name: "OneShareDetails",
  emits: ["accept"],
  components: { 
    ShareDetails,
    IconBlueInfo, 
    IconBlueClose
  },
  data() {
    return {
      urlNotfound: false,
      isConfirmed: false
    }
  },
  methods: {
    agreeContinue() {
      document.body.style.overflow = 'auto'
      this.$emit("accept");
    },
    handleClick() {
      document.body.style.overflow = 'auto'
      this.urlNotfound = false;
      proxyApi.get("/activateOneAccount").then((data) => {
        if (data.data.redirect_url) {
          document.location.href = data.data.redirect_url
        } else{
          this.urlNotfound=true
        }
      })
    },
    mounted() {
      gTagEvent("View ONE Share Details Popup", "Earnings", `partner_id: ${state.user.partnerId}`)
    }
  },
};
</script>

<style scoped>
.modal-window_container {
  max-width: 375px;
  border-radius: 12px 12px 0 0;
}

.share-details-wrapper {
  padding: 15px;
}

.share-details-wrapper > .icon-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-bottom: 10px;
}

.share-details-wrapper .header {
  font-size: 24px;
  color: #3171FF;
  margin-bottom: 10px;
}

.share-details-wrapper .text-wrapper {
  margin-bottom: 20px;
}

.share-details-wrapper .description {
  margin-bottom: 10px;
  line-height: 1.5em;
}

.share-details-wrapper .bulletpoint {
  line-height: 1em;
  font-size: 14px;
}

.icon-blue-close {
  cursor: pointer;
}

.confirm {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 21px;
}

.confirm input {
  width: 18px;
}

.confirm label {
  font-size: 13px;
  color: #3B4459;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

a {color: #3171FF; text-decoration-line: underline; text-decoration-color: #3171FF;}
</style>
