import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d2934bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "whitelabel-header_wrapper"
}
const _hoisted_2 = { class: "whitelabel-header_left" }
const _hoisted_3 = { class: "right-header-group" }
const _hoisted_4 = { class: "pointer" }
const _hoisted_5 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portal_header = _resolveComponent("portal-header")!
  const _component_IconSparkDriverLogo = _resolveComponent("IconSparkDriverLogo")!
  const _component_oracle_button = _resolveComponent("oracle-button")!
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_flyout_menu = _resolveComponent("flyout-menu")!
  const _component_portal_footer = _resolveComponent("portal-footer")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showHeader)
      ? (_openBlock(), _createBlock(_component_portal_header, {
          key: 0,
          onMenuopen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setMenuState(true))),
          id: "header"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("main", null, [
      (_ctx.showWhiteLabeHead)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["whitelabel-header_container", _ctx.showCloseBtn ? 'customNavbar' : ''])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_IconSparkDriverLogo)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_oracle_button),
                (_ctx.showLoginBtn)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args))),
                      class: "button header-signin"
                    }, "Sign in"))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_4, [
                  (_ctx.showCloseBtn && !_ctx.showLoginBtn)
                    ? (_openBlock(), _createBlock(_component_icon_close, {
                        key: 0,
                        onClick: _ctx.login
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 2)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["overlay", { 'show-nav': _ctx.showNav }]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setMenuState(false)))
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["container", { 'show-nav': _ctx.showNav }])
      }, [
        _createVNode(_component_flyout_menu, {
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setMenuState(false))),
          paymentAgreementComplete: _ctx.paymentAgreementComplete
        }, null, 8, ["paymentAgreementComplete"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createElementVNode("div", {
                class: _normalizeClass(["main-component", _ctx.containerHeight])
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ]),
                  _: 2
                }, 1024))
              ], 2),
              _createVNode(_component_portal_footer)
            ]),
            _: 1
          })
        ])
      ], 2)
    ])
  ], 64))
}