<template>
<div class="error-page-container">
  <div class="module-large">
  <h4>
    You have completed your onboarding process
  </h4>
  <img class="ddi-branch-logo" src="@/assets/alert-octagon_l.png" alt="DDI and Branch"/>
  </div>

  <div class="module-large">
  <p>
    Onboarding process is complete and contact us through support button on the right corner if you haven't been able to claim the branch wallet</p>
  </div>
  </div>
  </template>
  <style scoped>
  .error-page-container{
    margin: 10px;
  }
  h4{
    text-align: center;
  }
  .error-msg{
    font-weight: 700;
    font-size: 30px;
  }
  p{
    text-align: center;
    font-size: 16px;
  }
  </style>