
import { defineComponent, ref, Ref, onMounted,computed } from "vue";
import InitChecklist from "../components/Checklist/InitChecklist.vue";
import checklistMethods from "../components/Checklist/Helpers/Index";
import DateDropDown from "@/components/forms/DateDropDown.vue";
import { proxyApi } from "@/http/http.config";
import { IconWarningOctagon } from "@/components";
import { state } from "@/harlem/stores";
import router from "@/router";
import { useRoute } from "vue-router";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import { FunnelStatus } from "@/util/FunnelStatus";
import { gTagEvent } from "@/util/GoogleAnalytics";
import VuePdfEmbed from 'vue-pdf-embed';


export default defineComponent({
  name: "InsuranceUpload",
  components: {
        InitChecklist,
        DateDropDown,
        IconWarningOctagon,
        VuePdfEmbed
  },
  methods: {
    isMobile() {
      return checklistMethods.isMobile();
    },
  },
  setup() {
    const route = useRoute(); 
    const fileURL: Ref<string | ArrayBuffer | null> = ref("");
    const previewImage = ref(false);
    const isPdfFile = ref(false);
    const errorMessage = ref("");
    const expiryDate = ref("");
    const insureTempId = ref("");
    const previewFile: Ref<File | null> = ref(null);
    const applicationId = route.query.applicationId;
    const locationID = route.query.locationID;

    const errorMessageComp = computed(() => {
      return errorMessage.value;
    });

    const uploadImage=(event:any)=> {
      const input: HTMLInputElement = event.target;
      let acceptedFormat = ["jpg", "png", "jpeg", "gif","pdf"];
      isPdfFile.value = false;
      if(input.files) {
        let nameOfFile = input.files.item(0)?.name;
        // let fileFormat = input.files.item(0)?.name.split(".")[1].toLowerCase();
        let fileFormat = nameOfFile?.substring(nameOfFile.lastIndexOf(".")+1);
        let format = acceptedFormat.some((o) => fileFormat?.includes(o));
        let fileSize: number = input.files.item(0)?.size || 0;
        if(format)
        {
          if(fileSize < 4194304 && fileSize && format) {
            previewFile.value = event.target.files[0]
            isPdfFile.value = fileFormat === "pdf";
            fileURL.value = URL.createObjectURL(event.target.files[0]);
            previewImage.value = true;
            errorMessage.value = "";
          } else {
          errorMessage.value ="Image exceeds acceptable file size"
          }
        }
        else{
          errorMessage.value ="Not a valid file"
        }
      } else {
        errorMessage.value="Not a valid file"
      }
    }

    const expirydateValidate =(e: any)=>{
      expiryDate.value = e;
      if(isFutureDate(e)) {
          errorMessage.value=""
      } else {
            const datePattern = /\d\d\d\d-\d\d-\d\d/g;
            if(datePattern.test(e))
            errorMessage.value="Please select future date"
      }
    }

    const isFutureDate = (dateVal)=> {
      const g1 = new Date();
      const g2 = new Date(dateVal);
      if (g1.getTime() < g2.getTime()) return true;
      else if (g1.getTime() > g2.getTime()) return false;
    }

  const submit = ()=>{
    if(fileURL.value) {
		  if(expiryDate.value && isFutureDate(expiryDate.value)) {
			      errorMessage.value="";
            const formData = new FormData();
            let type = "ocr";
            let isUploadInsurance="true";
            formData.append("file", previewFile.value || "");
            formData.append("documentTemplateID", insureTempId.value);
            formData.append("signature", state.user.signature);
            formData.append("type", type);
            formData.append("originalname",previewFile.value?.name || "insurance image");
            formData.append("acl", "public");
            formData.append("insuranceExpiry",expiryDate.value);
            formData.append("addInsuranceInfo",isUploadInsurance);
            proxyApi.post("/uploadFile", formData).then(async()=>{

				    //await proxyApi.post('/sendBranchMagicLinkBySMS')

             await proxyApi.post(
            "/addApplicationStatus",
            encodeWithParam({status: FunnelStatus.INSURANCE_UPLOAD_COMPLETE}))
              gTagEvent("Complete Insurance Upload", "Insurance Upload", `partner_id: ${state.user.partnerId}`)
                if (checklistMethods.isMobile()) {
                  router.push({
                    name: "checklist",
                    params: { step: "earningsAccount" },
                    query: {locationId: state.user.locationId},
                  });
                } else {
                  router.push({
                  name: "earningsAccount",
                  query: {
                  applicationId: applicationId,
                  locationID: locationID,
                  },
                });
              }
			      })
		} else {
          errorMessage.value="Insurance Date is not valid or expired"
      }
		} else { 
			  errorMessage.value="Upload the Insurance";
		  }
	 }
		
    onMounted(()=>{
        proxyApi.get("/getOnBoardingDocuments").then(({ data }) => {
        if (data.success) {
          data.result.map((item) => {
            if (item.title == "Insurance") {
              insureTempId.value = item.id;
            }
          });
        }
      });
    })

    return {
      uploadImage,
      previewImage,
      fileURL,
      isPdfFile,
      previewFile,
      errorMessageComp,
      expiryDate,
      expirydateValidate,
      submit
    };
  },
});
