import { RouteRecordRaw } from "vue-router";
import stepValidationGuard from "@/router/stepValidationGuard";

export default [
  {
    path: "/documents",
    name: "dashboardDocuments",
    component: () => import("../Documents.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  {
    path: "/documents/:id",
    name: "document",
    component: () => import("../Document.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  {
    path: "/documents/signed",
    name: "signedDocuments",
    component: () => import("../SignedDocuments.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  {
    path: "/documents/resubmit/:id",
    name: "resubmitDocument",
    component: () => import("../ResubmitDocument.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  {
    path: "/documents/personalDocuments",
    name: "personalDocuments",
    beforeEnter: stepValidationGuard,
    component: () => import("../PersonalDocuments.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  {
    path: "/documents/personalDocuments/editInsurance",
    name: "editInsurance",
    component: () => import("../EditInsurance.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  {
    path: "/documents/personalDocuments/editDriversLicense",
    name: "editDriversLicense",
    component: () => import("../EditDriversLicense.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  {
    path: "/documents/tax",
    name: "taxDocuments",
    beforeEnter: stepValidationGuard,
    component: () => import("../TaxDocuments.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  // {
  //   path: "/documents/tax/1099KWalmart",
  //   name: "1099KWalmart",
  //   component: () => import("../1099KWalmart.vue"),
  //   meta: { title: "Delivery Drivers Inc", noHeader: false },
  // },
  {
    path: "/documents/tax/NECDocument",
    name: "NECDocument",
    beforeEnter: (to, from,next) => {
      if (from.name=='taxDocuments') {
        next();
      } else {
        next({name: "taxDocuments"});
      }
    },
    component: () => import("../NECDocument.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  {
    path: "/documents/signed/W9Document",
    name: "W9Document",
    component: () => import("../W9Document.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
  // {
  //   path: "/documents/tax/1099KDDI",
  //   name: "1099KDDI",
  //   component: () => import("../1099KDDI.vue"),
  //   meta: { title: "Delivery Drivers Inc", noHeader: false },
  // },
  {
    path: "/documents/:id/versions",
    name: "documentVersions",
    component: () => import("../DocumentVersions.vue"),
    meta: { title: "Delivery Drivers Inc", noHeader: false },
  },
] as Array<RouteRecordRaw>;
