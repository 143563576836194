import authenticationService from "@/services/authentication.service";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
// import http from "@/config/http.config";

import { generatePublicAuthToken } from "@/util/DataEncodeDecode";
const sessionToken = sessionStorage.getItem("accesstoken");
const accessToken = localStorage.getItem("access_token");
const baseConfig: AxiosRequestConfig = {
  baseURL: config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
    withCredentials: true,
  },
};
const basicAuthConfig: AxiosRequestConfig = {
  baseURL:config.VUE_APP_PROXY_BASE_URL || process.env.VUE_APP_PROXY_BASE_URL,
};

const signupConfig: AxiosRequestConfig = {
  baseURL: config.VUE_APP_PROXY_BASE_URL || process.env.VUE_APP_PROXY_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
const authConfig: AxiosRequestConfig = {
  baseURL: config.VUE_APP_AUTH_BASE_URL || process.env.VUE_APP_AUTH_BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
    "auth-type": "password",
    withCredentials: true,
  },
};
const fileConfig: AxiosRequestConfig = {
  baseURL: config.VUE_APP_API_BASE_URL || process.env.VUE_APP_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    withCredentials: true,
  },
};
const proxyConfig: AxiosRequestConfig = {
  baseURL: config.VUE_APP_PROXY_BASE_URL || process.env.VUE_APP_PROXY_BASE_URL,
  headers: {
    authorization: `${accessToken}`,
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  }
}

const restConfig: AxiosRequestConfig = {
  baseURL: config.VUE_APP_REST_BASE_URL || process.env.VUE_APP_REST_BASE_URL,
  headers: {
    authorization: `${accessToken}`,
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  }
}

const kycConfig: AxiosRequestConfig = {
  baseURL: config.VUE_APP_KYC_BASE_URL || process.env.VUE_APP_KYC_BASE_URL,
  headers: {
    authorization: `${accessToken}`,
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  }
}



const baseAPI: AxiosInstance = axios.create(baseConfig);
const signupAPI: AxiosInstance = axios.create(signupConfig);
const authAPI: AxiosInstance = axios.create(authConfig);
const fileAPI: AxiosInstance = axios.create(fileConfig);
const proxyAPI: AxiosInstance = axios.create(proxyConfig);
const restAPI: AxiosInstance = axios.create(restConfig);
const kycAPI: AxiosInstance = axios.create(kycConfig);
const basicAuthAPI = axios.create(basicAuthConfig);

signupAPI.interceptors.request.use(config => {
  const token = generatePublicAuthToken();
  if (token) {
    config.headers['client-token'] = token;
  }
  return config;
}, error => Promise.reject(error));

baseAPI.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    // if (error.config && error?.response?.status === 401) {
    //   authenticationService.refreshToken().then(() => {
    //     error.config.headers.authorization =
    //       localStorage.getItem("access_token");
    //     return axios.request(error.config);
    //   });
    // }
    if (error.config && error?.response?.status === 500) {
      // TODO: Should probably log these somewhere like datadog
    }
    return Promise.reject(error);
  }
);

baseAPI.interceptors.request.use((request: AxiosRequestConfig) => {
  if (localStorage.getItem("access_token")) {
    request.headers.Authorization = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
  }
  request.withCredentials = true;
  request.headers["Access-Control-Allow-Origin"] = "*";
  request.headers.Accept = "*/*";

  return request;
});

proxyAPI.interceptors.request.use((request: AxiosRequestConfig) => {
  if (localStorage.getItem("access_token")) {
    request.headers.authorization = `${localStorage.getItem("access_token")}`;
  }
  //request.withCredentials = true;
  request.headers["Access-Control-Allow-Origin"] = "*";
  request.headers.Accept = "*/*";
  return request;
});

restAPI.interceptors.request.use((request: AxiosRequestConfig) => {
  if (localStorage.getItem("access_token")) {
    request.headers.authorization = `${localStorage.getItem("access_token")}`;
  }
  //request.withCredentials = true;
  request.headers["Access-Control-Allow-Origin"] = "*";
  request.headers.Accept = "*/*";
  return request;
});

kycAPI.interceptors.request.use((request: AxiosRequestConfig) => {
  if (localStorage.getItem("access_token")) {
    request.headers.authorization = `${localStorage.getItem("access_token")}`;
  }
  //request.withCredentials = true;
  request.headers["Access-Control-Allow-Origin"] = "*";
  request.headers.Accept = "*/*";
  return request;
})

export const anonymousApi = axios.create({});
export const api: AxiosInstance = baseAPI;
export const signup: AxiosInstance = signupAPI;
export const authApi: AxiosInstance = authAPI;
export const fileApi: AxiosInstance = fileAPI;
export const proxyApi: AxiosInstance = proxyAPI;
export const restApi: AxiosInstance = restAPI;
export const kycApi: AxiosInstance = kycAPI;
export const basicAuth = basicAuthAPI;
