<template>
<div class="error-page-container">
  <div class="module-large">
    <img class="ddi-branch-logo" src="@/assets/Trophy.png" alt="DDI and Branch" />
  </div>
  <div class="module-large">
  <h4 class="error-msg">Thank you !! <br> Your Signup Process has been completed.</h4>
  </div>
  <div class="module-large">
  <p>
    <b>IMPORTANT:</b> Click below to check the status of your application. Use your Walmart username and password at anytime to log back in and check where you are in the process at any time later on.
    You will be notified via email and text when your application is complete or if we need additional information.
    </p>
  </div>
    <button @click="login" class="button next-button">SIGN-In TO PROCEED</button>
  </div>
  </template>
  <script>
  import authenticationService from "@/services/authentication.service";
  import router from "@/router";
  export default {
    methods:{
        login(){
            authenticationService.logout();
            router.push({ name: "login" });
        }
    }

  }
  
  
  </script>
  <style scoped>
  .error-page-container{
    margin: 10px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
  }
  h4{
    text-align: center;
  }
  p{
    text-align: center;
    font-size: 16px;
  }
  </style>